import React, {useState,useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyDatePicker from '../MyDatePicker';
import MyAutoComplete from '../MyAutoComplete';
import MyAlert from '../MyAlert';
import {GetMessage} from "../../Modules/Message";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import {isDate,GetToken,GetElencoStatoDip,GetRole, GetIDResponsabile} from "../../Modules/Funzioni";
import { Ruolo_Azienda } from '../../Modules/Costanti';
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewDipendente (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [Ruolo, setRuolo] = React.useState("");
    const [Sesso, setSesso] = React.useState("");
    const [StatoImpiego, setStatoImpiego] = React.useState("");
    const [CodNazioneNascita, setCodNazioneNascita] = React.useState(0);
    const [NazioneNascita, setNazioneNascita] = React.useState("");
    const [IDAzienda, setIDAzienda] = React.useState("");
    const [Azienda, setAzienda] = React.useState("");
    const [Nome, setNome] = React.useState("");
    const [Cognome, setCognome] = React.useState("");
    const [CodFiscale, setCodFiscale] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    const [Indirizzo, setIndirizzo] = React.useState("");
    const [ComuneResidenza, setComuneResidenza] = React.useState("");
    const [CodComuneResidenza, setCodComuneResidenza] = React.useState("");
    const [ComuneNascita, setComuneNascita] = React.useState("");
    const [CodComuneNascita, setCodComuneNascita] = React.useState("");
    const [DataNascita, setDataNascita] = React.useState("");
    const [Note, setNote] = React.useState("");
    let UserRole =GetRole(localStorage.getItem("token"));
    let User_id ="";
    if (UserRole===Ruolo_Azienda)
    {
        User_id=GetIDResponsabile(localStorage.getItem("token"));
    }
        

    const handleAzienda = (value,text) => {
        setIDAzienda(value);
        setAzienda(text);  
    }; 
    const handleRuolo = (event) => {  
        setRuolo(event.target.value);  
    }; 
    const handleSesso = (event) => {  
        setSesso(event.target.value);  
    };
    const handleStatoImpiego = (event) => {  
        setStatoImpiego(event.target.value);  
    }; 
    const handleNome = (event) => {  
        setNome(event.target.value);  
    }; 
    const handleCognome = (event) => {  
        setCognome(event.target.value);  
    }; 
    const handleCodFiscale = (event) => {  
        setCodFiscale(event.target.value);  
    }; 
    const handleEmail = (event) => {  
        setEmail(event.target.value);  
    }; 
    const handleCellulare = (event) => {  
        setCellulare(event.target.value);  
    }; 
    const handleIndirizzo = (event) => {  
        setIndirizzo(event.target.value);  
    }; 
    const handleComuneResidenza = (value,text) => {
        setCodComuneResidenza(value);
        setComuneResidenza(text);  
    }; 
    const handleNazioneNascita = (value,text) => {
        setCodNazioneNascita(value);
        setNazioneNascita(text);  
    }; 
    const handleComuneNascita = (value,text) => {
        setCodComuneNascita(value);
        setComuneNascita(text);  
    }; 
    const handleComuneEsteroNascita = (event) => {  
        setComuneNascita(event.target.value); 
        setCodComuneNascita(0); 
    }; 
    const handleDataNascita = (value) => {  
        setDataNascita(value);  
    };
    const handleNote = (event) => {  
        setNote(event.target.value);  
    }; 
    
    
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    function ValidationCodFiscale () {
        let ValMancante="";
        if (Sesso==="")
        {
            ValMancante +="Sesso, "
        }

        if (Nome==="")
        {
            ValMancante +="Nome, "
        }

        if (Cognome==="")
        {
            ValMancante +="Cognome, "
        }

        if (CodNazioneNascita==="")
        {
            ValMancante +="Nazione Nascita, "
        }

        if (CodNazioneNascita!==0 && CodNazioneNascita!==""){
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("Il calcolo del codice fiscale può essere effettuato solo per nazionalità italiane!");
            setOpenAlert(true);
            return false;
        }

        if (CodComuneNascita==="")
        {
            ValMancante +="Comune Nascita, "
        }

        if (isDate(DataNascita)!=="")
        {
            ValMancante +="Data Nascita, "
        }

        if (ValMancante!==""){
            //elimina l'ultima virgola
            ValMancante=ValMancante.substring(0,ValMancante.length-2)
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("Valori mancanti nei seguenti campi: " + ValMancante);
            setOpenAlert(true);
            return false;
        }

        return true;
        
    }

    const CalcolaCodFiscale= (event) => 
        {
            
            if (ValidationCodFiscale()) {
                setIsLoading(true); 
                const fd = new FormData();
                fd.append("Sesso", Sesso);
                fd.append("Nome", Nome);
                fd.append("Cognome", Cognome);
                fd.append("CodComuneNascita", CodComuneNascita);
                fd.append("DataNascita", DataNascita);
                
                const token = GetToken(localStorage.getItem("token"));
                const ax= axios;
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                        
                ax
                .post(process.env.REACT_APP_API_URL+'calcolacodicefiscale', fd,axiosConfig)
                .then((response) => {
                    
                    if (response.status === 200) {
                        if (response.data.error==="OK"){
                            setCodFiscale(response.data.CodiceFiscale);
                            setSeverityAlert("success");
                            setVerticalAlert("center");
                            setMsgAlert("Calcolo del Codice Fiscale esguito correttamente!");
                            setOpenAlert(true);
                        }
                        else {
                            
                            setSeverityAlert("error");
                            setVerticalAlert("center");
                            setMsgAlert("Errore durante il calcolo del codice fiscale!");
                            setOpenAlert(true);
                        }
                    }
                    else
                    { 
                    const msg=GetMessage(response.status,response.data.message);
                    setSeverityAlert("error");
                    setVerticalAlert("center");
                    setMsgAlert(msg);
                    setOpenAlert(true);
                    }
                })
                .catch((error) => {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning")
                    setVerticalAlert("center")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                });
                setIsLoading(false); 
            } 
        }

        const submitForm= (event) => 
        {
            if (validator.current.allValid()) {
                setisSubmit(true);
                const fd = new FormData();
                fd.append("Azienda_id", IDAzienda);
                fd.append("Sesso", Sesso);
                fd.append("StatoImpiego", StatoImpiego);
                fd.append("Ruolo", Ruolo);
                fd.append("Nome", Nome);
                fd.append("Cognome", Cognome);
                fd.append("CodFiscale", CodFiscale);
                fd.append("Email", Email);
                fd.append("Cellulare", Cellulare);
                fd.append("Indirizzo", Indirizzo);
                fd.append("CodComuneResidenza", CodComuneResidenza);
                fd.append("DesComuneResidenza", ComuneResidenza);
                fd.append("CodNazioneNascita", CodNazioneNascita);
                fd.append("CodComuneNascita", CodComuneNascita);
                fd.append("DesComuneNascita", ComuneNascita);
                fd.append("DataNascita", DataNascita);
                fd.append("Note", Note);
                
                    const token = GetToken(localStorage.getItem("token"));
                        const ax= axios;
                        ax.defaults.headers.common['Authorization']="Bearer " + token;
                        const axiosConfig = {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                        
                        ax
                        .post(process.env.REACT_APP_API_URL+'dipendenteinsert', fd,axiosConfig)
                        .then((response) => {
                            
                            if (response.status === 200) {
                                if (response.data.error==="OK"){
                                    props.handleClose();
                                    props.onInsert();
                                }
                                else {
                                    let msg="";
                                    let ris=JSON.stringify(response.data);
                                    ris=ris.replaceAll("\"", "");
                                    ris=ris.replaceAll("{", "");
                                    ris=ris.replaceAll("}", "");
                                    ris=ris.replaceAll("[", "");
                                    ris=ris.replaceAll("]", "");
                                    msg="errore durante l'inserimento! " + ris;
                                    setSeverityAlert("error")
                                    setVerticalAlert("top")
                                    setMsgAlert(msg);
                                    setOpenAlert(true);
                                }
                            }
                            else
                            { 
                            const msg=GetMessage(response.status,response.data.message);
                            setSeverityAlert("error")
                            setVerticalAlert("top")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                            
                            }
                        })
                        .catch((error) => {
                            let msg=""
                            if (error.response!==undefined) {
                                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                            }
                            else
                            {
                                msg=GetMessage(undefined,"");
                            }
                            setSeverityAlert("error")
                            setVerticalAlert("top")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                            
                        });
                        setisSubmit(false);

            } else {
                validator.current.showMessages();
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(MsgAlertDefault);
                //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
                setOpenAlert(true);
            }
        }
 
    return (
        <div>
            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>     
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.bgHead}>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Inserisci Dipendente
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitForm}>
                            Salva {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <form autoComplete ="new-password">
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col xl={6} md={5}>
                                                <FormGroup>
                                                    <Label for="txtAzienda">Azienda *</Label>
                                                    <MyAutoComplete id="txtAzienda" name="txtAzienda" 
                                                        url={process.env.REACT_APP_API_URL+'customersinglelistbyresp'} 
                                                        optionname="RagioneSociale" 
                                                        optionvalue="id"
                                                        onBlur={() => validator.current.showMessageFor('Azienda')}
                                                        customvalue={Azienda} 
                                                        onChange={handleAzienda}
                                                        idparent={User_id}
                                                    />
                                                    {validator.current.message('Azienda', Azienda, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} md={3}>
                                                <FormGroup>
                                                    <Label for="txtRuolo">Ruolo *</Label>
                                                    <Input type="text" name="txtRuolo" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Ruolo')}
                                                    value={Ruolo} onChange={handleRuolo}
                                                    />
                                                    {validator.current.message('Ruolo', Ruolo, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col xl={3} md={2}>
                                                <FormGroup>
                                                    <Label for="selStatoImpiego">Stato *</Label>
                                                    <Input type="select" name="selStatoImpiego" id="selStatoImpiego" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('StatoImpiego')}
                                                    value={StatoImpiego} onChange={handleStatoImpiego}
                                                    >
                                                        <option key=""></option>
                                                        {GetElencoStatoDip().map((item) => (
                                                            <option key={item.stato_value} value={item.stato_value}>{item.stato_name}</option>
                                                        ))}
                                                    </Input>
                                                    {validator.current.message('StatoImpiego', StatoImpiego, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col xl={1} md={2}>
                                                <FormGroup>
                                                    <Label for="selSesso">Sesso *</Label>
                                                    <Input type="select" name="selSesso" id="selSesso" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Sesso')}
                                                    value={Sesso} onChange={handleSesso}
                                                    >
                                                        <option key=""></option>
                                                        <option key="M" value="M">M</option>
                                                        <option key="F" value="F">F</option>
                                                    </Input>
                                                    {validator.current.message('Sesso', Sesso, 'required|alpha', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label for="txtNome">Nome *</Label>
                                                    <Input type="text" name="txtNome" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Nome')}
                                                    value={Nome} onChange={handleNome}
                                                    />
                                                    {validator.current.message('Nome', Nome, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label for="txtCognome">Cognome *</Label>
                                                    <Input type="text" name="txtCognome" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Cognome')}
                                                    value={Cognome} onChange={handleCognome}
                                                    />
                                                    {validator.current.message('Cognome', Cognome, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtDataNascita">D. Nascita *</Label>
                                                    <MyDatePicker name="txtDataNascita" 
                                                    onBlur={() => validator.current.showMessageFor('DataNascita')}
                                                    value={DataNascita} onChange={handleDataNascita} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataNascita', DataNascita, 'required|data', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                        <Col lg={4} md={3}>
                                                <FormGroup>
                                                    <Label for="txtNazioneNascita">Nazione Nascita *</Label>
                                                    <MyAutoComplete id="txtNazioneNascita" name="txtNazioneNascita" 
                                                    url={process.env.REACT_APP_API_URL+'nazioni'} 
                                                    optionname="DesNazione" optionvalue="CodNazione"
                                                    onBlur={() => validator.current.showMessageFor('NazioneNascita')}
                                                    customvalue={NazioneNascita} onChange={handleNazioneNascita}
                                                    />
                                                    {validator.current.message('NazioneNascita', NazioneNascita, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col lg={4} md={3}>
                                                <FormGroup>
                                                    <Label for="txtComuneNascita">Comune *</Label>
                                                    {CodNazioneNascita> 0 && (
                                                    <Input type="text" name="txtComuneNascitaEstero" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('ComuneNascita')}
                                                    value={ComuneNascita} onChange={handleComuneEsteroNascita}
                                                    />)}
                                                    {CodNazioneNascita=== 0 && (
                                                    <MyAutoComplete id="txtComuneNascita" name="txtComuneNascita" 
                                                    url={process.env.REACT_APP_API_URL+'comuni'} 
                                                    optionname="Comune" optionvalue="CodComune"
                                                    onBlur={() => validator.current.showMessageFor('ComuneNascita')}
                                                    customvalue={ComuneNascita} onChange={handleComuneNascita}
                                                    />)}
                                                    {validator.current.message('ComuneNascita', ComuneNascita, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3} md={4}>
                                                <FormGroup>
                                                    <Label for="txtCodiceFiscale">Codice Fiscale *</Label>
                                                    <Input type="text" name="txtCodiceFiscale" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('CodFiscale')}
                                                    value={CodFiscale} onChange={handleCodFiscale}
                                                    />
                                                    {validator.current.message('CodFiscale', CodFiscale, 'required|alpha_num|min:16', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col lg={1} md={2}>
                                                <FormGroup>
                                                    <Label>&nbsp;</Label>
                                                    <Button type="button" className="btn-mbg btn-small btn-block" onClick={CalcolaCodFiscale} >Calcola</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtIndirizzo">Indirizzo Residenza</Label>
                                                <Input type="text" name="txtIndirizzo" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('Indirizzo')}
                                                value={Indirizzo} onChange={handleIndirizzo}
                                                />
                                                {validator.current.message('Indirizzo', Indirizzo, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtComuneResidenza">Comune</Label>
                                                <MyAutoComplete id="txtComuneResidenza" name="txtComuneResidenza" url={process.env.REACT_APP_API_URL+'comuni'} optionname="Comune" optionvalue="CodComune"
                                                onBlur={() => validator.current.showMessageFor('ComuneResidenza')}
                                                customvalue={ComuneResidenza} onChange={handleComuneResidenza}
                                                />
                                                {validator.current.message('ComuneResidenza', ComuneResidenza, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row> 
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                        <Col md={8}>
                                                <FormGroup>
                                                    <Label for="txtEmail">Email</Label>
                                                    <Input type="email" name="txtEmail" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Email')}
                                                    value={Email} onChange={handleEmail}
                                                    />
                                                    {validator.current.message('Email', Email, 'email', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtCellulare">Cellulare</Label>
                                                    <Input type="text" name="txtCellulare" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                    onBlur={() => validator.current.showMessageFor('Cellulare')}
                                                    value={Cellulare} onChange={handleCellulare}
                                                    />
                                                    {validator.current.message('Cellulare', Cellulare, 'cphone', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="txtNote">Note [max 200 caratteri]</Label>
                                                    <Input type="textarea" name="txtNote" id="txtNote" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('Note')}
                                                    value={Note} onChange={handleNote} maxLength="200"
                                                    />
                                                    {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
        