import React, { useEffect,useRef,useState } from "react";
import MaskedInput from 'react-text-mask'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
function getTime(myTime) {
  myTime=myTime+ '';
  let mtxTime=myTime.split(":");
  
  let nHours = mtxTime[0];
  let nMinutes = mtxTime[1];
  let nTime;
  if (isNaN(parseFloat(nHours)) || isNaN(parseFloat(nMinutes))) return "";
  
  
  if (parseFloat(nHours) > 24) {
    nHours = '00';
  }

  if (parseFloat(nMinutes) > 60) {
    nMinutes = '00';
  }

  /*if (nHours < 10) {
    nHours = '0' + nHours;
  }

  if (nMinutes < 10) {
    nMinutes = '0' + nMinutes;
  }*/

  nTime = nHours + ':' + nMinutes;
  return nTime;
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM');

//const MyCustomInput = ({ value, onClick }) => (
  

 
//);

export default function MyDatePicker(props) {
  const inputRef = useRef()
  const [height, setHeight] = useState(0)

  let otherclass="";
  if (props.className!==undefined){
    otherclass=props.className + " ";
  }
  
  useEffect(() => {
    setHeight(inputRef.current.clientHeight)
  })

  const onhandleChange = (event) =>{

    let valueTime=event.target.value.replaceAll("_","");
    if (valueTime.length===5){
      let selectedTime=getTime(valueTime);
      props.onChange(selectedTime);
    }
    else {
      if (valueTime===""){
        props.onChange("");
      }
    }
  }

  return (
  <div ref={inputRef} className={otherclass + "form-group"}>
    <MaskedInput
        className={otherclass + "form-control"}
        pipe={autoCorrectedDatePipe}
        mask={[/\d/, /\d/, ':', /\d/, /\d/]}
        keepCharPositions= {true}
        placeholder="hh:mm"
        guide = {true}
        value={props.value}
        disabled={props.disabled===true}
        onChange={onhandleChange}
    />
    <QueryBuilderIcon color="primary" style={{float: 'right',marginRight: '6px', marginTop: '-' + (height-parseInt(height/7)) +'px'  , position: 'relative', zIndex: '0'}}/>
  </div>
  );
}
