export function GetMessage(CodError,DesError) {
    let Message="";
        if (CodError!==""){
            switch (CodError)
            {
                case 401: {
                        switch (DesError)
                        {
                            case "Unauthorized":
                                {
                                    Message="Nome Utente o password non validi!";
                                    break;
                                }
                                
                            case "Unauthenticated.":
                                {
                                    Message="Sessione Scaduta, eseguire il log-out e rifare l'autenticazione!";
                                    break;
                                }
                            case undefined:
                                {
                                    Message="Errore durante la connessione con il server!";
                                    break;
                                }
                            case null:
                                {
                                    Message="Errore durante la connessione con il server!";
                                    break;
                                }
                            case "":
                                {
                                    Message="Errore durante la connessione con il server!";
                                    break;
                                }
                            default:
                                {
                                    Message=DesError;
                                    break;
                                }
                        }
                    break;
                }
                case 500: {
                    Message="Problemi di connessione con il server!";
                    break;
                }
                case 404: {
                    Message="La risorsa non è stata trovata sul server!";
                    break;
                }
                case undefined: {
                    Message="Errore di rete!";
                    break;
                }
                default:{
                    Message=DesError;
                    break;
                }
            }            
        }
        else {
            Message=DesError;
        }
        if (Message==="") {Message="Errore di sviluppo, contattare i fornitori!";}
        return Message;
}