import React from "react";
import NumericInput from 'react-numeric-input';
import MyAlert from './MyAlert';

export default function MyInputNumber(props) {
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const [ValueNum,setValueNum]=React.useState("");
  const onCloseAlert = (event) => {
      setOpenAlert(false);
  };
  //------------
  const NumMax=(props.numMax!==undefined)?(props.numMax):(999);
 
   const handleChange=(_value)=>{
      let _num=1;
      
      if ((!(_value > 0)) || isNaN(_value) || (!(parseInt(_value) === _value))) {
        setMsgAlert("Sono ammessi solo valori numerici interi maggiori di zero.");
        setOpenAlert(true);
      }
      else {
          if (parseInt(_value)>NumMax || parseInt(_value)<1){
            setMsgAlert("Il valore deve essere compreso tra 1 e 999.");
            setOpenAlert(true);
            _num=1;
          }
          else
          {
            _num=parseInt(_value);
          }
          setValueNum(_num);
          props.onChange(_num,props.otherparam);
      }
      
   }
   React.useEffect(() => {
      setValueNum(props.value);
  }, [props.value]);
  return (
    <div>
      <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/>     
      <NumericInput
      className="form-control-sm form-control"
      value={ValueNum}
      onChange={handleChange}
      precision={0}
      size={2}
      step={1}
      min={1}
      max={NumMax}
      mobile={true}
      disabled={props.disabled!=null ? (props.disabled):false}
      readOnly={props.readOnly!=null ? (props.readOnly):false}
      style={{
          wrap: {
              background: '#E2E2E2',
              /*boxShadow: '0 0 1px 1px #fff inset, 1px 1px 5px -1px #000',*/
              /*padding: '2px 2.26ex 2px 2px',*/
              borderRadius: '6px 3px 3px 6px',
              width: (() => {return props.width!=null ? (props.width):('90px')})()
              /*fontSize: 8*/
          },
          input: {
              borderRadius: '4px 2px 2px 4px',
              color: '#000',
              /*padding: '0.1ex 0.1ex',*/
              border: '1px solid #ccc',
              marginRight: 4,
              display: 'block',
              fontWeight: 100,
              /*textShadow: '1px 1px 1px rgba(0, 0, 0, 0.1)',*/
              width:(() => {return props.width!=null ? (props.width):('90px')})(),
              textAlign:'center'
              /*padding: '0.1ex 0.1ex 0.1ex 0.1ex'*/
          },
          'input:focus' : {
              border: '1px inset #69C',
              outline: 'none'
          },
          arrowUp: {
              borderBottomColor: 'rgba(66, 54, 0, 0.63)'
          },
          arrowDown: {
              borderTopColor: 'rgba(66, 54, 0, 0.63)'
          }
      }}/>
    </div>
  );
}
