import React, {useRef,useState,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import {Col,Row,Input,FormGroup,Label,Button} from "reactstrap";
import MyAlert from '../../Components/MyAlert';
import {GetMessage} from "../../Modules/Message";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import {GetToken} from "../../Modules/Funzioni";
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import { RadioGroup } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import MyDatePicker from '../../Components/MyDatePicker';
import MyAutoComplete from '../../Components/MyAutoComplete';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    table: {
        minWidth: 400,
    },
    containerMin: {
        maxHeight: 220,
        minHeight: 220,
    },
    container: {
        maxHeight: 330,
        minHeight: 330,
    },
    FormPopup: {
        maxWidth: "900px !important",
        minWidth: "800px !important",
      },
      HeadSection: {
        backgroundColor:"#074873",
        textAlign:"center",
        fontWeight:"bold",
        color:"#fff",
        height:"30px",
        fontSize:"14pt",
        minHeight: "40px",
        zIndex: 3,
        position: "sticky",
        top: 0,
        left: 0,
    },HeadSticky:{
        top: "40px",
        left: 0,
        zIndex: 2,
        position: "sticky",
        backgroundColor:"#fff",
        borderBottom: "1px solid #011e87 !important",
        fontWeight: "bold !important",
    },
      BtnEdit: {
        '&:hover':{
            cursor:"pointer"
        }  
    }
}));
 
function DialogConfirmImpianto(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [TipoVerifica, setTipoVerifica] = React.useState("");

    const handleTipoVerifica =(event) => {
        setTipoVerifica(event.target.value);
    }

    const handleConfirm =() => {
        props.onConfirm(TipoVerifica);
    }
    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={true} 
          onClose={props.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Selezione Tipo Verifica</DialogTitle>
          <DialogContent className="form-popup">
            <DialogContentText>
              <Row>
                  <Col md={12}>
                      <FormGroup>
                        <Input type="select" name="selTipoVerifica" id="selTipoVerifica" placeholder=""
                        value={TipoVerifica} onChange={handleTipoVerifica}
                        >
                            <option key="1" value="1">Verifica messa a terra</option>
                            <option key="2" value="2">Verifica maggior rischio esplosione</option>
                            <option key="3" value="3">Verifica Entrambi</option>
                            <option key="4" value="4">Verifica solo scariche atmosferiche</option>
                        </Input>
                      </FormGroup>
                  </Col>
              </Row>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
              <div style={{width:"50%"}}>
                  <Button  onClick={handleConfirm} className="btn-mbg-cert btn-block">
                      Conferma
                  </Button>
              </div>
              <div style={{width:"50%"}}>
                  <Button onClick={props.onClose} className="btn-block">
                      Annulla
                  </Button>
              </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

export default function NewOrdineImpianto (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    const [isSubmit, setisSubmit] = React.useState(false);
     //alert object
     const [OpenAlert, setOpenAlert] = React.useState(false);
     const [VerticalAlert, setVerticalAlert] = React.useState("top");
     const [SeverityAlert, setSeverityAlert] = React.useState("error");
     const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
     const onCloseAlert = (event) => {
         setOpenAlert(false);
     };
    
     //------------
    const [CloseForm, setCloseForm] = React.useState(false);
    const [IDImpianto, setIDImpianto] = React.useState(0);
    const VerificaImpianto = true;
    const [isLoading, setIsLoading] = useState(true);
    const [isNext, setisNext] = React.useState(false);
    const [AddNewImp, setAddNewImp] = React.useState(false);
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [data, setData] = useState([]);
    
    const [NumCabine, setNumCabine] = React.useState("");
    const [NumPod, setNumPod] = React.useState("");
    const [Superficie, setSuperficie] = React.useState("");
    const [Indirizzo, setIndirizzo] = React.useState("");
    const [Comune, setComune] = React.useState("");
    const [CodComune, setCodComune] = React.useState("");
    const [DescrizioneAttivita, setDescrizioneAttivita] = React.useState("");
    const [DataUltimaVerifica, setDataUltimaVerifica] = React.useState("");
    const [DataOmologazione, setDataOmologazione] = React.useState("");
    const [DataChiusuraPrev, setDataChiusuraPrev] = React.useState("");
    const [DataInstallazione, setDataInstallazione] = React.useState("");
    const [Tensione, setTensione] = React.useState("");
    const [SistDistribuzione, setSistDistribuzione] = React.useState("");
    const [PotenzaKw, setPotenzaKw] = React.useState("");
    const [AnniValidita, setAnniValidita] = React.useState("");
    const [IDPersVerifica, setIDPersVerifica] = React.useState("");
    const [TipoAmbiente, setTipoAmbiente] = React.useState("");
    const [IDTipoAmbiente, setIDTipoAmbiente] = React.useState("");
    const [OpenDialogConfirm, setOpenDialogConfirm] = React.useState(false);
    
    
    const handleTipoAmbiente = (value,text) => {
        var arrValue = value.split("|");
        setIDTipoAmbiente(arrValue[0]);
        setAnniValidita(arrValue[1]);
        setIDPersVerifica(arrValue[2]);
        setTipoAmbiente(text);  
    }; 

    const handleDescrizioneAttivita = (event) => {  
        setDescrizioneAttivita(event.target.value);  
    }; 
    const handlePotenzaKw = (event) => {  
        setPotenzaKw(event.target.value);  
    }; 
    const handleDataInstallazione = (value) => {  
        setDataInstallazione(value);  
    }; 
    const handleDataOmologazione = (value) => {  
        setDataOmologazione(value);  
    }; 
    const handleDataUltimaVerifica = (value) => {  
        setDataUltimaVerifica(value);  
    }; 
    
    const handleDataChiusuraPrev = (value) => {  
        setDataChiusuraPrev(value);  
    }; 
    const handleIndirizzo = (event) => {  
        setIndirizzo(event.target.value);  
    }; 
    const handleSistDistribuzione = (event) => {  
        setSistDistribuzione(event.target.value);  
    }; 
    const handleComune = (value,text) => {
        setCodComune(value);
        setComune(text);  
    }; 
    const handleTensione = (event) => {  
        setTensione(event.target.value);  
    }; 
    const handleNumCabine = (event) => {  
        setNumCabine(event.target.value);  
    }; 
    const handleNumPod = (event) => {  
        setNumPod(event.target.value);  
    }; 
    const handleSuperficie = (event) => {  
        setSuperficie(event.target.value);  
    }; 
    
    const handleChangeImpianto = (event) => {
        setIDImpianto(parseFloat(event.target.value));
        setAddNewImp(false);
    };
    const handleAddNewImp = (event) => {
        setAddNewImp(!AddNewImp);
        if (event.target.checked){
            setIDImpianto(0);
        }
        
      };

    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    const onNext = () => {
        if (VerificaImpianto){
            if (AddNewImp){
                if (validator.current.allValid()) {
                    const fd = new FormData();
                    fd.append("Azienda_id", localStorage.getItem("idazienda"));
                    fd.append("DataInstallazione", DataInstallazione);
                    fd.append("Indirizzo", Indirizzo);
                    if (CodComune!=null){
                        fd.append("CodComune", CodComune);    
                    }
                    else {
                        fd.append("CodComune", 0);
                    }
                    fd.append("DesComune", Comune);
                    fd.append("PotenzaKw", PotenzaKw);
                    fd.append("SistemaDistribuzione", SistDistribuzione);
                    fd.append("TensioneAlimentazione", Tensione);
                    fd.append("NumeroCabine", NumCabine);
                    fd.append("NumPod", NumPod);
                    fd.append("DescrizioneAttivita", DescrizioneAttivita);
                    fd.append("SuperficieLuogo", Superficie);
                    fd.append("DataOmologazione", DataOmologazione);
                    fd.append("TipoAmbiente_id", IDTipoAmbiente);
                    fd.append("PersVerifica_id", IDPersVerifica);
                    fd.append("DataPrevistaChiusura", DataChiusuraPrev);    
                    fd.append("DataChiusura", "");
                    fd.append("DataUltimaVerifica", DataUltimaVerifica);
                    fd.append("Note", "");
                    
                    handleConfirmNewImpianto(fd);
                } else {
                    validator.current.showMessages();
                    setSeverityAlert("error")
                    setMsgAlert(MsgAlertDefault);
                    setVerticalAlert("top");
                    setOpenAlert(true);
                }
            }
            else{
                if (IDImpianto>0){
                    localStorage.setItem("idimpianto",IDImpianto)
                    setOpenDialogConfirm(true);
                }
                else
                {
                    setSeverityAlert("warning");
                    setMsgAlert("Per proseguire è necessario scegliere un impianto esistente, oppure aggiungerne uno nuovo!");
                    setVerticalAlert("top");
                    setOpenAlert(true);
                }
            }
            
        }
        else {
            localStorage.setItem("idimpianto","0")
            setisNext(true);
        }
    }
   
    const LoadElencoImpianti = () => {
        if (localStorage.getItem("idazienda")!==""){
            setIsLoading(true); 
            const token =  GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{

                    let url=process.env.REACT_APP_API_URL+'impiantoellist';
                    const fd = new FormData();
                    fd.append("Azienda_id", localStorage.getItem("idazienda"));
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    result = await ax.post(url,fd,axiosConfig);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning")
                    setMsgAlert(msg);
                    setVerticalAlert("center");
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                if (result.data!==undefined)    {
                    setData(result.data);
                    if (result.data.length===0){
                        setAddNewImp(true);
                    }
                }
                else {
                    setData([]);
                }
            })();  
        }
    }

    useEffect(() => {    
        LoadElencoImpianti();
    }, []); 
    
    const handleConfirmNewImpianto = (fd) => {  
        setisSubmit(true); 
        const token =  GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                let url="";
                url=process.env.REACT_APP_API_URL+'impiantoelinsert';               
                
                result = await ax.post(url,fd,axiosConfig);
                if (result.status === 200) {
                    if (result.data.error==="OK"){
                        if (parseFloat(result.data.id)>0){
                            setIDImpianto(parseFloat(result.data.id));
                            localStorage.setItem("idimpianto",parseFloat(result.data.id))
                            setOpenDialogConfirm(true);
                        }
                        else{
                            setSeverityAlert("error");
                            setVerticalAlert("top");
                            setMsgAlert("Errore nella creazione dell'impianto!");
                            setOpenAlert(true);
                            setisSubmit(false);
                        }
                    }
                    else {
                        let msg="";
                        let ris=JSON.stringify(result.data);
                        ris=ris.replaceAll("\"", "");
                        ris=ris.replaceAll("{", "");
                        ris=ris.replaceAll("}", "");
                        ris=ris.replaceAll("[", "");
                        ris=ris.replaceAll("]", "");
                        msg="errore durante l'operazione! " + ris;
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                        setisSubmit(false);
                    }
                }
                else
                { 
                    const msg=GetMessage(result.status,result.data.message);
                    setSeverityAlert("error")
                    setVerticalAlert("top")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                    setisSubmit(false);
                }
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning");
                setVerticalAlert("center");
                setMsgAlert(msg);
                setOpenAlert(true);
                setisSubmit(false);
            }
            
            
        })();    
    };
    
    React.useEffect(() => {
        if (localStorage.getItem("idazienda")!==""){
            LoadDatiAzienda();
        }
    }, []);

    const LoadDatiAzienda = (event) => {
        if(localStorage.getItem("idazienda")!==""){
            setIsLoading(true); 
            const token =  GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'customer/' + localStorage.getItem("idazienda");               
                    result = await ax.post(url);
                    setRagioneSociale(result.data[0].RagioneSociale);
                    setEmail(result.data[0].Email);
                    //li svuota per sicurezza nel caso non passo da elenco ordini
                    localStorage.setItem("verificamessaterra","0");
                    localStorage.setItem("verificaexp","0");
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning");
                    setVerticalAlert("center");
                    setMsgAlert(msg);
                    setOpenAlert(true);
                }
                setIsLoading(false);    
                
            })();  
        }  
    }

    const onCloseForm = (event) => {
        setCloseForm(true);
    };

    const onConfirmTipoVerifica = (_TipoVerifica) => {
       
        switch (_TipoVerifica)
        {
            case "1":   //Verifica messa a terra
                {
                    localStorage.setItem("verificamessaterra","1");
                    localStorage.setItem("verificaexp","0");
                    setisNext(true);
                    break;
                }
            case "2":   //Verifica maggior rischio esplosione
                {
                    localStorage.setItem("verificamessaterra","0");
                    localStorage.setItem("verificaexp","1");
                    setisNext(true);
                    break;
                }
            case "3":   //Verifica Entrambi
                {
                    localStorage.setItem("verificamessaterra","1");
                    localStorage.setItem("verificaexp","1");
                    setisNext(true);
                    break;
                }
                case "4":   //sol oscariche atmosferiche
                {
                    localStorage.setItem("verificamessaterra","0");
                    localStorage.setItem("verificaexp","0");
                    setisNext(true);
                    break;
                }
            default:
                {
                    localStorage.setItem("verificamessaterra","1");
                    localStorage.setItem("verificaexp","0");
                    setisNext(true);
                    break;
                }
        }
        
    };

    const onCloseTipoVerifica = () => {
        setOpenDialogConfirm(false);
    };

    let urlRedirect="";
    
    if (CloseForm){
        if (localStorage.getItem("menunew")==="1")
        {
            urlRedirect="/home";
        }
        else {
            urlRedirect="/ordini";
        }
    }
    

    if (isNext && localStorage.getItem("idazienda") !==""){
        if ((VerificaImpianto && IDImpianto>0) || (!VerificaImpianto)) {
            urlRedirect="/nuovoordineservizi";    
        }
    }

    if (urlRedirect!==""){
        return <Redirect to={urlRedirect} />;
    }
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className="bg-head-form-cert">
                    <IconButton edge="start" color="inherit" onClick={onCloseForm} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Dettaglio Ordine - Impianto Elettrico
                    </Typography>
                    <MatButton autoFocus color="inherit" onClick={onNext} disabled={isSubmit}>
                        Avanti {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                    </MatButton>
                </Toolbar>
            </AppBar>
            <div className="page-container bg-form">
                <div className="content-wrap">
                    <form autoComplete ="new-password">
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <MyAlert 
                            message={MsgAlert} 
                            severity={SeverityAlert} 
                            handleClose={onCloseAlert} 
                            vertical={VerticalAlert} 
                            horizontal="right" 
                            open={OpenAlert}
                            />
                        {OpenDialogConfirm && (
                        <DialogConfirmImpianto
                        onConfirm={onConfirmTipoVerifica} 
                        onClose={onCloseTipoVerifica}
                        />)
                        }
                        <Grid container spacing={3} className="custom-form">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={7}>
                                            <FormGroup>
                                                <Label for="txtRagioneSociale">Ragione Sociale</Label>
                                                <Input type="text" name="txtRagioneSociale" autoComplete="new-password" placeholder=""
                                                value={RagioneSociale} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col md={5}>
                                            <FormGroup>
                                                <Label for="txtEmail">Email</Label>
                                                <Input type="text" name="txtEmail" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                value={Email} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            {/* <Row>
                                <Col md={12}>
                                    <FormControlLabel
                                        control={<Switch checked={VerificaImpianto} 
                                        onChange={handleVerificaImpianto} />}
                                        label="Abilita Verifica messa a terra e/o maggior rischio esplosione"
                                    />
                                </Col>
                            </Row> */}
                            { (data.length>0 && VerificaImpianto) && (
                                <>
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper} className={classes.container}>
                                            <Toolbar className={classes.HeadSection}>
                                                Elenco Impianti già presenti
                                            </Toolbar>
                                            <RadioGroup aria-label="gender" name="customized-radios" value={IDImpianto} onChange={handleChangeImpianto}>
                                                <Table className={classes.table} aria-label="spanning table" size="small">
                                                    <TableBody>
                                                        {
                                                            data.map((row) => {
                                                                
                                                                return (
                                                                    
                                                                <TableRow key={row.id} hover tabIndex={-1}>
                                                                    <TableCell style={{width:"5%"}}>
                                                                        <FormControlLabel value={row.id} control={<Radio />} label="" />
                                                                    </TableCell>
                                                                    <TableCell style={{width:"25%"}} >{row.DesComune}</TableCell>
                                                                    <TableCell style={{width:"25%"}}>{row.Indirizzo}</TableCell>
                                                                    <TableCell style={{width:"10%"}}>{row.DescAmbiente}</TableCell>
                                                                    <TableCell style={{width:"10%"}}>{row.PotenzaKw + ' KW'}</TableCell>
                                                                    <TableCell style={{width:"25%"}}>{row.DescrizioneAttivita}</TableCell>
                                                                </TableRow>
                                                                );
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </RadioGroup>
                                        </TableContainer>
                                    </Grid>
                                </>
                                )
                            }
                            {  VerificaImpianto && data.length>0 &&
                                (<Row>
                                    <Col md={12}>
                                        <FormControlLabel
                                            control={<Switch checked={AddNewImp} 
                                            onChange={handleAddNewImp} />}
                                            label="Aggiungi Nuovo Impianto"
                                        />
                                    </Col>
                                </Row>)
                            }
                            { (IDImpianto===0 && VerificaImpianto && AddNewImp) && 
                            (<>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtNumPod">N. Pod</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtNumPod" 
                                                    autoComplete="new-password" 
                                                    placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('NumPod')}
                                                    value={NumPod} 
                                                    onChange={handleNumPod}
                                                />
                                                {validator.current.message('NumPod', NumPod, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtDataInstallazione">Installato Il</Label>
                                                <MyDatePicker name="txtDataInstallazione" 
                                                onBlur={() => validator.current.showMessageFor('DataInstallazione')}
                                                value={DataInstallazione} onChange={handleDataInstallazione} autoComplete="new-password"
                                                />
                                                {validator.current.message('DataInstallazione', DataInstallazione, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtDataOmologazione">Omologato Il</Label>
                                                <MyDatePicker name="txtDataOmologazione" 
                                                onBlur={() => validator.current.showMessageFor('DataOmologazione')}
                                                value={DataOmologazione} onChange={handleDataOmologazione} autoComplete="new-password"
                                                />
                                                {validator.current.message('DataOmologazione', DataOmologazione, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtDataUltimaVerifica">Ultima Verifica</Label>
                                                <MyDatePicker name="txtDataUltimaVerifica" 
                                                onBlur={() => validator.current.showMessageFor('DataUltimaVerifica')}
                                                value={DataUltimaVerifica} onChange={handleDataUltimaVerifica} autoComplete="new-password"
                                                />
                                                {validator.current.message('DataUltimaVerifica', DataUltimaVerifica, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtDescrizioneAttivita">Tipo Attività</Label>
                                                <Input type="text" name="txtDescrizioneAttivita" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('DescrizioneAttivita')}
                                                value={DescrizioneAttivita} onChange={handleDescrizioneAttivita}
                                                />
                                                {validator.current.message('DescrizioneAttivita', DescrizioneAttivita, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtTipoAmbiente">Tipo Ambiente *</Label>
                                                <MyAutoComplete id="txtTipoAmbiente" name="txtTipoAmbiente" 
                                                url={process.env.REACT_APP_API_URL+'tipoambientiimplist'} 
                                                optionname="Descrizione" optionvalue="Codice"
                                                onBlur={() => validator.current.showMessageFor('TipoAmbiente')}
                                                customvalue={TipoAmbiente} onChange={handleTipoAmbiente}
                                                />
                                                {validator.current.message('TipoAmbiente', TipoAmbiente, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtPersValidita">Periodicità *</Label>
                                                <Input type="text" name="txtPersValidita" 
                                                disabled={true}
                                                autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('AnniValidita')}
                                                value={AnniValidita}
                                                />
                                                {/* <MyAutoComplete id="txtPersValidita" name="txtPersValidita" 
                                                url={process.env.REACT_APP_API_URL+'persverificheimplist'} 
                                                optionname="AnniValidita" optionvalue="id"
                                                onBlur={() => validator.current.showMessageFor('AnniValidita')}
                                                customvalue={AnniValidita} onChange={handleAnniValidita}
                                                /> */}
                                                {validator.current.message('AnniValidita', AnniValidita, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtPotenzaKw">Potenza Kw *</Label>
                                                <Input type="text" name="txtPotenzaKw" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('PotenzaKw')}
                                                value={PotenzaKw} onChange={handlePotenzaKw}
                                                />
                                                {validator.current.message('PotenzaKw', PotenzaKw, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="selSistDistribuzione">Categoria *</Label>
                                                <Input type="select" name="selSistDistribuzione" id="selSistDistribuzione" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('SistDistribuzione')}
                                                value={SistDistribuzione} onChange={handleSistDistribuzione}
                                                >
                                                    <option key=""></option>
                                                    <option key="0" value="0">TT</option>
                                                    <option key="1" value="1">TN</option>
                                                </Input>
                                                {validator.current.message('SistDistribuzione', SistDistribuzione, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtTensione">Tensione V *</Label>
                                                <Input type="text" name="txtTensione" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('Tensione')}
                                                value={Tensione} onChange={handleTensione}
                                                />
                                                {validator.current.message('Tensione', Tensione, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtNumCabine">N. Cabine *</Label>
                                                <Input type="text" name="txtNumCabine" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('NumCabine')}
                                                value={NumCabine} onChange={handleNumCabine}
                                                />
                                                {validator.current.message('NumCabine', NumCabine, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtSuperficie">Superficie *</Label>
                                                <Input type="text" name="txtSuperficie" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('Superficie')}
                                                value={Superficie} onChange={handleSuperficie}
                                                />
                                                {validator.current.message('Superficie', Superficie, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="txtIndirizzo">Indirizzo *</Label>
                                            <Input type="text" name="txtIndirizzo" autoComplete="new-password" placeholder=""
                                            onBlur={() => validator.current.showMessageFor('Indirizzo')}
                                            value={Indirizzo} onChange={handleIndirizzo}
                                            />
                                            {validator.current.message('Indirizzo', Indirizzo, 'string|required', { className: 'text-danger' })}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="txtComune">Comune *</Label>
                                            <MyAutoComplete id="txtComune" name="txtComune" url={process.env.REACT_APP_API_URL+'comuni'} optionname="Comune" optionvalue="CodComune"
                                            onBlur={() => validator.current.showMessageFor('Comune')}
                                            customvalue={Comune} onChange={handleComune}
                                            />
                                            {validator.current.message('Comune', Comune, 'string|required', { className: 'text-danger' })}
                                        </FormGroup>
                                    </Col>
                                </Row> 
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtDataChiusuraPrev">Data Chiusura Prevista</Label>
                                                <MyDatePicker name="txtDataChiusuraPrev" 
                                                onBlur={() => validator.current.showMessageFor('DataChiusuraPrev')}
                                                value={DataChiusuraPrev} onChange={handleDataChiusuraPrev} autoComplete="new-password"
                                                />
                                                {validator.current.message('DataChiusuraPrev', DataChiusuraPrev, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            </>)
                            }
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
}
        