import React, {useState,useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyDatePicker from '../MyDatePicker';
import MyAutoComplete from '../MyAutoComplete';
import MyAlert from '../MyAlert';
import {GetMessage} from "../../Modules/Message";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import {GetToken,GetRole} from "../../Modules/Funzioni";
import { Ruolo_Azienda } from '../../Modules/Costanti';
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModImpElettrico (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [IDAzienda, setIDAzienda] = React.useState("");
    const [Azienda, setAzienda] = React.useState("");
    const [NumCabine, setNumCabine] = React.useState("");
    const [NumPod, setNumPod] = React.useState("");
    const [Superficie, setSuperficie] = React.useState("");
    const [Indirizzo, setIndirizzo] = React.useState("");
    const [Comune, setComune] = React.useState("");
    const [CodComune, setCodComune] = React.useState("");
    const [DescrizioneAttivita, setDescrizioneAttivita] = React.useState("");
    const [DataUltimaVerifica, setDataUltimaVerifica] = React.useState("");
    const [DataOmologazione, setDataOmologazione] = React.useState("");
    const [DataChiusuraPrev, setDataChiusuraPrev] = React.useState("");
    const [DataChiusura, setDataChiusura] = React.useState("");
    const [DataInstallazione, setDataInstallazione] = React.useState("");
    const [Tensione, setTensione] = React.useState("");
    const [SistDistribuzione, setSistDistribuzione] = React.useState("");
    const [PotenzaKw, setPotenzaKw] = React.useState("");
    const [AnniValidita, setAnniValidita] = React.useState("");
    const [IDPersVerifica, setIDPersVerifica] = React.useState("");
    const [TipoAmbiente, setTipoAmbiente] = React.useState("");
    const [IDTipoAmbiente, setIDTipoAmbiente] = React.useState("");
    const [Note, setNote] = React.useState("");
    let UserRole =GetRole(localStorage.getItem("token"));
    
    const handleAzienda = (value,text) => {
        setIDAzienda(value);
        setAzienda(text);  
    }; 
    const handleTipoAmbiente = (value,text) => {
        var arrValue = value.split("|");
        setIDTipoAmbiente(arrValue[0]);
        setAnniValidita(arrValue[1]);
        setIDPersVerifica(arrValue[2]);
        setTipoAmbiente(text);  
    };  
 
    const handleDescrizioneAttivita = (event) => {  
        setDescrizioneAttivita(event.target.value);  
    }; 
    const handlePotenzaKw = (event) => {  
        setPotenzaKw(event.target.value);  
    }; 
    const handleDataInstallazione = (value) => {  
        setDataInstallazione(value);  
    }; 
    const handleDataOmologazione = (value) => {  
        setDataOmologazione(value);  
    }; 
    const handleDataUltimaVerifica = (value) => {  
        setDataUltimaVerifica(value);  
    }; 
    const handleDataChiusura = (value) => {  
        setDataChiusura(value);  
    }; 
    const handleDataChiusuraPrev = (value) => {  
        setDataChiusuraPrev(value);  
    }; 
    const handleIndirizzo = (event) => {  
        setIndirizzo(event.target.value);  
    }; 
    const handleSistDistribuzione = (event) => {  
        setSistDistribuzione(event.target.value);  
    }; 
    const handleComune = (value,text) => {
        setCodComune(value);
        setComune(text);  
    }; 
    const handleTensione = (event) => {  
        setTensione(event.target.value);  
    }; 
    const handleNumCabine = (event) => {  
        setNumCabine(event.target.value);  
    }; 
    const handleNumPod = (event) => {  
        setNumPod(event.target.value);  
    }; 
    const handleSuperficie = (event) => {  
        setSuperficie(event.target.value);  
    }; 
    const handleNote = (event) => {  
        setNote(event.target.value);  
    }; 
    
    React.useEffect(() => {
        if (props.id!==""){
            LoadData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);
    
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    const LoadData = (event) => {
        if(props.id!==""){
            setIsLoading(true); 
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'impiantoel/' + props.id;               
                    result = await ax.post(url);
                    setAzienda(result.data[0].RagioneSociale);
                    setIDAzienda(result.data[0].Azienda_id);
                    setDataInstallazione(result.data[0].DataInstallazione===null ? "": result.data[0].DataInstallazione);
                    setIndirizzo(result.data[0].Indirizzo);
                    setCodComune(result.data[0].CodComune);
                    setComune(result.data[0].DesComune);
                    setPotenzaKw(result.data[0].PotenzaKw);
                    setSistDistribuzione(result.data[0].SistemaDistribuzione);
                    setTensione(result.data[0].TensioneAlimentazione);
                    setNumCabine(result.data[0].NumeroCabine);
                    setNumPod(result.data[0].NumPod===null ? "": result.data[0].NumPod);
                    setDescrizioneAttivita(result.data[0].DescrizioneAttivita);
                    setSuperficie(result.data[0].SuperficieLuogo);
                    setDataOmologazione(result.data[0].DataOmologazione===null ? "": result.data[0].DataOmologazione);
                    setIDTipoAmbiente(result.data[0].TipoAmbiente_id);
                    setTipoAmbiente(result.data[0].DescAmbiente);
                    setIDPersVerifica(result.data[0].PersVerifica_id);
                    setAnniValidita(result.data[0].AnniValidita);
                    setDataChiusuraPrev(result.data[0].DataPrevistaChiusura===null ? "": result.data[0].DataPrevistaChiusura);
                    setDataChiusura(result.data[0].DataChiusura===null ? "": result.data[0].DataChiusura);
                    setDataUltimaVerifica(result.data[0].DataUltimaVerifica===null ? "": result.data[0].DataUltimaVerifica);
                    setNote(result.data[0].Note);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning")
                    setVerticalAlert("center")
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                
            })();  
        }  
    }


    const submitForm= (event) => 
    {
        if (validator.current.allValid()) {
            setisSubmit(true);
            const fd = new FormData();
            fd.append("id", props.id);
            fd.append("Azienda_id", IDAzienda);
            fd.append("DataInstallazione", DataInstallazione);
            fd.append("Indirizzo", Indirizzo);
            if (CodComune!=null){
                fd.append("CodComune", CodComune);    
            }
            else {
                fd.append("CodComune", 0);
            }
            fd.append("DesComune", Comune);
            fd.append("PotenzaKw", PotenzaKw);
            fd.append("SistemaDistribuzione", SistDistribuzione);
            fd.append("TensioneAlimentazione", Tensione);
            fd.append("NumeroCabine", NumCabine);
            fd.append("NumPod", NumPod);
            fd.append("DescrizioneAttivita", DescrizioneAttivita);
            fd.append("SuperficieLuogo", Superficie);
            fd.append("DataOmologazione", DataOmologazione);
            fd.append("TipoAmbiente_id", IDTipoAmbiente);
            fd.append("PersVerifica_id", IDPersVerifica);
            fd.append("DataPrevistaChiusura", DataChiusuraPrev);
            fd.append("DataChiusura", DataChiusura);
            fd.append("DataUltimaVerifica", DataUltimaVerifica);
            fd.append("Note", Note);
            
                const token = GetToken(localStorage.getItem("token"));
                    const ax= axios;
                    ax.defaults.headers.common['Authorization']="Bearer " + token;
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    
                    ax
                    .post(process.env.REACT_APP_API_URL+'impiantoelupdate', fd,axiosConfig)
                    .then((response) => {
                        
                        if (response.status === 200) {
                            if (response.data.error==="OK"){
                                props.handleClose();
                                props.onUpdate();
                            }
                            else {
                                let msg="";
                                let ris=JSON.stringify(response.data);
                                ris=ris.replaceAll("\"", "");
                                ris=ris.replaceAll("{", "");
                                ris=ris.replaceAll("}", "");
                                ris=ris.replaceAll("[", "");
                                ris=ris.replaceAll("]", "");
                                msg="errore durante la modifica! " + ris;
                                setSeverityAlert("error")
                                setVerticalAlert("top")
                                setMsgAlert(msg);
                                setOpenAlert(true);
                            }
                        }
                        else
                        { 
                        const msg=GetMessage(response.status,response.data.message);
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                        
                        }
                    })
                    .catch((error) => {
                        let msg=""
                        if (error.response!==undefined) {
                            msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                        }
                        else
                        {
                            msg=GetMessage(undefined,"");
                        }
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                        
                    });
                    setisSubmit(false);

        } else {
            validator.current.showMessages();
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert(MsgAlertDefault);
            //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
            setOpenAlert(true);
        }
    }
 
    return (
        <div>
            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>     
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className="bg-head-form-cert">
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Anagrafica Impianto Elettrico
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitForm}>
                            Salva {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <form autoComplete ="new-password">
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            {
                                            parseFloat(UserRole)!==Ruolo_Azienda && (
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="txtAzienda">Azienda *</Label>
                                                    <MyAutoComplete id="txtAzienda" name="txtAzienda" 
                                                    url={process.env.REACT_APP_API_URL+'customersinglelist'} 
                                                    optionname="RagioneSociale" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('Azienda')}
                                                    customvalue={Azienda} onChange={handleAzienda}
                                                    />
                                                    {validator.current.message('Azienda', Azienda, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>)
                                            }
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtDataInstallazione">Installato Il</Label>
                                                    <MyDatePicker name="txtDataInstallazione" 
                                                    onBlur={() => validator.current.showMessageFor('DataInstallazione')}
                                                    value={DataInstallazione} onChange={handleDataInstallazione} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataInstallazione', DataInstallazione, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtDataOmologazione">Omologato Il</Label>
                                                    <MyDatePicker name="txtDataOmologazione" 
                                                    onBlur={() => validator.current.showMessageFor('DataOmologazione')}
                                                    value={DataOmologazione} onChange={handleDataOmologazione} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataOmologazione', DataOmologazione, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtDataUltimaVerifica">Ultima Verifica</Label>
                                                    <MyDatePicker name="txtDataUltimaVerifica" 
                                                    onBlur={() => validator.current.showMessageFor('DataUltimaVerifica')}
                                                    value={DataUltimaVerifica} onChange={handleDataUltimaVerifica} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataUltimaVerifica', DataUltimaVerifica, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="txtDescrizioneAttivita">Tipo Attività</Label>
                                                    <Input type="text" name="txtDescrizioneAttivita" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('DescrizioneAttivita')}
                                                    value={DescrizioneAttivita} onChange={handleDescrizioneAttivita}
                                                    />
                                                    {validator.current.message('DescrizioneAttivita', DescrizioneAttivita, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtTipoAmbiente">Tipo Ambiente *</Label>
                                                    <MyAutoComplete id="txtTipoAmbiente" name="txtTipoAmbiente" 
                                                    url={process.env.REACT_APP_API_URL+'tipoambientiimplist'} 
                                                    optionname="Descrizione" optionvalue="Codice"
                                                    onBlur={() => validator.current.showMessageFor('TipoAmbiente')}
                                                    customvalue={TipoAmbiente} onChange={handleTipoAmbiente}
                                                    />
                                                    {validator.current.message('TipoAmbiente', TipoAmbiente, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtPersValidita">Periodicità *</Label>
                                                    <Input type="text" name="txtPersValidita" 
                                                    disabled={true}
                                                    autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('AnniValidita')}
                                                    value={AnniValidita}
                                                    />
                                                    {/* <MyAutoComplete id="txtPersValidita" name="txtPersValidita" 
                                                    url={process.env.REACT_APP_API_URL+'persverificheimplist'} 
                                                    optionname="AnniValidita" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('AnniValidita')}
                                                    customvalue={AnniValidita} onChange={handleAnniValidita}
                                                    /> */}
                                                    {validator.current.message('AnniValidita', AnniValidita, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtPotenzaKw">Potenza Kw *</Label>
                                                    <Input type="text" name="txtPotenzaKw" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('PotenzaKw')}
                                                    value={PotenzaKw} onChange={handlePotenzaKw}
                                                    />
                                                    {validator.current.message('PotenzaKw', PotenzaKw, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="selSistDistribuzione">Categoria *</Label>
                                                    <Input type="select" name="selSistDistribuzione" id="selSistDistribuzione" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('SistDistribuzione')}
                                                    value={SistDistribuzione} onChange={handleSistDistribuzione}
                                                    >
                                                        <option key=""></option>
                                                        <option key="0" value="0">TT</option>
                                                        <option key="1" value="1">TN</option>
                                                    </Input>
                                                    {validator.current.message('SistDistribuzione', SistDistribuzione, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtTensione">Tensione V *</Label>
                                                    <Input type="text" name="txtTensione" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Tensione')}
                                                    value={Tensione} onChange={handleTensione}
                                                    />
                                                    {validator.current.message('Tensione', Tensione, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtNumCabine">N. Cabine *</Label>
                                                    <Input type="text" name="txtNumCabine" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('NumCabine')}
                                                    value={NumCabine} onChange={handleNumCabine}
                                                    />
                                                    {validator.current.message('NumCabine', NumCabine, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="txtSuperficie">Superficie *</Label>
                                                    <Input type="text" name="txtSuperficie" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Superficie')}
                                                    value={Superficie} onChange={handleSuperficie}
                                                    />
                                                    {validator.current.message('Superficie', Superficie, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtIndirizzo">Indirizzo *</Label>
                                                <Input type="text" name="txtIndirizzo" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('Indirizzo')}
                                                value={Indirizzo} onChange={handleIndirizzo}
                                                />
                                                {validator.current.message('Indirizzo', Indirizzo, 'string|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtComune">Comune *</Label>
                                                <MyAutoComplete id="txtComune" name="txtComune" url={process.env.REACT_APP_API_URL+'comuni'} optionname="Comune" optionvalue="CodComune"
                                                onBlur={() => validator.current.showMessageFor('Comune')}
                                                customvalue={Comune} onChange={handleComune}
                                                />
                                                {validator.current.message('Comune', Comune, 'string|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row> 
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtNumPod">N. Pod</Label>
                                                    <Input 
                                                        type="text" 
                                                        name="txtNumPod" 
                                                        autoComplete="new-password" 
                                                        placeholder="" 
                                                        onBlur={() => validator.current.showMessageFor('NumPod')}
                                                        value={NumPod} 
                                                        onChange={handleNumPod}
                                                    />
                                                    {validator.current.message('NumPod', NumPod, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="txtDataChiusuraPrev">Data Chiusura Prevista</Label>
                                                    <MyDatePicker name="txtDataChiusuraPrev" 
                                                    onBlur={() => validator.current.showMessageFor('DataChiusuraPrev')}
                                                    value={DataChiusuraPrev} onChange={handleDataChiusuraPrev} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataChiusuraPrev', DataChiusuraPrev, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="txtDataChiusura">Data Chiusura</Label>
                                                    <MyDatePicker name="txtDataChiusura" 
                                                    onBlur={() => validator.current.showMessageFor('DataChiusura')}
                                                    value={DataChiusura} onChange={handleDataChiusura} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataChiusura', DataChiusura, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="txtNote">Note [max 200 caratteri]</Label>
                                                    <Input type="textarea" name="txtNote" id="txtNote" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('Note')}
                                                    value={Note} onChange={handleNote} maxLength="200"
                                                    />
                                                    {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}