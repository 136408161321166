import React, {useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyAutoComplete from './MyAutoComplete';
import MyAlert from './MyAlert';
import {GetMessage} from "../Modules/Message";
import {GetTextRischioAteco,GetToken} from "../Modules/Funzioni";
import axios from 'axios';
import { app_cpService } from "../Modules/Costanti";
import '../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));


  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewAzienda (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [SedeOp, setSedeOp] = React.useState(false);
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [PartitaIVA, setPartitaIVA] = React.useState("");
    const [CodFiscale, setCodFiscale] = React.useState("");
    const [CodiceUnivoco, setCodiceUnivoco] = React.useState("");
    const [EmailPec, setEmailPec] = React.useState("");
    const [IndirizzoSL, setIndirizzoSL] = React.useState("");
    const [ComuneSL, setComuneSL] = React.useState("");
    const [CodComuneSL, setCodComuneSL] = React.useState("");
    const [CapSL, setCapSL] = React.useState("");
    const [TelefonoSL, setTelefonoSL] = React.useState("");
    const [IndirizzoSO, setIndirizzoSO] = React.useState("");
    const [ComuneSO, setComuneSO] = React.useState("");
    const [CodComuneSO, setCodComuneSO] = React.useState("");
    const [CapSO, setCapSO] = React.useState("");
    const [DescrizioneAteco, setDescrizioneAteco] = React.useState("");
    const [CodiceAteco, setCodiceAteco] = React.useState("");
    const [DesRischioAteco, setDesRischioAteco] = React.useState("");
    const [TelefonoSO, setTelefonoSO] = React.useState("");
    const [Referente, setReferente] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    const [EmailReferente, setEmailReferente] = React.useState("");
    const [Medico, setMedico] = React.useState("");
    const [Zona, setZona] = React.useState("");
    const [Consenso, setConsenso] = React.useState("");
    const [IDCommerciale, setIDCommerciale] = React.useState("");
    const [Commerciale, setCommerciale] = React.useState("");
    const [IDResponsabile, setIDResponsabile] = React.useState("");
    const [Responsabile, setResponsabile] = React.useState("");
    const [Note, setNote] = React.useState("");

    const handleRagioneSociale = (event) => {  
        setRagioneSociale(event.target.value);  
    }; 
    const handlePartitaIVA = (event) => {  
        setPartitaIVA(event.target.value); 
    }; 
    const handleCodFiscale = (event) => {  
        setCodFiscale(event.target.value);  
    }; 
    const handleCodiceUnivoco = (event) => {  
        setCodiceUnivoco(event.target.value);  
    }; 
    const handleEmailPec = (event) => {  
        setEmailPec(event.target.value);  
    }; 
    const handleIndirizzoSL = (event) => {  
        setIndirizzoSL(event.target.value);  
    }; 
    const handleComuneSL = (value,text) => {
        var arrValue = value.split("|");
        setCodComuneSL(arrValue[0]);
        setCapSL(arrValue[1]);
        setComuneSL(text);  
    }; 
    const handleComuneSO = (value,text) => {  
        var arrValue = value.split("|");
        setCodComuneSO(arrValue[0]);
        setCapSO(arrValue[1]);
        setComuneSO(text);  
    }; 
    const handleCapSL = (event) => {  
        setCapSL(event.target.value);  
    }; 
    const handleAteco = (value,text) => {  
        var arrValue = value.split("|");
        setCodiceAteco(arrValue[0]);
        setDesRischioAteco(GetTextRischioAteco(arrValue[1]));
        setDescrizioneAteco(text);  
    }; 
    const handleTelefonoSL = (event) => {  
        setTelefonoSL(event.target.value);  
    }; 
    const handleIndirizzoSO = (event) => {  
        setIndirizzoSO(event.target.value);  
    }; 
    const handleCapSO = (event) => {  
        setCapSO(event.target.value);  
    }; 
    const handleTelefonoSO = (event) => {  
        setTelefonoSO(event.target.value);  
    }; 
    const handleReferente = (event) => {  
        setReferente(event.target.value);  
    };
    const handleCellulare = (event) => {  
        setCellulare(event.target.value);  
    };
    const handleEmailReferente = (event) => {  
        setEmailReferente(event.target.value);  
    };
    const handleMedico = (event) => {  
        setMedico(event.target.value);  
    };
    const handleCommerciale = (value,text) => {  
        setIDCommerciale(value);
        setCommerciale(text);  
    };
    const handleResponsabile = (value,text) => {  
        setIDResponsabile(value);
        setResponsabile(text);  
    };  
    const handleNote = (event) => {  
        setNote(event.target.value);  
    }; 
    const handleZona = (event) => {  
        setZona(event.target.value);  
    }; 
    const handleConsenso = (event) => {
        setConsenso(event.target.value);
    };
    const handleViewSedeOp = (event) => {
        setSedeOp(!SedeOp);
    };
    
    
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    
    const submitForm= (event) => 
    {
        if (validator.current.allValid()) {
            setisSubmit(true);
            const fd = new FormData();
            const intSedeOp = SedeOp ? 1 : 0;
            fd.append("RagioneSociale", RagioneSociale);
            fd.append("CodFiscale", CodFiscale);
            fd.append("PartitaIva", PartitaIVA);
            fd.append("CodUnivoco", CodiceUnivoco);
            fd.append("CodiceAteco", CodiceAteco);
            if (Zona===""){
                fd.append("Zona", "0");
            }
            else{
                fd.append("Zona", Zona);
            }
            if (Consenso===""){
                fd.append("NegaConsenso", "0");
            }
            else{
                fd.append("NegaConsenso", Consenso);
            }
            fd.append("EmailPec", EmailPec);
            fd.append("Email", EmailReferente);
            fd.append("IndirizzoSL", IndirizzoSL);
            fd.append("CodComuneSL", CodComuneSL);
            fd.append("DesComuneSL", ComuneSL);
            fd.append("CapSL", CapSL);
            fd.append("TelefonoSL", TelefonoSL);
            fd.append("IndirizzoSO", IndirizzoSO);
            fd.append("CodComuneSO", parseInt("0" + CodComuneSO));
            fd.append("DesComuneSO", ComuneSO);
            fd.append("CapSO", CapSO);
            fd.append("TelefonoSO", TelefonoSO);
            fd.append("SedeDifferente", intSedeOp);
            fd.append("Referente", Referente);
            fd.append("CellulareReferente", Cellulare);
            fd.append("NomeMedico", Medico);
            fd.append("Note", Note);
            fd.append("Commerciale_id", IDCommerciale);
            if (parseInt(IDResponsabile)>0){
                fd.append("Responsabile_id", IDResponsabile);
            }
            else {
                fd.append("Responsabile_id", 0);
            }
            
            
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            
            ax
            .post(process.env.REACT_APP_API_URL+'customerinsert', fd,axiosConfig)
            .then((response) => {
                
                if (response.status === 200) {
                    if (response.data.error==="OK"){
                        props.handleClose();
                        props.onInsert();
                    }
                    else {
                        let msg="";
                        let ris=JSON.stringify(response.data);
                        ris=ris.replaceAll("\"", "");
                        ris=ris.replaceAll("{", "");
                        ris=ris.replaceAll("}", "");
                        ris=ris.replaceAll("[", "");
                        ris=ris.replaceAll("]", "");
                        msg="errore durante l'inserimento! " + ris;
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    }
                }
                else
                { 
                const msg=GetMessage(response.status,response.data.message);
                setMsgAlert(msg);
                setOpenAlert(true);
                }
            })
            .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);
            });
            setisSubmit(false);

        } else {
            validator.current.showMessages();
            setMsgAlert(MsgAlertDefault);
            //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
            setOpenAlert(true);
        }
    }
    
    let HeadClassName="";
    const app_selected =process.env.REACT_APP_APPSELECT;
    if (parseInt(app_selected)===app_cpService) {
        HeadClassName="bg-head-form";
    }
    else{
        HeadClassName ="bg-head-form-cert";
    }
    return (
        <div>
            <MyAlert message={MsgAlert} severity="error" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>     
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={HeadClassName}>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Inserisci Azienda
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitForm}>
                            Salva {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        <form autoComplete ="new-password">
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="txtPartitaIVA">Partita IVA *</Label>
                                                    <Input type="number" name="txtPartitaIVA" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('PartitaIva')}
                                                    value={PartitaIVA} onChange={handlePartitaIVA}
                                                    />
                                                    {validator.current.message('PartitaIva', PartitaIVA, 'required|integer|min:11|max:11', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="txtCodiceFiscale">Codice Fiscale</Label>
                                                    <Input type="text" name="txtCodiceFiscale" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('CodFiscale')}
                                                    value={CodFiscale} onChange={handleCodFiscale}
                                                    />
                                                    {validator.current.message('CodFiscale', CodFiscale, 'alpha_num|min:11', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtRagioneSociale">Ragione Sociale *</Label>
                                                <Input type="text" name="txtRagioneSociale" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('RagioneSociale')}
                                                value={RagioneSociale} onChange={handleRagioneSociale}
                                                />
                                                {validator.current.message('RagioneSociale', RagioneSociale,'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="selConsenso">Consenso Marketing</Label>
                                                    <Input type="select" name="selConsenso" id="selConsenso" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Consenso')}
                                                    value={Consenso} onChange={handleConsenso}
                                                    >
                                                        <option key="0" value="0">Non Negato</option>
                                                        <option key="1" value="1">Negato</option>
                                                    </Input>
                                                    {validator.current.message('Consenso', Consenso, 'numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                { parseInt(app_selected)===app_cpService &&
                                (<Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={10}>
                                                <FormGroup>
                                                    <Label for="txtCodiceAteco">Codice Ateco</Label>
                                                    <MyAutoComplete id="txtCodiceAteco" name="txtCodiceAteco" url={process.env.REACT_APP_API_URL+'ateco'} optionname="DescrizioneAteco" optionvalue="Codice"
                                                    onBlur={() => validator.current.showMessageFor('CodiceAteco')}
                                                    customvalue={DescrizioneAteco} onChange={handleAteco}
                                                    />
                                                    {validator.current.message('CodiceAteco', DescrizioneAteco, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtRischio">Rischio</Label>
                                                    <Input type="text" name="txtRischio" autoComplete="new-password" placeholder=""
                                                    value={DesRischioAteco} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>)
                                }
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="selZona">Zona</Label>
                                                    <Input type="select" name="selZona" id="selZona" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Zona')}
                                                    value={Zona} onChange={handleZona}
                                                    >
                                                        <option></option>
                                                        <option key="1" value="1">Centro Nord</option>
                                                        <option key="2" value="2">Centro Sud</option>
                                                    </Input>
                                                    {validator.current.message('Zona', Zona, 'numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="txtCodiceUnivoco">Cod. Univoco</Label>
                                                    <Input type="text" name="txtCodiceUnivoco" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('CodiceUnivoco')}
                                                    value={CodiceUnivoco} onChange={handleCodiceUnivoco}
                                                    />
                                                    {validator.current.message('CodiceUnivoco', CodiceUnivoco, 'alpha_num', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={7}>
                                                <FormGroup>
                                                    <Label for="txtEmailPec">Email Pec</Label>
                                                    <Input type="email" name="txtEmailPec" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('EmailPec')}
                                                    value={EmailPec} onChange={handleEmailPec}
                                                    />
                                                    {validator.current.message('EmailPec', EmailPec, 'email', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={5}>
                                            <FormGroup>
                                                <Label for="txtIndirizzoSL">Indirizzo Sede Legale *</Label>
                                                <Input type="text" name="txtIndirizzoSL" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('IndirizzoSL')}
                                                value={IndirizzoSL} onChange={handleIndirizzoSL}
                                                />
                                                {validator.current.message('IndirizzoSL', IndirizzoSL, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtComuneSL">Comune *</Label>
                                                <MyAutoComplete id="txtComuneSL" name="txtComuneSL" url={process.env.REACT_APP_API_URL+'comuniwithcap'} optionname="Comune" optionvalue="Codice"
                                                onBlur={() => validator.current.showMessageFor('ComuneSL')}
                                                customvalue={ComuneSL} onChange={handleComuneSL}
                                                />
                                                {validator.current.message('ComuneSL', ComuneSL, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={1}>
                                            <FormGroup>
                                                <Label for="txtCapSL">Cap</Label>
                                                <Input type="text" name="txtCapSL" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('CapSL')}
                                                value={CapSL} onChange={handleCapSL}
                                                />
                                                {validator.current.message('CapSL', CapSL, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtTelefonoSL">Telefono</Label>
                                                <Input type="text" name="txtTelefonoSL" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                onBlur={() => validator.current.showMessageFor('TelefonoSL')}
                                                value={TelefonoSL} onChange={handleTelefonoSL}
                                                />
                                                {validator.current.message('TelefonoSL', TelefonoSL, 'cphone', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row> 
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="checkbox" name="chkSedeOperativaDifferente" onChange={handleViewSedeOp} />{' '}
                                                        Sede Operativa differente dalla sede legale
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                {SedeOp> 0 && (
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label for="txtIndirizzoSO">Indirizzo Sede Operativa</Label>
                                                    <Input type="text" name="txtIndirizzoSO" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('IndirizzoSO')}
                                                    value={IndirizzoSO} onChange={handleIndirizzoSO}
                                                    />
                                                    {validator.current.message('IndirizzoSO', IndirizzoSO, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtComuneSO">Comune</Label>
                                                    <MyAutoComplete id="txtComuneSO" name="txtComuneSO" url={process.env.REACT_APP_API_URL+'comuniwithcap'} optionname="Comune" optionvalue="Codice"
                                                onBlur={() => validator.current.showMessageFor('ComuneSO')}
                                                customvalue={ComuneSO} onChange={handleComuneSO}
                                                />
                                                {validator.current.message('ComuneSO', ComuneSO, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={1}>
                                                <FormGroup>
                                                    <Label for="txtCapSO">Cap</Label>
                                                    <Input type="text" name="txtCapSO" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('CapSO')}
                                                    value={CapSO} onChange={handleCapSO}
                                                    />
                                                    {validator.current.message('CapSO', CapSO, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtTelefonoSO">Telefono</Label>
                                                    <Input type="text" name="txtTelefonoSO" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                    onBlur={() => validator.current.showMessageFor('TelefonoSO')}
                                                    value={TelefonoSO} onChange={handleTelefonoSO}
                                                    />
                                                    {validator.current.message('TelefonoSO', TelefonoSO, 'cphone', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtReferente">Persona Referente</Label>
                                                    <Input type="text" name="txtReferente" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Referente')}
                                                    value={Referente} onChange={handleReferente}
                                                    />
                                                    {validator.current.message('Referente', Referente, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtCellulareReferente">Cellulare</Label>
                                                    <Input type="text" name="txtCellulareReferente" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                    onBlur={() => validator.current.showMessageFor('Cellulare')}
                                                    value={Cellulare} onChange={handleCellulare}
                                                    />
                                                    {validator.current.message('Cellulare', Cellulare, 'cphone', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtEmailReferente">Email</Label>
                                                    <Input type="email" name="txtEmailReferente" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Email')}
                                                    value={EmailReferente} onChange={handleEmailReferente}
                                                    />
                                                    {validator.current.message('EmailReferente', EmailReferente, 'email', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            {(parseInt(app_selected)===app_cpService) && 
                                            (<Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtResponsabile">Leg. Rappresentante</Label>
                                                    <MyAutoComplete id="txtIntestatario" name="txtIntestatario" 
                                                        url={process.env.REACT_APP_API_URL+'responsabilesinglelist'} 
                                                        optionname="Descrizione" optionvalue="id"
                                                        onBlur={() => validator.current.showMessageFor('Responsabile')}
                                                        customvalue={Responsabile} onChange={handleResponsabile} 
                                                        />
                                                    {validator.current.message('Responsabile', IDResponsabile, 'alpha_num', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>)
                                            }
                                            {(parseInt(app_selected)===app_cpService) && 
                                            (<Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtNomeMedico">Medico Competente</Label>
                                                    <Input type="text" name="txtNomeMedico" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Medico')}
                                                    value={Medico} onChange={handleMedico}
                                                    />
                                                    {validator.current.message('Medico', Medico, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>)
                                            }
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtCommerciale">Commerciale *</Label>
                                                    <MyAutoComplete id="txtCommerciale" name="txtCommerciale" 
                                                        url={process.env.REACT_APP_API_URL+'commercialesinglelist'} 
                                                        optionname="DesCommerciale" optionvalue="id"
                                                        customvalue={Commerciale} onChange={handleCommerciale}
                                                        onBlur={() => validator.current.showMessageFor('Commerciale')}
                                                        />
                                                    {validator.current.message('Commerciale', IDCommerciale, 'required|alpha_num', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="txtNote">Note [max 200 caratteri]</Label>
                                                    <Input type="textarea" name="txtNote" id="txtNote" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('Note')}
                                                    value={Note} onChange={handleNote} maxLength="200"
                                                    />
                                                    {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
        