import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css'; 
import { useState, useEffect } from 'react';   
import { Toolbar } from '@material-ui/core';
import NewDistinteProvvigioni from './NewDistinteProvvigioni';
import ModDistinteProvvigioni from './ModDistinteProvvigioni';
import MyDatePicker from '../MyDatePicker';
import MyAutoComplete from '../MyAutoComplete';
import {GetToken, GetTipoDistintaProvvName, ccyFormat} from "../../Modules/Funzioni";
import {GetMessage} from "../../Modules/Message";
import { TipoDistintaProvv_Commerciale, app_cpService } from "../../Modules/Costanti";
import MyAlert from '../MyAlert';
import imgSearch from "../../img/search.png";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
      BtnEdit: {
        '&:hover':{
            cursor:"pointer"
      }  
    }
}));
  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Avatar', string: false, disablePadding: true, label: '' },
    { id: 'DataDistintaCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'TipoDistinta', string: false, disablePadding: true, label: 'Tipo Distinta' },
    { id: 'Riferimento', string: false, disablePadding: true, label: 'Nome Cognome' },
    { id: 'ImportoProvvigioni', string: false, disablePadding: true, label: 'Provvigioni' },
    { id: 'ImportoSpese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'Importo', string: false, disablePadding: true, label: 'Totale' },
    { id: 'DataInserimentoCast', string: false, disablePadding: true, label: 'Inserito Il' },
];


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [order, setOrder] = React.useState('desc');

  const [RicTipoDistinta, setRicTipoDistinta] = React.useState("");
  const [RicPersona, setRicPersona] = React.useState("");
  const [RicIDPersona, setRicIDPersona] = React.useState("");
  const [RicNumOrdine, setRicNumOrdine] = React.useState("");
  const [RicAnnoOrdine, setRicAnnoOrdine] = React.useState("");
  const [orderBy, setOrderBy] = React.useState('DataInserimentoCast');
  const [page, setPage] = React.useState(0);  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(5);  
  const [dense, setDense] = React.useState(false);
  const [isOpenNewDistinta, setIsOpenNewDistinta] = useState(false);
  const [isOpenModDistinta, setIsOpenModDistinta] = useState(false);
  const [IdModDistinta, setIdModDistinta] = useState("");
  
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
  //-----------
  const toggleNewDistinta = () => {
    setIsOpenNewDistinta(!isOpenNewDistinta);
  }
  const toggleModDistinta = (id) => {
    setIsOpenModDistinta(!isOpenModDistinta);
    if (!isOpenModDistinta){
        setIdModDistinta(id);
    }
  }

  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'distinteprovvigionilist'
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("TipoDistinta", RicTipoDistinta);
                fd.append("IDPersona", RicIDPersona);
                fd.append("NumOrdine", RicNumOrdine);
                fd.append("AnnoOrdine", RicAnnoOrdine);

                //idazienda/0/codfiscale/0/cognome/0
                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })(); 
        
}, [isSending]);   


  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onLoadData = () => {
    setIsSending(!isSending);
}; 

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
    setPage(0);
}; 

const handleRicTipoDistinta = (event) => {
    setRicTipoDistinta(event.target.value);
    setRicPersona("");
    setRicIDPersona("");
    setDisableFilter(false);
};

const handleRicNumOrdine = (event) => {
    setRicNumOrdine(event.target.value);
    setDisableFilter(false);
};

const handleRicAnnoOrdine = (event) => {
    setRicAnnoOrdine(event.target.value);
    setDisableFilter(false);
};

const handleRicPersona = (value,text) => {
    setRicPersona(text);
    setRicIDPersona(value);
    setDisableFilter(false);
};
const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
let ButtonClassName="";
const app_selected =process.env.REACT_APP_APPSELECT
if (parseInt(app_selected)===app_cpService) {
    ButtonClassName="btn-mbg";
}
else{
    ButtonClassName ="btn-mbg-cert";
}
  return (  
    
    <div className={classes.root}>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>     
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        {isOpenModDistinta && <ModDistinteProvvigioni onUpdate={onLoadData} handleClose={toggleModDistinta} id={IdModDistinta}/>}
        {isOpenNewDistinta && <NewDistinteProvvigioni onInsert={onLoadData} handleClose={toggleNewDistinta}/>}
        <div className="small" style={{width:"98%"}}>
            <Row> 
                <Col md={3}>
                    <FormGroup>
                        <Label for="txtRicStato">Tipo Distinta</Label>
                        <Input type="select" className="search" name="selRicTipoDistinta" 
                        id="selRicTipoDistinta" placeholder="" bsSize="sm"
                            value={RicTipoDistinta} onChange={handleRicTipoDistinta}>
                                <option key="Tutte" value="">Tutte</option>
                                <option key="0" value="0">Commerciale</option>
                                <option key="1" value="1">Ispettore</option>
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                {
                    RicTipoDistinta !== "" && 
                    (<Col xl={6} lg={7} md={4} xs={12}>
                        <FormGroup>
                            <Label for="txtRicPersona">Nome Cognome</Label>
                            <MyAutoComplete id="txtRicPersona" name="txtRicPersona" 
                            url={parseInt(RicTipoDistinta) === TipoDistintaProvv_Commerciale ? process.env.REACT_APP_API_URL+'commercialesinglelist' : process.env.REACT_APP_API_URL+'ispettoresinglelist'}
                            optionname={parseInt(RicTipoDistinta) === TipoDistintaProvv_Commerciale ? "DesCommerciale" : "DesIspettore"} optionvalue="id"
                            customvalue={RicPersona} onChange={handleRicPersona} 
                            className="MuiAutocomplete-inputsm-search"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col>)
                }
                <Col xl={2} lg={4} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicNumOrdine">N. Ordine</Label>
                        <Input type="search" className="search" name="txtRicNumOrdine" placeholder=""
                        value={RicNumOrdine} onChange={handleRicNumOrdine}
                        bsSize="sm" style={{textAlign:'right'}} />
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={4} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicAnnoOrdine">Anno Ordine</Label>
                        <Input type="search" className="search" name="txtRicAnnoOrdine" placeholder=""
                        value={RicAnnoOrdine} onChange={handleRicAnnoOrdine}
                        bsSize="sm" style={{textAlign:'right'}} />
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className={ButtonClassName + " btn-sm btn-block"} disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <div>&nbsp;</div>
        <Paper className={classes.paper}> 
        <Toolbar>
        <div style={{width:"100%" }}><Button onClick={toggleNewDistinta} className={ButtonClassName}>Inserisci</Button></div>
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                                className="rt-tr-group"
                            >
                                <TableCell id={labelId} scope="row">
                                    <Avatar className={ButtonClassName + " " + classes.BtnEdit}  onClick={() => toggleModDistinta(row.id)}>
                                        <AssignmentIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell>{row.DataDistinta}</TableCell>
                                <TableCell>{GetTipoDistintaProvvName(row.TipoDistinta)}</TableCell>
                                <TableCell>{row.Riferimento}</TableCell>
                                <TableCell>{ccyFormat(parseFloat(row.ImportoProvvigioni))}</TableCell>
                                <TableCell>{ccyFormat(parseFloat(row.ImportoSpese))}</TableCell>
                                <TableCell>{ccyFormat(parseFloat(row.Importo))}</TableCell>
                                <TableCell>{row.DataInserimentoCast}</TableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={8} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[5, 10, 15]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 