import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import { FormGroup, Label,Button, Form, Input} from "reactstrap";
import '../css/login.css';
import ImgHeadCPServ from '../img/CPService.png';
//import ImgHeadCPServ from '../img/logo-cpapp.png';
import ImgHeadCPCert from '../img/logo-cpcert.png';
import Footer from "../Components/Footer";
import {GetMessage} from "../Modules/Message"
import { app_cpService } from "../Modules/Costanti";
export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errMsgEmail: "",
            errMsgPwd: "",
            redirect:false,
        };
    }
    onKeyDown = (event) => {
        if (event.key === 'Enter' && this.onValidateForm) {
          this.onLogin();
        }
      }

    onValidateForm = () => {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }
    
    onChangeHndl = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    onLogin = () => {
        this.setState({ isLoading: true });
        (async () => {

            try {
                const fd = new FormData();
                fd.append("email", this.state.email);
                fd.append("password", this.state.password);

                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                
                let response = await axios.post(process.env.REACT_APP_API_URL+'login', fd,axiosConfig)
                
                if (response.status === 200) {
                    this.setState({ isLoading: false }); 
                    let _IDComm="0";
                    let _GradoComm="0";
                    let _IDIsp="0";
                    let _EmulazioneIDIsp="0";
                    let _IDResp="0";
                    if (response.data.GradoCommerciale!==null) _GradoComm=String(response.data.GradoCommerciale)
                    if (response.data.Commerciale_id!==null) _IDComm=String(response.data.Commerciale_id)
                    if (response.data.Responabile_id!==null) _IDResp=String(response.data.Responsabile_id)
                    if (response.data.Ispettore_id!==null) _IDIsp=String(response.data.Ispettore_id)
                    if (response.data.EmulazioneIspettore_id!==null) _EmulazioneIDIsp=String(response.data.EmulazioneIspettore_id)
                    localStorage.setItem("token", response.data.access_token + "|x|" + response.data.Ruolo + "|x|" + _IDComm + "|x|" + _IDResp + "|x|" + _IDIsp + "|x|" + _GradoComm + "|x|" + _EmulazioneIDIsp);
                    localStorage.setItem("idordine", "");
                    localStorage.setItem("idazienda", "");
                    localStorage.setItem("idimpianto","")
                    this.setState({redirect: true});
                }
                else
                {
                    this.setState({
                        errMsg:GetMessage(response.status,response.statusText),
                    });
                    localStorage.setItem("token", "");
                    this.setState({ isLoading: false });
                }
            }
            catch (error) {
                if (error.response!==undefined)
                    this.setState({
                        errMsg: GetMessage(error.response.status,error.response.data.error),
                    });
                localStorage.setItem("token", "");
                this.setState({ isLoading: false });
            }
        })(); 
    };

    render() {

        
        let token =localStorage.getItem("token");
        
        if (token !==null && token!=="" && this.state.redirect) {
            return <Redirect to="/welcome"/>;
            
        }

        const app_selected =process.env.REACT_APP_APPSELECT;

        let ButtonClassName="";
        let ImgHead="";
        let ColorLink="";
        let TitleHead="";
        if (parseInt(app_selected)===app_cpService) {
            ButtonClassName="btn-mbg";
            ImgHead=ImgHeadCPServ;
            TitleHead="CP Service";
            ColorLink="color-serv";
        }
        else{
            ButtonClassName ="btn-mbg-cert";
            ImgHead=ImgHeadCPCert;
            TitleHead="CP Certificazioni";
            ColorLink="color-cert";
        }

        const isLoading = this.state.isLoading;
        return (
            <div className="login-page-container">
                <div className="content-wrap">
                    <Form className="login-form" >
                        <div><img src={ImgHead} alt={TitleHead} style={{maxWidth:'310px'}}/></div>
                        <hr className="solid"/>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input type="email" name="email" placeholder="Email"
                                   value={this.state.email}
                                   onChange={this.onChangeHndl}
                            />
                            <span className="text-danger">{this.state.errMsgEmail}</span>
                        </FormGroup>
                        <FormGroup>
                            <Label>Password</Label>
                            <Input type="password" name="password" placeholder="Password" onKeyDown={this.onKeyDown} value={this.state.password}
                                   onChange={this.onChangeHndl}
                            />
                            <span className="text-danger">{this.state.errMsgPwd}</span>
                        </FormGroup>
                        <Button type="button" className={ButtonClassName + " btn-lg btn-block"} disabled={!this.onValidateForm()} onClick={this.onLogin}>Log In
                            {isLoading ? (
                            <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                        ) : (
                            <span></span>
                        )}</Button>
                        <span className="text-danger">{this.state.errMsg}</span>
                        <hr className="solid"/>
                        <a style={{fontSize:"9pt"}} className={ColorLink} href="/recuperopassword">Password Dimenticata?</a>
                    </Form>
                </div>
                <Footer/>
            </div>
        );
    }
}
