import React, {useState,useRef,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyDatePicker from '../MyDatePicker';
import MyAutoComplete from '../MyAutoComplete';
import MyAlert from '../MyAlert';
import {GetMessage} from "../../Modules/Message";
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import {GetToken, ccyFormat} from "../../Modules/Funzioni";
import {TipoDistintaProvv_Commerciale} from "../../Modules/Costanti";
import imgSearch from "../../img/search.png";
import ReactExport from "react-export-excel";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function ExportToExcel (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Distinta">
                <ExcelColumn label="N.Ordine" value="NumOrdine"/>
                <ExcelColumn label="Data Ordine" value="DataOrdine"/>
                <ExcelColumn label="Cliente" value="RagioneSociale"/>
                <ExcelColumn label="Data Verifica" value="DataVerifica"/>
                <ExcelColumn label="Data Saldo" value="DataSaldo"/>
                <ExcelColumn label="Data Ordine" value="DataOrdine"/>
                <ExcelColumn label="Imponibile" value="Imponibile"/>
                <ExcelColumn label="Descrizione" value="Descrizione"/>
                <ExcelColumn label="% Provvigione" value="Provvigione"/>
                <ExcelColumn label="Importo Provvigione" value="ImportoProvvigione"/>
                <ExcelColumn label="Spesa" value="Spesa"/>
            </ExcelSheet>
        </ExcelFile>
    );
}

export default function ModDistintaProvv (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    const [ForceUpdate, setForceUpdate] = React.useState(false);
    const [TipoDistinta, setTipoDistinta] = React.useState("0");
    const [TipoPagamento, setTipoPagamento] = React.useState("0");
    const [TotaleSelezionato, setTotaleSelezionato] = React.useState(0);
    const [TotaleSpese, setTotaleSpese] = React.useState(0);
    const [TotaleProvvigioni, setTotaleProvvigioni] = React.useState(0);
    const [Iban, setIban] = React.useState("");
    const [DataPagamento, setDataPagamento] = React.useState("");
    const [IDRiferimento, setIDRiferimento] = React.useState("");
    const [DesRiferimento, setDesRiferimento] = React.useState("");
    const [data, setData] = useState([]);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    
    const handleRiferimento = (value,text) => {  
        setIDRiferimento(value); 
        setDesRiferimento(text);
    };
    
    const handleDataPagamento = (value) => {  
        setDataPagamento(value);  
    };
    
    const handleTipoDistinta = (event) => {
        setTipoDistinta(event.target.value);
        setIDRiferimento(""); 
        setDesRiferimento("");
    };
    
    const handleTipoPagamento = (event) => {
        setTipoPagamento(event.target.value);
    };
    
    const handleIban = (event) => {
        setIban(event.target.value);
    };
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  
    async function LoadData() {
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        let result=[];
        try{
            let url=process.env.REACT_APP_API_URL+'distintaprovvigione/' + props.id;               
            result = await ax.post(url);
    
            setTipoDistinta(result.data[0].TipoDistinta);
            setTipoPagamento(result.data[0].TipoPagamento);
            setIban(result.data[0].IBAN);
            setDataPagamento(result.data[0].DataPagamento);
            setIDRiferimento(result.data[0].Riferimento_id);
            setDesRiferimento(result.data[0].Riferimento);
            setTotaleSelezionato(result.data[0].Importo);
            setTotaleProvvigioni(result.data[0].ImportoProvvigioni);
            setTotaleSpese(result.data[0].ImportoSpese);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msg);
            setOpenAlert(true);

        }
    }
    async function LoadDatiElenco() {
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        let result=[];
        try{
            const fd = new FormData();
            fd.append("IDDistinta", props.id);
            let url=process.env.REACT_APP_API_URL+'distinteprovvigionielencolist';  
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }             
            result = await ax.post(url,fd,axiosConfig);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        if (result.data!==undefined)    {
            setData(result.data);
        }
        else {
            setData([]);
        }
    }
    React.useEffect(() => {
        if (props.id!==""){
            (async () => {
                setIsLoading(true); 
                await LoadData();
                await LoadDatiElenco();
                setIsLoading(false); 
            })(); 
        }
    }, [props.id]);

    const submitForm= (event) => 
    {
        if (isSubmit) return;
        if (data.length===0){
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert("Non sono presenti elementi in elenco!");
            setOpenAlert(true);
            return;
        }
        if (validator.current.allValid()) {
            setisSubmit(true);
            const fd = new FormData();
            
            fd.append("id", props.id);
            fd.append("Riferimento_id", IDRiferimento);
            fd.append("DataPagamento", DataPagamento === null ? "" : DataPagamento);
            fd.append("TipoPagamento", parseInt(TipoPagamento));
            fd.append("TipoDistinta", TipoDistinta);
            fd.append("IBAN", Iban);
            
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
                    
            ax
            .post(process.env.REACT_APP_API_URL+'distintaprovvigioneupdate', fd,axiosConfig)
            .then((response) => {
                
                if (response.status === 200) {
                    if (response.data.error==="OK"){
                        props.handleClose();
                        props.onInsert();
                    }
                    else {
                        let msg="";
                        let ris=JSON.stringify(response.data);
                        ris=ris.replaceAll("\"", "");
                        ris=ris.replaceAll("{", "");
                        ris=ris.replaceAll("}", "");
                        ris=ris.replaceAll("[", "");
                        ris=ris.replaceAll("]", "");
                        msg="errore durante la modifica! " + ris;
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    }
                }
                else
                { 
                const msg=GetMessage(response.status,response.data.message);
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
                
                }
            })
            .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
                
            });
            setisSubmit(false);

        } else {
            validator.current.showMessages();
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert(MsgAlertDefault);
            //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
            setOpenAlert(true);
        }
    };
    
    const ButtonClassName="btn-mbg-cert";
    const HeadClassName="bg-head-form-cert";
    const app_selected =process.env.REACT_APP_APPSELECT;
    
    return (
        <div>
            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>     
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={HeadClassName}>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Distinta Provvigione
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitForm}>
                            Salva {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        <form autoComplete ="new-password">
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                <Label for="selTipoDistinta">Tipo Distinta *</Label>
                                                    <Input type="select" name="selTipoDistinta" readOnly disabled
                                                    id="selTipoDistinta" placeholder="" 
                                                        value={TipoDistinta} onChange={handleTipoDistinta}>
                                                            <option key="0" value="0">Commerciale</option>
                                                            <option key="1" value="1">Ispettore</option>
                                                    </Input>
                                                    {validator.current.message('TipoDistinta', TipoDistinta, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            {
                                                TipoDistinta !== "" && (
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="txtPersona">{parseInt(TipoDistinta) === TipoDistintaProvv_Commerciale ? "Commerciale *" : "Ispettore *" }</Label>
                                                        <MyAutoComplete id="txtPersona" name="txtPersona" readOnly disabled
                                                            url={parseInt(TipoDistinta) === TipoDistintaProvv_Commerciale ? process.env.REACT_APP_API_URL+'commercialesinglelist' : process.env.REACT_APP_API_URL+'ispettoresinglelist'} 
                                                            optionname={parseInt(TipoDistinta) === TipoDistintaProvv_Commerciale ? "DesCommerciale" : "DesIspettore"} optionvalue="id"
                                                            onBlur={() => validator.current.showMessageFor('Persona')}
                                                            customvalue={DesRiferimento} onChange={handleRiferimento} 
                                                        
                                                            />
                                                        {validator.current.message('Persona', IDRiferimento, 'required|alpha_num', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>
                                                )

                                            }
                                           <Col md={2}>
                                                <FormGroup>
                                                <Label for="selTipoPagamento">Tipo Pagamento</Label>
                                                    <Input type="select" name="selTipoPagamento" 
                                                    id="selTipoPagamento" placeholder="" 
                                                        value={TipoPagamento} onChange={handleTipoPagamento}>
                                                        <option key=""></option>
                                                        <option key="1" value="1">Assegno bancario</option>
                                                        <option key="2" value="2">Assegno Circolare</option>
                                                        <option key="3" value="3">Bonifico Bancario</option>
                                                        <option key="4" value="4">Denaro Contante</option>
                                                        <option key="5" value="5">Pos</option>
                                                    </Input>
                                                    {validator.current.message('TipoPagamento', TipoPagamento, 'numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtIban">IBAN</Label>
                                                    <Input type="text" name="txtIban" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Iban')}
                                                    value={Iban} onChange={handleIban}
                                                    />
                                                    {validator.current.message('Iban', Iban, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtDataPagamento">Data Pagamento</Label>
                                                    <MyDatePicker name="txtDataPagamento" 
                                                    onBlur={() => validator.current.showMessageFor('DataPagamento')}
                                                    value={DataPagamento} onChange={handleDataPagamento} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataPagamento', DataPagamento, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Totale Provvigioni</Label>
                                                    <Input type="text" name="txtTotalProvv" autoComplete="new-password" placeholder="" 
                                                    value={ccyFormat(parseFloat(TotaleProvvigioni))} 
                                                    readOnly disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Totale Spese</Label>
                                                    <Input type="text" name="txtTotalSpese" autoComplete="new-password" placeholder="" 
                                                    value={ccyFormat(parseFloat(TotaleSpese))} 
                                                    readOnly disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Totale Selezionato</Label>
                                                    <Input type="text" name="txtTotal" autoComplete="new-password" placeholder="" 
                                                    value={ccyFormat(parseFloat(TotaleSelezionato))} 
                                                    readOnly disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={1}>
                                                &nbsp;
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <div><Label>&nbsp;</Label></div>
                                                    <ExportToExcel data={data}/>    
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>  
                                    {isLoading && ( <CircularProgress className="sp-center" size={150} />)}         
                                    <TableContainer component={Paper} className={classes.container}>
                                        <Table className={classes.table} aria-label="spanning table" size="small">
                                            <TableHead>            
                                                <TableRow>
                                                    <TableCell className="grid-head" align="center">N. Ordine</TableCell>
                                                    <TableCell className="grid-head" align="center">del</TableCell>
                                                    <TableCell className="grid-head" align="center">Cliente</TableCell>
                                                    <TableCell className="grid-head" align="center">D. Verifica</TableCell>
                                                    <TableCell className="grid-head" align="center">D. Saldo</TableCell>
                                                    <TableCell className="grid-head" align="center">Imponibile</TableCell>
                                                    <TableCell className="grid-head" align="center">Desc.</TableCell>
                                                    <TableCell className="grid-head" align="center">%</TableCell>
                                                    <TableCell className="grid-head" align="center">Provvigione</TableCell>
                                                    <TableCell className="grid-head" align="center">Spesa</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { data.length>0? (
                                                    data.map((row) => {
                                                        return (
                                                            
                                                        <TableRow key={row.Ordine_id} hover tabIndex={-1}>
                                                            <TableCell>{row.NumOrdine}</TableCell>
                                                            <TableCell>{row.DataOrdine}</TableCell>
                                                            <TableCell>{row.RagioneSociale}</TableCell>
                                                            <TableCell>{row.DataVerifica}</TableCell>
                                                            <TableCell>{row.DataSaldo}</TableCell>
                                                            <TableCell>{row.Imponibile}</TableCell>
                                                            <TableCell>{row.Descrizione}</TableCell>
                                                            <TableCell>{row.Provvigione}</TableCell>
                                                            <TableCell>{ccyFormat(parseFloat(row.ImportoProvvigione))}</TableCell>
                                                            <TableCell>{row.Spesa}</TableCell>
                                                        </TableRow>
                                                        );
                                                    })
                                                ):(
                                                    <TableRow>
                                                        <TableCell colSpan={11} style={{textAlign:"center"}}><b>- Ordini non trovati -</b></TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
        