import React, {Component} from 'react'
import {GetLocation,GetRole} from "../Modules/Funzioni";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import '../css/SideBar.css';
import { Ruolo_Azienda,Ruolo_Commerciale,Ruolo_Admin, app_cpCert, app_cpService, Color_CpService, Color_CpCert, Ruolo_Ispettore, Ruolo_CommTecnico } from '../Modules/Costanti';
import MyCountAvvisi from './MyCountAvvisi';


export default class  Sidebar extends Component {
    constructor(props) {
        super(props);
        const myLocation=GetLocation();
        
        let result=0;
        let openState=true;
        switch (myLocation) {
                        
            case "/nuovoordineazienda":{
                result=1;
                break;
            }
            case "/ordini":{
                result=2;
                break;
            }
            case "/aziende":{
                result=3;
                break;
            }
            case "/rappresentanti":{
                result=4;
                break;
            }
            case "/dipendenti":{
                result=5;
                break;
            }
            case "/attestati":{
                result=6;
                break;
            }
            case "/documenti":{
                result=7;
                break;
            }
            case "/commerciali":{
                result=8;
                break;
            }
            case "/utenti":{
                result=9;
                break;
            }
            case "/servizi":{
                result=10;
                break;
            }
            case "/listini":{
                result=11;
                break;
            }
            case "/modnomine":{
                result=12;
                break;
            }
            case "/servizisa":{
                result=13;
                break;
            }
            case "/servizimt":{
                result=14;
                break;
            }
            case "/impelettrici":{
                result=15;
                break;
            }
            case "/ispettori":{
                result=16;
                break;
            }
            case "/strumentiverifiche":{
                result=17;
                break;
            }
            case "/serviziexp":{
                result=18;
                break;
            }
            case "/verifiche":{
                result=19;
                break;
            }
            case "/allemail":{
                result=20;
                break;
            }
            case "/avvisi":{
                result=21;
                break;
            }
            case "/spesedirettori":{
                result=22;
                break;
            }
            case "/preventivi":{
                result=23;
                break;
            }
            case "/campagne":{
                result=24;
                break;
            }
            case "/persspeseordini":{
                result=25;
                break;
            }
            case "/contratti":{
                result=26;
                break;
            }
            case "/distinteprovv":{
                result=27;
                break;
            }
            default:
                {
                result=0;
                break;
            }

        }
        

        if (result>0 && window.innerWidth<1040){
            openState=false;
        }
        this.state = {
            role: GetRole(localStorage.getItem("token")),
            mOpen: openState,     
            ItemSelected: result,
        }
        
    }
    
    handleClickNewOrdine = (event) => {
        localStorage.setItem("menunew", "1");
    }

    toggleSidebar = (event) => {
        this.setState({ mOpen: !this.state.mOpen });
    }

    render() {
        
        //alert(this.state.loc);
        //let item=this.onClickItem();
        //this.setState({ItemSelected:item});
        const Open = this.state.mOpen ? '' : 'toggled';
        const app_selected =process.env.REACT_APP_APPSELECT;
        let myColor="";
        if (parseInt(app_selected)===app_cpService) {
            myColor=Color_CpService;
        }
        else{
            myColor =Color_CpCert;
        }
        return (
            <div className={`d-flex ${Open}`} id="wrapper">
                <div className="bg-light border-right" id="sidebar-wrapper">
                    <div className={`d-float-right ${Open}`} id="sidebar-header" style={{color:myColor}} onClick={this.toggleSidebar} >
                        {Open ? "☰" : "×"}
                    </div>
                    <div id="sidebar-content-wrapper">
                        <div id="sidebar-content">
                            <div className="list-group list-group-flush">

                            {(() => {
                            switch (parseInt(app_selected))
                            {
                                case app_cpCert:
                                    {
                                        return (
                                        <div className="list-group list-group-flush">
                                            <a onClick={this.handleClickNewOrdine} href="/nuovoordineazienda" style={{display: this.state.role!==Ruolo_Admin && this.state.role!==Ruolo_Commerciale && this.state.role!==Ruolo_CommTecnico ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===1 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Nuovo Ordine</b></span></span>)
                                                    :
                                                    (<span>Nuovo Ordine</span>)}
                                            </a>
                                            <a href="/ordini" 
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===2 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Ordini</b></span></span>)
                                                    :
                                                    (<span>Ordini</span>)}
                                            </a>
                                            <a href="/preventivi" 
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===23 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Preventivi</b></span></span>)
                                                    :
                                                    (<span>Preventivi</span>)}
                                            </a>
                                            <a href="/aziende" style={{display: this.state.role!==Ruolo_Admin && this.state.role!==Ruolo_Ispettore && this.state.role!==Ruolo_CommTecnico ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===3 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Aziende</b></span></span>)
                                                    :
                                                    (<span>Aziende</span>)}
                                            </a>
                                            <a href="/verifiche" style={{display: this.state.role!==Ruolo_Admin && this.state.role!==Ruolo_Ispettore && this.state.role!==Ruolo_CommTecnico ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===19 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Verifiche</b></span></span>)
                                                    :
                                                    (<span>Verifiche</span>)}
                                            </a>
                                            <a href="/rappresentanti" style={{display: this.state.role!==Ruolo_Admin || parseInt(app_selected)!==app_cpService ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===4 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Legali Rappresentanti</b></span></span>)
                                                    :
                                                    (<span>Legali Rappresentanti</span>)}
                                            </a>
                                            <a href="/impelettrici" style={{display: this.state.role===Ruolo_Azienda ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===15 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Impianti</b></span></span>)
                                                    :
                                                    (<span>Impianti</span>)}
                                            </a>
                                            <a href="/commerciali" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===8 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Commerciali</b></span></span>)
                                                :
                                                (<span>Commerciali</span>)}
                                            </a>
                                            <a href="/ispettori" style={{display: this.state.role!==Ruolo_Admin && this.state.role!==Ruolo_Ispettore && this.state.role!==Ruolo_CommTecnico ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===16 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Ispettori</b></span></span>)
                                                :
                                                (<span>Ispettori</span>)}
                                            </a>
                                            <a href="/strumentiverifiche" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===17 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Strumenti</b></span></span>)
                                                :
                                                (<span>Strumenti</span>)}
                                            </a>
                                            <a href="/utenti" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===9 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Utenti</b></span></span>)
                                                :
                                                (<span>Utenti</span>)}
                                            </a>
                                            <a href="/spesedirettori" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===22 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Spese Direttori</b></span></span>)
                                                :
                                                (<span>Spese Direttori</span>)}
                                            </a>
                                            <a href="/servizimt" style={{display: this.state.role===Ruolo_Ispettore ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===14 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Listino MT</b></span></span>)
                                                :
                                                (<span>Listino MT</span>)}
                                            </a>
                                            <a href="/serviziexp" style={{display: this.state.role===Ruolo_Ispettore ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===18 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Listino EXP</b></span></span>)
                                                :
                                                (<span>Listino EXP</span>)}
                                            </a>
                                            <a href="/servizisa" style={{display: this.state.role===Ruolo_Ispettore ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===13 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Listino ATM</b></span></span>)
                                                :
                                                (<span>Listino ATM</span>)}
                                            </a>
                                            <a href="/persspeseordini" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===25 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Spese Ordini</b></span></span>)
                                                :
                                                (<span>Spese Ordini</span>)}
                                            </a>
                                            <a href="/modnomine" 
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===12 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Moduli Interni</b></span></span>)
                                                :
                                                (<span>Moduli Interni</span>)}
                                            </a>
                                            <a href="/distinteprovv" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===26 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Distinte Provvigioni</b></span></span>)
                                                :
                                                (<span>Distinte Provvigioni</span>)}
                                            </a>
                                            {/* <a href="/avvisi" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===21 ? 
                                                (<span><span className="bullet-list" style={{float:'left'}}>&#9642;&nbsp;</span><span style={{display:'flex',float:'left',paddingTop:'5px'}}><NotificationsActiveIcon/>&nbsp;<b>Notifiche&nbsp;</b><MyCountAvvisi/></span></span>)
                                                :
                                                (<span style={{display:'flex'}}><NotificationsActiveIcon/>&nbsp;Notifiche&nbsp;<MyCountAvvisi/></span>)}
                                            </a> */}
                                        </div>
                                    )
                                }
                                case app_cpService:
                                {
                                    return(
                                        <div className="list-group list-group-flush">
                                            <a onClick={this.handleClickNewOrdine} href="/nuovoordineazienda" style={{display: this.state.role===Ruolo_Azienda ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===1 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Nuovo Ordine</b></span></span>)
                                                    :
                                                    (<span>Nuovo Ordine</span>)}
                                            </a>
                                            <a href="/ordini" style={{display: this.state.role===Ruolo_Azienda ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===2 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Ordini</b></span></span>)
                                                    :
                                                    (<span>Ordini</span>)}
                                            </a>
                                            <a href="/contratti" 
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===26 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Contratti</b></span></span>)
                                                    :
                                                    (<span>Contratti</span>)}
                                            </a>
                                            <a href="/aziende" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===3 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Aziende</b></span></span>)
                                                    :
                                                    (<span>Aziende</span>)}
                                            </a>
                                            <a href="/rappresentanti" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===4 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Legali Rappresentanti</b></span></span>)
                                                    :
                                                    (<span>Legali Rappresentanti</span>)}
                                            </a>
                                            <a href="/dipendenti" style={{display: (this.state.role===Ruolo_Commerciale) ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                    this.state.ItemSelected===5 ? 
                                                    (<span><span className="bullet-list">&#9642; </span><span><b>Dipendenti</b></span></span>)
                                                    :
                                                    (<span>Dipendenti</span>)}
                                            </a>
                                            <a href="/commerciali" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===8 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Commerciali</b></span></span>)
                                                :
                                                (<span>Commerciali</span>)}
                                            </a>
                                            <a href="/utenti" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===9 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Utenti</b></span></span>)
                                                :
                                                (<span>Utenti</span>)}
                                            </a>
                                            <a href="/servizi" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===10 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Servizi</b></span></span>)
                                                :
                                                (<span>Servizi</span>)}
                                            </a>
                                            <a href="/listini" style={{display: this.state.role===Ruolo_Azienda ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===11 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Listini</b></span></span>)
                                                :
                                                (<span>Listini</span>)}
                                            </a>
                                            <a href="/attestati" style={{display: this.state.role===Ruolo_Commerciale ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===6 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Attestati</b></span></span>)
                                                :
                                                (<span>Attestati</span>)}
                                            </a>
                                            <a href="/documenti" style={{display: this.state.role===Ruolo_Commerciale ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===7 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Documenti</b></span></span>)
                                                :
                                                (<span>Documenti</span>)}
                                            </a>
                                            <a href="/modnomine" 
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===12 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Moduli Nomine</b></span></span>)
                                                :
                                                (<span>Moduli Nomine</span>)}
                                            </a>
                                            <a href="/allemail" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===20 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Allegati Email</b></span></span>)
                                                :
                                                (<span>Allegati Email</span>)}
                                            </a>
                                            <a href="/campagne" style={{display: this.state.role!==Ruolo_Admin ? "none":""}}
                                                className="list-group-item list-group-item-action bg-light">{
                                                this.state.ItemSelected===24 ? 
                                                (<span><span className="bullet-list">&#9642; </span><span><b>Campagne Marketing</b></span></span>)
                                                :
                                                (<span>Campagne Marketing</span>)}
                                            </a>
                                        </div>
                                    )
                                }
                                default:{
                                    
                                }
                            }
                            })()} 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}