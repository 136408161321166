import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Person } from '@material-ui/icons';
import { Button, ListItemIcon } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { GetToken } from '../Modules/Funzioni';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function MenuHeader(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [SecondForRetrieveNotifications,setSecondForRetrieveNotifications]=useState(0);
  const [CountNotifications,setCountNotifications]=useState(0);
  var [date,setDate] = useState(new Date());
  const IDUser = props.userId;
  useEffect(() => {
    var timer = setInterval(()=>setDate(new Date()), 1000 );
    return function cleanup() {
        clearInterval(timer)
    }
  });

  useEffect(() => {
    
    if ((SecondForRetrieveNotifications + 1)>=parseFloat(process.env.REACT_APP_SECOND_RETRIEVE_NOTIFICATIONS))
    {
        setSecondForRetrieveNotifications(0);
        retrieveNotifications();
    }
    else {
      setSecondForRetrieveNotifications(SecondForRetrieveNotifications+1);
    }
},[date]);

useEffect(() => {
      setSecondForRetrieveNotifications(0);
      retrieveNotifications();
},[IDUser]);

async function retrieveNotifications()  {
    try {

      if (parseInt(IDUser) > 0)
        {
          let url=process.env.REACT_APP_API_URL+'countnotifications';
          const token = GetToken(localStorage.getItem("token"));
          const ax= axios;
          ax.defaults.headers.common['Authorization']="Bearer " + token;
          const axiosConfig = {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }
          const fd = new FormData();
          
          fd.append("User_id", IDUser);
          const result = await ax.post(url,fd,axiosConfig);
          if (result.data!==undefined)
          {
              const notificationCount = parseFloat(result.data.count);

              setCountNotifications(isNaN(notificationCount) ? 0 : notificationCount);
          }
        }
        
      } catch(error) {
           
      }
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangePwd = () => {
    handleMenuClose();
    props.onChangePwd();
  };

  const handleLogOut = () => {
    handleMenuClose();
    props.onLogOut();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = 'primary-settings-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogOut}>
        <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        Log-out
      </MenuItem>
      <MenuItem onClick={handleChangePwd}>
        <ListItemIcon>
            <LockIcon fontSize="small" />
        </ListItemIcon>
        Cambio PWD
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
        <div className={classes.grow} />
        <div>
            <a href="/usernotifications">
                <IconButton aria-label="show notifications" color="inherit" component="span">
                    <Badge badgeContent={CountNotifications} color="secondary">
                    <NotificationsIcon fontSize="large" style={{ color: "#172547" }}/>
                    </Badge>
                </IconButton>
            </a>
            <Button
                size="large"
                startIcon={<Person fontSize="large" style={{ color: "#172547" }}/>}
                onClick={handleProfileMenuOpen}
                component="span"
            >
                {props.userName}
            </Button>
        </div>
      {/* {renderMobileMenu} */}
      {renderMenu}
    </div>
  );
}
