import React, { Component } from "react";
import axios from 'axios';
import { FormGroup, Label,Button, Form, Input} from "reactstrap";
import '../css/login.css';
import ImgHeadCPServ from '../img/CPService.png';
import ImgHeadCPCert from '../img/logo-cpcert.png';
import Footer from "../Components/Footer";
import {GetMessage} from "../Modules/Message"
import { app_cpService } from "../Modules/Costanti";
export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            msgSuccess: "",
        };
    }
    onKeyDown = (event) => {
        if (event.key === 'Enter' && this.onValidateForm) {
          this.onLogin();
        }
      }

    onValidateForm = () => {
        return this.state.email.length > 0;
    }

    handleEmail = (event) => {
        this.setState({ email: event.target.value });
    }
    
    onSubmit = () => {
        this.setState({ isLoading: true });
        const fd = new FormData();
        fd.append("email", this.state.email);
        
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        axios
            .post(process.env.REACT_APP_API_URL+'recuperopwd', fd,axiosConfig)
            .then((response) => {
                this.setState({ isLoading: false });

                if (response.status === 200) {
                    this.setState({ msgSuccess: "Abbiamo inviato la password all'indirizzo email indicato!" });
                    
                }
                else
                {
                    this.setState({
                        errMsg:GetMessage(response.status,response.message || response.data.message || response.statusText),
                    });
                    this.setState({ isLoading: false });
                }
            })
            .catch((error) => {
                if (error.response.data.message!==undefined){
                    this.setState({
                        errMsg: error.response.data.message,
                    });
                }
                else {
                    if (error.response!==undefined)
                    this.setState({
                        errMsg: GetMessage(error.response.status, error.response.message || error.response.data.message || error.response.data.error),
                    });
                }
                
                this.setState({ isLoading: false });
            });
    };

    render() {

        const app_selected =process.env.REACT_APP_APPSELECT;

        let ButtonClassName="";
        let ImgHead="";
        let ColorLink="";
        let TitleHead="";
        if (parseInt(app_selected)===app_cpService) {
            ButtonClassName="btn-mbg";
            ImgHead=ImgHeadCPServ;
            TitleHead="CP Service";
            ColorLink="color-serv";
        }
        else{
            ButtonClassName ="btn-mbg-cert";
            ImgHead=ImgHeadCPCert;
            TitleHead="CP Certificazioni";
            ColorLink="color-cert";
        }

        const isLoading = this.state.isLoading;
        return (
            <div className="login-page-container">
                <div className="content-wrap">
                    <Form className="login-form" >
                        <div><img src={ImgHead} alt={TitleHead} style={{maxWidth:'310px'}}/></div>
                        <hr className="solid"/>
                        <FormGroup>
                            <Label>Email di registrazione</Label>
                            <Input type="email" name="email" placeholder="Email"
                                   value={this.state.email}
                                   onChange={this.handleEmail}
                            />
                            <p style={{fontSize:"9pt"}}>Inserisci l'email di registrazione, ti invieremo una nuova password!</p>
                        </FormGroup>
                        <Button type="button" className={ButtonClassName + " btn-lg btn-block"} disabled={!this.onValidateForm()} onClick={this.onSubmit}>Invia
                            {isLoading ? (
                            <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>
                        ) : (
                            <span></span>
                        )}</Button>
                        <p>{this.state.msgSuccess}</p>
                        <p className="text-danger">{this.state.errMsg}</p>
                        <a style={{fontSize:"9pt"}} className={ColorLink} href="/login">Ritorna alla login</a>
                    </Form>
                </div>
                <Footer/>
            </div>
        );
    }
}
