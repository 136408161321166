import React, {useState,useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyDatePicker from './MyDatePicker';
import MyAutoComplete from './MyAutoComplete';
import MyAlert from './MyAlert';
import {GetMessage} from "../Modules/Message";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import {isDate,GetToken, GetListRuoloCommercialeServ, GetListRuoloCommercialeCert} from "../Modules/Funzioni";
import { Grado_Partner,app_cpService, Grado_Direttore ,Grado_Manager, Grado_Executive} from "../Modules/Costanti";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModCommerciale (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [AbilitaLogin, setAbilitaLogin] = React.useState(false);
    const [Sesso, setSesso] = React.useState("");
    const [IDResponsabile, setIDResponsabile] = React.useState("");
    const [DesResponsabile, setDesResponsabile] = React.useState("");
    const [IDPartner, setIDPartner] = React.useState("");
    const [DesPartner, setDesPartner] = React.useState("");
    const [IDManager, setIDManager] = React.useState("");
    const [DesManager, setDesManager] = React.useState("");
    const [IDExecutive, setIDExecutive] = React.useState("");
    const [DesExecutive, setDesExecutive] = React.useState("");
    const [Grado, setGrado] = React.useState("");
    const [PartitaIVA, setPartitaIVA] = React.useState("");
    const [Provvigione, setProvvigione] = React.useState("");
    const [ProvvigioneDirettore, setProvvigioneDirettore] = React.useState("");
    const [ProvvigioneExecutive, setProvvigioneExecutive] = React.useState("");
    const [ProvvigionePartner, setProvvigionePartner] = React.useState("");
    const [ProvvigioneManager, setProvvigioneManager] = React.useState("");
    const [CodNazioneNascita, setCodNazioneNascita] = React.useState(0);
    const [NazioneNascita, setNazioneNascita] = React.useState("");
    const [IDUser, setIDUser] = React.useState("");
    const [Nome, setNome] = React.useState("");
    const [Cognome, setCognome] = React.useState("");
    const [CodFiscale, setCodFiscale] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    const [Indirizzo, setIndirizzo] = React.useState("");
    const [ComuneResidenza, setComuneResidenza] = React.useState("");
    const [CodComuneResidenza, setCodComuneResidenza] = React.useState("");
    const [ComuneNascita, setComuneNascita] = React.useState("");
    const [CodComuneNascita, setCodComuneNascita] = React.useState("");
    const [DataNascita, setDataNascita] = React.useState("");
    const [Note, setNote] = React.useState("");

    const handleResponsabile = (value,text) => {
        setIDResponsabile(value);
        setDesResponsabile(text);  
    }; 
    const handlePartner = (value,text) => {
        setIDPartner(value);
        setDesPartner(text);  
    }; 
    const handleManager = (value,text) => {
        setIDManager(value);
        setDesManager(text);  
    }; 
    const handleExecutive = (value,text) => {
        setIDExecutive(value);
        setDesExecutive(text);  
    }; 
    const handleGrado = (event) => {  
        setGrado(parseInt(event.target.value));
    }; 
    const handlePartitaIVA = (event) => {  
        setPartitaIVA(event.target.value);  
    }; 
    const handleProvvigione = (event) => {  
        setProvvigione(event.target.value);  
    }; 
    const handleProvvigioneDirettore = (event) => {  
        setProvvigioneDirettore(event.target.value);  
    };
    const handleProvvigioneExecutive = (event) => {  
        setProvvigioneExecutive(event.target.value);  
    };
    const handleProvvigionePartner = (event) => {  
        setProvvigionePartner(event.target.value);  
    };
    const handleProvvigioneManager = (event) => {  
        setProvvigioneManager(event.target.value);  
    }; 
    const handleAbilitaLogin = (event) => {  
        setAbilitaLogin(!AbilitaLogin); 
    };  
    const handleSesso = (event) => {  
        setSesso(event.target.value);  
    }; 
    const handleNome = (event) => {  
        setNome(event.target.value);  
    }; 
    const handleCognome = (event) => {  
        setCognome(event.target.value);  
    }; 
    const handleCodFiscale = (event) => {  
        setCodFiscale(event.target.value);  
    }; 
    const handleEmail = (event) => {  
        setEmail(event.target.value);  
    }; 
    const handleCellulare = (event) => {  
        setCellulare(event.target.value);  
    }; 
    const handleIndirizzo = (event) => {  
        setIndirizzo(event.target.value);  
    }; 
    const handleComuneResidenza = (value,text) => {
        setCodComuneResidenza(value);
        setComuneResidenza(text);  
    }; 
    const handleNazioneNascita = (value,text) => {
        setCodNazioneNascita(value);
        setNazioneNascita(text);  
    }; 
    const handleComuneNascita = (value,text) => {
        setCodComuneNascita(value);
        setComuneNascita(text);  
    }; 
    const handleComuneEsteroNascita = (event) => {  
        setComuneNascita(event.target.value); 
        setCodComuneNascita(0); 
    }; 
    const handleDataNascita = (value) => {  
        setDataNascita(value);  
    };
    const handleNote = (event) => {  
        setNote(event.target.value);  
    }; 
    
    React.useEffect(() => {
        if (props.id!==""){
            LoadData();
        }
    }, [props.id]);
    
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    const LoadData = (event) => {
        if(props.id!==""){
            setIsLoading(true); 
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'commerciale/' + props.id;               
                    result = await ax.post(url);
                    if (result.data[0].DesDirettore===null){
                        setDesResponsabile("");
                    }
                    else {
                        setDesResponsabile(result.data[0].DesDirettore);
                    }
                    if (result.data[0].DesPartner===null){
                        setDesPartner("");
                    }
                    else {
                        setDesPartner(result.data[0].DesPartner);
                    }
                    if (result.data[0].DesManager===null){
                        setDesManager("");
                    }
                    else {
                        setDesManager(result.data[0].DesManager);
                    }
                    if (result.data[0].DesExecutive===null){
                        setDesExecutive("");
                    }
                    else {
                        setDesExecutive(result.data[0].DesExecutive);
                    }
                    setGrado(result.data[0].Grado);
                    setPartitaIVA(result.data[0].PartitaIva);
                    setProvvigione(result.data[0].Provvigione);
                    setProvvigioneDirettore(result.data[0].ProvvigioneDirettore);
                    setProvvigioneExecutive(result.data[0].ProvvigioneExecutive);
                    setProvvigionePartner(result.data[0].ProvvigionePartner);
                    setProvvigioneManager(result.data[0].ProvvigioneManager);
                    setIDResponsabile(result.data[0].Direttore_id);
                    setIDPartner(result.data[0].Partner_id);
                    setIDManager(result.data[0].Manager_id);
                    setIDExecutive(result.data[0].Executive_id);
                    setIDUser(result.data[0].User_id);
                    setAbilitaLogin(result.data[0].UserAbilitato!==0);
                    setSesso(result.data[0].Sesso);
                    setNome(result.data[0].Nome);
                    setCognome(result.data[0].Cognome);
                    setCodFiscale(result.data[0].CodFiscale);
                    setNazioneNascita(result.data[0].DesNazione);
                    setCodNazioneNascita(result.data[0].CodNazioneNascita);
                    setCodComuneNascita(result.data[0].CodComuneNascita);
                    setComuneNascita(result.data[0].DesComuneNascita);
                    setDataNascita(result.data[0].DataNascita);
                    setIndirizzo(result.data[0].Indirizzo);
                    setCodComuneResidenza(result.data[0].CodComuneResidenza);
                    setComuneResidenza(result.data[0].DesComuneResidenza);
                    setEmail(result.data[0].Email);
                    setCellulare(result.data[0].Cellulare);
                    setNote(result.data[0].Note);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning")
                    setVerticalAlert("center")
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                
            })();  
        }  
    }

    function ValidationCodFiscale () {
        let ValMancante="";
        if (Sesso==="")
        {
            ValMancante +="Sesso, "
        }

        if (Nome==="")
        {
            ValMancante +="Nome, "
        }

        if (Cognome==="")
        {
            ValMancante +="Cognome, "
        }

        if (CodNazioneNascita==="")
        {
            ValMancante +="Nazione Nascita, "
        }

        if (CodNazioneNascita!==0 && CodNazioneNascita!==""){
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("Il calcolo del codice fiscale può essere effettuato solo per nazionalità italiane!");
            setOpenAlert(true);
            return false;
        }

        if (CodComuneNascita==="")
        {
            ValMancante +="Comune Nascita, "
        }

        if (isDate(DataNascita)!=="")
        {
            ValMancante +="Data Nascita, "
        }

        if (ValMancante!==""){
            //elimina l'ultima virgola
            ValMancante=ValMancante.substring(0,ValMancante.length-2)
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("Valori mancanti nei seguenti campi: " + ValMancante);
            setOpenAlert(true);
            return false;
        }

        return true;
        
    }

    const CalcolaCodFiscale= (event) => 
        {
            
            if (ValidationCodFiscale()) {
                setIsLoading(true); 
                const fd = new FormData();
                fd.append("Sesso", Sesso);
                fd.append("Nome", Nome);
                fd.append("Cognome", Cognome);
                fd.append("CodComuneNascita", CodComuneNascita);
                fd.append("DataNascita", DataNascita);
                
                const token = GetToken(localStorage.getItem("token"));
                const ax= axios;
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                        
                ax
                .post(process.env.REACT_APP_API_URL+'calcolacodicefiscale', fd,axiosConfig)
                .then((response) => {
                    
                    if (response.status === 200) {
                        if (response.data.error==="OK"){
                            setCodFiscale(response.data.CodiceFiscale)
                            setSeverityAlert("success");
                            setVerticalAlert("center");
                            setMsgAlert("Calcolo del Codice Fiscale esguito correttamente!");
                            setOpenAlert(true);
                        }
                        else {
                            
                            setSeverityAlert("error");
                            setVerticalAlert("center");
                            setMsgAlert("Errore durante il calcolo del codice fiscale!");
                            setOpenAlert(true);
                        }
                    }
                    else
                    { 
                    const msg=GetMessage(response.status,response.data.message);
                    setSeverityAlert("error");
                    setVerticalAlert("center");
                    setMsgAlert(msg);
                    setOpenAlert(true);
                    }
                })
                .catch((error) => {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning")
                    setVerticalAlert("center")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                });
                setIsLoading(false); 
            } 
        }

        const submitForm= (event) => 
        {
            if (validator.current.allValid()) {
                setisSubmit(true);
                const intAbilitaLogin = AbilitaLogin ? 1 : 0;
                const fd = new FormData();
                fd.append("id", props.id);
                
                if (parseFloat(Grado)<Grado_Direttore){
                    fd.append("Direttore_id", "0");
                }
                else {
                    fd.append("Direttore_id", IDResponsabile);
                }
                if (parseFloat(Grado)<Grado_Partner){
                    fd.append("Partner_id", "0");
                }
                else {
                    fd.append("Partner_id", IDPartner);
                }
                if (parseFloat(Grado)<Grado_Manager){
                    fd.append("Manager_id", "0");
                }
                else {
                    fd.append("Manager_id", IDManager);
                }
                if (parseFloat(Grado)<Grado_Executive){
                    fd.append("Executive_id", "0");
                }
                else {
                    fd.append("Executive_id", IDExecutive);
                }
                if (parseFloat(Grado)===Grado_Direttore){
                    fd.append("ProvvigioneDirettore", ProvvigioneDirettore);
                }
                else {
                    fd.append("ProvvigioneDirettore", 0);
                }
                if (parseFloat(Grado)===Grado_Executive){
                    fd.append("ProvvigioneExecutive", ProvvigioneExecutive);
                }
                else {
                    fd.append("ProvvigioneExecutive", 0);
                }
                if (parseFloat(Grado)===Grado_Partner){
                    fd.append("ProvvigionePartner", ProvvigionePartner);
                }
                else {
                    fd.append("ProvvigionePartner", 0);
                }
                if (parseFloat(Grado)===Grado_Manager){
                    fd.append("ProvvigioneManager", ProvvigioneManager);
                }
                else {
                    fd.append("ProvvigioneManager", 0);
                }
                fd.append("Grado", Grado);
                fd.append("PartitaIva", PartitaIVA);
                fd.append("Provvigione", Provvigione);
                fd.append("User_id", IDUser);
                fd.append("Sesso", Sesso);
                fd.append("UserAbilitato", intAbilitaLogin);
                fd.append("Nome", Nome);
                fd.append("Cognome", Cognome);
                fd.append("CodFiscale", CodFiscale);
                fd.append("Email", Email);
                fd.append("Cellulare", Cellulare);
                fd.append("Indirizzo", Indirizzo);
                fd.append("CodComuneResidenza", CodComuneResidenza);
                fd.append("DesComuneResidenza", ComuneResidenza);
                fd.append("CodNazioneNascita", CodNazioneNascita);
                fd.append("CodComuneNascita", CodComuneNascita);
                fd.append("DesComuneNascita", ComuneNascita);
                fd.append("DataNascita", DataNascita);
                fd.append("Note", Note);
                
                    const token = GetToken(localStorage.getItem("token"));
                        const ax= axios;
                        ax.defaults.headers.common['Authorization']="Bearer " + token;
                        const axiosConfig = {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                        
                        ax
                        .post(process.env.REACT_APP_API_URL+'commercialeupdate', fd,axiosConfig)
                        .then((response) => {
                            
                            if (response.status === 200) {
                                if (response.data.error==="OK"){
                                    props.handleClose();
                                    props.onUpdate();
                                }
                                else {
                                    let msg="";
                                    let ris=JSON.stringify(response.data);
                                    ris=ris.replaceAll("\"", "");
                                    ris=ris.replaceAll("{", "");
                                    ris=ris.replaceAll("}", "");
                                    ris=ris.replaceAll("[", "");
                                    ris=ris.replaceAll("]", "");
                                    msg="errore durante la modifica! " + ris;
                                    setSeverityAlert("error")
                                    setVerticalAlert("top")
                                    setMsgAlert(msg);
                                    setOpenAlert(true);
                                }
                            }
                            else
                            { 
                            const msg=GetMessage(response.status,response.data.message);
                            setSeverityAlert("error")
                            setVerticalAlert("top")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                            
                            }
                        })
                        .catch((error) => {
                            let msg=""
                            if (error.response!==undefined) {
                                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                            }
                            else
                            {
                                msg=GetMessage(undefined,"");
                            }
                            setSeverityAlert("error")
                            setVerticalAlert("top")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                            
                        });
                        setisSubmit(false);

            } else {
                validator.current.showMessages();
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(MsgAlertDefault);
                //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
                setOpenAlert(true);
            }
        }
        let ButtonClassName="";
        let HeadClassName="";
        const app_selected =process.env.REACT_APP_APPSELECT;
        if (parseInt(app_selected)===app_cpService) {
            ButtonClassName="btn-mbg";
            HeadClassName="bg-head-form";
        }
        else{
            ButtonClassName ="btn-mbg-cert";
            HeadClassName ="bg-head-form-cert";
        }
    return (
        <div>
            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>     
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={HeadClassName}>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Anagrafica Commerciale
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitForm}>
                            Salva {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <form autoComplete ="new-password">
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                        <Col md={3}>
                                                <FormGroup>
                                                    <Label for="selGrado">Qualifica *</Label>
                                                    <Input type="select" name="selGrado" 
                                                    id="selGrado" placeholder="" 
                                                        value={Grado} onChange={handleGrado}>
                                                            <option key="Tutti" value=""></option>
                                                            {
                                                            parseInt(app_selected)===app_cpService ? 
                                                                GetListRuoloCommercialeServ().map((item) => (
                                                                <option key={item.value} value={item.value}>{item.description}</option>
                                                            )):
                                                            GetListRuoloCommercialeCert().map((item) => (
                                                                <option key={item.value} value={item.value}>{item.description}</option>
                                                            ))
                                                            }
                                                    </Input>
                                                    {validator.current.message('Qualifica', Grado, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtProvvigione">% Provvigione *</Label>
                                                    <Input type="number" name="txtProvvigione" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Provvigione')}
                                                    value={Provvigione} onChange={handleProvvigione}
                                                    />
                                                    {validator.current.message('Provvigione', Provvigione, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="txtPartitaIVA">Partita IVA</Label>
                                                    <Input type="number" name="txtPartitaIVA" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('PartitaIva')}
                                                    value={PartitaIVA} onChange={handlePartitaIVA}
                                                    />
                                                    {validator.current.message('PartitaIva', PartitaIVA, 'integer|min:11|max:11', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="selSesso">Sesso *</Label>
                                                    <Input type="select" name="selSesso" id="selSesso" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Sesso')}
                                                    value={Sesso} onChange={handleSesso}
                                                    >
                                                        <option key="" value=""></option>
                                                        <option key="M" value="M">M</option>
                                                        <option key="F" value="F">F</option>
                                                    </Input>
                                                    {validator.current.message('Sesso', Sesso, 'required|alpha', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup style={{textAlign:"right"}}>
                                                    <div>&nbsp;</div>
                                                    <Label>
                                                        <Input type="checkbox" name="chkAbilitaLogin" onChange={handleAbilitaLogin} checked={AbilitaLogin> 0} />{' '}
                                                        Abilita Login
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                {Grado===Grado_Direttore && (
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Row>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="txtProvvigioneDirettore">% Provvigione da Direttore *</Label>
                                                        <Input type="number" name="txtProvvigioneDirettore" autoComplete="new-password" placeholder="" 
                                                        onBlur={() => validator.current.showMessageFor('ProvvigioneDirettore')}
                                                        value={ProvvigioneDirettore} onChange={handleProvvigioneDirettore}
                                                        />
                                                        {validator.current.message('ProvvigioneDirettore', ProvvigioneDirettore, 'required|string', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Paper>
                                    </Grid>
                                )
                                }
                                {Grado===Grado_Executive && (
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Row>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="txtProvvigioneExecutive">% Provvigione da Executive *</Label>
                                                        <Input type="number" name="txtProvvigioneExecutive" autoComplete="new-password" placeholder="" 
                                                        onBlur={() => validator.current.showMessageFor('ProvvigioneExecutive')}
                                                        value={ProvvigioneExecutive} onChange={handleProvvigioneExecutive}
                                                        />
                                                        {validator.current.message('ProvvigioneExecutive', ProvvigioneExecutive, 'required|string', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Paper>
                                    </Grid>
                                )
                                }
                                {Grado===Grado_Partner && (
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Row>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="txtProvvigionePartner">% Provvigione da Partner *</Label>
                                                        <Input type="number" name="txtProvvigionePartner" autoComplete="new-password" placeholder="" 
                                                        onBlur={() => validator.current.showMessageFor('ProvvigionePartner')}
                                                        value={ProvvigionePartner} onChange={handleProvvigionePartner}
                                                        />
                                                        {validator.current.message('ProvvigionePartner', ProvvigionePartner, 'required|string', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Paper>
                                    </Grid>
                                )
                                }
                                {Grado===Grado_Manager && (
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Row>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <Label for="txtProvvigioneManager">% Provvigione da Manager *</Label>
                                                        <Input type="number" name="txtProvvigioneManager" autoComplete="new-password" placeholder="" 
                                                        onBlur={() => validator.current.showMessageFor('ProvvigioneManager')}
                                                        value={ProvvigioneManager} onChange={handleProvvigioneManager}
                                                        />
                                                        {validator.current.message('ProvvigioneManager', ProvvigioneManager, 'required|string', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Paper>
                                    </Grid>
                                )
                                }
                                {Grado>Grado_Direttore && 
                                (<Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                    <Row>
                                            {Grado>Grado_Direttore && (<Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtResponsabile">Direttore</Label>
                                                    <MyAutoComplete id="txtResponsabile" name="txtResponsabile" 
                                                    url={process.env.REACT_APP_API_URL+'commercialedirettoresinglelist'} 
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('Responsabile')}
                                                    customvalue={DesResponsabile} onChange={handleResponsabile}
                                                    />
                                                    {validator.current.message('Responsabile', DesResponsabile, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>)}
                                            {Grado>Grado_Executive && (<Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtExecutive">Executive</Label>
                                                    <MyAutoComplete id="txtExecutive" name="txtExecutive" 
                                                    url={process.env.REACT_APP_API_URL+'commercialeexecutivesinglelist'} 
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('Executive')}
                                                    customvalue={DesExecutive} onChange={handleExecutive}
                                                    />
                                                    {validator.current.message('Executive', DesExecutive, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>)}
                                            {Grado>Grado_Partner && (<Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtPartner">Partner</Label>
                                                    <MyAutoComplete id="txtPartner" name="txtPartner" 
                                                    url={process.env.REACT_APP_API_URL+'commercialepartnersinglelist'} 
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('Partner')}
                                                    customvalue={DesPartner} onChange={handlePartner}
                                                    />
                                                    {validator.current.message('Partner', DesPartner, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>)}
                                            {Grado>Grado_Manager && (<Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtManager">Manager</Label>
                                                    <MyAutoComplete id="txtManager" name="txtManager" 
                                                    url={process.env.REACT_APP_API_URL+'commercialemanagersinglelist'} 
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('Manager')}
                                                    customvalue={DesManager} onChange={handleManager}
                                                    />
                                                    {validator.current.message('Manager', DesManager, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>)}
                                        </Row>
                                    </Paper>
                                </Grid>)}
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label for="txtNome">Nome *</Label>
                                                    <Input type="text" name="txtNome" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Nome')}
                                                    value={Nome} onChange={handleNome}
                                                    />
                                                    {validator.current.message('Nome', Nome, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup>
                                                    <Label for="txtCognome">Cognome *</Label>
                                                    <Input type="text" name="txtCognome" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Cognome')}
                                                    value={Cognome} onChange={handleCognome}
                                                    />
                                                    {validator.current.message('Cognome', Cognome, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtDataNascita">D. Nascita *</Label>
                                                    <MyDatePicker name="txtDataNascita" 
                                                    onBlur={() => validator.current.showMessageFor('DataNascita')}
                                                    value={DataNascita} onChange={handleDataNascita} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataNascita', DataNascita, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtNazioneNascita">Nazione Nascita *</Label>
                                                    <MyAutoComplete id="txtNazioneNascita" name="txtNazioneNascita" 
                                                    url={process.env.REACT_APP_API_URL+'nazioni'} 
                                                    optionname="DesNazione" optionvalue="CodNazione"
                                                    onBlur={() => validator.current.showMessageFor('NazioneNascita')}
                                                    customvalue={NazioneNascita} onChange={handleNazioneNascita}
                                                    />
                                                    {validator.current.message('NazioneNascita', NazioneNascita, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtComuneNascita">Comune *</Label>
                                                    {CodNazioneNascita> 0 && (
                                                    <Input type="text" name="txtComuneNascitaEstero" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('ComuneNascita')}
                                                    value={ComuneNascita} onChange={handleComuneEsteroNascita}
                                                    />)}
                                                    {CodNazioneNascita=== 0 && (
                                                    <MyAutoComplete id="txtComuneNascita" name="txtComuneNascita" 
                                                    url={process.env.REACT_APP_API_URL+'comuni'} 
                                                    optionname="Comune" optionvalue="CodComune"
                                                    onBlur={() => validator.current.showMessageFor('ComuneNascita')}
                                                    customvalue={ComuneNascita} onChange={handleComuneNascita}
                                                    />)}
                                                    {validator.current.message('ComuneNascita', ComuneNascita, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="txtCodiceFiscale">Codice Fiscale *</Label>
                                                    <Input type="text" name="txtCodiceFiscale" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('CodFiscale')}
                                                    value={CodFiscale} onChange={handleCodFiscale}
                                                    />
                                                    {validator.current.message('CodFiscale', CodFiscale, 'required|alpha_num|min:16', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={1}>
                                            <FormGroup>
                                                <Label>&nbsp;</Label>
                                                <Button type="button" className={ButtonClassName + " btn-small btn-block"} onClick={CalcolaCodFiscale} >Calcola</Button>
                                            </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtIndirizzo">Indirizzo Residenza *</Label>
                                                <Input type="text" name="txtIndirizzo" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('Indirizzo')}
                                                value={Indirizzo} onChange={handleIndirizzo}
                                                />
                                                {validator.current.message('Indirizzo', Indirizzo, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtComuneResidenza">Comune *</Label>
                                                <MyAutoComplete id="txtComuneResidenza" name="txtComuneResidenza" url={process.env.REACT_APP_API_URL+'comuni'} optionname="Comune" optionvalue="CodComune"
                                                onBlur={() => validator.current.showMessageFor('ComuneResidenza')}
                                                customvalue={ComuneResidenza} onChange={handleComuneResidenza}
                                                />
                                                {validator.current.message('ComuneResidenza', ComuneResidenza, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row> 
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                        <Col md={8}>
                                                <FormGroup>
                                                    <Label for="txtEmail">Email *</Label>
                                                    <Input type="email" name="txtEmail" autoComplete="new-password" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Email')}
                                                    value={Email} onChange={handleEmail}
                                                    />
                                                    {validator.current.message('Email', Email, 'required|email', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtCellulare">Cellulare</Label>
                                                    <Input type="text" name="txtCellulare" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                    onBlur={() => validator.current.showMessageFor('Cellulare')}
                                                    value={Cellulare} onChange={handleCellulare}
                                                    />
                                                    {validator.current.message('Cellulare', Cellulare, 'cphone', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="txtNote">Note [max 200 caratteri]</Label>
                                                    <Input type="textarea" name="txtNote" id="txtNote" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('Note')}
                                                    value={Note} onChange={handleNote} maxLength="200"
                                                    />
                                                    {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}