import React, { Component } from "react";
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import Home from "./Pages/Home";
import Welcome from "./Pages/Welcome";
import Login from "./Pages/Login";
import RecuperoPWD from "./Pages/RecuperoPWD";
import NewContratto_Azienda from "./Pages/CPServ/NewContratto_Azienda";
import NewContratto_Servizi from "./Pages/CPServ/NewContratto_Servizi";
import ModContratto_Servizi from "./Pages/CPServ/ModContratto_Servizi";
import NewOrdine_Azienda from "./Pages/CPServ/NewOrdine_Azienda";
import NewOrdine_AziendaCert from "./Pages/CPCert/NewOrdine_Azienda";
import NewPreventivo_AziendaCert from "./Pages/CPCert/NewPreventivo_Azienda";
import NewPreventivo_ServiziCert from "./Pages/CPCert/NewPreventivo_Servizi";
import ModPreventivo_ServiziCert from "./Pages/CPCert/ModPreventivo_Servizi";
import NewOrdine_Servizi from "./Pages/CPServ/NewOrdine_Servizi";
import NewOrdine_ImpiantoCert from "./Pages/CPCert/NewOrdine_Impianto";
import NewOrdine_ServiziCert from "./Pages/CPCert/NewOrdine_Servizi";
import ModOrdine_ServiziCert from "./Pages/CPCert/ModOrdine_Servizi";
import ModOrdine_Servizi from "./Pages/CPServ/ModOrdine_Servizi";
import NewOrdine_Responsabili from "./Pages/CPServ/NewOrdine_Responsabile";
import NewOrdine_Dipendenti from "./Pages/CPServ/NewOrdine_Dipendente";
import ModVerifica from "./Pages/CPCert/ModVerifica";
import {app_cpCert, app_cpService} from './Modules/Costanti';
export default class App extends Component {

    render() {
        
        const app_selected =process.env.REACT_APP_APPSELECT;
        switch (parseInt(app_selected))
            {
                case app_cpService:
                    {
                        return (
                                <Router>
                                    <Switch>
                                        <Route path="/recuperopassword" component={RecuperoPWD}></Route>
                                        <Route path="/login" component={Login}></Route>
                                        <Route path="/nuovoordineazienda" component={NewOrdine_Azienda}></Route>
                                        <Route path="/nuovoordineservizi" component={NewOrdine_Servizi}></Route>
                                        <Route path="/nuovoordineresponsabili" component={NewOrdine_Responsabili}></Route>
                                        <Route path="/nuovoordinedipendenti" component={NewOrdine_Dipendenti}></Route>
                                        <Route path="/modificaordineservizi" component={ModOrdine_Servizi}></Route>
                                        <Route path="/nuovocontrattoazienda" component={NewContratto_Azienda}></Route>
                                        <Route path="/nuovocontrattoservizi" component={NewContratto_Servizi}></Route>
                                        <Route path="/modificacontrattoservizi" component={ModContratto_Servizi}></Route>
                                        <Route exact path="/" component={Home}></Route>
                                        <Route path="/ordini" component={Home}></Route>
                                        <Route path="/contratti" component={Home}></Route>
                                        <Route path="/attestati" component={Home}></Route>
                                        <Route path="/welcome" component={Welcome}></Route>
                                        <Route path="/home" component={Home}></Route>
                                        <Route path="/aziende" component={Home}></Route>
                                        <Route path="/dipendenti" component={Home}></Route>
                                        <Route path="/utenti" component={Home}></Route>
                                        <Route path="/commerciali" component={Home}></Route>
                                        <Route path="/servizi" component={Home}></Route>
                                        <Route path="/listini" component={Home}></Route>
                                        <Route component={Home}></Route>
                                    </Switch>
                                </Router>
                        );
                        break;
                    }
                case app_cpCert:
                    {
                        return (
                                <Router>
                                    <Switch>
                                        <Route path="/recuperopassword" component={RecuperoPWD}></Route>
                                        <Route path="/login" component={Login}></Route>
                                        <Route exact path="/" component={Home}></Route>
                                        <Route path="/ordini" component={Home}></Route>
                                        <Route path="/preventivi" component={Home}></Route>
                                        <Route path="/attestati" component={Home}></Route>
                                        <Route path="/welcome" component={Welcome}></Route>
                                        <Route path="/home" component={Home}></Route>
                                        <Route path="/aziende" component={Home}></Route>
                                        <Route path="/dipendenti" component={Home}></Route>
                                        <Route path="/utenti" component={Home}></Route>
                                        <Route path="/commerciali" component={Home}></Route>
                                        <Route path="/servizi" component={Home}></Route>
                                        <Route path="/servizisa" component={Home}></Route>
                                        <Route path="/spesedirettori" component={Home}></Route>
                                        <Route path="/serviziexp" component={Home}></Route>
                                        <Route path="/servizimt" component={Home}></Route>
                                        <Route path="/impelettrici" component={Home}></Route>
                                        <Route path="/listini" component={Home}></Route>
                                        <Route path="/allemail" component={Home}></Route>
                                        <Route path="/nuovoordineazienda" component={NewOrdine_AziendaCert}></Route>
                                        <Route path="/nuovoordineimpianto" component={NewOrdine_ImpiantoCert}></Route>
                                        <Route path="/nuovoordineservizi" component={NewOrdine_ServiziCert}></Route>
                                        <Route path="/modificaordineservizi" component={ModOrdine_ServiziCert}></Route>
                                        <Route path="/nuovopreventivoazienda" component={NewPreventivo_AziendaCert}></Route>
                                        <Route path="/nuovopreventivoservizi" component={NewPreventivo_ServiziCert}></Route>
                                        <Route path="/modificapreventivoservizi" component={ModPreventivo_ServiziCert}></Route>
                                        <Route path="/modificaverifica" component={ModVerifica}></Route>
                                        <Route path="/usernotifications" component={Home}></Route>
                                        <Route component={Home}></Route>
                                    </Switch>
                                </Router>
                        );
                        break;
                    }
                
                default:
                {
                    return (
                            <Router>
                                <Switch>
                                    <Route path="/recuperopassword" component={RecuperoPWD}></Route>
                                    <Route path="/login" component={Login}></Route>
                                    <Route path="/welcome" component={Welcome}></Route>
                                    <Route path="/home" component={Home}></Route>
                                    <Route exact path="/" component={Welcome}></Route>
                                    <Route component={Welcome}></Route>
                                </Switch>
                            </Router>
                    );
                    
                }
                
            }
    }
}