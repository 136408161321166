import React, {useState,useRef,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyDatePicker from '../MyDatePicker';
import MyAutoComplete from '../MyAutoComplete';
import MyAlert from '../MyAlert';
import {GetMessage} from "../../Modules/Message";
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import MyConfirmInserimento from '../MyConfirm';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import {DateDiff, GetToken, ccyFormat} from "../../Modules/Funzioni";
import {TipoDistintaProvv_Commerciale, TipoDistintaProvv_Ispettore, app_cpService} from "../../Modules/Costanti";
import ReactExport from "react-export-excel";
import { isDate } from "moment";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#2370A4 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ExportToExcel (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Distinta">
                <ExcelColumn label="N.Ordine" value="NumeroOrdine"/>
                <ExcelColumn label="Data Ordine" value="DataOrdine"/>
                <ExcelColumn label="Cliente" value="RagioneSociale"/>
                <ExcelColumn label="Data Verifica" value="DataVerifica"/>
                <ExcelColumn label="Data Saldo" value="DataSaldo"/>
                <ExcelColumn label="Data Ordine" value="DataOrdine"/>
                <ExcelColumn label="Imponibile" value="Imponibile"/>
                <ExcelColumn label="Descrizione" value="Descrizione"/>
                <ExcelColumn label="% Provvigione" value="Provvigione"/>
                <ExcelColumn label="Importo Provvigione" value="ImportoProvvigione"/>
                <ExcelColumn label="Spesa" value="Spesa"/>
            </ExcelSheet>
        </ExcelFile>
    );
}

export default function NewDistintaProvv (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    const [isSubmit, setisSubmit] = React.useState(false);
    const [TipoDistinta, setTipoDistinta] = React.useState("0");
    const [TipoPagamento, setTipoPagamento] = React.useState("0");
    const [TotaleSelezionato, setTotaleSelezionato] = React.useState(0);
    const [TotaleProvvigioni, setTotaleProvvigioni] = React.useState(0);
    const [TotaleSpese, setTotaleSpese] = React.useState(0);
    const [Iban, setIban] = React.useState("");
    const [DataPagamento, setDataPagamento] = React.useState("");
    const [IDRiferimento, setIDRiferimento] = React.useState("");
    const [DesRiferimento, setDesRiferimento] = React.useState("");
    const [DisableFilter, setDisableFilter] = useState(false);
    const [OpenConfirmInserimento, setOpenConfirmInserimento] = useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = React.useState([]);
    const [RicDataVerificaInizio, setRicDataVerificaInizio] = React.useState("");
    const [RicDataVerificaFine, setRicDataVerificaFine] = React.useState("");
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    
    const onCloseConfirmInserimento = (event) => {
        setOpenConfirmInserimento(false);
    };
    const onConfirmInserimento = (event) => {
        setOpenConfirmInserimento(false);
        submitForm(true);
    };
    const handleRicDataVerificaInizio = (value) => {  
        setRicDataVerificaInizio(value); 
        if (isDate(RicDataVerificaFine)===""){
            if (DateDiff(value, RicDataVerificaFine)<0){
                setRicDataVerificaFine("");
            }
        }
        setDisableFilter(false);
    };
    
    const handleRicDataVerificaFine = (value) => {  
        setRicDataVerificaFine(value);  
        if (isDate(RicDataVerificaInizio)===""){
            if (DateDiff(RicDataVerificaInizio, value)<0){
                setRicDataVerificaInizio("");
            }
        }
        setDisableFilter(false);
    };
    const normalizeData = (response) => {
        
        let array = [];
        
        response.map(p => {
            const _prezzoScontato = parseFloat(p.PrezzoTotale) - (parseFloat(p.PrezzoTotale)*parseFloat(p.sconto/100)) 
            if (parseInt(TipoDistinta) === TipoDistintaProvv_Commerciale){
                switch (parseInt(IDRiferimento)) {
                    case p.Commerciale_id:
                        {
                            let importoProvv = _prezzoScontato * (p.ProvvigioneCommerciale/100);
                            const spesa = parseFloat(p.SpesaCommerciale);
                            const item = {Ordine_id: p.Ordine_id, RagioneSociale: p.RagioneSociale , DataSaldo: p.DataSaldo, DataVerifica: p.DataVerifica, NumeroOrdine: p.NumOrdine , DataOrdine: p.DataOrdine, Descrizione: 'Commerciale' , Provvigione: p.ProvvigioneCommerciale, Imponibile: _prezzoScontato, ImportoProvvigione: importoProvv, Spesa: spesa}
                            array.push(item);
                            break;
                        }
                    case p.Direttore_id:
                        {
                            let importoProvv = _prezzoScontato * (p.ProvvigioneDirettore/100);
                            const spesa = parseFloat(p.SpesaDirettore);
                            const item = {Ordine_id: p.Ordine_id, RagioneSociale: p.RagioneSociale , DataSaldo: p.DataSaldo, DataVerifica: p.DataVerifica, NumeroOrdine: p.NumOrdine , DataOrdine: p.DataOrdine, Descrizione: 'Direttore', Provvigione: p.ProvvigioneDirettore, Imponibile: _prezzoScontato, ImportoProvvigione: importoProvv, Spesa: spesa}
                            array.push(item);
                            break;
                        }
                    case p.Executive_id:
                        {
                            let importoProvv = _prezzoScontato * (p.ProvvigioneExecutive/100);
                            const item = {Ordine_id: p.Ordine_id, RagioneSociale: p.RagioneSociale , DataSaldo: p.DataSaldo, DataVerifica: p.DataVerifica, NumeroOrdine: p.NumOrdine , DataOrdine: p.DataOrdine, Descrizione: 'Executive', Provvigione: p.ProvvigioneExecutive, Imponibile: _prezzoScontato, ImportoProvvigione: importoProvv, Spesa: 0}
                            array.push(item);
                            break;
                        }
                    case p.Partner_id:
                        {
                            let importoProvv = _prezzoScontato * (p.ProvvigionePartner/100);
                            const item = {Ordine_id: p.Ordine_id, RagioneSociale: p.RagioneSociale , DataSaldo: p.DataSaldo, DataVerifica: p.DataVerifica, NumeroOrdine: p.NumOrdine , DataOrdine: p.DataOrdine, Descrizione: 'Partner', Provvigione: p.ProvvigionePartner, Imponibile: _prezzoScontato, ImportoProvvigione: importoProvv, Spesa: 0}
                            array.push(item);
                            break;
                        }
                    case p.Manager_id:
                        {
                            let importoProvv = _prezzoScontato * (p.ProvvigioneManager/100);
                            const item = {Ordine_id: p.Ordine_id, RagioneSociale: p.RagioneSociale , DataSaldo: p.DataSaldo, DataVerifica: p.DataVerifica, NumeroOrdine: p.NumOrdine , DataOrdine: p.DataOrdine, Descrizione: 'Manager', Provvigione: p.ProvvigioneManager, Imponibile: _prezzoScontato, ImportoProvvigione: importoProvv, Spesa: 0}
                            array.push(item);
                            break;
                        }
                }
            }
            else if (parseInt(TipoDistinta) === TipoDistintaProvv_Ispettore && parseInt(IDRiferimento) === p.Ispettore_id) {
                let importoProvv = _prezzoScontato * (p.ProvvigioneIspettore/100);
                const item = {Ordine_id: p.Ordine_id, RagioneSociale: p.RagioneSociale , DataSaldo: p.DataSaldo, DataVerifica: p.DataVerifica, NumeroOrdine: p.NumOrdine , DataOrdine: p.DataOrdine, Descrizione: 'Ispettore', Provvigione: p.ProvvigioneIspettore, Imponibile: _prezzoScontato, ImportoProvvigione: importoProvv, Spesa: 0}
                array.push(item);
            }

        });
        
        setData(array);
    };

    const handleRiferimento = (value,text) => {  
        setIDRiferimento(value); 
        setDesRiferimento(text);
        setDisableFilter(false); 
    };
    
    const handleDataPagamento = (value) => {  
        setDataPagamento(value);  
    };
    
    const handleTipoDistinta = (event) => {
        setTipoDistinta(event.target.value);
        setIDRiferimento(""); 
        setDesRiferimento("");
        setDisableFilter(false);
    };
    
    const handleTipoPagamento = (event) => {
        setTipoPagamento(event.target.value);
    };
    
    const handleIban = (event) => {
        setIban(event.target.value);
    };

    const onFilter = () => {
        setDisableFilter(true);
        setIsFilter(true);
    }; 
    
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    useEffect(() => {   
        if (isFilter){
            setIsLoading(true);
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'distintaprovvigionesearch'
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    const fd = new FormData();
                    fd.append("DataVerificaInizio", RicDataVerificaInizio);
                    fd.append("DataVerificaFine", RicDataVerificaFine);
                    fd.append("TipoDistinta", TipoDistinta);
                    fd.append("IDPersona", IDRiferimento);
                    
                    //idazienda/0/codfiscale/0/cognome/0
                    result = await ax.post(url,fd,axiosConfig);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);
    
                }
                setIsLoading(false);    
                if (result.data!==undefined)    {
                    normalizeData(result.data);
                }
                else {
                    setData([]);
                }
            })();
            setSelected([]);
            setTotaleSelezionato(0);
            setTotaleProvvigioni(0);
            setTotaleSpese(0);
            setIsFilter(false); 
        }
        
        
    }, [isFilter]);   

    const submitForm= (isConfirm) => 
    {
        if (isSubmit) return;
        if (selected.length===0){
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert("Per confermare è necessario selezionare almeno un pagamento!");
            setOpenAlert(true);
            return;
        }
        if (validator.current.allValid()) {
            if (!isConfirm) {
                setOpenConfirmInserimento(true);
                return;
            }
            setisSubmit(true);
            const fd = new FormData();
            if (data!==undefined){
                if (data.length>0){
                    fd.append("selected", JSON.stringify(selected));
                }
            }
            fd.append("DataPagamento", DataPagamento);
            fd.append("TipoPagamento", parseInt(TipoPagamento));
            fd.append("Riferimento_id", IDRiferimento);
            fd.append("TipoDistinta", TipoDistinta);
            fd.append("Importo", TotaleSelezionato);
            fd.append("ImportoProvvigioni", TotaleProvvigioni);
            fd.append("ImportoSpese", TotaleSpese);
            fd.append("IBAN", Iban);
            
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
                    
            ax
            .post(process.env.REACT_APP_API_URL+'distintaprovvigioneinsert', fd,axiosConfig)
            .then((response) => {
                
                if (response.status === 200) {
                    if (response.data.error==="OK"){
                        props.handleClose();
                        props.onInsert();
                    }
                    else {
                        let msg="";
                        let ris=JSON.stringify(response.data);
                        ris=ris.replaceAll("\"", "");
                        ris=ris.replaceAll("{", "");
                        ris=ris.replaceAll("}", "");
                        ris=ris.replaceAll("[", "");
                        ris=ris.replaceAll("]", "");
                        msg="errore durante l'inserimento! " + ris;
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    }
                }
                else
                { 
                const msg=GetMessage(response.status,response.data.message);
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
                
                }
            })
            .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
                
            });
            setisSubmit(false);

        } else {
            validator.current.showMessages();
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert(MsgAlertDefault);
            setOpenAlert(true);
        }
    };
    const handleRowAllClick = (event) => {
        if (event.target.checked) {
            let newSelecteds = [];
            data.map(item => {
                newSelecteds.push(item);
            });
            setSelected(newSelecteds);
            CalcolaTotaleSelezionati(newSelecteds);
            return;
        }
        setSelected([]);
        CalcolaTotaleSelezionati([]);
    };
    const handleRowClick = (Ordine_id) => {
        
        const index = selected.findIndex(e => e.Ordine_id === Ordine_id);
        let newSelected = selected;
        
        if (index === -1) {
            const item = data.filter(e => e.Ordine_id === Ordine_id);
            if (item.length === 1) newSelected.push(item[0]);
            
        } else 
        {
            newSelected.splice(index, 1)
        }

        setSelected(newSelected);
        CalcolaTotaleSelezionati(newSelected);
        
        
    };
    const CalcolaTotaleSelezionati  = (_selected) => {
        const sum = _selected.reduce((acc, x) => { return acc + (parseFloat(x.ImportoProvvigione) + parseFloat(x.Spesa)); }, 0);
        const sumProvv = _selected.reduce((acc, x) => { return acc + parseFloat(x.ImportoProvvigione); }, 0);
        const sumSpese = _selected.reduce((acc, x) => { return acc + parseFloat(x.Spesa); }, 0);
        setTotaleSelezionato(sum);
        setTotaleProvvigioni(sumProvv);
        setTotaleSpese(sumSpese);
    };
    const isSelected = (name) => selected.map(e => e.Ordine_id).indexOf(name) !== -1;

    const ButtonClassName="btn-mbg-cert";
    const HeadClassName="bg-head-form-cert";
    
    return (
        <div>
            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>     
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={HeadClassName}>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Inserisci Distinta Provvigioni
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => submitForm(false)}>
                            Salva {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        <form autoComplete ="new-password">
                        <MyConfirmInserimento title="Conferma Inserimento" message="Continuando con l'operazione non sarà più possibile fare modifiche agli importi, si desidera confermare l'inserimento?" handleClose={onCloseConfirmInserimento} handleConfirm={onConfirmInserimento} open={OpenConfirmInserimento}/>
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col xl={2} lg={4} md={3} xs={12}>
                                                <FormGroup>
                                                <Label for="selTipoDistinta">Tipo Distinta *</Label>
                                                    <Input type="select" name="selTipoDistinta" 
                                                    id="selTipoDistinta" placeholder="" 
                                                        value={TipoDistinta} onChange={handleTipoDistinta}>
                                                            <option key="0" value="0">Commerciale</option>
                                                            <option key="1" value="1">Ispettore</option>
                                                    </Input>
                                                    {validator.current.message('TipoDistinta', TipoDistinta, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            {
                                                TipoDistinta !== "" && (
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="txtPersona">{parseInt(TipoDistinta) === TipoDistintaProvv_Commerciale ? "Commerciale *" : "Ispettore *" }</Label>
                                                        <MyAutoComplete id="txtPersona" name="txtPersona" 
                                                            url={parseInt(TipoDistinta) === TipoDistintaProvv_Commerciale ? process.env.REACT_APP_API_URL+'commercialesinglelist' : process.env.REACT_APP_API_URL+'ispettoresinglelist'} 
                                                            optionname={parseInt(TipoDistinta) === TipoDistintaProvv_Commerciale ? "DesCommerciale" : "DesIspettore"} optionvalue="id"
                                                            onBlur={() => validator.current.showMessageFor('Persona')}
                                                            customvalue={DesRiferimento} onChange={handleRiferimento} 
                                                        
                                                            />
                                                        {validator.current.message('Persona', IDRiferimento, 'required|alpha_num', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>
                                                )

                                            }
                                            <Col xl={2} lg={4} md={3} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtRicDataVerificaInizio">Verificato dal</Label>
                                                    <MyDatePicker name="txtRicDataVerificaInizio" className="form-control"
                                                        value={RicDataVerificaInizio} onChange={handleRicDataVerificaInizio} autoComplete="new-password"
                                                        />
                                                </FormGroup>
                                            </Col>
                                            <Col xl={2} lg={4} md={3} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtRicDataVerificaFine">Al</Label>
                                                    <MyDatePicker name="txtRicDataVerificaFine" className="form-control" 
                                                        value={RicDataVerificaFine} onChange={handleRicDataVerificaFine} autoComplete="new-password"
                                                        />
                                                </FormGroup>
                                            </Col> 
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label>&nbsp;</Label>
                                                    <Button type="button" className={ButtonClassName + " btn-sm btn-block"} disabled={DisableFilter || DesRiferimento === ""} onClick={onFilter} >Ricerca</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={2}>
                                                <FormGroup>
                                                <Label for="selTipoPagamento">Tipo Pagamento</Label>
                                                    <Input type="select" name="selTipoPagamento" 
                                                    id="selTipoPagamento" placeholder="" 
                                                        value={TipoPagamento} onChange={handleTipoPagamento}>
                                                        <option key=""></option>
                                                        <option key="1" value="1">Assegno bancario</option>
                                                        <option key="2" value="2">Assegno Circolare</option>
                                                        <option key="3" value="3">Bonifico Bancario</option>
                                                        <option key="4" value="4">Denaro Contante</option>
                                                        <option key="5" value="5">Pos</option>
                                                    </Input>
                                                    {validator.current.message('TipoPagamento', TipoPagamento, 'numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtIban">IBAN</Label>
                                                    <Input type="text" name="txtIban" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Iban')}
                                                    value={Iban} onChange={handleIban}
                                                    />
                                                    {validator.current.message('Iban', Iban, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtDataPagamento">Data Pagamento</Label>
                                                    <MyDatePicker name="txtDataPagamento" 
                                                    onBlur={() => validator.current.showMessageFor('DataPagamento')}
                                                    value={DataPagamento} onChange={handleDataPagamento} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataPagamento', DataPagamento, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Totale Provvigioni</Label>
                                                    <Input type="text" name="txtTotal" autoComplete="new-password" placeholder="" 
                                                    value={ccyFormat(TotaleProvvigioni)} 
                                                    readOnly disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Totale Spese</Label>
                                                    <Input type="text" name="txtTotal" autoComplete="new-password" placeholder="" 
                                                    value={ccyFormat(TotaleSpese)} 
                                                    readOnly disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Totale Selezionato</Label>
                                                    <Input type="text" name="txtTotal" autoComplete="new-password" placeholder="" 
                                                    value={ccyFormat(TotaleSelezionato)} 
                                                    readOnly disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={1}>
                                               &nbsp;
                                            </Col>
                                            {
                                                selected.length > 0 && 
                                                (<Col md={2}>
                                                    <FormGroup>
                                                        <div><Label>&nbsp;</Label></div>
                                                        <ExportToExcel data={selected}/>    
                                                    </FormGroup>
                                                </Col>)
                                            }
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>  
                                    {isLoading && ( <CircularProgress className="sp-center" size={150} />)}         
                                    <TableContainer component={Paper} className={classes.container}>
                                        <Table className={classes.table} aria-label="spanning table" size="small">
                                            <TableHead>            
                                                <TableRow>
                                                    <TableCell className="grid-head" align="center">
                                                        <Checkbox
                                                            checked={data.length > 0 && selected.length === data.length}
                                                            onChange={handleRowAllClick}
                                                            inputProps={{ 'aria-label': 'Seleziona Tutto' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="grid-head" align="center">N. Ordine</TableCell>
                                                    <TableCell className="grid-head" align="center">del</TableCell>
                                                    <TableCell className="grid-head" align="center">Cliente</TableCell>
                                                    <TableCell className="grid-head" align="center">D. Verifica</TableCell>
                                                    <TableCell className="grid-head" align="center">D. Saldo</TableCell>
                                                    <TableCell className="grid-head" align="center">Imponibile</TableCell>
                                                    <TableCell className="grid-head" align="center">Desc.</TableCell>
                                                    <TableCell className="grid-head" align="center">%</TableCell>
                                                    <TableCell className="grid-head" align="center">Provvigione</TableCell>
                                                    <TableCell className="grid-head" align="center">Spesa</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { data.length>0? (
                                                    data.map((row) => {
                                                        const isItemSelected = isSelected(row.Ordine_id);
                                                        const labelId = `row-${row.Ordine_id}`;
                                                        
                                                        return (
                                                            
                                                        <TableRow key={row.Ordine_id} hover tabIndex={-1} onClick={(event) => handleRowClick(row.Ordine_id)}>
                                                            <TableCell>
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    style={{transform: "scale(1.2)"}}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{row.NumeroOrdine}</TableCell>
                                                            <TableCell>{row.DataOrdine}</TableCell>
                                                            <TableCell>{row.RagioneSociale}</TableCell>
                                                            <TableCell>{row.DataVerifica}</TableCell>
                                                            <TableCell>{row.DataSaldo}</TableCell>
                                                            <TableCell>{row.Imponibile}</TableCell>
                                                            <TableCell>{row.Descrizione}</TableCell>
                                                            <TableCell>{row.Provvigione}</TableCell>
                                                            <TableCell>{ccyFormat(row.ImportoProvvigione)}</TableCell>
                                                            <TableCell>{row.Spesa}</TableCell>
                                                        </TableRow>
                                                        );
                                                    })
                                                ):(
                                                    <TableRow>
                                                        <TableCell colSpan={11} style={{textAlign:"center"}}><b>- Ordini non trovati -</b></TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
        