import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function MyAlert(props) {
  const classes = useStyles();
 
  /*
  severity:
    error
    warning
    info
    success
  */
  return (
    <div className={classes.root}>
      <Snackbar anchorOrigin={{
          vertical: props.vertical,
          horizontal: props.horizontal,
        }} open={props.open} autoHideDuration={6000} onClose={props.handleClose}>
        <MuiAlert elevation={6} variant="filled" onClose={props.handleClose} severity={props.severity}>{props.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}