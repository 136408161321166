import React from 'react';  
import {Button} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/DeleteForever';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Toolbar } from '@material-ui/core';
import NewServizioRischioEsplosione from './NewServizioRischioEsplosione';
import ModServizioRischioEsplosione from './ModServizioRischioEsplosione';
import {GetMessage} from "../../Modules/Message";
import MyConfirm from '../MyConfirm';
/*import { Servizio_Azienda } from "../Modules/Costanti";*/
import MyAlert from '../MyAlert';
import Avatar from '@material-ui/core/Avatar';
import {GetToken} from '../../Modules/Funzioni';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    BtnEdit: {
        '&:hover':{
            cursor:"pointer"
      } 
    }
}));
  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Del', string: false, disablePadding: true, label: '' },
    { id: 'Mod', string: false, disablePadding: true, label: '' },
    { id: 'OrarioMin', string: false, disablePadding: true, label: 'Da N.Ore' },
    { id: 'OrarioMax', string: false, disablePadding: true, label: 'A N.Ore' },
    { id: 'Prezzo', string: false, disablePadding: true, label: 'Prezzo' },
];


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [order, setOrder] = React.useState('asc');

  const [orderBy, setOrderBy] = React.useState('OrarioMin');
  const [page, setPage] = React.useState(0);  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(20);  
  const [dense, setDense] = React.useState(false);
  const [isOpenNewServizio, setIsOpenNewServizio] = useState(false);
  const [isOpenModServizio, setIsOpenModServizio] = useState(false);
  const [IdModServizio, setIdModServizio] = useState("");
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
  //-----------
  //confirm object
  const MsgConfirm="Si desidera procedere alla cancellazione del servizio?";
  const [OpenConfirm, setOpenConfirm] = React.useState(false);
  const [IdDelConfirm, setIdDelConfirm] = React.useState(false);
  const onCloseConfirm = (event) => {
    setOpenConfirm(false);
};
const toggleDelServizio = (_Id) => {
    setIdDelConfirm(_Id);
    setOpenConfirm(true);
  }
const onDelConfirm = (event) => {
    setOpenConfirm(false);
    setIsLoading(true);    
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    (async () => {
        let result=[];
        try{
            let url=process.env.REACT_APP_API_URL+'servizioexpdelete/' + IdDelConfirm;
                       
            //idazienda/0/codfiscale/0/cognome/0
            result = await ax.post(url);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
        if (result.data!==undefined)    {
            onLoadData();
        }
        else {
            setData([]);
        }
    })(); 

};
const toggleNewServizio = () => {
    setIsOpenNewServizio(!isOpenNewServizio);
}
const toggleModServizio = (mId) => {
    setIsOpenModServizio(!isOpenModServizio);
    if (!isOpenModServizio){
        setIdModServizio(mId);
    }
}

  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'servizioexplist'
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                //idazienda/0/codfiscale/0/cognome/0
                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })(); 
        
}, [isSending]);   


  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onLoadData = () => {
    setIsSending(!isSending);
}; 

const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
let emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

if (emptyRows>5){emptyRows=3}
  return (  
    
    <div className={classes.root}>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/>
        <MyConfirm title="Conferma Cancellazione" message={MsgConfirm} handleClose={onCloseConfirm} handleConfirm={onDelConfirm} open={OpenConfirm}/>          
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        {isOpenModServizio && <ModServizioRischioEsplosione onUpdate={onLoadData} handleClose={toggleModServizio} id={IdModServizio}/>}
        {isOpenNewServizio && <NewServizioRischioEsplosione onInsert={onLoadData} handleClose={toggleNewServizio}/>}
        <Paper className={classes.paper}> 
        <Toolbar>
        <div style={{width:"100%" }}><Button onClick={toggleNewServizio} className="btn-mbg-cert">Inserisci</Button></div>
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                                className="rt-tr-group"
                            >
                                <TableCell id={labelId} scope="row">
                                    <Avatar className={"btn-mbg-cert " + classes.BtnEdit}  onClick={() => toggleModServizio(row.id)}>
                                        <AssignmentIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell>
                                    <Avatar className={"btn-mbg-cert " + classes.BtnEdit}  onClick={() => toggleDelServizio(row.id)}>
                                        <DeleteIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell>{row.OrarioMin}</TableCell>
                                <TableCell>{row.OrarioMax>0 ? (row.OrarioMax):('')}</TableCell>
                                <TableCell>{row.Prezzo}</TableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={5} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[20, 40, 60]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 