import React, { useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { FormGroup, Label, Col, Input, Row } from "reactstrap";
import MyDatePicker from '../MyDatePicker';
import MyAlert from '../MyAlert';
import { GetMessage } from "../../Modules/Message";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GetToken, VerifyPDF } from "../../Modules/Funzioni";
import "../../Modules/Costanti";
import SimpleReactValidator from 'simple-react-validator';
import '../../css/Form.css';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModStrumentoVerifica(props) {
    const classes = useStyles();
    const MsgAlertDefault = "Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert, setMsgAlert] = React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [Marca, setMarca] = React.useState("");
    const [Modello, setModello] = React.useState("");
    const [Matricola, setMatricola] = React.useState("");
    const [DataTaratura, setDataTaratura] = React.useState("");
    const [DataCertificatoTaratura, setDataCertificatoTaratura] = React.useState("");
    const [NumCertificatoTaratura, setNumCertificatoTaratura] = React.useState("");
    const [ScadenzaTaratura, setScadenzaTaratura] = React.useState("");
    const [FileCertificatoErr, setFileCertificatoErr] = React.useState(false);
    const [FileCertificato, setFileCertificato] = React.useState("");
    const handleMarca = (event) => {
        setMarca(event.target.value);
    };
    const handleModello = (event) => {
        setModello(event.target.value);
    };
    const handleMatricola = (event) => {
        setMatricola(event.target.value);
    };
    const handleDataTaratura = (value) => {
        setDataTaratura(value);
    };
    const handleScadenzaTaratura = (value) => {
        setScadenzaTaratura(value);
    };
    const handleDataCertificatoTaratura = (value) => {
        setDataCertificatoTaratura(value);
    };
    const handleNumCertificatoTaratura = (event) => {
        setNumCertificatoTaratura(event.target.value);
    };
    React.useEffect(() => {
        if (props.id !== "") {
            LoadData();
        }
    }, [props.id]);

    const hiddenFileCertificato = React.useRef(null);

    const handleClickCertificato = (event) => {
        hiddenFileCertificato.current.click();
    };

    const handleChangeCertificato = (event) => {
        const _file = event.target.files[0];
        const msg = VerifyPDF(_file);

        if (msg !== "") {
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert(msg);
            setOpenAlert(true);
            setFileCertificatoErr(true);
        }
        else {
            setFileCertificato(_file);
            setFileCertificatoErr(false);
        }
    };
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
        },
        validators: {
            cphone: {  // name the rule
                //message: 'obbligatorio o non corretto',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
                },
                //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                //required: true  // optional
            }
        }
    }));

    const LoadData = (event) => {
        if (props.id !== "") {
            setIsLoading(true);
            const token = GetToken(localStorage.getItem("token"));
            const ax = axios;
            ax.defaults.headers.common['Authorization'] = "Bearer " + token;
            (async () => {
                let result = [];
                try {
                    let url = process.env.REACT_APP_API_URL + 'strumentiverifiche/' + props.id;
                    result = await ax.post(url);
                    setMarca(result.data[0].Marca);
                    setModello(result.data[0].Modello);
                    setMatricola(result.data[0].Matricola);
                    setDataTaratura(result.data[0].DataTaratura);
                    setScadenzaTaratura(result.data[0].ScadenzaTaratura);
                    setDataCertificatoTaratura(result.data[0].DataCertificatoTaratura);
                    setNumCertificatoTaratura(result.data[0].NumCertificatoTaratura);
                }
                catch (error) {
                    let msg = ""
                    if (error.response !== undefined) {
                        msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
                    }
                    else {
                        msg = GetMessage(undefined, "");
                    }
                    setSeverityAlert("warning")
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);
            })();
        }
    }

    const submitForm = (event) => {
        if (validator.current.allValid() && FileCertificatoErr !== true) {
            setisSubmit(true);
            const fd = new FormData();
            fd.append("id", props.id);
            fd.append("Marca", Marca);
            fd.append("Modello", Modello);
            fd.append("Matricola", Matricola);
            fd.append("DataTaratura", DataTaratura);
            fd.append("ScadenzaTaratura", ScadenzaTaratura);
            fd.append("NumCertificatoTaratura", NumCertificatoTaratura);
            fd.append("DataCertificatoTaratura", DataCertificatoTaratura);
            fd.append('FileCertificato', FileCertificato);

            const token = GetToken(localStorage.getItem("token"));
            const ax = axios;
            ax.defaults.headers.common['Authorization'] = "Bearer " + token;
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            ax

                .post(process.env.REACT_APP_API_URL + 'strumentiverificheupdate', fd, axiosConfig)
                .then((response) => {


                    if (response.status === 200) {
                        if (response.data.error === "OK") {
                            props.handleClose();
                            props.onUpdate();
                        }
                        else {
                            let msg = "";
                            let ris = JSON.stringify(response.data);
                            ris = ris.replaceAll("\"", "");
                            ris = ris.replaceAll("{", "");
                            ris = ris.replaceAll("}", "");
                            ris = ris.replaceAll("[", "");
                            ris = ris.replaceAll("]", "");
                            msg = "errore durante la modifica! " + ris;
                            setSeverityAlert("error")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                        }
                    }
                    else {
                        const msg = GetMessage(response.status, response.data.message);
                        setSeverityAlert("error")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    }
                })
                .catch((error) => {
                    let msg = ""
                    if (error.response !== undefined) {
                        msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
                    }
                    else {
                        msg = GetMessage(undefined, "");
                    }
                    setSeverityAlert("error")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                });
            setisSubmit(false);
        } else {
            validator.current.showMessages();
            setSeverityAlert("error")
            setMsgAlert(MsgAlertDefault);
            //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
            setOpenAlert(true);
        }
    }

    return (
        <div>
            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert} />
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className="bg-head-form-cert">
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Modifica Strumento di verifica
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitForm}>
                            Salva {isSubmit ? (<span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>) : (<span></span>)}
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        {isLoading ? (<CircularProgress className="sp-center" />) : (<span></span>)}
                        <form autoComplete ="new-password">
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtMarca">Marca *</Label>
                                                    <Input type="text" name="txtMarca" autoComplete="new-password" placeholder=""
                                                        onBlur={() => validator.current.showMessageFor('Marca')}
                                                        value={Marca} onChange={handleMarca}
                                                    />
                                                    {validator.current.message('Marca', Marca, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtModello">Modello *</Label>
                                                    <Input type="text" name="txtModello" autoComplete="new-password" placeholder=""
                                                        onBlur={() => validator.current.showMessageFor('Modello')}
                                                        value={Modello} onChange={handleModello}
                                                    />
                                                    {validator.current.message('Modello', Modello, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtMatricola">Matricola *</Label>
                                                    <Input type="text" name="txtMatricola" autoComplete="new-password" placeholder=""
                                                        onBlur={() => validator.current.showMessageFor('Matricola')}
                                                        value={Matricola} onChange={handleMatricola}
                                                    />
                                                    {validator.current.message('Matricola', Matricola, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={4}>
                                                <Label for="txtDataTaratura">Data Taratura *</Label>
                                                <MyDatePicker name="txtDataTaratura"
                                                    onBlur={() => validator.current.showMessageFor('DataTaratura')}
                                                    value={DataTaratura} onChange={handleDataTaratura} autoComplete="new-password"
                                                />
                                                {validator.current.message('DataTaratura', DataTaratura, 'required|data', { className: 'text-danger' })}
                                            </Col>
                                            <Col md={4}>
                                                <Label for="txtScadenzaTaratura">Scadenza Taratura *</Label>
                                                <MyDatePicker name="txtScadenzaTaratura"
                                                    onBlur={() => validator.current.showMessageFor('ScadenzaTaratura')}
                                                    value={ScadenzaTaratura} onChange={handleScadenzaTaratura} autoComplete="new-password"
                                                />
                                                {validator.current.message('ScadenzaTaratura', ScadenzaTaratura, 'required|data', { className: 'text-danger' })}
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <input
                                                        type="file"
                                                        ref={hiddenFileCertificato}
                                                        onChange={handleChangeCertificato}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <Label>&nbsp;</Label>
                                                    <Button onClick={handleClickCertificato} className="btn-mbg-cert btn-block">Carica Certificato</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={6}>
                                                <Label for="txtDataCertificatoTaratura">Data Certificato Taratura *</Label>
                                                <MyDatePicker name="txtDataCertificatoTaratura"
                                                    onBlur={() => validator.current.showMessageFor('DataCertificatoTaratura')}
                                                    value={DataCertificatoTaratura} onChange={handleDataCertificatoTaratura} autoComplete="new-password"
                                                />
                                                {validator.current.message('DataCertificatoTaratura', DataCertificatoTaratura, 'required|data', { className: 'text-danger' })}
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="txtNumCertificatoTaratura">N. Certificato Taratura *</Label>
                                                    <Input type="text" name="txtNumCertificatoTaratura" autoComplete="new-password" placeholder=""
                                                        onBlur={() => validator.current.showMessageFor('NumCertificatoTaratura')}
                                                        value={NumCertificatoTaratura} onChange={handleNumCertificatoTaratura}
                                                    />
                                                    {validator.current.message('NumCertificatoTaratura', NumCertificatoTaratura, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}