import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
/*import { blue } from '@material-ui/core/colors';*/
import { makeStyles } from '@material-ui/core/styles';  
import { app_cpService } from '../Modules/Costanti';

const useStyles = makeStyles((theme) => ({
  primary: {
    /*backgroundColor: blue[500],*/
    backgroundColor:'#3f51b5',
    contrastText: '#fff',
    color:'#fff',
    fontWeight:'bold',
    "&:hover": {
      /*backgroundColor: blue[900],*/
      backgroundColor:'#00064C'
    },
  },
  secondary: {
    /*borderColor: blue[500],*/
    borderColor: '#3f51b5',
    border:'1px solid',
    /*color:blue[500],*/
    color:'#3f51b5',
    fontWeight:'bold',
  },
  primaryCert: {
    /*backgroundColor: blue[500],*/
    backgroundColor:'#2370A4',
    contrastText: '#fff',
    color:'#fff',
    fontWeight:'bold',
    "&:hover": {
      /*backgroundColor: blue[900],*/
      backgroundColor:'#254F6B'
    },
  },
  secondaryCert: {
    /*borderColor: blue[500],*/
    borderColor: '#2370A4',
    border:'1px solid',
    /*color:blue[500],*/
    color:'#2370A4',
    fontWeight:'bold',
  },
}));

export default function MyConfirm(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  let ButtonClassPrimary="";
  let ButtonClassSecondary="";
  const app_selected =process.env.REACT_APP_APPSELECT;
  if (parseInt(app_selected)===app_cpService) {
    ButtonClassPrimary=classes.primary;
    ButtonClassSecondary=classes.secondary;
  }
  else{
    ButtonClassPrimary =classes.primaryCert;
    ButtonClassSecondary=classes.secondaryCert;
  }
  let textOk="Conferma";
  let textAnnulla="Annulla";
  if (props.textOk!=="" && props.textOk!==undefined) textOk=props.textOk;
  if (props.textAnnulla!=="" && props.textAnnulla!==undefined) textAnnulla=props.textAnnulla;
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{props.title}
        <hr/>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>{props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.handleConfirm} className={ButtonClassPrimary} >
            {textOk}
          </Button>
          <Button onClick={props.handleClose} className={ButtonClassSecondary}>
            {textAnnulla}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}