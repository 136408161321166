import React from "react";
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SvgCalendar from "../img/calendar.svg";
import '../css/MyDatePicker.css';
function getDate(myDate) {
  var nDate = new Date(myDate);
  var dd = nDate.getDate();
  var mm = nDate.getMonth() + 1; //January is 0!
  var yyyy = nDate.getFullYear();

  if (isNaN(dd) || isNaN(mm) || isNaN(yyyy)) return "";
  
  if (dd < 10) {
      dd = '0' + dd;
  }

  if (mm < 10) {
      mm = '0' + mm;
  }

  nDate = dd + '/' + mm + '/' + yyyy;
  return nDate;
}

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/MM/yyyy HH:MM');
let selectedDate="";
//const MyCustomInput = ({ value, onClick }) => (
  
  
    
//);

export default function MyDatePicker(props) {
  let otherclass="";
  if (props.className!==undefined){
    otherclass=props.className + " ";
  }
  return (
    <DatePicker
    disabled={props.disabled===true}
    value={props.value}
    onChange={(date) =>{
      selectedDate=getDate(date);
      props.onChange(selectedDate);
    }}
    dateFormat="dd/MM/yyyy"
    customInput={<div className="form-group">
    <MaskedInput
        className={otherclass + "form-control"}
        pipe={autoCorrectedDatePipe}
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        keepCharPositions= {true}
        placeholder="gg/mm/aaaa"
        guide = {true}
        value={props.value}
        disabled={props.disabled===true}
        onChange={(event) =>{
          if (event.target.value === "" || event.target.value === null){
            
            props.onChange("");
          }
        }}
    />
    <img style={{height:"15pt"}} src={SvgCalendar} className="date-picker-icon" alt=""/>
  </div>}
/>
  );
}
