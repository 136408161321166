import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DeleteIcon from '@material-ui/icons/DeleteForever';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Toolbar } from '@material-ui/core';
import NewResponsabile from './NewResponsabile';
import ModResponsabile from './ModResponsabile';
import MyAutoComplete from './MyAutoComplete';
import {GetMessage} from "../Modules/Message";
import {GetToken} from "../Modules/Funzioni";
import MyAlert from './MyAlert';
import MyConfirm from './MyConfirm';
import imgSearch from "../img/search.png";
import { app_cpService } from "../Modules/Costanti";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
      BtnEdit: {
        '&:hover':{
            cursor:"pointer"
      }  
    }
}));
  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Avatar', string: false, disablePadding: true, label: '' },
    { id: 'Del', string: false, disablePadding: true, label: '' },
    { id: 'Cognome', string: false, disablePadding: true, label: 'Cognome' },
    { id: 'Nome', string: false, disablePadding: true, label: 'Nome' },
    { id: 'Cellulare', string: false, disablePadding: true, label: 'Cellulare' },
    { id: 'DataInserimentoCast', string: false, disablePadding: true, label: 'Inserito Il' },
];


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [order, setOrder] = React.useState('asc');

  const [RicAzienda, setRicAzienda] = React.useState("");
  const [RicIDAzienda, setRicIDAzienda] = React.useState("");
  const [RicCodFiscale, setRicCodFiscale] = React.useState("");
  const [RicCognome, setRicCognome] = React.useState("");
  const [RicNome, setRicNome] = React.useState("");
  const [orderBy, setOrderBy] = React.useState('Cognome');
  const [page, setPage] = React.useState(0);  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(5);  
  const [dense, setDense] = React.useState(false);
  const [isOpenNewResponsabile, setIsOpenNewResponsabile] = useState(false);
  const [isOpenModResponsabile, setIsOpenModResponsabile] = useState(false);
  const [IdModResponsabile, setIdModResponsabile] = useState("");
  //confirm object
 const [MsgConfirm,setMsgConfirm]=React.useState("Si desidera procedere alla cancellazione del Legale Rappresentante?");
 const [OpenConfirm, setOpenConfirm] = React.useState(false);
 const [IdDelConfirm, setIdDelConfirm] = React.useState(false);
 const onCloseConfirm = (event) => {
   setOpenConfirm(false);
};
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
  //-----------
  const toggleNewResponsabile = () => {
    setIsOpenNewResponsabile(!isOpenNewResponsabile);
  }
  const toggleModResponsabile = (id) => {
    setIsOpenModResponsabile(!isOpenModResponsabile);
    if (!isOpenModResponsabile){
        setIdModResponsabile(id);
    }
  }
  const toggleDelResponsabile = (_Id) => {
    setIdDelConfirm(_Id);
    setOpenConfirm(true);
  }
 const onDelConfirm = (event) => {
    setOpenConfirm(false);
    setIsLoading(true);    
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    (async () => {
        let result=[];
        try{
            let url=process.env.REACT_APP_API_URL+'responsabiledelete/' + IdDelConfirm;
                       
            result = await ax.post(url);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);
 
        }
        setIsLoading(false);    
        if (result.data!==undefined)    {
            onLoadData();
        }
    })(); 
 
 };

  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'responsabilelist/'
                if (RicIDAzienda!=="") {
                    url +=RicIDAzienda;
                }
                else {
                    url +="0";
                }
                if (RicCodFiscale!=="") {
                    url +="/codfiscale/" + RicCodFiscale;
                }
                else {
                    url +="/codfiscale/0";
                }

                if (RicCognome!=="") {
                    url +="/cognome/" + RicCognome;
                }
                else {
                    url +="/cognome/0";
                }
                if (RicNome!=="") {
                    url +="/nome/" + RicNome;
                }
                else {
                    url +="/nome/0";
                }
                //idazienda/0/codfiscale/0/cognome/0
                result = await ax.post(url);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })(); 
        
}, [isSending]);   


  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onLoadData = () => {
    setIsSending(!isSending);
}; 

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
    setPage(0);
}; 
const handleRicAzienda = (value,text) => {
    setRicAzienda(text);
    setRicIDAzienda(value);;
    setDisableFilter(false);
};

const handleRicCodFiscale = (event) => {
    setRicCodFiscale(event.target.value);
    setDisableFilter(false);
};

const handleRicCognome = (event) => {
    setRicCognome(event.target.value);
    setDisableFilter(false);
};
const handleRicNome = (event) => {
    setRicNome(event.target.value);
    setDisableFilter(false);
};
const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
let ButtonClassName="";
const app_selected =process.env.REACT_APP_APPSELECT;
if (parseInt(app_selected)===app_cpService) {
    ButtonClassName="btn-mbg";
}
else{
    ButtonClassName ="btn-mbg-cert";
}
  return (  
    
    <div className={classes.root}>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/> 
        <MyConfirm title="Conferma Cancellazione" message={MsgConfirm} handleClose={onCloseConfirm} handleConfirm={onDelConfirm} open={OpenConfirm}/>         
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        {isOpenModResponsabile && <ModResponsabile onUpdate={onLoadData} handleClose={toggleModResponsabile} id={IdModResponsabile}/>}
        {isOpenNewResponsabile && <NewResponsabile onInsert={onLoadData} handleClose={toggleNewResponsabile}/>}
        <div className="small" style={{width:"98%"}}>
            <Row> 
                <Col md={4}>
                    <FormGroup>
                        <Label for="txtRicAzienda">Azienda</Label>
                        <MyAutoComplete id="txtRicAzienda" name="txtRicAzienda" 
                        url={process.env.REACT_APP_API_URL+'customersinglelist'} 
                        optionname="RagioneSociale" optionvalue="id"
                        customvalue={RicAzienda} onChange={handleRicAzienda} 
                        className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label for="txtRicCognome">Cognome</Label>
                        <Input type="search" className="search" name="txtRicCognome" placeholder=""
                        value={RicCognome} onChange={handleRicCognome}
                        bsSize="sm"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label for="txtRicNome">Nome</Label>
                        <Input type="search" className="search" name="txtRicNome" placeholder=""
                        value={RicNome} onChange={handleRicNome}
                        bsSize="sm"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label for="txtRicCodFiscale">Cod. Fiscale</Label>
                        <Input type="search" className="search" name="txtRicCodFiscale" placeholder=""
                        value={RicCodFiscale} onChange={handleRicCodFiscale}
                        bsSize="sm"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className={ButtonClassName + " btn-sm btn-block"} disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <div>&nbsp;</div>
        <Paper className={classes.paper}> 
        <Toolbar>
        <div style={{width:"100%" }}><Button onClick={toggleNewResponsabile} className={ButtonClassName}>Inserisci</Button></div>
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                                className="rt-tr-group"
                            >
                                <TableCell id={labelId} scope="row">
                                    <Avatar className={ButtonClassName + " " + classes.BtnEdit}  onClick={() => toggleModResponsabile(row.id)}>
                                        <AssignmentIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell>
                                    <Avatar className={ButtonClassName + " " + classes.BtnEdit}  onClick={() => toggleDelResponsabile(row.id)}>
                                        <DeleteIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell>{row.Cognome}</TableCell>
                                <TableCell>{row.Nome}</TableCell>
                                <TableCell>{row.Cellulare}</TableCell>
                                <TableCell>{row.DataInserimento}</TableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[5, 10, 15]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 