import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Toolbar } from '@material-ui/core';
import NewModuloNomina from './NewModuloNomina';
import ModModuloNomina from './ModModuloNomina';
import {GetMessage} from "../../Modules/Message";
import MyAlert from '../MyAlert';
import imgSearch from "../../img/search.png";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import {Ruolo_Admin } from '../../Modules/Costanti';
import {GetToken,GetRole} from "../../Modules/Funzioni";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
      BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(18, 35, 95, 0.84) !important",
        '&:hover':{
            cursor:"pointer"
      }  
    }
}));
  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Avatar', string: false, disablePadding: true, label: '' },
    { id: 'Download', string: false, disablePadding: true, label: '' },
    { id: 'Descrizione', string: false, disablePadding: true, label: 'Descrizione' },
    { id: 'DataInserimentoCast', string: false, disablePadding: true, label: 'Inserito il' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [order, setOrder] = React.useState('asc');

  const [RicDescrizione, setRicDescrizione] = React.useState("");
  const [orderBy, setOrderBy] = React.useState('Descrizione');
  const [page, setPage] = React.useState(0);  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(5);  
  const [dense, setDense] = React.useState(false);
  const [isOpenNewModuloNomina, setIsOpenNewModuloNomina] = useState(false);
  const [isOpenModModuloNomina, setIsOpenModModuloNomina] = useState(false);
  const [IdModModuloNomina, setIdModModuloNomina] = useState("");
  let UserRole =GetRole(localStorage.getItem("token"));

  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
  //-----------
  const toggleNewModuloNomina = () => {
    setIsOpenNewModuloNomina(!isOpenNewModuloNomina);
  }
  const toggleModModuloNomina = (id) => {
    setIsOpenModModuloNomina(!isOpenModModuloNomina);
    if (!isOpenModModuloNomina){
        setIdModModuloNomina(id);
    }
  }
  const DownloadModuloNomina = (name_Modulo,id) => {
    setIsLoading(true);
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    
    (async () => {
        let result=[];
        try{
            const axiosConfig = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }
            const fd = new FormData();
            fd.append("Modulo_Name", name_Modulo);
            fd.append("id", id);
            let url=process.env.REACT_APP_API_URL+'modulonominafile'
            await ax.post(url,fd,axiosConfig)
                .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                [response.data], 
                {type: 'application/pdf'});
                const downloadUrl = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'modulo.pdf'); 
                document.body.appendChild(link);
                link.click();
                link.remove();
              });
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
    })(); 
  }

  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        
        
        (async () => {
            let result=[];
            try{
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                let url=process.env.REACT_APP_API_URL+'modulonominalist'
                const fd = new FormData();
                fd.append("Descrizione", RicDescrizione);
                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })(); 
        
}, [isSending]);   


  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onLoadData = () => {
    setIsSending(!isSending);
}; 

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
    setPage(0);
}; 

const handleRicDescrizione = (event) => {
    setRicDescrizione(event.target.value);
    setDisableFilter(false);
};
const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};

const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (  
    
    <div className={classes.root}>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>     
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        {isOpenModModuloNomina && <ModModuloNomina onUpdate={onLoadData} handleClose={toggleModModuloNomina} id={IdModModuloNomina}/>}
        {isOpenNewModuloNomina && <NewModuloNomina onInsert={onLoadData} handleClose={toggleNewModuloNomina}/>}
        <div className="small" style={{width:"98%"}}>
            <Row> 
                <Col md={6}>
                    <FormGroup>
                        <Label for="txtRicDescrizione">Descrizione</Label>
                        <Input type="search" className="search" name="txtRicDescrizione" placeholder=""
                        value={RicDescrizione} onChange={handleRicDescrizione}
                        bsSize="sm"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className="btn-mbg btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <div>&nbsp;</div>
        <Paper className={classes.paper}> 
        <Toolbar>
            {
               parseFloat(UserRole)===Ruolo_Admin && (
                <div style={{width:"100%" }}><Button onClick={toggleNewModuloNomina} className="btn-mbg">Inserisci</Button></div>
                )
            }
        
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                                className="rt-tr-group"
                            >
                                <TableCell scope="row">
                                    {
                                    parseFloat(UserRole)===Ruolo_Admin && (
                                    <Avatar className={classes.BtnEdit}  onClick={() => toggleModModuloNomina(row.id)}>
                                        <AssignmentIcon />
                                    </Avatar>)
                                    }
                                </TableCell>
                                <TableCell>{
                                    row.Modulo_Name!=="" && row.Modulo_Name!==null  &&
                                        (
                                        <Avatar className={classes.BtnEdit}  onClick={() => DownloadModuloNomina(row.Modulo_Name,row.id)}>
                                            <DownloadIcon />
                                        </Avatar>
                                    )
                                    }
                                    
                                </TableCell>
                                <TableCell>{row.Descrizione}</TableCell>
                                <TableCell>{row.DataInserimento}</TableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={4} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[5, 10, 15]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 