import React, {Component} from "react";
import "reactstrap";
import '../css/App.css';
export default class NotFound extends Component {
    render() {
        return (
            <div className="content-center col-sm">
                <h1>Pagina Inesistente o in fase di sviluppo!</h1>
            </div>
        )
    }
}