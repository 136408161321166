import React, {useRef,useState,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import MyNumInput from '../../Components/MyNumInput';
import MyAlert from '../../Components/MyAlert';
import MyAlertSelServizio from '../../Components/MyAlert';
import {GetMessage} from "../../Modules/Message";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import {GetTextZona,GetIDCommerciale,ccyFormatValue,ccyFormat,GetTitServizi,GetTextRischioAteco,GetToken, GetRole} from "../../Modules/Funzioni";
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import imgSearch from "../../img/search.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import MyConfirmDelServizio from '../../Components/MyConfirm';
import MyConfirmImporto from '../../Components/MyConfirm';
import SignCanvas from 'react-signature-canvas'
import MyAutoComplete from "../../Components/MyAutoComplete";
import { Ruolo_Admin } from "../../Modules/Costanti";
import MyDatePicker from "../../Components/MyDatePicker";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 400,
        minHeight: 400,
      },
    FormPopup: {
        maxWidth: "900px !important",
        minWidth: "800px !important",
      },
    
      BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(18, 35, 95, 0.84) !important",
        '&:hover':{
            cursor:"pointer"
        }  
    }
}));

function CreateSign(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [Signed, setSigned] = React.useState(false);
    const mySign=useRef({});

    const onConfirm = () => {
        props.handleConfirm(mySign.current.getTrimmedCanvas().toDataURL("image/png"));
    }
    
    const onClear = () => {
        setSigned(false);
        mySign.current.clear();
    }

    const onSign = () => {
        setSigned(true);
    }
    
  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogContent className={classes.FormPopup} >
            <SignCanvas ref={mySign}  canvasProps={{className: 'signCanvas'}} onEnd={onSign} />
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg btn-block" disabled={!Signed} readOnly={!Signed}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-block btn btn-secondary">
                    Annulla
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button  onClick={onClear} className="btn-mbg1 btn-block" disabled={!Signed} readOnly={!Signed}>
                    Cancella 
                </Button>
            </div>            
        </DialogActions>
      </Dialog>
  );
}

  function ElencaServizio(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [RicTitolo, setRicTitolo] = React.useState("");
    const [RicDescrizione, setRicDescrizione] = React.useState("");
    const [isSending, setIsSending] = useState(true);   
    const [DisableFilter, setDisableFilter] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selected, setSelected] = React.useState("");
    const [SelectedData, setSelectedData] = React.useState([]);
    
    
//alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const handleRicDescrizione = (event) => {  
        setRicDescrizione(event.target.value);  
        setDisableFilter(false);
    };

    const handleRicTitolo = (event) => {  
        setRicTitolo(event.target.value);  
        setDisableFilter(false);
    };

    React.useEffect(() => {
        setSelected("");
    }, [props.open]);
    
    const handleRowClick = (_id,_titolo,_descrizione,_prezzo) => {
        let newSelected = "";
        newSelected = _id;
        setSelected(newSelected);
        var date = new Date();
        const ArrayRis = [
            { id: _id, Titolo: _titolo, DescrizioneAnno: date.getFullYear(), Descrizione: _descrizione, Prezzo: _prezzo, Quantita: 1},
        ];
        setSelectedData(ArrayRis);
      };

    const onFilter = () => {
        setDisableFilter(true);
        setSelected("");
        setSelectedData([]);
        setIsSending(true);
    }; 

    const onConfirm = () => {
        props.handleConfirm(SelectedData);
    }
    
   
    useEffect(() => {    
        
        if (isSending && props.rischio!=="" && props.zona!==""){
            setIsLoading(true);
            const token =  GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'listinolist'
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    const fd = new FormData();
                    fd.append("Descrizione", RicDescrizione);
                    fd.append("Rischio", props.rischio);
                    fd.append("RischioIndifferente", 1);
                    fd.append("Titolo", RicTitolo);
                    fd.append("Zona", props.zona);
                    
                    result = await ax.post(url,fd,axiosConfig);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                if (result.data!==undefined)    {
                    setData(result.data);
                }
                else {
                    setData([]);
                }
                setIsSending(false);
            })(); 
    }
        
}, [isSending,props.zona,props.rischio]); 

    
    const isSelected = (name) => selected===name;

  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="responsive-dialog-title">
            <div className="small" style={{width:"98%"}}>
                <Row>
                    <Col xs={4}>
                        <FormGroup>
                            <Label bsSize="sm" for="selRicTitolo">Titolo</Label>
                            <Input type="select" className="search" name="selRicTitolo" 
                            id="selRicTitolo" placeholder="" bsSize="sm"
                                value={RicTitolo} onChange={handleRicTitolo}>
                                    <option key="" value="">Tutti</option>
                                    {GetTitServizi().map((item) => (
                                    <option key={item.titolo_value} value={item.titolo_value}>{item.titolo_name}</option>
                                ))}
                            </Input>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col>
                    
                    <Col xs={6}>
                        <FormGroup>
                            <Label bsSize="sm" for="selRicDescrizione">Descrizione</Label>
                            <Input type="search" className="search" name="txtRicDescrizione" placeholder=""
                            value={RicDescrizione} onChange={handleRicDescrizione}
                            bsSize="sm"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col> 
                    <Col xs={2}>
                        <FormGroup>
                            <Label bsSize="sm">&nbsp;</Label>
                            <Button type="button" className="btn-mbg btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                        </FormGroup>
                    </Col>
                    
                </Row>
            </div>
        </DialogTitle>
        <DialogContent className={classes.FormPopup} >
            <DialogContentText>
                <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
                {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                        <TableHead>            
                            <TableRow>
                                <TableCell className="grid-head" align="center">Titolo</TableCell>
                                <TableCell className="grid-head" align="center">Descrizione</TableCell>
                                <TableCell className="grid-head" align="center">Prezzo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { data.length>0? (
                                data.map((row) => {
                                    const isItemSelected = isSelected(row.Servizio_id);
                            
                                    return (
                                        
                                    <TableRow key={row.Servizio_id} hover tabIndex={-1} className={isItemSelected ? 'row-selected' : ''} onClick={(event) => handleRowClick(row.Servizio_id,row.Titolo,row.Descrizione,row.Prezzo)}>
                                        <TableCell>{row.Titolo}</TableCell>
                                        <TableCell>{row.Descrizione}</TableCell>
                                        <TableCell align="right">{row.Prezzo}</TableCell>
                                    </TableRow>
                                    );
                                })
                            ):(
                                <TableRow>
                                    <TableCell colSpan={3} style={{textAlign:"center"}}><h4>- Nessun Servizio Presente -</h4></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg btn-block" disabled={selected===""}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
  );
}

function CreateTableServizi(props) {
    const classes = useStyles();
    const[Rows, setRows] = React.useState([]);
    
    const [PercIva, setPercIva] = React.useState(process.env.REACT_APP_IVA);
    const [CostoImponibile, setCostoImponibile] = React.useState(0);
    const [PrezzoRiservato, setPrezzoRiservato] = React.useState(0);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [CostoIva, setCostoIva] = React.useState(0);
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };

    const handleFocusQuantita= (event) =>{
        event.target.select();
    };
    const handlePercIva= (event) =>{
        if (parseFloat(event.target.value)>100){
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
            event.target.value=0;

        }
        let _PercIva=parseFloat(event.target.value);
        if (isNaN(_PercIva)) _PercIva=0;
        setPercIva(_PercIva);
        CalcolaPrezzi(_PercIva, PrezzoRiservato);
        props.onChangeIva(_PercIva);
        
    }
    const handleFocusPercIva= (event) =>{
        event.target.select();
    };
    const handlePrezzoRiservato= (_event) =>{
        let _prezzo=parseFloat(_event.target.value);
        if (isNaN(_prezzo)) _prezzo=0;
        CalcolaPrezzi(PercIva, _prezzo);
        props.onChangePrezzo(_prezzo);
    };
    const handleQuantita= (_num,_ind) =>{
        if (props.rows.length>0){
            props.rows.map((item, index) => {
                if (_ind === index){
                    item.Quantita=_num;
                }
                return true;
            });
        }
        CalcolaPrezzi(PercIva, PrezzoRiservato);
    };
    const handleAnno= (_num, ind) =>{
        if (props.rows.length>0){
            props.rows.map((item, index) => {
                if (index===ind){
                    console.log(item);
                    item.DescrizioneAnno = _num;
                }
                return true;
            });
        }
    };
    const handleFocusAnno= (event) =>{
        event.target.select();
    };
    const CalcolaPrezzi = (_PercIva, _PrezzoRiservato)=> {
        let newCostoImponibile=0;
        let newCostoTotale=0;
        let newCostoIva=0;
        let newPrezzoRiservato=_PrezzoRiservato;
        if (props.rows.length>0){
            props.rows.map((item) => {
                
                newCostoImponibile +=parseFloat(item.Prezzo)*parseFloat(item.Quantita); 
                
                return newCostoImponibile;
            });
        }
        
        newCostoImponibile=parseFloat(newCostoImponibile.toFixed(2));
        newCostoIva=newPrezzoRiservato*(parseFloat(_PercIva)/100);
        newCostoIva=parseFloat(newCostoIva.toFixed(2));
        newCostoTotale=newCostoIva + newPrezzoRiservato;
        setPrezzoRiservato(newPrezzoRiservato);
        setCostoImponibile(newCostoImponibile);
        setCostoIva(newCostoIva);
        setCostoTotale(newCostoTotale);
        props.onChangeCostoTotale(newCostoTotale);
    };
    React.useEffect(() => {
        setRows(props.rows);
        //alert("state rows");
        CalcolaPrezzi(PercIva, PrezzoRiservato);
    }, [props.rows]);
    
    React.useEffect(() => {
        CalcolaPrezzi(PercIva, PrezzoRiservato);
    }, [props.onRicalcola]);
    

    return (

        <TableContainer component={Paper}>
            <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
            <Table className={classes.table} aria-label="spanning table" size="small">
            <TableHead>            
                <TableRow>
                    <TableCell className="grid-head"></TableCell>
                    <TableCell className="grid-head">Titolo</TableCell>
                    <TableCell className="grid-head">Descrizione</TableCell>
                    <TableCell className="grid-head">Anno</TableCell>
                    <TableCell className="grid-head" align="right">Listino</TableCell>
                    <TableCell className="grid-head">Q.ta</TableCell>
                    <TableCell className="grid-head" align="right">Importo</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { Rows.length>0? (Rows.map((row, index) => {
                        return (
                        <TableRow key={row.id} className="rt-tr-group">
                            <TableCell>
                                <Avatar className={classes.BtnEdit}  onClick={() => props.onDelete(row.id)}>
                                    <DeleteIcon />
                                </Avatar>
                            </TableCell>
                            <TableCell>{row.Titolo}</TableCell>
                            <TableCell>{row.Descrizione}</TableCell>
                            <TableCell>
                                <MyNumInput 
                                    otherparam={index}
                                    onChange={handleAnno} 
                                    numMax = {9999}
                                    value={row.DescrizioneAnno}
                                    onFocus={handleFocusAnno}
                                    width = {"100px"}
                                />
                        </TableCell>
                            <TableCell align="right">{row.Prezzo}</TableCell>
                            <TableCell><MyNumInput otherparam={index} value={row.Quantita}  onFocus={handleFocusQuantita} onChange={handleQuantita}/></TableCell>
                            <TableCell align="right">{ccyFormatValue(parseFloat(row.Prezzo)*parseFloat(row.Quantita))}</TableCell>
                        </TableRow>
                );}
                )):(
                    <TableRow>
                        <TableCell colSpan={7} style={{textAlign:"center"}}><h5>- Nessun Servizio Selezionato -</h5></TableCell>
                    </TableRow>
                )}
                <TableRow className="rt-tr-group">
                    <TableCell colSpan={7}>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4}/>
                    <TableCell colSpan={2} style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}><b>Sub-Totale</b></TableCell>
                    <TableCell align="right" style={{background:"#deeaf6"}}>
                        <b>{ccyFormatValue(CostoImponibile)}</b>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4}/>
                    <TableCell colSpan={2} style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}><b>Prezzo Annuale</b></TableCell>
                    <TableCell align="right" style={{background:"#deeaf6"}}>
                        <Input 
                            type="number" 
                            name="txtPrezzoRiservato" 
                            autoComplete="new-password" 
                            placeholder=""
                            value={PrezzoRiservato}
                            onChange={handlePrezzoRiservato}
                            style={{textAlign:'right'}}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4}/>
                    <TableCell style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)",display:"flex"}}>
                        <span style={{paddingTop:"4px",width:"60px"}}><b>IVA</b></span> 
                        <Input type="number" name="txtIva" min="0" max="99" autoComplete="new-password"
                            onChange={handlePercIva} value={PercIva}
                            onFocus={handleFocusPercIva}
                            style={{width:"90px"}} bsSize="sm"
                        />
                        <span style={{paddingTop:"4px"}}>  <b>&nbsp;&nbsp;%</b></span> 
                    </TableCell>
                    <TableCell colSpan={2} style={{background:"#deeaf6"}} align="right"><b>{ccyFormatValue(CostoIva)}</b></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4}/> 
                    <TableCell colSpan={2} style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}><b>Totale &euro;</b></TableCell>
                    <TableCell align="right" style={{background:"#deeaf6"}}><b>{ccyFormatValue(CostoTotale)}</b></TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
    );
  }

export default function NewContratto (props) {
    const UserRole =GetRole(localStorage.getItem("token"));
    var isAdmin=false;
    if (parseFloat(UserRole)===Ruolo_Admin) isAdmin=true
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
     //alert object
     const [OpenAlert, setOpenAlert] = React.useState(false);
     const [OpenAlertSelServizio, setOpenAlertSelServizio] = React.useState(false);
     const [VerticalAlert, setVerticalAlert] = React.useState("top");
     const [SeverityAlert, setSeverityAlert] = React.useState("error");
     const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
     const onCloseAlert = (event) => {
         setOpenAlert(false);
     };
     const onCloseAlertSelServizio = (event) => {
        setOpenAlertSelServizio(false);
    };
     //------------
    const [CloseForm, setCloseForm] = React.useState(false);
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [IDCommerciale, setIDCommerciale] = React.useState("");
    const [DesCommerciale, setDesCommerciale] = React.useState("");
    const [DesRischioAteco, setDesRischioAteco] = React.useState("");
    const [CodiceAteco, setCodiceAteco] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    const [CodiceIva, setCodiceIva] = React.useState(process.env.REACT_APP_IVA);
    const [ImportoRiservato, setImportoRiservato] = React.useState(0);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [Zona, setZona] = React.useState("");
    const [DesZona, setDesZona] = React.useState("");
    const [RischioAteco, setRischioAteco] = React.useState("");
    const [CifraAcconto, setCifraAcconto] = React.useState(0);
    const [Saldo, setSaldo] = React.useState("0");
    const [ServizioDeleted, setServizioDeleted] = React.useState(false);
    const [RowsServizi, setRowsServizi] = React.useState([]);
    const [Note, setNote] = React.useState("");
    const [CondEconomiche, setCondEconomiche] = React.useState("");
    const [DataScadenza, setDataScadenza] = React.useState("");
    const [OpenSelServizio, setOpenSelServizio] = React.useState(false);
    const [OpenSign, setOpenSign] = useState(false);
    const [ImgSign, setImgSign] = React.useState(null);
    //confirm object
    const [MsgConfirmDelServizio,setMsgConfirmDelServizio]=React.useState("Si desidera eliminare il servizio dalla lista dei selezionati?");
    const [OpenConfirmDelServizio, setOpenConfirmDelServizio] = React.useState(false);
    const [IdConfirmDelServizio, setIdConfirmDelServizio] = React.useState(false);

    const [MsgConfirmImporto,setMsgConfirmImporto]=React.useState("Si desidera confermare il contratto con importo a zero?");
    const [OpenConfirmImporto, setOpenConfirmImporto] = React.useState(false);
    const onCloseConfirmDelServizio = (event) => {
        setOpenConfirmDelServizio(false);
    };
    const onConfirmSign = (_Sign) => {
        setImgSign(_Sign);
        setOpenSign(false);
    };
    const onCloseSign = (event) => {
        setOpenSign(false);
    };
    const onOpenSign = (event) => {  
        setOpenSign(!OpenSign);  
    };
    const toggleDelServizio = (_Id) => {
        setIdConfirmDelServizio(_Id);
        setOpenConfirmDelServizio(true);
    };
    const handleNote = (event) => {  
        setNote(event.target.value);  
    };
    const handleCommerciale = (value,text) => {  
        setIDCommerciale(value);
        setDesCommerciale(text);  
    }; 
    const handleCondEconomiche = (event) => {  
        setCondEconomiche(event.target.value);  
    };
    const handleDataScadenza = (value) => {
        setDataScadenza(value);
    };
    const handleCifraAcconto = (event) => {  
        setCifraAcconto(event.target.value);  
        ControllaValoreAcconto(CostoTotale,event.target.value);
    }; 

    const ChangeCostoTotale = (_value) => {
        setCostoTotale(_value);
        ControllaValoreAcconto(_value,CifraAcconto);
    };

    const ControllaValoreAcconto = (_costo,_acconto) => {
        if (parseFloat(_acconto)>parseFloat(_costo)){
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("L'acconto supera il totale da pagare verrà azzerato!");
            setCifraAcconto(0);
            setOpenAlert(true);
        }
    }
    // const handleSaldo = (event) => {  
    //     setSaldo(event.target.value); 
    //     if (event.target.value==="1"){ 
    //         setCifraAcconto(0);  
    //     }
    // }; 
    const onConfirmImporto = (_event) => {
        onSubmitForm(true);
        setOpenConfirmImporto(false);
    };
    const onCloseConfirmImporto = (_event) => {
        setOpenConfirmImporto(false);
    };
    const onDelServizio = (event) => {
        setOpenConfirmDelServizio(false);
        
        let newRows = RowsServizi;
        let index = -1;
        let indexSel = -1;

        if (newRows.length>0){
            newRows.map((item) => {
                index +=1;
                if (item.id===IdConfirmDelServizio){
                    indexSel=index;
                }
                return indexSel;
            });
        }
        
        if (indexSel !== -1) {
            newRows.splice(indexSel, 1);
            setRowsServizi(newRows);
            setServizioDeleted(!ServizioDeleted);
        }

    };
    
    const handleOpenSelServizio = (event) => {  
        setOpenSelServizio(true);  
    };

    const CloseSelServizio = (event) => {  
        setOpenSelServizio(false);  
    };

    const onConfirmSelServizio = (RisArray) => {  

        let newArray;


        let id = RisArray[0].id;
        //let descrizioneAnno = RisArray[0].DescrizioneAnno;
        let res=false;
        // if (RowsServizi.length>0){
        //     RowsServizi.map(function(item) {
        //         if (item.id === id && item.DescrizioneAnno === descrizioneAnno){
        //             res=true;
        //             return true;
        //         }
        //         return res;
        //     });
        // }
        
        if (!res){
            if (RowsServizi.length>0){
                newArray= RowsServizi.concat(RisArray);
            }
            else {
                newArray= RisArray;
            }
            //newArray.push(RisArray)
            setRowsServizi(newArray);
            setOpenSelServizio(false);  
        }
        else {
            setMsgAlert("Servizio già selezionato, per aumentare il quantitativo incrementare il campo quantità!");
            setOpenAlertSelServizio(true);

        }
    };
    const ChangeIva = (_value) => {
        setCodiceIva(_value); 
    };

    const ChangePrezzo = (_value) => {
        setImportoRiservato(_value); 
    };

    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    React.useEffect(() => {
        if (localStorage.getItem("idazienda")!==""){
            LoadDatiAzienda();
        }
    }, []);

    const LoadDatiAzienda = (event) => {
        if(localStorage.getItem("idazienda")!==""){
            setIsLoading(true); 
            const token =  GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'customer/' + localStorage.getItem("idazienda");               
                    result = await ax.post(url);
                    setRagioneSociale(result.data[0].RagioneSociale);
                    setDesRischioAteco(GetTextRischioAteco(result.data[0].Rischio));
                    setCodiceAteco(result.data[0].CodiceAteco);
                    setDesZona(GetTextZona(result.data[0].Zona));
                    setZona(result.data[0].Zona);
                    setRischioAteco(result.data[0].Rischio);
                    setCellulare(result.data[0].CellulareReferente);
                    if (parseFloat(result.data[0].Commerciale_id)>0 && isAdmin){
                        setDesCommerciale(result.data[0].DesCommerciale);
                        setIDCommerciale(result.data[0].Commerciale_id);
                    }
                    else {
                        let User_id =GetIDCommerciale(localStorage.getItem("token"));
                        if (parseFloat(User_id)===0){
                            setIDCommerciale("");
                        }
                        else {
                            setIDCommerciale(User_id);
                        }
                    }
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning");
                    setVerticalAlert("center");
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                
            })();  
        }  
    }

    const onCloseForm = (event) => {
        setCloseForm(true);
    };
    const CheckDescrizioneAnno = (RisArray) => { 
        let hash = Object.create(null);
        let duplicates = RisArray.some((item) => {
            return (item.DescrizioneAnno && item.id) && (hash[item.id + "_" + item.DescrizioneAnno] || !(hash[item.id + "_" + item.DescrizioneAnno] = true));
        });
        if (duplicates) return "Non è consentito selezionare più volte lo stesso servizio nella stessa annualità.";
        return "";
        
    };
    const onSubmitForm= (_force) => 
    {
        let checkDescrizioneAnno = CheckDescrizioneAnno(RowsServizi);
        if (checkDescrizioneAnno !== "") {
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert(checkDescrizioneAnno);
            setOpenAlert(true);
            return;
        }
        if (validator.current.allValid() && RowsServizi.length>0) {
            
            if (ImportoRiservato == 0 && _force !== true){
                setOpenConfirmImporto(true);
                return;
            }
            setisSubmit(true);
            let ParamServizi="";
            ParamServizi =RowsServizi.map(function(item) {
                return item.id + "|" + item.Quantita + "|" + item.DescrizioneAnno;
            });

            const fd = new FormData();
            fd.append("Azienda_id", localStorage.getItem("idazienda"));
            fd.append("Commerciale_id", IDCommerciale); 
            fd.append("CodiceIva", CodiceIva);
            fd.append("Approvato", 0);
            fd.append("Acconto", CifraAcconto);
            fd.append("Importo", ImportoRiservato);
            fd.append("Saldo", Saldo);
            fd.append("SaldoCommerciale", 0);
            fd.append("SaldoDirettore", 0);
            fd.append("Zona", Zona);
            if (ImgSign!==null){
                fd.append("Firmato", 1);
                fd.append("imgFirma", ImgSign);
            }
            fd.append("Annullato", 0);
            fd.append("Note", Note);
            fd.append("CondEconomiche", CondEconomiche);
            fd.append("DataScadenza", DataScadenza);
            fd.append("ParamServizi", ParamServizi);
            const token =  GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
                
            ax
            .post(process.env.REACT_APP_API_URL+'contrattoinsert', fd,axiosConfig)
            .then((response) => {
                
                if (response.status === 200) {
                    if (response.data.error==="OK"){
                        setCloseForm(true);
                    }
                    else {
                        setisSubmit(false);
                        let msg="";
                        let ris=JSON.stringify(response.data);
                        ris=ris.replaceAll("\"", "");
                        ris=ris.replaceAll("{", "");
                        ris=ris.replaceAll("}", "");
                        ris=ris.replaceAll("[", "");
                        ris=ris.replaceAll("]", "");
                        msg="errore durante l'inserimento! " + ris;
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    }
                }
                else
                { 
                setisSubmit(false);    
                const msg=GetMessage(response.status,response.data.message);
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
                
                }
            })
            .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
                setisSubmit(false);
            });
            
        } else {
            if (RowsServizi.length>0){
                validator.current.showMessages();
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(MsgAlertDefault);
                setOpenAlert(true);
            }
            else
            {
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert("Selezionare almeno un servizio");
                setOpenAlert(true);
            }
            
        }
    }
    
    let urlRedirect="";
    
    if (CloseForm){
            urlRedirect="/contratti";
    }
    
    if (urlRedirect!==""){
        return <Redirect to={urlRedirect} />;
    }
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.bgHead}>
                    <IconButton edge="start" color="inherit" onClick={onCloseForm} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Nuovo Contratto - Servizi
                    </Typography>
                    <MatButton autoFocus color="inherit" onClick={() => onSubmitForm(false)} disabled={isSubmit} >
                        Conferma {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                    </MatButton>
                </Toolbar>
            </AppBar>
            <div className="page-container bg-form">
                <div className="content-wrap">
                    <form autoComplete ="new-password">
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>
                        <ElencaServizio open={OpenSelServizio} handleClose={CloseSelServizio} handleConfirm={onConfirmSelServizio} zona={Zona} rischio={RischioAteco}/>
                        <Grid container spacing={3} className="custom-form">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtRagioneSociale">Ragione Sociale</Label>
                                                <Input type="text" name="txtRagioneSociale" autoComplete="new-password" placeholder=""
                                                value={RagioneSociale} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtCellulareReferente">Cellulare</Label>
                                                <Input type="text" name="txtCellulareReferente" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                value={Cellulare} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtCodiceAteco">Cod. Ateco</Label>
                                                <Input type="text" name="txtCodiceAteco" id="txtCodiceAteco" placeholder=""
                                                value={CodiceAteco} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtRischio">Rischio</Label>
                                                <Input type="text" name="txtRischio" autoComplete="new-password" placeholder=""
                                                value={DesRischioAteco} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtZona">Zona</Label>
                                                <Input type="text" name="txtZona" id="txtZona" placeholder=""
                                                value={DesZona} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Toolbar>
                                    <Button className="btn-mbg" onClick={handleOpenSelServizio}>Aggiungi Servizio</Button>
                                </Toolbar> 
                                <MyAlertSelServizio message={MsgAlert} severity="warning" handleClose={onCloseAlertSelServizio} vertical="top" horizontal="center" open={OpenAlertSelServizio}/>
                                <MyConfirmDelServizio title="Conferma Cancellazione" message={MsgConfirmDelServizio} handleClose={onCloseConfirmDelServizio} handleConfirm={onDelServizio} open={OpenConfirmDelServizio}/>
                                <MyConfirmImporto title="Conferma Importo" message={MsgConfirmImporto} handleClose={onCloseConfirmImporto} handleConfirm={onConfirmImporto} open={OpenConfirmImporto}/>          
                                <CreateTableServizi 
                                    rows={RowsServizi} 
                                    onChangeCostoTotale={ChangeCostoTotale} 
                                    onDelete={toggleDelServizio} 
                                    onRicalcola={ServizioDeleted} 
                                    onChangeIva={ChangeIva} 
                                    onChangePrezzo={ChangePrezzo}
                                />
                                <CreateSign open={OpenSign} handleClose={onCloseSign} handleConfirm={onConfirmSign}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtNote">Note interne C&P [max 1000 caratteri]</Label>
                                                <Input type="textarea" name="txtNote" id="txtNote" autoComplete="new-password"
                                                onBlur={() => validator.current.showMessageFor('Note')}
                                                value={Note} onChange={handleNote} maxLength="1000"
                                                />
                                                {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtCondEconomiche">Cond. Economiche [max 1000 caratteri]</Label>
                                                <Input type="textarea" name="txtCondEconomiche" id="txtCondEconomiche" autoComplete="new-password"
                                                onBlur={() => validator.current.showMessageFor('CondEconomiche')}
                                                value={CondEconomiche} onChange={handleCondEconomiche} maxLength="1000"
                                                />
                                                {validator.current.message('CondEconomiche', CondEconomiche, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtDataScadenza">D. Scadenza *</Label>
                                                <MyDatePicker name="txtDataScadenza" 
                                                onBlur={() => validator.current.showMessageFor('DataScadenza')}
                                                value={DataScadenza} onChange={handleDataScadenza} autoComplete="new-password"
                                                />
                                                {validator.current.message('DataScadenza', DataScadenza, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} xs={12}>
                                            <FormGroup>
                                                <Label for="txtCommerciale">Commerciale *</Label>
                                                <MyAutoComplete id="txtCommerciale" name="txtCommerciale" 
                                                    url={process.env.REACT_APP_API_URL+'commercialesinglelist'} 
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    customvalue={DesCommerciale} onChange={handleCommerciale}
                                                    onBlur={() => validator.current.showMessageFor('Commerciale')}
                                                    disabled={isAdmin===false} readOnly={isAdmin===false}
                                                    />
                                                {validator.current.message('Commerciale', IDCommerciale, 'required|alpha_num', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>

                                        </Col>
                                         <Col md={3}>
                                            {/*<Row>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <label>
                                                            <input type="radio" name="radioPagamento" value="0" checked={Saldo==="0"} onClick={handleSaldo} />
                                                                &nbsp;Acconto
                                                        </label>
                                                        {
                                                            Saldo==="0" && (
                                                                <Input type="number" name="txtAcconto" autoComplete="new-password" placeholder=""
                                                                onBlur={() => validator.current.showMessageFor('Acconto')}
                                                                value={CifraAcconto} onChange={handleCifraAcconto} className="text-left"
                                                                />
                                                            )
                                                        }
                                                        {validator.current.message('Acconto', CifraAcconto, 'required|numeric', { className: 'text-danger' })}
                                                    </FormGroup> 
                                                </Col>
                                                <Col xs={6}>
                                                    <label>
                                                        <input type="radio" name="radioPagamento" value="1" checked={Saldo==="1"} onClick={handleSaldo}/>
                                                        &nbsp;Saldo
                                                    </label>
                                                </Col>
                                            </Row> */}
                                        </Col>   
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>&nbsp;</Label>
                                                <Button onClick={onOpenSign} className="btn-mbg btn-block">Firma</Button>
                                            </FormGroup>
                                        </Col>  
                                    </Row>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
}
        