import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Toolbar } from '@material-ui/core';
import NewImpelettrico from './NewImpElettrico';
import ModImpElettrico from './ModImpElettrico';
import MyAutoComplete from '../MyAutoComplete';
import {GetMessage} from "../../Modules/Message";
import {GetToken,GetRole,GetIDResponsabile, GetElencoStatoImpElettrico, GetIDCommerciale, isDate, DateDiff, GetIDIspettore} from "../../Modules/Funzioni";
import  {Ruolo_Azienda,Ruolo_Admin, Ruolo_Commerciale, Ruolo_Ispettore, Ruolo_CommTecnico} from '../../Modules/Costanti';
import MyAlert from '../MyAlert';
import MyConfirm from '../MyConfirm';
import imgSearch from "../../img/search.png";
import MyDatePicker from '../MyDatePicker';
import ReactExport from "react-export-excel";
import { Redirect } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
      BtnEdit: {
        '&:hover':{
            cursor:"pointer"
      }  
    }
}));
  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Avatar', string: false, disablePadding: true, label: '' },
    { id: 'Del', string: false, disablePadding: true, label: '' },    
    { id: 'shop', string: false, disablePadding: true, label: '' },    
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Azienda' },
    { id: 'Indirizzo', string: false, disablePadding: true, label: 'Indirizzo' },
    { id: 'Comune', string: false, disablePadding: true, label: 'Comune' },
    { id: 'DataUltimaVerificaCast', string: false, disablePadding: true, label: 'Verificato il' },
    { id: 'DataInserimentoCast', string: false, disablePadding: true, label: 'Inserito Il' },
];


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
function ExportToExcel (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Impianti">
                <ExcelColumn label="Azienda" value="RagioneSociale"/>
                <ExcelColumn label="P.Iva" value="PartitaIva"/>
                <ExcelColumn label="Indirizzo" value="Indirizzo"/>
                <ExcelColumn label="Comune" value="DesComune"/>
                <ExcelColumn label="D.Verifica" value="DataUltimaVerificaCast"/>
            </ExcelSheet>
        </ExcelFile>
    );
}
export default function MatPaginationTable() {  
  const IDCommerciale = GetIDCommerciale(localStorage.getItem("token"));
  const classes = useStyles();  
  const [order, setOrder] = React.useState('asc');
  const [RicTipoLuogo, setRicTipoLuogo] = React.useState("0");
  const [RicLuogo, setRicLuogo] = React.useState("");
  const [RicAzienda, setRicAzienda] = React.useState("");
  const [RicIDAzienda, setRicIDAzienda] = React.useState("");
  const [RicIndirizzo, setRicIndirizzo] = React.useState("");
  const [RicStato, setRicStato] = React.useState("");
  const [orderBy, setOrderBy] = React.useState('Cognome');
  const [page, setPage] = React.useState(0);  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(25);  
  const [dense, setDense] = React.useState(false);
  const [isOpenNewImpianto, setIsOpenNewImpianto] = useState(false);
  const [isOpenModImpianto, setIsOpenModImpianto] = useState(false);
  const [NewOrdineImpianto, setNewOrdineImpianto] = useState(false);
  const [IdModImpianto, setIdModImpianto] = useState("");
  const [RicOptionValue, setRicOptionValue] = useState("");
  const [RicOptionName, setRicOptionName] = useState("");
  const [RicUrlLuogo, setRicUrlLuogo] = useState(process.env.REACT_APP_API_URL+"comuni");
  const [RicTextLuogo, setRicTextLuogo] = React.useState("");
  const [RicDataVerificaInizio, setRicDataVerificaInizio] = React.useState("");
  const [RicDataVerificaFine, setRicDataVerificaFine] = React.useState("");

  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
 //confirm object
 const [MsgConfirm,setMsgConfirm]=React.useState("Si desidera procedere alla cancellazione dell'impianto?");
 const [OpenConfirm, setOpenConfirm] = React.useState(false);
 const [IdDelConfirm, setIdDelConfirm] = React.useState(false);
 let UserRole =GetRole(localStorage.getItem("token"));
 let User_id =GetIDResponsabile(localStorage.getItem("token"));
 const onCloseConfirm = (event) => {
   setOpenConfirm(false);
};
const toggleDelImpElettrico = (_Id) => {
   setIdDelConfirm(_Id);
   setOpenConfirm(true);
 }
const onDelConfirm = (event) => {
   setOpenConfirm(false);
   setIsLoading(true);    
   const token = GetToken(localStorage.getItem("token"));
   const ax= axios;
   ax.defaults.headers.common['Authorization']="Bearer " + token;
   
   (async () => {
       let result=[];
       try{
           let url=process.env.REACT_APP_API_URL+'impiantoeldelete/' + IdDelConfirm;
                      
           result = await ax.post(url);
       }
       catch(error) {
           let msg=""
           if (error.response!==undefined) {
               msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
           }
           else
           {
               msg=GetMessage(undefined,"");
           }
           setMsgAlert(msg);
           setOpenAlert(true);

       }
       setIsLoading(false);    
       if (result.data!==undefined)    {
           onLoadData();
       }
       
   })(); 

};
  //-----------
  const toggleNewImpElettrico = () => {
    setIsOpenNewImpianto(!isOpenNewImpianto);
  }
  const toggleModImpElettrico = (id) => {
    setIsOpenModImpianto(!isOpenModImpianto);
    if (!isOpenModImpianto){
        setIdModImpianto(id);
    }
  }

  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const IDIspettore = GetIDIspettore(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'impiantoellist';
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                if (parseFloat(UserRole)===Ruolo_Azienda){
                    fd.append("Responsabile_id", User_id);
                }
                else{
                    fd.append("Azienda_id", RicIDAzienda);
                }
                if (UserRole===Ruolo_Commerciale || UserRole===Ruolo_Ispettore || UserRole===Ruolo_CommTecnico){
                    if (parseFloat(IDCommerciale)>0){
                        fd.append("Commerciale_id", IDCommerciale); 
                    } 
                    if (parseFloat(IDIspettore)>0){
                        fd.append("Ispettore_id", IDIspettore);    
                    }  
                }
                fd.append("Indirizzo", RicIndirizzo);
                fd.append("StatoImpianto", RicStato);
                fd.append("DataVerificaInizio", RicDataVerificaInizio);
                fd.append("DataVerificaFine", RicDataVerificaFine);
                fd.append("RicLuogo", RicLuogo);
                fd.append("RicTipoLuogo", RicTipoLuogo);
                
                //idazienda/0/codfiscale/0/cognome/0
                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })(); 
        
}, [isSending]);   


  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onLoadData = () => {
    setIsSending(!isSending);
}; 

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
    setPage(0);
}; 
const handleRicAzienda = (value,text) => {
    setRicAzienda(text);
    setRicIDAzienda(value);;
    setDisableFilter(false);
};
const handleRicDataVerificaInizio = (value) => {  
    setRicDataVerificaInizio(value); 
    if (isDate(RicDataVerificaFine)===""){
        if (DateDiff(value,RicDataVerificaFine)<0){
            setRicDataVerificaFine("");
        }
    }
    setDisableFilter(false);
};

const handleRicDataVerificaFine = (value) => {  
    setRicDataVerificaFine(value);  
    if (isDate(RicDataVerificaInizio)===""){
        if (DateDiff(RicDataVerificaInizio,value)<0){
            setRicDataVerificaInizio("");
        }
    }
    setDisableFilter(false);
};
const handleRicIndirizzo = (event) => {
    setRicIndirizzo(event.target.value);
    setDisableFilter(false);
};

const handleRicLuogo = (value,text) => {
    setRicTextLuogo(text);
    setRicLuogo(value);
    setDisableFilter(false);
};

const handleRicTipoLuogo = (event) => { 
    setRicTipoLuogo(event.target.value);
    
    setRicLuogo("");
    setRicTextLuogo("");
    if (event.target.value==="0"){
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"province");
        setRicOptionValue("CodProv");
        setRicOptionName("Provincia");
    }
    else
    {
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"regioni");
        setRicOptionValue("CodRegione");
        setRicOptionName("NomeRegione");
    }
    
    setDisableFilter(false);
};

const handleRicStato = (event) => {
    setRicStato(event.target.value);
    setDisableFilter(false);
};
const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
const creaOrdineImpianto = (_idImpianto,_idazienda) =>{
    localStorage.setItem("idazienda", _idazienda);
    localStorage.setItem("idimpianto", _idImpianto);
    setNewOrdineImpianto(true);
}

if (NewOrdineImpianto){
    const urlRedirect="/nuovoordineimpianto";
    return <Redirect to={urlRedirect} />;
}
const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (  
    
    <div className={classes.root}>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
        <MyConfirm title="Conferma Cancellazione" message={MsgConfirm} handleClose={onCloseConfirm} handleConfirm={onDelConfirm} open={OpenConfirm}/>     
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        {isOpenModImpianto && <ModImpElettrico onUpdate={onLoadData} handleClose={toggleModImpElettrico} id={IdModImpianto}/>}
        {isOpenNewImpianto && <NewImpelettrico onInsert={onLoadData} handleClose={toggleNewImpElettrico}/>}
        <div className="small" style={{width:"98%"}}>
            <Row> 
                {
                parseFloat(UserRole)!==Ruolo_Azienda && (
                <Col md={4}>
                    <FormGroup>
                        <Label for="txtRicAzienda">Azienda</Label>
                        <MyAutoComplete id="txtRicAzienda" name="txtRicAzienda" 
                        url={process.env.REACT_APP_API_URL+'customersinglelist'} 
                        idparent={IDCommerciale}
                        optionname="RagioneSociale" optionvalue="id"
                        customvalue={RicAzienda} onChange={handleRicAzienda} 
                        className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>)
                }
                <Col md={4}>
                    <FormGroup>
                            <Label for="txtRicIndirizzo">Indirizzo</Label>
                            <Input type="search" className="search" name="txtRicIndirizzo" placeholder=""
                            value={RicIndirizzo} onChange={handleRicIndirizzo}
                            bsSize="sm"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Row>
                           <Col md="6">
                                <label>
                                <input type="radio" name="radioRicLuogo" value="0" onClick={handleRicTipoLuogo} />
                                    &nbsp;Provincia
                                </label>
                           </Col> 
                           <Col md="6">
                            <label>
                                <input type="radio" name="radioRicLuogo" value="1" onClick={handleRicTipoLuogo}/>
                                &nbsp;Regione
                            </label>
                           </Col> 
                        </Row>
                        <MyAutoComplete id="txtRicLuogo" name="txtRicLuogo" url={RicUrlLuogo} 
                        optionname={RicOptionName} optionvalue={RicOptionValue}
                        customvalue={RicTextLuogo} onChange={handleRicLuogo} className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={2} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataVerificaInizio">Da Data</Label>
                        <MyDatePicker name="txtRicDataVerificaInizio" className="form-control-sm"
                            value={RicDataVerificaInizio} onChange={handleRicDataVerificaInizio} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col>
                <Col xl={2} lg={2} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataVerificaFine">A Data</Label>
                        <MyDatePicker name="txtRicDataVerificaFine" className="form-control-sm" 
                            value={RicDataVerificaFine} onChange={handleRicDataVerificaFine} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col> 
                <Col md={2}>
                    <FormGroup>
                        <Label for="selRicStato">Stato</Label>
                        <Input type="select" className="search" name="selRicStato" 
                        id="selRicStato" placeholder="" bsSize="sm"
                            value={RicStato} onChange={handleRicStato}>
                                <option key="" value="">Tutti</option>
                                {GetElencoStatoImpElettrico().map((item) => (
                                    <option key={item.stato_value} value={item.stato_value}>{item.stato_name}</option>
                                ))}
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col> 
                <Col md={2}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className="btn-mbg-cert btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <div>&nbsp;</div>
        <Paper className={classes.paper}> 
        <Toolbar>
            <Row style={{width:'100%'}}>
                <Col xs={6} md={4} lg={2} xl={1}><Button onClick={toggleNewImpElettrico} className="btn-mbg-cert">Inserisci</Button></Col>
                <Col xs={6} md={4} lg={2} xl={1}><ExportToExcel data={data}/></Col>
            </Row>
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                                className="rt-tr-group"
                            >
                                <TableCell id={labelId} scope="row">
                                    <Avatar className={"btn-mbg-cert " + classes.BtnEdit}  onClick={() => toggleModImpElettrico(row.id)}>
                                        <AssignmentIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell>
                                {
                                    (parseFloat(UserRole)===Ruolo_Admin) && (
                                    <Avatar className={"btn-mbg-cert " + classes.BtnEdit}  onClick={() => toggleDelImpElettrico(row.id)}>
                                        <DeleteIcon />
                                    </Avatar>)
                                }
                                </TableCell>
                                <TableCell>
                                {
                                    (parseFloat(UserRole)===Ruolo_Admin) && (
                                    <Avatar className={"btn-mbg-cert " + classes.BtnEdit}  onClick={() => creaOrdineImpianto(row.id,row.Azienda_id)}>
                                        <ShoppingCartIcon />
                                    </Avatar>)
                                }
                                </TableCell>
                                <TableCell>{row.RagioneSociale}</TableCell>
                                <TableCell>{row.Indirizzo}</TableCell>
                                <TableCell>{row.DesComune}</TableCell>
                                <TableCell>{row.DataUltimaVerificaCast}</TableCell>                                
                                <TableCell>{row.DataInserimento}</TableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={8} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[25, 50, 100]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 