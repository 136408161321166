module.exports = {
    Ruolo_Admin :1,
    Ruolo_Commerciale :2,
    Ruolo_Azienda :3,
    Ruolo_Ispettore :4,
    Ruolo_CommTecnico :5,
    Grado_Amministratore :1,
    Grado_Direttore :2,
    Grado_Executive :3,
    Grado_Partner :4,
    Grado_Manager :5,
    Grado_Specialist :6,
    Rischio_Indifferente :0,
    Rischio_Alto :1,
    Rischio_Medio :2,
    Rischio_Basso :3,
    Zona_cNord :1,
    Zona_cSud :2,
    Nomina_Indifferente :0,
    Nomina_DL :1,
    Nomina_DIP :2,
    app_cpService :1,
    app_cpCert :2,
    Dip_Operativo :0,
    Dip_Licenziato :1,
    Color_CpService :'#011e87',
    Color_CpCert:'#2370A4',
    Stato_DaConvalidare: "1",
    Stato_DaCompletare: "2",
    Stato_DaApprovare: "3",
    Stato_DaConsegnare: "4",
    Stato_DaPagare: "5",
    Stato_Completato: "6",
    Stato_InScadenza: "7",
    Stato_Annullato: "8",
    Stato_Scaduti: "9",
    Stato_Rimandato: "10",
    Stato_Preventivo_DaInviare: "1",
    Stato_Preventivo_DaApprovare: "2",
    Stato_Preventivo_Approvato: "3",
    Stato_Preventivo_Annullato: "4",
    Verifica_FissareAppuntamento:"1",
    Verifica_DaVerificare:"2",
    Verifica_DaApprovare:"3",
    Verifica_Completata:"4", 
    Verifica_Annullata:"5", 
    Verifica_AppuntamentoRimandato:"6",   
    Tipo_verifica_ScaricheAtmosferiche: 1,
    Tipo_verifica_MessaTerra : 2,
    Tipo_verifica_RischioEsplosione : 3, 
    ModalSpesa_Fisso:0,
    ModalSpesa_Percentuale:1,
    Campaign_State_DaInviare:0,
    Campaign_State_Inviate:1,
    TipoDistintaProvv_Commerciale:0,
    TipoDistintaProvv_Ispettore:1,
    Notification_Channel_WhatsApp:0,
    Notification_Channel_Memo:1,
    Notification_Direction_Inbound:0,
    Notification_Direction_Outbound:1
}