import React, {useRef,useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import MyNumInput from '../../Components/MyNumInput';
import MyTimeInput from '../../Components/MyTimeInput';
import MyViewImage from '../../Components/MyViewImage';
import MyAlert from '../../Components/MyAlert';
import {GetMessage} from "../../Modules/Message";
import icoOk from "../../img/ok.png";
import {GetToken,GetRole,ccyFormat, GetTextStatoVerificaCert, GetTextTipoVerifica,  ccyFormatValue, VerifyImage, GetIDIspettore, GetIDIspettoreEmulato} from "../../Modules/Funzioni";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import '../../css/Form.css';
import '../../css/mobile.scss';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import SignCanvas from 'react-signature-canvas'
import MyConfirmEsecuzioneVerifica from '../../Components/MyConfirm';
import MyConfirmApprovaVerifica from '../../Components/MyConfirm';
import MyConfirmScattaFoto from '../../Components/MyConfirm';
import MyConfirmCancellaFoto from '../../Components/MyConfirm';
import {Ruolo_Admin, Tipo_verifica_MessaTerra, Tipo_verifica_RischioEsplosione, Tipo_verifica_ScaricheAtmosferiche } from '../../Modules/Costanti';
import MyDatePicker from '../../Components/MyDatePicker';
import MyAutoComplete from '../../Components/MyAutoComplete';
import { Checkbox, DialogTitle, FormControlLabel } from "@material-ui/core";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import MyPhoneInput from "../../Components/MyPhoneInput";
const useStyles = makeStyles((theme) => ({
    
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    titleSezione: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        fontSize:'3em',
        flex: 1,
        color: '#FFFFFF',
        background: "linear-gradient(130deg, #0A4F9C 60%, #fdcd3b 40%)"
    },
    riquadro: {
        border:"4px solid #fdcd3b",
        padding:"10px",
        borderRadius:"5px"
    },
    legenda: {
        fontSize:'0.9em',
        flex: 1,
        color: '#8f8c8c',
        textAlign:"justify"
    },
    titleLegenda: {
        fontSize:'1.2em',
        flex: 1,
        color: '#0A4F9C',
        textAlign:"center"
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }, 
    table: {
        minWidth: 750,
    },
    tableCustom: {
        width:'100%',
        marginLeft:'auto',
        marginRight:'auto',
        fontSize:'1.4em', 
    },
    tableCustomColor: {
        backgroundColor:'#fdcd3b'
    },
    tableCustomBorder: {
        border: '1pt solid #C0C0C0',
    },
    container: {
        maxHeight: 400,
        minHeight: 400,
      },
    FormPopup: {
        maxWidth: "900px !important",
        minWidth: "800px !important",
      },
      BtnEdit: {
        '&:hover':{
            cursor:"pointer"
        }  
    },
}));
function CreateSign(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [Signed, setSigned] = React.useState(false);
    const mySign=useRef({});

    const onConfirm = () => {
        props.handleConfirm(mySign.current.getTrimmedCanvas().toDataURL("image/png"));
    }
    
    const onClear = () => {
        setSigned(false);
        mySign.current.clear();
    }

    const onSign = () => {
        setSigned(true);
    }
    
  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle>
            <Typography variant="h6" className={classes.title}>
                {props.title}
            </Typography>
        </DialogTitle>
        <DialogContent className={classes.FormPopup} >
            <SignCanvas ref={mySign}  canvasProps={{className: 'signCanvas'}} onEnd={onSign} />
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg-cert btn-block" disabled={!Signed} readOnly={!Signed}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-block btn btn-secondary">
                    Annulla
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button  onClick={onClear} className="btn-mbg1 btn-block" disabled={!Signed} readOnly={!Signed}>
                    Cancella 
                </Button>
            </div>            
        </DialogActions>
      </Dialog>
  );
}

 
export default function ModVerifica (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [DataDomandeSa, setDataDomandeSa] = useState([]);
    const [DataFoto, setDataFoto] = useState([]);
    const [DataDomandeExp, setDataDomandeExp] = useState([]);
    const [isSubmit, setisSubmit] = React.useState(false);
     //alert object
     const [OpenAlert, setOpenAlert] = React.useState(false);
     const [VerticalAlert, setVerticalAlert] = React.useState("top");
     const [SeverityAlert, setSeverityAlert] = React.useState("error");
     const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
     const onCloseAlert = (event) => {
         setOpenAlert(false);
     };
     //------------
    const [CloseForm, setCloseForm] = React.useState(false);
    const [OpenConfirmEsecuzioneVerifica, setOpenConfirmEsecuzioneVerifica] = React.useState(false);
    const [OpenConfirmScattaFoto, setOpenConfirmScattaFoto] = React.useState(false);
    const [OpenConfirmCancellaFoto, setOpenConfirmCancellaFoto] = React.useState(false);
    const [OpenConfirmApprovazioneVerifica, setOpenConfirmApprovazioneVerifica] = React.useState(false);
    const [NumOrdine, setNumOrdine] = React.useState("");
    const [NumRapporto, setNumRapporto] = React.useState("");
    const [DataOrdine, setDataOrdine] = React.useState("");
    const [DataVerifica, setDataVerifica] = React.useState("");
    const [DesIspettore, setDesIspettore] = React.useState("");
    const [IdIspettore, setIdIspettore] = React.useState("");
    const [IdImpianto, setIdImpianto] = React.useState("");
    const [IdOrdine, setIdOrdine] = React.useState("");
    const [IdAzienda, setIdAzienda] = React.useState("");
    const [DesIspettore2, setDesIspettore2] = React.useState("");
    const [IdIspettore2, setIdIspettore2] = React.useState("");
    const [DesStrumento, setDesStrumento] = React.useState("");
    const [DataTaraturaStrumento, setDataTaraturaStrumento] = React.useState("");
    const [NumTecnici, setNumTecnici] = React.useState(1);

    const [Annulla, setAnnulla] = React.useState("");
    const [Rimandato, setRimandato] = React.useState("");
    const [Approvato, setApprovato] = React.useState("");
    const [ApprovatoOld, setApprovatoOld] = React.useState("");
    const [MotivoRimandato, setMotivoRimandato] = React.useState("");
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [CodiceUnivoco, setCodiceUnivoco] = React.useState("");
    const [PartitaIva, setPartitaIva] = React.useState("");
    const [PartitaIvaOld, setPartitaIvaOld] = React.useState("");
    const [Telefono, setTelefono] = React.useState("");
    const [Saldo, setSaldo] = React.useState(0);
    const [Cellulare, setCellulare] = React.useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [OpenSignCliente, setOpenSignCliente] = useState(false);
    const [Note, setNote] = React.useState("");
    const [ImportoSaldo, setImportoSaldo] = React.useState(0);
    const [ModalPag, setModalPag] = React.useState("");
    const [ImgSignCliente, setImgSignCliente] = React.useState(null);    
    const [TipoVerifica, setTipoVerifica] = React.useState(0);
    const [DesTipoVerifica, setDesTipoVerifica] = React.useState("");
    const [StatoVerifica, setStatoVerifica] = React.useState("");
    const [DataAppuntamento, setDataAppuntamento] = React.useState("");    
    const [OraAppuntamento, setOraAppuntamento] = React.useState("");    
    //Impianto
    const [ModImpianto, setModImpianto] = React.useState(0);
    const [NumCabine, setNumCabine] = React.useState("");
    const [NumPod, setNumPod] = React.useState("");
    const [Superficie, setSuperficie] = React.useState("");
    const [Indirizzo, setIndirizzo] = React.useState("");
    const [Comune, setComune] = React.useState("");
    const [DescrizioneAttivita, setDescrizioneAttivita] = React.useState("");
    const [DescrizioneImpianto, setDescrizioneImpianto] = React.useState("");
    const [DataUltimaVerifica, setDataUltimaVerifica] = React.useState("");
    const [DataOmologazione, setDataOmologazione] = React.useState("");
    const [DataInstallazione, setDataInstallazione] = React.useState("");
    const [DataChiusura, setDataChiusura] = React.useState("");
    const [DataChiusuraPrev, setDataChiusuraPrev] = React.useState("");
    const [CodComune, setCodComune] = React.useState("");
    const [Tensione, setTensione] = React.useState("");
    const [SistDistribuzione, setSistDistribuzione] = React.useState("");
    const [PotenzaKw, setPotenzaKw] = React.useState("");
    const [AnniValidita, setAnniValidita] = React.useState("");
    const [IdPersVerifica, setIdPersVerifica] = React.useState("");
    const [TipoAmbiente, setTipoAmbiente] = React.useState("");
    const [IdTipoAmbiente, setIdTipoAmbiente] = React.useState("");
    //Elementi Aggiuntivi Impianto
    const [DichiarazioneConformita, setDichiarazioneConformita] = React.useState("");
    const [AnnoPrimaInstallazione, setAnnoPrimaInstallazione] = React.useState("");
    const [DispProgetto, setDispProgetto] = React.useState("");
    const [SistemaProtezione, setSistemaProtezione] = React.useState("");
    const [DispDiRi, setDispDiRi] = React.useState("");
    //piano ispezione
    const [OraInizio, setOraInizio] = React.useState("");
    const [OraInizioFase1, setOraInizioFase1] = React.useState("");
    const [OraInizioFase2, setOraInizioFase2] = React.useState("");
    const [OraInizioFase3, setOraInizioFase3] = React.useState("");
    const [OraInizioFase4, setOraInizioFase4] = React.useState("");
    const [OraFineFase1, setOraFineFase1] = React.useState("");
    const [OraFineFase2, setOraFineFase2] = React.useState("");
    const [OraFineFase3, setOraFineFase3] = React.useState("");
    const [OraFineFase4, setOraFineFase4] = React.useState("");
    const [StimaFase1, setStimaFase1] = React.useState("");
    const [StimaFase2, setStimaFase2] = React.useState("");
    const [StimaFase3, setStimaFase3] = React.useState("");
    const [StimaFase4, setStimaFase4] = React.useState("");
    //Verifica
    const [VerificaEseguita, setVerificaEseguita] = React.useState("");
    const [ResistenzaTerra, setResistenzaTerra] = React.useState("");
    const [EsitoVerificaSa, setEsitoVerificaSa] = React.useState("1");
    const [EsitoVerificaMt, setEsitoVerificaMt] = React.useState("1");
    const [EsitoVerificaEx, setEsitoVerificaEx] = React.useState("1");
    const [TensioneLimite, setTensioneLimite] = React.useState(0);
    const [MassimaResistenza, setMassimaResistenza] = React.useState("");
    const [RtPrimaVerifica, setRtPrimaVerifica] = React.useState("");
    const [RiferimentoCliente, setRiferimentoCliente] = React.useState("");
    const [EsameImpiantoLPS, setEsameImpiantoLPS] = React.useState("");
    const [MisuraResistenzaOhm, setMisuraResistenzaOhm] = React.useState("");
    const [TensioneCalcolata, setTensioneCalcolata] = React.useState(0);
    const [NumeroProveInterruttori, setNumeroProveInterruttori] = React.useState("");
    const [NumeroInterruttoriTotali, setNumeroInterruttoriTotali] = React.useState("");
    const [DifferenzialiPresenti, setDifferenzialiPresenti] = React.useState("");
    const [VediOsservazioni, setVediOsservazioni] = React.useState(0);
    const [Gruppo, setGruppo] = React.useState("");
    const [NodoEquipotenziale, setNodoEquipotenziale] = React.useState("");
    const [DifferenzialiConformi, setDifferenzialiConformi] = React.useState("");
    const [DispersoreConduttoreCordato, setDispersoreConduttoreCordato] = React.useState(0);
    const [DispersoreConduttoreCordato1, setDispersoreConduttoreCordato1] = React.useState(0);
    const [DispersoreConduttoreCordato2, setDispersoreConduttoreCordato2] = React.useState(0);
    const [DispersorePiastra, setDispersorePiastra] = React.useState(0);
    const [DispersorePiastra1, setDispersorePiastra1] = React.useState(0);
    const [DispersorePiastra2, setDispersorePiastra2] = React.useState(0);
    const [DispersoreTondino, setDispersoreTondino] = React.useState(0);
    const [DispersoreTondino1, setDispersoreTondino1] = React.useState(0);
    const [DispersoreTondino2, setDispersoreTondino2] = React.useState(0);
    const [DispersoreNastro, setDispersoreNastro] = React.useState(0);
    const [DispersoreNastro1, setDispersoreNastro1] = React.useState(0);
    const [DispersoreNastro2, setDispersoreNastro2] = React.useState(0);
    const [PicchettoTubo, setPicchettoTubo] = React.useState(0);
    const [PicchettoTubo1, setPicchettoTubo1] = React.useState(0);
    const [PicchettoTubo2, setPicchettoTubo2] = React.useState(0);
    const [PicchettoTubo3, setPicchettoTubo3] = React.useState(0);
    const [PicchettoMassiccio, setPicchettoMassiccio] = React.useState(0);
    const [PicchettoMassiccio1, setPicchettoMassiccio1] = React.useState(0);
    const [PicchettoMassiccio2, setPicchettoMassiccio2] = React.useState(0);
    const [PicchettoMassiccio3, setPicchettoMassiccio3] = React.useState(0);
    const [PicchettoProfilato, setPicchettoProfilato] = React.useState(0);
    const [PicchettoProfilato1, setPicchettoProfilato1] = React.useState(0);
    const [PicchettoProfilato2, setPicchettoProfilato2] = React.useState(0);
    const [PicchettoProfilato3, setPicchettoProfilato3] = React.useState(0);
    const [SaImpBaseElementoArtificiale, setSaImpBaseElementoArtificiale] = React.useState(0);
    const [SaImpBaseElementoNaturale, setSaImpBaseElementoNaturale] = React.useState(0);
    const [SaImpBaseSistemaDisperdente, setSaImpBaseSistemaDisperdente] = React.useState(0);
    const [SaImpBaseDispersoreDiFatto, setSaImpBaseDispersoreDiFatto] = React.useState(0);
    const [SaElCapAdAsteVerticali, setSaElCapAdAsteVerticali] = React.useState(0);
    const [SaElCapAFuni, setSaElCapAFuni] = React.useState(0);
    const [SaElCapAMaglia, setSaElCapAMaglia] = React.useState(0);
    const [SaElCapFerroArmatura, setSaElCapFerroArmatura] = React.useState(0);
    const [SaElCapAdAsteVerticaliN, setSaElCapAdAsteVerticaliN] = React.useState("");
    const [SaElCapAdAsteVerticaliMq, setSaElCapAdAsteVerticaliMq] = React.useState("");
    const [SaElCapAFuniN, setSaElCapAFuniN] = React.useState("");
    const [SaElCapAFuniMq, setSaElCapAFuniMq] = React.useState("");
    const [SaElCapAMagliaN, setSaElCapAMagliaN] = React.useState("");
    const [SaElCapAMagliaMq, setSaElCapAMagliaMq] = React.useState("");
    const [SaElCapFerroArmaturaN, setSaElCapFerroArmaturaN] = React.useState("");
    const [SaElCapFerroArmaturaMq, setSaElCapFerroArmaturaMq] = React.useState("");
    const [MetodoMisuraVoltAmperometrico, setMetodoMisuraVoltAmperometrico] = React.useState(0);
    const [MetodoMisuraAnelloDiGuasto, setMetodoMisuraAnelloDiGuasto] = React.useState(0);
    const [Fulminazione, setFulminazione] = React.useState(0);
    const [Ex_ia, setEx_ia] = React.useState(0);
    const [Ex_ma, setEx_ma] = React.useState(0);
    const [Ex_d, setEx_d] = React.useState(0);
    const [Ex_e, setEx_e] = React.useState(0);
    const [Ex_p, setEx_p] = React.useState(0);
    const [Ex_m, setEx_m] = React.useState(0);
    const [Ex_o, setEx_o] = React.useState(0);
    const [Ex_q, setEx_q] = React.useState(0);
    const [Ex_n, setEx_n] = React.useState(0);
    const [Zona0_Ex_i, setZona0_Ex_i] = React.useState(0);
    const [Zona1_Ex_i, setZona1_Ex_i] = React.useState(0);
    const [Zona2_Ex_i, setZona2_Ex_i] = React.useState(0);
    const [Zone0, setZone0] = React.useState(0);
    const [Zone1, setZone1] = React.useState(0);
    const [Zone2, setZone2] = React.useState(0);
    const [Zone20, setZone20] = React.useState(0);
    const [Zone21, setZone21] = React.useState(0);
    const [Zone22, setZone22] = React.useState(0);
    const [TerrenoAsciutto, setTerrenoAsciutto] = React.useState(0);
    const [TerrenoUmido, setTerrenoUmido] = React.useState(0);
    const [TerrenoBagnato, setTerrenoBagnato] = React.useState(0);
    const [TerrenoGelato, setTerrenoGelato] = React.useState(0);
    //foto
    const [FotoScattata, setFotoScattata] = React.useState("");
    const [FileFoto, setFileFoto] = React.useState("");
    const [IdFoto, setIdFoto] = React.useState("");
    const [OpenFoto, setOpenFoto] = React.useState(false);
    const [DescFoto, setDescFoto] = React.useState("");
    const [DescFotoOpen, setDescFotoOpen] = React.useState("");
    const [SpecificaFoto, setSpecificaFoto] = React.useState("");
    const onCloseConfirmEsecuzioneVerifica = (event) => {
        setOpenConfirmEsecuzioneVerifica(false);
        onSubmitForm(false,true);
    };
    const onCloseViewFoto = (event) => {
        setOpenFoto(false);
    };
    const onCloseConfirmScattaFoto = (event) => {
        setOpenConfirmScattaFoto(false);
    };
    const onConfirmScattaFoto = (event) => {
        setOpenConfirmScattaFoto(false);
        submitFoto();
    };
    const onCloseConfirmCancellaFoto = (event) => {
        setOpenConfirmCancellaFoto(false);
    };
    const onConfirmCancellaFoto = (event) => {
        setOpenConfirmCancellaFoto(false);
        deleteFoto();
    };
    const onOpenFoto = (_pathFoto,_DescFoto) => {
        setIsLoading(true);    
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
   
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'docfotoverifica';
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("Foto_path", _pathFoto);
                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                var data = "data:image/png;base64," + result.data;
                setFileFoto(data);
                setDescFotoOpen(_DescFoto);
                setOpenFoto(true);
            }
            
        })(); 
        
    };
    const handleModalPag = (event) => {
        setModalPag(event.target.value);
    };
    const onCancellaFoto = (_id) => {
        setIdFoto(_id);
        setOpenConfirmCancellaFoto(true);
    };
    const onConfirmEsecuzioneVerifica = (event) => {
        setOpenConfirmEsecuzioneVerifica(false);
        onSubmitForm(true,false);
    };

    const onCloseConfirmApprovazioneVerifica = (event) => {
        setOpenConfirmApprovazioneVerifica(false);
    };
    const onConfirmApprovazioneVerifica = (event) => {
        setOpenConfirmApprovazioneVerifica(false);
        onSubmitForm(true,false);
    };

    //confirm object
    const onCloseSignCliente = (event) => {
        setOpenSignCliente(false);
    };
    const onConfirmSignCliente = (_Sign) => {
        setImgSignCliente(_Sign);
        setOpenSignCliente(false);
    };
    const onOpenSignCliente = (event) => {  
        setOpenSignCliente(!OpenSignCliente);  
    };
    const handleModImpianto = (event) => {  
        setModImpianto(!ModImpianto);  
    };
    const handleNote = (event) => {  
        setNote(event.target.value);  
    };
    const handleDispersoreConduttoreCordato= (event) =>{
        setDispersoreConduttoreCordato(!DispersoreConduttoreCordato);
        if (DispersoreConduttoreCordato){
            setDispersoreConduttoreCordato1(0);
            setDispersoreConduttoreCordato2(0);
        }
    };
    const handleDispersoreConduttoreCordato1= (event) =>{
        setDispersoreConduttoreCordato1(!DispersoreConduttoreCordato1);
    };
    const handleDispersoreConduttoreCordato2= (event) =>{
        setDispersoreConduttoreCordato2(!DispersoreConduttoreCordato2);
    };
    const handleDispersorePiastra= (event) =>{
        setDispersorePiastra(!DispersorePiastra);
        if (DispersorePiastra){
            setDispersorePiastra1(0);
            setDispersorePiastra2(0);
        }
    };
    const handleDispersorePiastra1= (event) =>{
        setDispersorePiastra1(!DispersorePiastra1);
    };
    const handleDispersorePiastra2= (event) =>{
        setDispersorePiastra2(!DispersorePiastra2);
    };
    const handleDispersoreNastro= (event) =>{
        setDispersoreNastro(!DispersoreNastro);
        if (DispersoreNastro){
            setDispersoreNastro1(0);
            setDispersoreNastro2(0);
        }
    };
    const handleDispersoreNastro1= (event) =>{
        setDispersoreNastro1(!DispersoreNastro1);
    };
    const handleDispersoreNastro2= (event) =>{
        setDispersoreNastro2(!DispersoreNastro2);
    };
    const handleDispersoreTondino= (event) =>{
        setDispersoreTondino(!DispersoreTondino);
        if (DispersoreTondino){
            setDispersoreTondino1(0);
            setDispersoreTondino2(0);
        }
    };
    const handleDispersoreTondino1= (event) =>{
        setDispersoreTondino1(!DispersoreTondino1);
    };
    const handleDispersoreTondino2= (event) =>{
        setDispersoreTondino2(!DispersoreTondino2);
    };
    const handlePicchettoTubo= (event) =>{
        setPicchettoTubo(!PicchettoTubo);
        if (PicchettoTubo){
            setPicchettoTubo1(0);
            setPicchettoTubo2(0);
            setPicchettoTubo3(0);
        }
    };
    const handlePicchettoTubo1= (event) =>{
        setPicchettoTubo1(!PicchettoTubo1);
    };
    const handlePicchettoTubo2= (event) =>{
        setPicchettoTubo2(!PicchettoTubo2);
    };
    const handlePicchettoTubo3= (event) =>{
        setPicchettoTubo3(!PicchettoTubo3);
    };
    const handlePicchettoMassiccio= (event) =>{
        setPicchettoMassiccio(!PicchettoMassiccio);
        if (PicchettoMassiccio){
            setPicchettoMassiccio1(0);
            setPicchettoMassiccio2(0);
            setPicchettoMassiccio3(0);
        }
    };
    const handlePicchettoMassiccio1= (event) =>{
        setPicchettoMassiccio1(!PicchettoMassiccio1);
    };
    const handlePicchettoMassiccio2= (event) =>{
        setPicchettoMassiccio2(!PicchettoMassiccio2);
    };
    const handlePicchettoMassiccio3= (event) =>{
        setPicchettoMassiccio3(!PicchettoMassiccio3);
    };
    const handlePicchettoProfilato= (event) =>{
        setPicchettoProfilato(!PicchettoProfilato);
        if (PicchettoProfilato){
            setPicchettoProfilato1(0);
            setPicchettoProfilato2(0);
            setPicchettoProfilato3(0);
        }
    };
    const handlePicchettoProfilato1= (event) =>{
        setPicchettoProfilato1(!PicchettoProfilato1);
    };
    const handlePicchettoProfilato2= (event) =>{
        setPicchettoProfilato2(!PicchettoProfilato2);
    };
    const handlePicchettoProfilato3= (event) =>{
        setPicchettoProfilato3(!PicchettoProfilato3);
    };
    const handleSaImpBaseElementoArtificiale= (event) =>{
        setSaImpBaseElementoArtificiale(!SaImpBaseElementoArtificiale);
    };
    const handleSaImpBaseElementoNaturale= (event) =>{
        setSaImpBaseElementoNaturale(!SaImpBaseElementoNaturale);
    };
    const handleSaImpBaseSistemaDisperdente= (event) =>{
        setSaImpBaseSistemaDisperdente(!SaImpBaseSistemaDisperdente);
    };
    const handleSaImpBaseDispersoreDiFatto= (event) =>{
        setSaImpBaseDispersoreDiFatto(!SaImpBaseDispersoreDiFatto);
    };
    const handleSaElCapAdAsteVerticali= (event) =>{
        setSaElCapAdAsteVerticali(!SaElCapAdAsteVerticali);
        setSaElCapAdAsteVerticaliN("");
        setSaElCapAdAsteVerticaliMq("");
        validator.current.hideMessages();
        validator.current.purgeFields();
    };
    const handleSaElCapAFuni= (event) =>{
        setSaElCapAFuni(!SaElCapAFuni);
        setSaElCapAFuniN("");
        setSaElCapAFuniMq("");
        validator.current.hideMessages();
        validator.current.purgeFields();
    };
    const handleSaElCapAMaglia= (event) =>{
        setSaElCapAMaglia(!SaElCapAMaglia);
        setSaElCapAMagliaN("");
        setSaElCapAMagliaMq("");
        validator.current.hideMessages();
        validator.current.purgeFields();
    };
    const handleSaElCapFerroArmatura= (event) =>{
        setSaElCapFerroArmatura(!SaElCapFerroArmatura);
        setSaElCapFerroArmaturaN("");
        setSaElCapFerroArmaturaMq("");
        validator.current.hideMessages();
        validator.current.purgeFields();
    };
    const handleRimandato= (event) =>{
        setRimandato(!Rimandato);
    };
    const handleMotivoRimandato= (event) =>{
        setMotivoRimandato(event.target.value);
    };
    const handleIspettore = (value,text) => {  
        setIdIspettore(value);
        setDesIspettore(text);  
    };
    const handleIspettore2 = (value,text) => {  
        setIdIspettore2(value);
        setDesIspettore2(text);  
    }; 
    const handleApprova= (event) =>{
        setApprovato(!Approvato);
    };
    const handleVediOsservazioni= (event) =>{
        setVediOsservazioni(parseInt(event.target.value));
    };
    const handleDescrizioneAttivita = (event) => {  
        setDescrizioneAttivita(event.target.value);  
    }; 
    const handleDescrizioneImpianto = (event) => {  
        setDescrizioneImpianto(event.target.value);  
    };
    const handleEmail = (event) => {  
        setEmail(event.target.value);  
    }; 
    const handleCodiceUnivoco = (event) => {  
        setCodiceUnivoco(event.target.value);  
    }; 
    const handlePartitaIva = (event) => {  
        setPartitaIva(event.target.value);  
    }; 
    const handlePotenzaKw = (event) => {  
        setPotenzaKw(event.target.value);  
    }; 
    const handleFulminazione = (event) => {  
        setFulminazione(event.target.value);  
    }; 
    const handleResistenzaTerra = (event) => {
        if (!isNaN(parseFloat(event.target.value))){
            setResistenzaTerra(event.target.value.replace(",","."));
            setMisuraResistenzaOhm(event.target.value.replace(",","."))
        }
        else {
            setResistenzaTerra("");
            setMisuraResistenzaOhm("");
        }
        let resistenza =parseFloat(event.target.value.replace(",","."));
        
        if (!isNaN(resistenza)){
            setTensioneCalcolata(Number(resistenza*50).toFixed(2).replace(',', '.'));
        }
        else{
            setTensioneCalcolata("");
        } 
        setMisuraResistenzaOhm(event.target.value);
        
        CalcolaTensioneLimite(event.target.value,DifferenzialiPresenti);
    };
    const handleDifferenzialiPresenti = (event) => {
        if (!isNaN(parseFloat(event.target.value))){
            setDifferenzialiPresenti(event.target.value.replace(",","."));
        }
        else {
            setDifferenzialiPresenti("");
        }
        CalcolaTensioneLimite(ResistenzaTerra,event.target.value);
    };
    const CalcolaTensioneLimite = (_resistenzaTerra,_differenzialiPresenti) => {
        let resTerra =parseFloat(_resistenzaTerra);
        let difPresenti =parseFloat(_differenzialiPresenti);

        if (!isNaN(resTerra) && !isNaN(difPresenti)){
            setTensioneLimite(Number(resTerra*difPresenti).toFixed(2));
        }
        else{
            setTensioneLimite("");
        }
        
    };
    const handleOraInizio = (value) => {
        CalcolaPianoIspezione(value,StimaFase1,StimaFase2,StimaFase3,StimaFase4);
        setOraInizio(value);
    };
    const handleStimaFase1 = (value) => {
        CalcolaPianoIspezione(OraInizio,value,StimaFase2,StimaFase3,StimaFase4);
        setStimaFase1(value);
    };
    const handleStimaFase2 = (value) => {
        CalcolaPianoIspezione(OraInizio,StimaFase1,value,StimaFase3,StimaFase4);
        setStimaFase2(value);
    };
    const handleStimaFase3 = (value) => {
        CalcolaPianoIspezione(OraInizio,StimaFase1,StimaFase2,value,StimaFase4);
        setStimaFase3(value);
    };
    const handleStimaFase4 = (value) => {
        CalcolaPianoIspezione(OraInizio,StimaFase1,StimaFase2,StimaFase3,value);
        setStimaFase4(value);
    };
    const handleDescFoto = (event) => {
        setDescFoto(event.target.value);
    };
    const handleSpecificaFoto = (event) => {
        setSpecificaFoto(event.target.value);
    };
    const handleClickPhoto = (event) => {
        if (DescFoto!==""){
            hiddenFilePhoto.current.click();
        }
        else {
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("prima di allegare la foto è necessario selezionare un valore nel campo descrizione foto!");
            setOpenAlert(true);
        }
    };
    const hiddenFilePhoto = React.useRef(null);
    const handleChangePhoto = (event) => {
        const _file = event.target.files[0];
        const msg= VerifyImage(_file);
        
        if (msg!==""){
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert(msg);
            setOpenAlert(true);
        }
        else {
            setFotoScattata(_file);
            //chiamare confirm
            setOpenConfirmScattaFoto(true);
        }
    };
    const submitFoto= () => 
    {
        setIsLoading(true);
        const fd = new FormData();
        fd.append("Ordine_id", IdOrdine);
        fd.append("Foto", FotoScattata);
        let _descrizioneFoto=DescFoto;
        if (SpecificaFoto!=="") _descrizioneFoto=_descrizioneFoto + ' ' + SpecificaFoto;
        fd.append("Descrizione", _descrizioneFoto);

        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
            
        ax
        
        .post(process.env.REACT_APP_API_URL+'fotoverificainsert', fd,axiosConfig)
        .then((response) => {
            
            
            if (response.status === 200) {
                if (response.data.error==="OK"){
                    (async () => {
                        await LoadDatiFoto();
                    })(); 
                }
                else {
                    let msg="";
                    let ris=JSON.stringify(response.data);
                    ris=ris.replaceAll("\"", "");
                    ris=ris.replaceAll("{", "");
                    ris=ris.replaceAll("}", "");
                    ris=ris.replaceAll("[", "");
                    ris=ris.replaceAll("]", "");
                    msg="errore durante l'inserimento della foto! " + ris;
                    setSeverityAlert("error")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                }
            }
            else
            { 
                const msg=GetMessage(response.status,response.data.message);
                setSeverityAlert("error")
                setMsgAlert(msg);
                setOpenAlert(true);
            }
        })
        .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setMsgAlert(msg);
                setOpenAlert(true);
            });
            setIsLoading(false);
    
}
const deleteFoto= () => 
    {
        setIsLoading(true);
        
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        ax
        .post(process.env.REACT_APP_API_URL+'fotoverificadelete/'+ IdFoto)
        .then((response) => {
            
            
            if (response.status === 200) {
                if (response.data.error==="OK"){
                    (async () => {
                        await LoadDatiFoto();
                    })(); 
                }
                else {
                    let msg="";
                    let ris=JSON.stringify(response.data);
                    ris=ris.replaceAll("\"", "");
                    ris=ris.replaceAll("{", "");
                    ris=ris.replaceAll("}", "");
                    ris=ris.replaceAll("[", "");
                    ris=ris.replaceAll("]", "");
                    msg="errore durante l'inserimento della foto! " + ris;
                    setSeverityAlert("error")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                }
            }
            else
            { 
                const msg=GetMessage(response.status,response.data.message);
                setSeverityAlert("error")
                setMsgAlert(msg);
                setOpenAlert(true);
            }
        })
        .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setMsgAlert(msg);
                setOpenAlert(true);
            });
            setIsLoading(false);
    
}
    const CalcolaPianoIspezione =(_oraInizio,_stimaFase1,_stimaFase2,_stimaFase3,_stimaFase4) =>{
        let momentoInizio;
        let mOraInizioFase1="";
        let mOraFineFase1="";
        let mOraInizioFase2="";
        let mOraFineFase2="";
        let mOraInizioFase3="";
        let mOraFineFase3="";
        let mOraInizioFase4="";
        let mOraFineFase4="";
        _stimaFase1="";
        
        if (_oraInizio!==""){
            
            momentoInizio = moment(moment().format("YYYY-MM-DD") + " " + _oraInizio);
            
            let mtxOrario1=_oraInizio.split(":");
            if (_stimaFase2!=="" && _stimaFase2!==null){
                //incrementa momento inizio
                //momentoInizio = moment(momentoInizio).add(mtxOrario1[0], 'hours').add(mtxOrario1[1], 'minutes');
                let mtxOrario2=_stimaFase2.split(":");
                //mOraInizioFase2=mOraFineFase1;
                mOraInizioFase2=_oraInizio;
                mOraFineFase2=moment(momentoInizio).add(mtxOrario2[0], 'hours').add(mtxOrario2[1], 'minutes').format("HH:mm");
                if (_stimaFase3!==""  && _stimaFase3!==null){
                    //incrementa momento inizio
                    momentoInizio = moment(momentoInizio).add(mtxOrario2[0], 'hours').add(mtxOrario2[1], 'minutes');
                    let mtxOrario3=_stimaFase3.split(":");
                    mOraInizioFase3=mOraFineFase2;
                    mOraFineFase3=moment(momentoInizio).add(mtxOrario3[0], 'hours').add(mtxOrario3[1], 'minutes').format("HH:mm");    
                    if (_stimaFase4!==""  && _stimaFase4!==null){
                        //incrementa momento inizio
                        momentoInizio = moment(momentoInizio).add(mtxOrario3[0], 'hours').add(mtxOrario3[1], 'minutes');
                        let mtxOrario4=_stimaFase4.split(":");
                        mOraInizioFase4=mOraFineFase3;
                        mOraFineFase4=moment(momentoInizio).add(mtxOrario4[0], 'hours').add(mtxOrario4[1], 'minutes').format("HH:mm");
                        
                        _stimaFase1=moment(momentoInizio).add(mtxOrario4[0], 'hours').add(mtxOrario4[1], 'minutes').subtract(mtxOrario1[0], 'hours').subtract(mtxOrario1[1], 'minutes').format("HH:mm");  
                        //alert(moment(momentoInizio).add(mtxOrario4[0], 'hours').add(mtxOrario4[1], 'minutes').format("HH:mm") + " - " + moment(moment().format("YYYY-MM-DD") + " " + _oraInizio).format("HH:mm"));
                        mOraInizioFase1=_oraInizio;
                        mOraFineFase1=mOraFineFase4;
                    } 
                }    
            }
        }

        setStimaFase1(_stimaFase1);
        setOraInizioFase1(mOraInizioFase1);
        setOraFineFase1(mOraFineFase1);
        setOraInizioFase2(mOraInizioFase2);
        setOraFineFase2(mOraFineFase2);
        setOraInizioFase3(mOraInizioFase3);
        setOraFineFase3(mOraFineFase3);
        setOraInizioFase4(mOraInizioFase4);
        setOraFineFase4(mOraFineFase4);
        
    };
    const handleSaldo = (event) => {
        setSaldo(event.target.checked ? 1 : 0);
    };
    const handleIndirizzo = (event) => {  
        setIndirizzo(event.target.value);  
    }; 
    const handleEsitoVerificaSa = (event) => {  
        setEsitoVerificaSa(event.target.value);  
    };
    const handleEsitoVerificaEx = (event) => {  
        setEsitoVerificaEx(event.target.value);  
    };
    const handleEsitoVerificaMt = (event) => {  
        setEsitoVerificaMt(event.target.value);  
    };
    const handleGruppo = (event) => {  
        setGruppo(event.target.value);  
    };
    const handleMassimaResistenza = (event) => {  
        setMassimaResistenza(event.target.value);  
    };
    
    const handleRiferimentoCliente = (event) => {  
        setRiferimentoCliente(event.target.value);  
    };
    const handleEsameImpiantoLPS = (event) => {  
        setEsameImpiantoLPS(event.target.value);  
    };
    const handleChangeDomandaSa = (value,_id) => { 
        if (DataDomandeSa.length>0){
            let _domande=[];
            DataDomandeSa.forEach((item) => {
              if (item.id===_id){
                  item.Valore=parseInt(value);
              }
              _domande=_domande.concat(item);
            });
            setDataDomandeSa(_domande);
        }
    };
    const handleChangeDomandaExp = (value,_id) => { 
        if (DataDomandeExp.length>0){
            let _domande=[];
            DataDomandeExp.forEach((item) => {
              if (item.id===_id){
                  item.Valore=parseInt(value);
              }  
              _domande=_domande.concat(item);
            });
            setDataDomandeExp(_domande);
        }
    };
    const ValidateDomandeSa = () => { 
        let _validate=true;
        if (DataDomandeSa.length>0){
            DataDomandeSa.forEach((item) => {
              if (parseInt(item.Valore)!==1 && parseInt(item.Valore)!==0 && parseInt(item.Valore)!==2){
                  _validate=false;
                  return false;
              }  
            });
        }
        return _validate;
    };

    const ValidateDomandeExp = () => { 
        let _validate=true;
        if (DataDomandeExp.length>0){
            DataDomandeExp.forEach((item) => {
              if (parseInt(item.Valore)!==1 && parseInt(item.Valore)!==0 && parseInt(item.Valore)!==2){
                _validate=false;
                return false;
              }  
            });
        }
        return _validate;
    };
    const handleMisuraResistenzaOhm = (event) => { 
        let resistenza =parseFloat(event.target.value.replace(",","."));
        
        if (!isNaN(resistenza)){
            setTensioneCalcolata(Number(resistenza*50).toFixed(2).replace(',', '.'));
        }
        else{
            setTensioneCalcolata("");
        } 
        setMisuraResistenzaOhm(event.target.value);
    };
    const handleNodoEquipotenziale = (event) => {  
        setNodoEquipotenziale(event.target.value);  
    };
    const handleDifferenzialiConformi = (event) => {  
        setDifferenzialiConformi(event.target.value);  
    };
    const handleSistDistribuzione = (event) => {  
        setSistDistribuzione(event.target.value);  
    }; 
    const handleDichiarazioneConformita = (event) => {  
        setDichiarazioneConformita(event.target.value);  
    }; 
    const handleAnnoPrimaInstallazione = (event) => {  
        setAnnoPrimaInstallazione(event.target.value);  
    }; 
    const handleDispProgetto = (event) => {  
        setDispProgetto(event.target.value);  
    }; 
    const handleSistemaProtezione = (event) => {  
        setSistemaProtezione(event.target.value);  
    }; 
    const handleDispDiRi = (event) => {  
        setDispDiRi(event.target.value);  
    }; 
    const handleComune = (value,text) => {
        setCodComune(value);
        setComune(text);  
    }; 
    const handleTensione = (event) => {  
        setTensione(event.target.value);  
    }; 
    const handleNumCabine = (event) => {  
        setNumCabine(event.target.value);  
    }; 
    const handleNumPod = (event) => {  
        setNumPod(event.target.value);  
    }; 
    const handleSuperficie = (event) => {  
        setSuperficie(event.target.value);  
    }; 
    const handleTipoAmbiente = (value,text) => {
        var arrValue = value.split("|");
        setIdTipoAmbiente(parseInt(arrValue[0]));
        setAnniValidita(arrValue[1]);
        setIdPersVerifica(arrValue[2]);
        setTipoAmbiente(text);  
    }; 
    const handleNumTecnici= (_num) =>{
        setNumTecnici(parseInt(_num));
    };
    const handleNumeroProveInterruttori= (_num) =>{
        setNumeroProveInterruttori(_num);
        setNumeroInterruttoriTotali(_num*2);
    };
    const handleNumeroInterruttoriTotali= (_num) =>{
        setNumeroInterruttoriTotali(_num);
    };
    const handleSaElCapAdAsteVerticaliN= (_num) =>{
        setSaElCapAdAsteVerticaliN(_num);
    };
    const handleSaElCapAdAsteVerticaliMq = (event) => {  
        setSaElCapAdAsteVerticaliMq(event.target.value);  
    };
    const handleSaElCapAFuniN= (_num) =>{
        setSaElCapAFuniN(_num);
    };
    const handleSaElCapAFuniMq = (event) => {  
        setSaElCapAFuniMq(event.target.value);  
    };
    const handleSaElCapAMagliaN= (_num) =>{
        setSaElCapAMagliaN(_num);
    };
    const handleSaElCapAMagliaMq = (event) => {  
        setSaElCapAMagliaMq(event.target.value);  
    };
    const handleSaElCapFerroArmaturaN= (_num) =>{
        setSaElCapFerroArmaturaN(_num);
    };
    const handleSaElCapFerroArmaturaMq = (event) => {  
        setSaElCapFerroArmaturaMq(event.target.value);  
    };
    const handleMetodoMisuraVoltAmperometrico= (_event) =>{
        setMetodoMisuraVoltAmperometrico(!MetodoMisuraVoltAmperometrico);
    };
    const handleMetodoMisuraAnelloDiGuasto= (_event) =>{
        setMetodoMisuraAnelloDiGuasto(!MetodoMisuraAnelloDiGuasto);
    };
    const handleTerrenoAsciutto= (_event) =>{
        setTerrenoAsciutto(!TerrenoAsciutto);
    };
    const handleTerrenoUmido= (_event) =>{
        setTerrenoUmido(!TerrenoUmido);
    };
    const handleTerrenoBagnato= (_event) =>{
        setTerrenoBagnato(!TerrenoBagnato);
    };
    const handleTerrenoGelato= (_event) =>{
        setTerrenoGelato(!TerrenoGelato);
    };
    const handleZone0= (_event) =>{
        setZone0(!Zone0);
    };
    const handleZone1= (_event) =>{
        setZone1(!Zone1);
    };
    const handleZone2= (_event) =>{
        setZone2(!Zone2);
    };
    const handleZone20= (_event) =>{
        setZone20(!Zone20);
    };
    const handleZone21= (_event) =>{
        setZone21(!Zone21);
    };
    const handleZone22= (_event) =>{
        setZone22(!Zone22);
    };
    const handleEx_ia= (_event) =>{
        setEx_ia(!Ex_ia);
    };
    const handleEx_ma= (_event) =>{
        setEx_ma(!Ex_ma);
    };
    const handleEx_d= (_event) =>{
        setEx_d(!Ex_d);
    };
    const handleEx_e= (_event) =>{
        setEx_e(!Ex_e);
    };
    const handleEx_p= (_event) =>{
        setEx_p(!Ex_p);
    };
    const handleEx_m= (_event) =>{
        setEx_m(!Ex_m);
    };
    const handleEx_o= (_event) =>{
        setEx_o(!Ex_o);
    };
    const handleEx_q= (_event) =>{
        setEx_q(!Ex_q);
    };
    const handleEx_n= (_event) =>{
        setEx_n(!Ex_n);
    };
    const handleZona0_Ex_i= (_event) =>{
        setZona0_Ex_i(!Zona0_Ex_i);
    };
    const handleZona1_Ex_i= (_event) =>{
        setZona1_Ex_i(!Zona1_Ex_i);
    };
    const handleZona2_Ex_i= (_event) =>{
        setZona2_Ex_i(!Zona2_Ex_i);
    };
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    React.useEffect(() => {
        if (localStorage.getItem("idverifica")!==""){
            (async () => {
                await LoadDatiVerifica();
                if(parseInt(localStorage.getItem("tipoverifica"))=== Tipo_verifica_ScaricheAtmosferiche) await LoadDatiDomandeSa();
                if(parseInt(localStorage.getItem("tipoverifica"))=== Tipo_verifica_RischioEsplosione) await LoadDatiDomandeExp();
                await LoadDatiFoto();
                await sleep(1000);
                setIsLoading(false); 
            })(); 
        }
    }, []);

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    const GetImportoSaldo = (_PrezzoTotale,_Sconto,_CodiceIva, _SpeseKm,_Acconto,_Saldo,_CostoSpesa,_CodiceIvaSpesa) =>{
        let PrezzoIvato=0;
        if (parseFloat(_Saldo)===0){
            let Prezzo=parseFloat(_PrezzoTotale)- (parseFloat(_PrezzoTotale)*parseFloat(_Sconto/100));
            PrezzoIvato=Prezzo + (Prezzo*parseFloat(_CodiceIva/100));
            let totaleSpesa = parseFloat(_SpeseKm) + parseFloat(_CostoSpesa);
            let PrezzoIvatoSpesa= totaleSpesa + (totaleSpesa*parseFloat(_CodiceIvaSpesa/100));
            PrezzoIvato=PrezzoIvato + PrezzoIvatoSpesa - parseFloat(_Acconto);
        }
        
        return PrezzoIvato;
    }
    async function LoadDatiVerifica() {
        if(localStorage.getItem("idverifica")!==""){
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'verifichecert/' + localStorage.getItem("idverifica");               
                result = await ax.post(url);
                setIdImpianto(result.data[0].Impianto_id);
                setIdOrdine(result.data[0].Ordine_id);
                setIdAzienda(result.data[0].Azienda_id);
                setIdIspettore(result.data[0].Ispettore_id);
                setIdIspettore2(result.data[0].Ispettore_id2!=null ? result.data[0].Ispettore_id2 : 0);
                setRagioneSociale(result.data[0].RagioneSociale);
                setNumRapporto(result.data[0].NumRapporto);
                setNumOrdine(result.data[0].NumOrdine);
                setAnnulla(result.data[0].Annullato);
                setRimandato(result.data[0].Rimandato);
                setApprovato(result.data[0].Approvato);
                setApprovatoOld(result.data[0].Approvato);
                setMotivoRimandato(result.data[0].MotivoRimandato);
                setDataOrdine(result.data[0].DataOrdine);
                setDataVerifica(result.data[0].DataVerifica!=null ? result.data[0].DataVerifica : "");
                setDataTaraturaStrumento(result.data[0].DataTaraturaStrumento);
                setDesStrumento(result.data[0].DesStrumento);
                setVerificaEseguita(result.data[0].VerificaEseguita);
                setNumTecnici(parseInt(result.data[0].NumeroTecnici));
                setNote(result.data[0].Note);
                let _desStato=GetTextStatoVerificaCert(result.data[0].Approvato,result.data[0].DataVerifica,result.data[0].DataAppuntamento,result.data[0].Rimandato);
                setStatoVerifica(_desStato);
                if (result.data[0].DataAppuntamento!=null){
                    let _Appuntamento=result.data[0].DataAppuntamento.split(" ");
                
                    setDataAppuntamento(_Appuntamento[0]);
                    setOraAppuntamento(_Appuntamento[1]);
                }
                else {
                    setDataAppuntamento("");
                    setOraAppuntamento("");
                }
                setEmail(result.data[0].Email);
                setCodiceUnivoco(result.data[0].CodUnivoco);
                setPartitaIva(result.data[0].PartitaIva === null ? "" : result.data[0].PartitaIva);
                setPartitaIvaOld(result.data[0].PartitaIva === null ? "" : result.data[0].PartitaIva);
                setTelefono(result.data[0].TelefonoSL);
                setCellulare(result.data[0].CellulareReferente);
                setTipoVerifica(result.data[0].TipologiaVerifica);
                setSaldo(result.data[0].Saldo);
                setModalPag(result.data[0].TipoPagamento);
                let _Importo=GetImportoSaldo(result.data[0].PrezzoTotale,result.data[0].Sconto,result.data[0].CodiceIva,result.data[0].SpeseKm,result.data[0].Acconto,result.data[0].Saldo,result.data[0].CostoSpesa,result.data[0].CodiceIvaSpesa);
                setImportoSaldo(_Importo);
                let _DesTipoVerifica=GetTextTipoVerifica(result.data[0].TipologiaVerifica);
                setDesTipoVerifica(_DesTipoVerifica);
                //dati impianto
                setDispDiRi(result.data[0].DispDiri===null ? "": result.data[0].DispDiri);
                setDispProgetto(result.data[0].DispProgetto===null ? "": result.data[0].DispProgetto);
                setSistemaProtezione(result.data[0].SistemaProtezione===null ? "": result.data[0].SistemaProtezione);
                setAnnoPrimaInstallazione(result.data[0].AnnoPrimaInstallazione===null ? "": result.data[0].AnnoPrimaInstallazione);
                setDichiarazioneConformita(result.data[0].DichiarazioneConformita===null ? "": result.data[0].DichiarazioneConformita);
                setDataInstallazione(result.data[0].DataInstallazione===null ? "": result.data[0].DataInstallazione);
                setIndirizzo(result.data[0].Indirizzo);
                setCodComune(result.data[0].CodComune);
                setComune(result.data[0].DesComune);
                setPotenzaKw(result.data[0].PotenzaKw);
                setSistDistribuzione(result.data[0].SistemaDistribuzione);
                setTensione(result.data[0].TensioneAlimentazione);
                setNumCabine(result.data[0].NumeroCabine);
                setNumPod((result.data[0].NumPod)===null? "": result.data[0].NumPod);
                setDesIspettore(result.data[0].DesIspettore);
                setDesIspettore2(result.data[0].DesIspettore2);
                setDescrizioneAttivita(result.data[0].DescrizioneAttivita);
                setDescrizioneImpianto(result.data[0].CaratteristicheImpianto);
                setSuperficie(result.data[0].SuperficieLuogo);
                setDataOmologazione(result.data[0].DataOmologazione===null ? "": result.data[0].DataOmologazione);
                setIdTipoAmbiente(result.data[0].TipoAmbiente_id);
                setTipoAmbiente(result.data[0].DescAmbiente);
                setIdPersVerifica(result.data[0].PersVerifica_id);
                setAnniValidita(result.data[0].AnniValidita);
                setDataChiusuraPrev(result.data[0].DataPrevistaChiusura===null ? "": result.data[0].DataPrevistaChiusura);
                setDataChiusura(result.data[0].DataChiusura===null ? "": result.data[0].DataChiusura);
                setDataUltimaVerifica(result.data[0].DataUltimaVerifica===null ? "": result.data[0].DataUltimaVerifica);
                //piano ispezione
                setStimaFase1(result.data[0].PI_StimaFase1===null ? "": result.data[0].PI_StimaFase1);
                setStimaFase2(result.data[0].PI_StimaFase2===null ? "": result.data[0].PI_StimaFase2);
                setStimaFase3(result.data[0].PI_StimaFase3===null ? "": result.data[0].PI_StimaFase3);
                setStimaFase4(result.data[0].PI_StimaFase4===null ? "": result.data[0].PI_StimaFase4);
                setOraInizio(result.data[0].PI_OraInizio1===null ? "": result.data[0].PI_OraInizio1);
                setOraInizioFase1(result.data[0].PI_OraInizio1===null ? "": result.data[0].PI_OraInizio1);
                setOraFineFase1(result.data[0].PI_OraFine1===null ? "": result.data[0].PI_OraFine1);
                setOraInizioFase2(result.data[0].PI_OraInizio2===null ? "": result.data[0].PI_OraInizio2);
                setOraFineFase2(result.data[0].PI_OraFine2===null ? "": result.data[0].PI_OraFine2);
                setOraInizioFase3(result.data[0].PI_OraInizio3===null ? "": result.data[0].PI_OraInizio3);
                setOraFineFase3(result.data[0].PI_OraFine3===null ? "": result.data[0].PI_OraFine3);
                setOraInizioFase4(result.data[0].PI_OraInizio4===null ? "": result.data[0].PI_OraInizio4);
                setOraFineFase4(result.data[0].PI_OraFine4===null ? "": result.data[0].PI_OraFine4);
                //DatiVerifica
                setTensioneLimite(result.data[0].TensioneLimite===null ? "": result.data[0].TensioneLimite);
                setResistenzaTerra(result.data[0].ResistenzaDiTerra===null ? "": result.data[0].ResistenzaDiTerra);
                setDifferenzialiPresenti(result.data[0].DifferenzialiPresenti===null ? "": result.data[0].DifferenzialiPresenti);
                setGruppo(result.data[0].Gruppo===null ? "": result.data[0].Gruppo);
                setMassimaResistenza(result.data[0].MassimaResistenza===null ? "": result.data[0].MassimaResistenza);
                setRtPrimaVerifica(result.data[0].RtPrimaVerifica===null ? "": result.data[0].RtPrimaVerifica);
                setRiferimentoCliente(result.data[0].RiferimentoCliente===null ? "": result.data[0].RiferimentoCliente);
                setEsameImpiantoLPS(result.data[0].EsameImpiantoLPS===null ? "": result.data[0].EsameImpiantoLPS);
                setMisuraResistenzaOhm(result.data[0].MisuraResistenzaOhm===null ? "": result.data[0].MisuraResistenzaOhm);
                setTensioneCalcolata(result.data[0].TensioneCalcolata===null ? "": result.data[0].TensioneCalcolata);
                setNumeroProveInterruttori(result.data[0].NumeroProveInterruttori===null ? "": result.data[0].NumeroProveInterruttori);
                setNumeroInterruttoriTotali(result.data[0].NumeroInterruttoriTotali===null ? "": result.data[0].NumeroInterruttoriTotali);
                setNodoEquipotenziale(result.data[0].NodoEquipotenziale===null ? "": result.data[0].NodoEquipotenziale);
                setDifferenzialiConformi(result.data[0].DifferenzialiConformi===null ? "": result.data[0].DifferenzialiConformi);
                if (result.data[0].DispersorePiastra1>0 || result.data[0].DispersorePiastra2>0){
                    setDispersorePiastra(1);
                    setDispersorePiastra1(result.data[0].DispersorePiastra1);
                    setDispersorePiastra2(result.data[0].DispersorePiastra2);
                }
                else {
                    setDispersorePiastra(0);
                    setDispersorePiastra1(0);
                    setDispersorePiastra2(0);
                }
                if (result.data[0].DispersoreNastro1>0 || result.data[0].DispersoreNastro2>0){
                    setDispersoreNastro(1);
                    setDispersoreNastro1(result.data[0].DispersoreNastro1);
                    setDispersoreNastro2(result.data[0].DispersoreNastro2);
                }
                else {
                    setDispersoreNastro(0);
                    setDispersoreNastro1(0);
                    setDispersoreNastro2(0);
                }
                if (result.data[0].DispersoreTondino1>0 || result.data[0].DispersoreTondino2>0){
                    setDispersoreTondino(1);
                    setDispersoreTondino1(result.data[0].DispersoreTondino1);
                    setDispersoreTondino2(result.data[0].DispersoreTondino2);
                }
                else {
                    setDispersoreTondino(0);
                    setDispersoreTondino1(0);
                    setDispersoreTondino2(0);
                }
                
                if (result.data[0].DispersoreConduttoreCordato1>0 || result.data[0].DispersoreConduttoreCordato2>0){
                    setDispersoreConduttoreCordato(1);
                    setDispersoreConduttoreCordato1(result.data[0].DispersoreConduttoreCordato1);
                    setDispersoreConduttoreCordato2(result.data[0].DispersoreConduttoreCordato2);
                }
                else {
                    setDispersoreConduttoreCordato(0);
                    setDispersoreConduttoreCordato1(0);
                    setDispersoreConduttoreCordato2(0);
                }
                if (result.data[0].PicchettoTubo1>0 || result.data[0].PicchettoTubo2>0 || result.data[0].PicchettoTubo3>0){
                    setPicchettoTubo(1);
                    setPicchettoTubo1(result.data[0].PicchettoTubo1);
                    setPicchettoTubo2(result.data[0].PicchettoTubo2);
                    setPicchettoTubo3(result.data[0].PicchettoTubo3);
                }
                else {
                    setPicchettoTubo(0);
                    setPicchettoTubo1(0);
                    setPicchettoTubo2(0);
                    setPicchettoTubo3(0);
                }
                if (result.data[0].PicchettoMassiccio1>0 || result.data[0].PicchettoMassiccio2>0 || result.data[0].PicchettoMassiccio3>0){
                    setPicchettoMassiccio(1);
                    setPicchettoMassiccio1(result.data[0].PicchettoMassiccio1);
                    setPicchettoMassiccio2(result.data[0].PicchettoMassiccio2);
                    setPicchettoMassiccio3(result.data[0].PicchettoMassiccio3);
                }
                else {
                    setPicchettoMassiccio(0);
                    setPicchettoMassiccio1(0);
                    setPicchettoMassiccio2(0);
                    setPicchettoMassiccio3(0);
                }
                if (result.data[0].PicchettoProfilato1>0 || result.data[0].PicchettoProfilato2>0 || result.data[0].PicchettoProfilato3>0){
                    setPicchettoProfilato(1);
                    setPicchettoProfilato1(result.data[0].PicchettoProfilato1);
                    setPicchettoProfilato2(result.data[0].PicchettoProfilato2);
                    setPicchettoProfilato3(result.data[0].PicchettoProfilato3);
                }
                else {
                    setPicchettoProfilato(0);
                    setPicchettoProfilato1(0);
                    setPicchettoProfilato2(0);
                    setPicchettoProfilato3(0);
                }
                if (result.data[0].SaImpBaseElementoArtificiale>0){
                    setSaImpBaseElementoArtificiale(1);
                }
                else {
                    setSaImpBaseElementoArtificiale(0);
                }
                if (result.data[0].SaImpBaseElementoNaturale>0){
                    setSaImpBaseElementoNaturale(1);
                }
                else {
                    setSaImpBaseElementoNaturale(0);
                }
                if (result.data[0].SaImpBaseSistemaDisperdente>0){
                    setSaImpBaseSistemaDisperdente(1);
                }
                else {
                    setSaImpBaseSistemaDisperdente(0);
                }
                if (result.data[0].SaImpBaseDispersoreDiFatto>0){
                    setSaImpBaseDispersoreDiFatto(1);
                }
                else {
                    setSaImpBaseDispersoreDiFatto(0);
                }
                if (result.data[0].SaElCapAdAsteVerticali>0){
                    setSaElCapAdAsteVerticali(1);
                    setSaElCapAdAsteVerticaliN(parseInt(result.data[0].SaElCapAdAsteVerticaliN));
                    setSaElCapAdAsteVerticaliMq(result.data[0].SaElCapAdAsteVerticaliMq);
                }
                else {
                    setSaElCapAdAsteVerticali(0);
                    setSaElCapAdAsteVerticaliN("");
                    setSaElCapAdAsteVerticaliMq("");
                }
                if (result.data[0].SaElCapAFuni>0){
                    setSaElCapAFuni(1);
                    setSaElCapAFuniN(result.data[0].SaElCapAFuniN);
                    setSaElCapAFuniMq(result.data[0].SaElCapAFuniMq);
                }
                else {
                    setSaElCapAFuni(0);
                    setSaElCapAFuniN("");
                    setSaElCapAFuniMq("");
                }
                if (result.data[0].SaElCapAMaglia>0){
                    setSaElCapAMaglia(1);
                    setSaElCapAMagliaN(result.data[0].SaElCapAMagliaN);
                    setSaElCapAMagliaMq(result.data[0].SaElCapAMagliaMq);
                }
                else {
                    setSaElCapAMaglia(0);
                    setSaElCapAMagliaN("");
                    setSaElCapAMagliaMq("");
                }
                if (result.data[0].SaElCapFerroArmatura>0){
                    setSaElCapFerroArmatura(1);
                    setSaElCapFerroArmaturaN(result.data[0].SaElCapFerroArmaturaN);
                    setSaElCapFerroArmaturaMq(result.data[0].SaElCapFerroArmaturaMq);
                }
                else {
                    setSaElCapFerroArmatura(0);
                    setSaElCapFerroArmaturaN("");
                    setSaElCapFerroArmaturaMq("");
                }
                if (result.data[0].MetodoMisuraAnelloDiGuasto>0){
                    setMetodoMisuraAnelloDiGuasto(1);
                }
                else {
                    setMetodoMisuraAnelloDiGuasto(0);
                }
                if (result.data[0].MetodoMisuraVoltAmperometrico>0){
                    setMetodoMisuraVoltAmperometrico(1);
                }
                else {
                    setMetodoMisuraVoltAmperometrico(0);
                }
                if (result.data[0].VediOsservazioni>0){
                    setVediOsservazioni(1);
                }
                else {
                    setVediOsservazioni(0);
                }
                setTerrenoAsciutto(result.data[0].Terreno_asciutto!=null ? (result.data[0].Terreno_asciutto):(0));
                setTerrenoUmido(result.data[0].Terreno_umido!=null ? (result.data[0].Terreno_umido):(0));
                setTerrenoBagnato(result.data[0].Terreno_bagnato!=null ? (result.data[0].Terreno_bagnato):(0));
                setTerrenoGelato(result.data[0].Terreno_gelato!=null ? (result.data[0].Terreno_gelato):(0));

                setZone0(result.data[0].Zona0!=null ? (result.data[0].Zona0):(0));
                setZone1(result.data[0].Zona1!=null ? (result.data[0].Zona1):(0));
                setZone2(result.data[0].Zona2!=null ? (result.data[0].Zona2):(0));
                setZone20(result.data[0].Zona20!=null ? (result.data[0].Zona20):(0));
                setZone21(result.data[0].Zona21!=null ? (result.data[0].Zona21):(0));
                setZone22(result.data[0].Zona22!=null ? (result.data[0].Zona22):(0));

                setEx_d(result.data[0].Ex_d!=null ? (result.data[0].Ex_d):(0));
                setEx_e(result.data[0].Ex_e!=null ? (result.data[0].Ex_e):(0));
                setEx_ia(result.data[0].Ex_ia!=null ? (result.data[0].Ex_ia):(0));
                setEx_m(result.data[0].Ex_m!=null ? (result.data[0].Ex_m):(0));
                setEx_ma(result.data[0].Ex_ma!=null ? (result.data[0].Ex_ma):(0));
                setEx_n(result.data[0].Ex_n!=null ? (result.data[0].Ex_n):(0));
                setEx_o(result.data[0].Ex_o!=null ? (result.data[0].Ex_o):(0));
                setEx_p(result.data[0].Ex_p!=null ? (result.data[0].Ex_p):(0));
                setEx_q(result.data[0].Ex_q!=null ? (result.data[0].Ex_q):(0));
                setZona0_Ex_i(result.data[0].Zona0_Ex_i!=null ? (result.data[0].Zona0_Ex_i):(0));
                setZona1_Ex_i(result.data[0].Zona1_Ex_i!=null ? (result.data[0].Zona1_Ex_i):(0));
                setZona2_Ex_i(result.data[0].Zona2_Ex_i!=null ? (result.data[0].Zona2_Ex_i):(0));

                setFulminazione(result.data[0].Fulminazione!=null ? (result.data[0].Fulminazione):(0));
                switch (parseInt(result.data[0].TipologiaVerifica)){
                    case Tipo_verifica_MessaTerra:
                    {
                        setEsitoVerificaMt(result.data[0].EsitoVerifica===null ? "": result.data[0].EsitoVerifica);
                        break;
                    }
                    case Tipo_verifica_RischioEsplosione:
                    {
                        setEsitoVerificaEx(result.data[0].EsitoVerifica===null ? "": result.data[0].EsitoVerifica);
                        break;
                    }
                    case Tipo_verifica_ScaricheAtmosferiche:
                    {
                        setEsitoVerificaSa(result.data[0].EsitoVerifica===null ? "": result.data[0].EsitoVerifica);
                        break;
                    }
                    default:
                } 
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning");
                setVerticalAlert("center");
                setMsgAlert(msg);
                setOpenAlert(true);

            }
        }  
    };

    async function LoadDatiDomandeSa () {
        if(localStorage.getItem("idverifica")!==""){
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            let result=[];
            try{
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("Verifica_id", localStorage.getItem("idverifica"));
                fd.append("Tipologia", Tipo_verifica_ScaricheAtmosferiche);
                let url=process.env.REACT_APP_API_URL+'verifichecertdomande';               
                result = await ax.post(url, fd,axiosConfig);
                
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning");
                setVerticalAlert("center");
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            if (result.data!==undefined)    {
                setDataDomandeSa(result.data);
            }
            else {
                setDataDomandeSa([]);
            }
        }  
    };
    async function LoadDatiDomandeExp () {
        if(localStorage.getItem("idverifica")!==""){
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            let result=[];
            try{
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("Verifica_id", localStorage.getItem("idverifica"));
                fd.append("Tipologia", Tipo_verifica_RischioEsplosione);
                let url=process.env.REACT_APP_API_URL+'verifichecertdomande';               
                result = await ax.post(url, fd,axiosConfig);
                
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning");
                setVerticalAlert("center");
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            if (result.data!==undefined)    {
                setDataDomandeExp(result.data);
            }
            else {
                setDataDomandeExp([]);
            }
        }  
    };
    
    async function LoadDatiFoto () {
        if(localStorage.getItem("idverifica")!==""){
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            let result=[];
            try{
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("Verifica_id", localStorage.getItem("idverifica"));
                let url=process.env.REACT_APP_API_URL+'fotoverificabyverificalist';               
                result = await ax.post(url, fd,axiosConfig);
                setDescFoto("");
                setSpecificaFoto("");
                
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning");
                setVerticalAlert("center");
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            if (result.data!==undefined)    {
                setDataFoto(result.data);
            }
            else {
                setDataFoto([]);
            }
        }  
    };
    const onCloseForm = (event) => {
        setCloseForm(true);
    };

    function getResponsabileRtId() {
        let  IDRespRt = GetIDIspettore(localStorage.getItem("token"));
        if (parseFloat(IDRespRt)>0 ) return IDRespRt;
        IDRespRt = GetIDIspettoreEmulato(localStorage.getItem("token"));
        return IDRespRt;
    }
    const handleSubmitForm = () => {
        //if (!ChangeServizi)
        //{
            onSubmitForm(false,false);
        //}
        //else {
        //    setOpenConfirmSubmit(true);
        //}
            
    };
    const onSubmitForm= (_ConfermaEsecuzioneVerifica,_forzatura) => 
    {
        
        if (validator.current.allValid() && parseFloat(localStorage.getItem("idverifica"))>0)  {
            if (!ValidateDomandeSa() || !ValidateDomandeExp()){
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert("E' necessario rispondere a tutte le domande nella sezione questionario!");
                setOpenAlert(true); 
                return;
            }
            
            let _okVerifica=false;
            const fd = new FormData();
            const IDRespRt = getResponsabileRtId();

            if (ImgSignCliente!==null){
                if (_ConfermaEsecuzioneVerifica){
                
                    fd.append("Firmato", 1);
                    fd.append("VerificaEseguita", 1);
                    fd.append("imgFirmaCliente", ImgSignCliente);
                    _okVerifica=true;
                }
                else {
                    if (!_forzatura){
                        setOpenConfirmEsecuzioneVerifica(true);
                    }
                    else {
                        _okVerifica=true;
                        fd.append("VerificaEseguita", VerificaEseguita);
                        fd.append("Firmato", 0);
                    }
                }
            }
            else{
                if (ApprovatoOld===0 && (Approvato===1 || Approvato===true)){
                    
                    if (DataFoto.length===0){
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert("Per proseguire è necessario allegare almeno una foto!");
                        setOpenAlert(true); 
                        return
                    }

                    if (parseInt(IDRespRt)===0){
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert("Per proseguire con l'approvazione è necessario essere censiti tra gli ispettori!");
                        setOpenAlert(true); 
                        return;
                    }
                    if (!_ConfermaEsecuzioneVerifica && ! _forzatura){
                        setOpenConfirmApprovazioneVerifica(true);
                    }
                    else {
                        _okVerifica=true;
                    }
                }
                else {
                    _okVerifica=true;
                }
                fd.append("VerificaEseguita", VerificaEseguita);
                fd.append("Firmato", 0);
            }
            if (_okVerifica){
                let _domandeSa="";
                _domandeSa=DataDomandeSa.map(function(item) {
                    return item.id + "|" + item.Valore;
                });
                let _domandeExp="";
                _domandeExp=DataDomandeExp.map(function(item) {
                    return item.id + "|" + item.Valore;
                });
                setisSubmit(true);
                const intApprovato = Approvato ? 1 : 0;
                const intAnnullato = Annulla ? 1 : 0;
                const intRimandato = Rimandato ? 1 : 0;
                const intModImpianto = ModImpianto ? 1 : 0;
                
                fd.append("id", localStorage.getItem("idverifica"));
                fd.append("ResponsabileRt_id",IDRespRt)
                fd.append("Ordine_id", IdOrdine);
                fd.append("Impianto_id", IdImpianto);
                fd.append("Azienda_id", IdAzienda);
                fd.append("Ispettore_id2", IdIspettore2);
                fd.append("Ispettore_id", IdIspettore);
                fd.append("Approvato", intApprovato);
                fd.append("ModImpianto", intModImpianto);
                fd.append("DataVerifica", DataVerifica);
                fd.append("NumeroTecnici", NumTecnici);
                fd.append("TipoPagamento", ModalPag);
                fd.append("Saldo", Saldo);
                fd.append("Email", Email);
                fd.append("CodUnivoco", CodiceUnivoco);
                fd.append("PartitaIva", PartitaIva);
                fd.append("DomandeSa", _domandeSa);
                fd.append("DomandeExp", _domandeExp);
                //dati impianto
                fd.append("DataInstallazione", DataInstallazione);
                fd.append("Indirizzo", Indirizzo);
                if (CodComune!=null){
                    fd.append("CodComune", CodComune);    
                }
                else {
                    fd.append("CodComune", 0);
                }
                fd.append("DesComune", Comune);
                fd.append("PotenzaKw", PotenzaKw);
                fd.append("SistemaDistribuzione", SistDistribuzione);
                fd.append("TensioneAlimentazione", Tensione);
                fd.append("NumeroCabine", NumCabine);
                fd.append("NumPod", NumPod);
                fd.append("NumRapporto", NumRapporto);
                fd.append("DescrizioneAttivita", DescrizioneAttivita);
                fd.append("CaratteristicheImpianto", DescrizioneImpianto);
                fd.append("SuperficieLuogo", Superficie);
                fd.append("DataOmologazione", DataOmologazione);
                fd.append("TipoAmbiente_id", IdTipoAmbiente);
                fd.append("PersVerifica_id", IdPersVerifica);
                fd.append("DataPrevistaChiusura", DataChiusuraPrev);
                fd.append("DataChiusura", DataChiusura);
                fd.append("DataUltimaVerifica", DataUltimaVerifica);
                fd.append("AnnoPrimaInstallazione", AnnoPrimaInstallazione);
                fd.append("DispDiri", DispDiRi);
                fd.append("DichiarazioneConformita", DichiarazioneConformita);
                fd.append("DispProgetto", DispProgetto===""? ("0"):(DispProgetto));
                fd.append("SistemaProtezione", SistemaProtezione===""? ("0"):(SistemaProtezione));
                //Piano Ispezione
                fd.append("PI_StimaFase1", StimaFase1);
                fd.append("PI_StimaFase2", StimaFase2);
                fd.append("PI_StimaFase3", StimaFase3);
                fd.append("PI_StimaFase4", StimaFase4);
                fd.append("PI_OraInizio1", OraInizioFase1);
                fd.append("PI_OraFine1", OraFineFase1);
                fd.append("PI_OraInizio2", OraInizioFase2);
                fd.append("PI_OraFine2", OraFineFase2);
                fd.append("PI_OraInizio3", OraInizioFase3);
                fd.append("PI_OraFine3", OraFineFase3);
                fd.append("PI_OraInizio4", OraInizioFase4);
                fd.append("PI_OraFine4", OraFineFase4);
                
                //DatiVerifica
                fd.append("NodoEquipotenziale", NodoEquipotenziale===""? ("0"):(NodoEquipotenziale));
                fd.append("DifferenzialiConformi", DifferenzialiConformi===""? ("0"):(DifferenzialiConformi));
                fd.append("Gruppo", Gruppo===""? ("0"):(Gruppo));
                fd.append("MassimaResistenza", MassimaResistenza===""? ("0"):(MassimaResistenza));
                fd.append("MisuraResistenzaOhm", MisuraResistenzaOhm===""? ("0"):(MisuraResistenzaOhm));
                fd.append("TensioneCalcolata", TensioneCalcolata===""? ("0"):(TensioneCalcolata));
                fd.append("NumeroProveInterruttori", NumeroProveInterruttori===""? ("0"):(NumeroProveInterruttori));
                fd.append("NumeroInterruttoriTotali", NumeroInterruttoriTotali===""? ("0"):(NumeroInterruttoriTotali));
                fd.append("ResistenzaDiTerra", ResistenzaTerra===""? ("0"):(ResistenzaTerra));
                fd.append("TensioneLimite", TensioneLimite===""? ("0"):(TensioneLimite));
                fd.append("Fulminazione", Fulminazione===""? ("0"):(Fulminazione));
                fd.append("DifferenzialiPresenti", DifferenzialiPresenti===""? ("0"):(DifferenzialiPresenti));
                const intDispersorePiastra1 = DispersorePiastra1 ? 1 : 0;
                const intDispersorePiastra2 = DispersorePiastra2 ? 1 : 0;
                const intDispersoreNastro1 = DispersoreNastro1 ? 1 : 0;
                const intDispersoreNastro2 = DispersoreNastro2 ? 1 : 0;
                const intDispersoreTondino1 = DispersoreTondino1 ? 1 : 0;
                const intDispersoreTondino2 = DispersoreTondino2 ? 1 : 0;
                const intDispersoreConduttoreCordato1 = DispersoreConduttoreCordato1 ? 1 : 0;
                const intDispersoreConduttoreCordato2 = DispersoreConduttoreCordato2 ? 1 : 0;
                fd.append("DispersorePiastra1", intDispersorePiastra1);
                fd.append("DispersorePiastra2", intDispersorePiastra2);
                fd.append("DispersoreNastro1", intDispersoreNastro1);
                fd.append("DispersoreNastro2", intDispersoreNastro2);
                fd.append("DispersoreTondino1", intDispersoreTondino1);
                fd.append("DispersoreTondino2", intDispersoreTondino2);
                fd.append("DispersoreConduttoreCordato1", intDispersoreConduttoreCordato1);
                fd.append("DispersoreConduttoreCordato2", intDispersoreConduttoreCordato2);
                const intPicchettoTubo1 = PicchettoTubo1 ? 1 : 0;
                const intPicchettoTubo2 = PicchettoTubo2 ? 1 : 0;
                const intPicchettoTubo3 = PicchettoTubo3 ? 1 : 0;
                const intPicchettoMassiccio1 = PicchettoMassiccio1 ? 1 : 0;
                const intPicchettoMassiccio2 = PicchettoMassiccio2 ? 1 : 0;
                const intPicchettoMassiccio3 = PicchettoMassiccio3 ? 1 : 0;
                const intPicchettoProfilato1 = PicchettoProfilato1 ? 1 : 0;
                const intPicchettoProfilato2 = PicchettoProfilato2 ? 1 : 0;
                const intPicchettoProfilato3 = PicchettoProfilato3 ? 1 : 0;
                fd.append("PicchettoTubo1", intPicchettoTubo1);
                fd.append("PicchettoTubo2", intPicchettoTubo2);
                fd.append("PicchettoTubo3", intPicchettoTubo3);
                fd.append("PicchettoMassiccio1", intPicchettoMassiccio1);
                fd.append("PicchettoMassiccio2", intPicchettoMassiccio2);
                fd.append("PicchettoMassiccio3", intPicchettoMassiccio3);
                fd.append("PicchettoProfilato1", intPicchettoProfilato1);
                fd.append("PicchettoProfilato2", intPicchettoProfilato2);
                fd.append("PicchettoProfilato3", intPicchettoProfilato3);
                const intSaImpBaseElementoArtificiale = SaImpBaseElementoArtificiale ? 1 : 0;
                const intSaImpBaseElementoNaturale = SaImpBaseElementoNaturale ? 1 : 0;
                const intSaImpBaseSistemaDisperdente = SaImpBaseSistemaDisperdente ? 1 : 0;
                const intSaImpBaseDispersoreDiFatto = SaImpBaseDispersoreDiFatto ? 1 : 0;
                const intSaElCapAdAsteVerticali = SaElCapAdAsteVerticali ? 1 : 0;
                const intSaElCapAFuni = SaElCapAFuni ? 1 : 0;
                const intSaElCapAMaglia = SaElCapAMaglia ? 1 : 0;
                const intSaElCapFerroArmatura = SaElCapFerroArmatura ? 1 : 0;
                fd.append("SaImpBaseElementoArtificiale", intSaImpBaseElementoArtificiale);
                fd.append("SaImpBaseElementoNaturale", intSaImpBaseElementoNaturale);
                fd.append("SaImpBaseSistemaDisperdente", intSaImpBaseSistemaDisperdente);
                fd.append("SaImpBaseDispersoreDiFatto", intSaImpBaseDispersoreDiFatto);
                fd.append("SaElCapAdAsteVerticali", intSaElCapAdAsteVerticali);
                fd.append("SaElCapAFuni", intSaElCapAFuni);
                fd.append("SaElCapAMaglia", intSaElCapAMaglia);
                fd.append("SaElCapFerroArmatura", intSaElCapFerroArmatura);
                fd.append("SaElCapAdAsteVerticaliN", SaElCapAdAsteVerticaliN==="" ? 0 : SaElCapAdAsteVerticaliN);
                fd.append("SaElCapAdAsteVerticaliMq", SaElCapAdAsteVerticaliMq==="" ? 0 : SaElCapAdAsteVerticaliMq);
                fd.append("SaElCapAFuniN", SaElCapAFuniN==="" ? 0 : SaElCapAFuniN);
                fd.append("SaElCapAFuniMq", SaElCapAFuniMq==="" ? 0 : SaElCapAFuniMq);
                fd.append("SaElCapAMagliaN", SaElCapAMagliaN==="" ? 0 : SaElCapAMagliaN);
                fd.append("SaElCapAMagliaMq", SaElCapAMagliaMq==="" ? 0 : SaElCapAMagliaMq);
                fd.append("SaElCapFerroArmaturaN", SaElCapFerroArmaturaN==="" ? 0 : SaElCapFerroArmaturaN);
                fd.append("SaElCapFerroArmaturaMq", SaElCapFerroArmaturaMq==="" ? 0 : SaElCapFerroArmaturaMq);
                const intMetodoMisuraVoltAmperometrico = MetodoMisuraVoltAmperometrico ? 1 : 0;
                const intMetodoMisuraAnelloDiGuasto = MetodoMisuraAnelloDiGuasto ? 1 : 0;
                fd.append("MetodoMisuraVoltAmperometrico", intMetodoMisuraVoltAmperometrico);
                fd.append("MetodoMisuraAnelloDiGuasto", intMetodoMisuraAnelloDiGuasto);
                const intVediOsservazioni = VediOsservazioni ? 1 : 0;
                fd.append("VediOsservazioni", intVediOsservazioni);
                fd.append("RtPrimaVerifica", RtPrimaVerifica==="" ? 0 : RtPrimaVerifica);
                fd.append("RiferimentoCliente", RiferimentoCliente);
                const intEsameImpiantoLPS = EsameImpiantoLPS===""? 0:EsameImpiantoLPS
                fd.append("EsameImpiantoLPS", intEsameImpiantoLPS);
                const intTerrenoAsciutto = TerrenoAsciutto ? 1 : 0;
                const intTerrenoUmido = TerrenoUmido ? 1 : 0;
                const intTerrenoBagnato = TerrenoBagnato ? 1 : 0;
                const intTerrenoGelato = TerrenoGelato ? 1 : 0;
                fd.append("Terreno_asciutto", intTerrenoAsciutto);
                fd.append("Terreno_umido", intTerrenoUmido);
                fd.append("Terreno_bagnato", intTerrenoBagnato);
                fd.append("Terreno_gelato", intTerrenoGelato);

                const intZone0 = Zone0 ? 1 : 0;
                const intZone1 = Zone1 ? 1 : 0;
                const intZone2 = Zone2 ? 1 : 0;
                const intZone20 = Zone20 ? 1 : 0;
                const intZone21 = Zone21 ? 1 : 0;
                const intZone22 = Zone22 ? 1 : 0;
                fd.append("Zona0", intZone0);
                fd.append("Zona1", intZone1);
                fd.append("Zona2", intZone2);
                fd.append("Zona20", intZone20);
                fd.append("Zona21", intZone21);
                fd.append("Zona22", intZone22);

                const intEx_ia = Ex_ia ? 1 : 0;
                const intEx_ma = Ex_ma ? 1 : 0;
                const intEx_d = Ex_d ? 1 : 0;
                const intEx_e = Ex_e ? 1 : 0;
                const intEx_p = Ex_p ? 1 : 0;
                const intEx_m = Ex_m ? 1 : 0;
                const intEx_o = Ex_o ? 1 : 0;
                const intEx_q = Ex_q ? 1 : 0;
                const intEx_n = Ex_n ? 1 : 0;
                const intZona0_Ex_i = Zona0_Ex_i ? 1 : 0;
                const intZona1_Ex_i = Zona1_Ex_i ? 1 : 0;
                const intZona2_Ex_i = Zona2_Ex_i ? 1 : 0;
                fd.append("Ex_ia", intEx_ia);
                fd.append("Ex_ma", intEx_ma);
                fd.append("Ex_d", intEx_d);
                fd.append("Ex_e", intEx_e);
                fd.append("Ex_p", intEx_p);
                fd.append("Ex_m", intEx_m);
                fd.append("Ex_o", intEx_o);
                fd.append("Ex_q", intEx_q);
                fd.append("Ex_n", intEx_n);
                fd.append("Zona0_Ex_i", intZona0_Ex_i);
                fd.append("Zona1_Ex_i", intZona1_Ex_i);
                fd.append("Zona2_Ex_i", intZona2_Ex_i);
                switch (TipoVerifica){
                    case Tipo_verifica_MessaTerra:
                    {
                        fd.append("EsitoVerifica", EsitoVerificaMt);
                        break;
                    }
                    case Tipo_verifica_RischioEsplosione:
                    {
                        fd.append("EsitoVerifica", EsitoVerificaEx);
                        break;
                    }
                    case Tipo_verifica_ScaricheAtmosferiche:
                    {
                        fd.append("EsitoVerifica", EsitoVerificaSa);
                        break;
                    }
                    default:
                }
                
                fd.append("Annullato", intAnnullato);
                fd.append("Rimandato", intRimandato);
                fd.append("MotivoRimandato", MotivoRimandato);
                fd.append("Note", Note===null? "" : Note);
                            
                const token = GetToken(localStorage.getItem("token"));
                const ax= axios;
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                    
                ax
                .post(process.env.REACT_APP_API_URL+'verifichecertupdate', fd,axiosConfig)
                .then((response) => {
                    
                    if (response.status === 200) {
                        if (response.data.error==="OK"){
                            setCloseForm(true);
                        }
                        else {
                            let msg="";
                            let ris=JSON.stringify(response.data);
                            ris=ris.replaceAll("\"", "");
                            ris=ris.replaceAll("{", "");
                            ris=ris.replaceAll("}", "");
                            ris=ris.replaceAll("[", "");
                            ris=ris.replaceAll("]", "");
                            msg="errore durante l'inserimento! " + ris;
                            setSeverityAlert("error")
                            setVerticalAlert("top")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                        }
                    }
                    else
                    { 
                    const msg=GetMessage(response.status,response.data.message);
                    setSeverityAlert("error")
                    setVerticalAlert("top")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                    
                    }
                })
                .catch((error) => {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("error")
                    setVerticalAlert("top")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                });
                setisSubmit(false);
            }

        } else {
            if (parseFloat(localStorage.getItem("idverifica"))>0){
                validator.current.showMessages();
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(MsgAlertDefault);
                setOpenAlert(true);
            }
            else
            {
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert("Selezionare almeno una verifica");
                setOpenAlert(true);
            }   
        }
    };
    
    let UserRole =GetRole(localStorage.getItem("token"));
    let isAdmin=false;
    if (parseFloat(UserRole)===Ruolo_Admin) isAdmin=true
    let urlRedirect="";
    
    if (CloseForm){
        urlRedirect="/verifiche";
    }
    
    if (urlRedirect!==""){
        return <Redirect to={urlRedirect} />;
    }
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className="bg-head-form-cert">
                    <IconButton edge="start" color="inherit" onClick={onCloseForm} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Dettaglio Verifica {DesTipoVerifica}
                    </Typography>
                    <MatButton autoFocus color="inherit" onClick={handleSubmitForm}>
                        Conferma {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                    </MatButton>
                </Toolbar>
            </AppBar>
            <div className="page-container bg-form">
                <div className="content-wrap mobile-page">
                {isLoading && ( <CircularProgress className="sp-center" size={200} />)}
                    <form autoComplete ="new-password">
                        <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>
                        <MyConfirmEsecuzioneVerifica title="Conferma Rapporto Verifica" message="Si desidera procedere alla redazione del rapporto di verifica e passare allo stato di approvazione?" handleClose={onCloseConfirmEsecuzioneVerifica} handleConfirm={onConfirmEsecuzioneVerifica} open={OpenConfirmEsecuzioneVerifica}/>
                        <MyConfirmApprovaVerifica title="Conferma Approvazione Verifica" message="Si desidera procedere alla redazione del verbale di verifica e passare allo stato di completamento?" handleClose={onCloseConfirmApprovazioneVerifica} handleConfirm={onConfirmApprovazioneVerifica} open={OpenConfirmApprovazioneVerifica}/>          
                        <MyConfirmScattaFoto title="Conferma Caricamento Foto" message="Si desidera confermare l'inserimento della foto?" handleClose={onCloseConfirmScattaFoto} handleConfirm={onConfirmScattaFoto} open={OpenConfirmScattaFoto}/>          
                        <MyConfirmCancellaFoto title="Conferma Cancellazione Foto" message="Si desidera confermare la cancellazione della foto?" handleClose={onCloseConfirmCancellaFoto} handleConfirm={onConfirmCancellaFoto} open={OpenConfirmCancellaFoto}/>          
                        <CreateSign open={OpenSignCliente} handleClose={onCloseSignCliente} handleConfirm={onConfirmSignCliente} title="Firma Cliente"/>
                        <MyViewImage open={OpenFoto} descFoto={DescFotoOpen} pathPhoto={FileFoto} handleClose={onCloseViewFoto}/>
                        <Grid container spacing={3} className="custom-form">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtNumVerifica">N. Verifica</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtNumVerifica" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={NumRapporto} 
                                                    readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col> 
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtStato">Stato</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtStato" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={StatoVerifica} 
                                                    readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col> 
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtNumOrdine">N. Ordine</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtNumOrdine" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={NumOrdine} 
                                                    readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtDataOrdine">Del</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtDataOrdine" 
                                                    autoComplete="new-password"
                                                    value={DataOrdine} 
                                                    readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={7}>
                                            <FormGroup>
                                                <Label for="txtRagioneSociale">Cliente</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtRagioneSociale" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={RagioneSociale} 
                                                    readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col md={5}>
                                            <FormGroup>
                                                <Label for="txtEmail">Email *</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtEmail" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={Email} 
                                                    onChange={handleEmail}
                                                    readOnly={VerificaEseguita===1} 
                                                    disabled={VerificaEseguita===1}
                                                />
                                                {validator.current.message('Email', Email, 'required|email', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtPartitaIva">Partita Iva *</Label>
                                                <Input 
                                                    type="number" 
                                                    name="txtPartitaIva" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={PartitaIva} 
                                                    onChange={handlePartitaIva}
                                                    readOnly={VerificaEseguita===1 || PartitaIvaOld !== ""} 
                                                    disabled={VerificaEseguita===1  || PartitaIvaOld !== ""}
                                                />
                                                {validator.current.message('PartitaIva', PartitaIva, VerificaEseguita===1 ? 'integer|min:11|max:11' : 'required|integer|min:11|max:11', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={5}>
                                            <FormGroup>
                                                <Label for="txtCodiceUnivoco">Codice Univoco *</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtCodiceUnivoco" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={CodiceUnivoco} 
                                                    onChange={handleCodiceUnivoco}
                                                    readOnly={VerificaEseguita===1} 
                                                    disabled={VerificaEseguita===1}
                                                />
                                                {validator.current.message('CodiceUnivoco', CodiceUnivoco, VerificaEseguita===1 ? 'alpha_num' : 'required|alpha_num', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtCellulare">Cellulare</Label>
                                                <MyPhoneInput 
                                                    type="text" 
                                                    name="txtCellulare" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={Cellulare} 
                                                    readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtTelefono">Telefono</Label>
                                                <MyPhoneInput 
                                                    type="text" 
                                                    name="txtTelefono" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={Telefono} 
                                                    readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtDataAppuntamento">Data Appuntamento</Label>
                                                <MyDatePicker name="txtDataAppuntamento" 
                                                    value={DataAppuntamento} 
                                                    autoComplete="new-password"
                                                    disabled
                                                    readOnly
                                                />
                                            </FormGroup>
                                        </Col> 
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtStato">Orario</Label>
                                                <MyTimeInput
												    value={OraAppuntamento} 
												    disabled
                                                    readOnly
											    />
											</FormGroup>
                                        </Col> 
                                        <Col md={7}>
                                            <Label for="txtIspettore">Ispettore</Label>
                                            <MyAutoComplete id="txtIspettore" name="txtIspettore" 
                                                url={process.env.REACT_APP_API_URL+'ispettoresinglelist'} 
                                                optionname="DesIspettore" optionvalue="id"
                                                customvalue={DesIspettore} onChange={handleIspettore}
                                                disabled
                                                readOnly
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <FormGroup>
                                                <Label for="txtStrumento">Strumento</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtStrumento" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={DesStrumento} 
                                                    readOnly 
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtDataUltimaRettifica">Rettificato il</Label>
                                                <MyDatePicker 
                                                    name="txtDataUltimaRettifica" 
                                                    autoComplete="new-password"
                                                    value={DataTaraturaStrumento} 
                                                    readOnly 
                                                    disabled
                                                />
                                            </FormGroup>
                                        </Col> 
                                    </Row>
                                </Paper>
                            </Grid>
                            <div className={classes.titleSezione}>
                                &nbsp;Dati Impianto Elettrico
                            </div>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    {
                                    (ApprovatoOld===0) &&
                                    (
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <FormGroup>
                                                <div>&nbsp;</div>
                                                <Label check>
                                                    <Input 
                                                        type="checkbox" 
                                                        name="chkModImpianto" 
                                                        onChange={handleModImpianto} 
                                                        checked={ModImpianto> 0} 
                                                        style={{transform: "scale(3)"}}
                                                    />{'  '}
                                                    <span style={{paddingLeft:'10px',fontSize:"1.4em"}}>&nbsp;Modifica Dati impianto</span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    )
                                    }
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtDataUltimaVerifica">Ultima Verifica</Label>
                                                <MyDatePicker 
                                                    name="txtDataUltimaVerifica"
                                                    value={DataUltimaVerifica}  
                                                    autoComplete="new-password" 
                                                    readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={8}>
                                            <FormGroup>
                                                <Label for="txtDescrizioneAttivita">Tipo Attività</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtDescrizioneAttivita" 
                                                    autoComplete="new-password" 
                                                    placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('DescrizioneAttivita')}
                                                    value={DescrizioneAttivita} 
                                                    onChange={handleDescrizioneAttivita}
                                                    readOnly={!ModImpianto} 
                                                    disabled={!ModImpianto}
                                                />
                                                {validator.current.message('DescrizioneAttivita', DescrizioneAttivita, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtTipoAmbiente">Tipo Ambiente</Label>
                                                <MyAutoComplete 
                                                    id="txtTipoAmbiente" 
                                                    name="txtTipoAmbiente" 
                                                    url={process.env.REACT_APP_API_URL+'tipoambientiimplist'} 
                                                    optionname="Descrizione" 
                                                    optionvalue="Codice"
                                                    onBlur={() => validator.current.showMessageFor('TipoAmbiente')}
                                                    customvalue={TipoAmbiente} 
                                                    onChange={handleTipoAmbiente}
                                                    readOnly={!ModImpianto} 
                                                    disabled={!ModImpianto}
                                                />
                                                {validator.current.message('TipoAmbiente', TipoAmbiente, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtPersValidita">Periodicità</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtPersValidita" 
                                                    disabled={true}
                                                    autoComplete="new-password" placeholder="" 
                                                    value={AnniValidita}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtPotenzaKw">Potenza Kw</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtPotenzaKw" 
                                                    autoComplete="new-password" 
                                                    placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('PotenzaKw')}
                                                    value={PotenzaKw} 
                                                    onChange={handlePotenzaKw}
                                                    readOnly={!ModImpianto} 
                                                    disabled={!ModImpianto}
                                                />
                                                {validator.current.message('PotenzaKw', PotenzaKw, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="selSistDistribuzione">Categoria</Label>
                                                <Input 
                                                    type="select" 
                                                    name="selSistDistribuzione" 
                                                    id="selSistDistribuzione" 
                                                    placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('SistDistribuzione')}
                                                    value={SistDistribuzione} 
                                                    onChange={handleSistDistribuzione}
                                                    readOnly
                                                    disabled
                                                >
                                                    <option key="" ></option>
                                                    <option key="0" value="0">TT</option>
                                                    <option key="1" value="1">TN</option>
                                                </Input>
                                                {validator.current.message('SistDistribuzione', SistDistribuzione, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtTensione">Tensione V</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtTensione" 
                                                    autoComplete="new-password" 
                                                    placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Tensione')}
                                                    value={Tensione} 
                                                    onChange={handleTensione}
                                                    readOnly={!ModImpianto} 
                                                    disabled={!ModImpianto}
                                                />
                                                {validator.current.message('Tensione', Tensione, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtNumPod">N. Pod (IT001E)</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtNumPod" 
                                                    autoComplete="new-password" 
                                                    placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('NumPod')}
                                                    value={NumPod} 
                                                    onChange={handleNumPod}
                                                    readOnly={!ModImpianto} 
                                                    disabled={!ModImpianto}
                                                />
                                                {validator.current.message('NumPod', NumPod, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtNumCabine">N. Cabine</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtNumCabine" 
                                                    autoComplete="new-password" 
                                                    placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('NumCabine')}
                                                    value={NumCabine} 
                                                    onChange={handleNumCabine}
                                                    readOnly={!ModImpianto} 
                                                    disabled={!ModImpianto}
                                                />
                                                {validator.current.message('NumCabine', NumCabine, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtSuperficie">Superficie</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtSuperficie" 
                                                    autoComplete="new-password" 
                                                    placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Superficie')}
                                                    value={Superficie} 
                                                    onChange={handleSuperficie}
                                                    readOnly={!ModImpianto} 
                                                    disabled={!ModImpianto}
                                                />
                                                {validator.current.message('Superficie', Superficie, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtIndirizzo">Indirizzo</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtIndirizzo" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('Indirizzo')}
                                                    value={Indirizzo} 
                                                    onChange={handleIndirizzo}
                                                    readOnly={!ModImpianto} 
                                                    disabled={!ModImpianto}
                                                />
                                                {validator.current.message('Indirizzo', Indirizzo, 'string|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtComune">Comune</Label>
                                                <MyAutoComplete 
                                                    id="txtComune" 
                                                    name="txtComune" 
                                                    url={process.env.REACT_APP_API_URL+'comuni'} 
                                                    optionname="Comune" 
                                                    optionvalue="CodComune"
                                                    onBlur={() => validator.current.showMessageFor('Comune')}
                                                    customvalue={Comune} 
                                                    onChange={handleComune}
                                                    readOnly={!ModImpianto} 
                                                    disabled={!ModImpianto}
                                                />
                                                {validator.current.message('Comune', Comune, 'string|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row> 
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="selectDichConformita">Dichiarazione conformità</Label>
                                                <Input 
                                                    type="select"
                                                    id="selectDichConformita"
                                                    value={DichiarazioneConformita}
                                                    onChange={handleDichiarazioneConformita}
                                                    onBlur={() => validator.current.showMessageFor('DichiarazioneConformita')}
                                                >
                                                    <option key="" value=""></option>
                                                    <option key="1" value="1">SI</option>
                                                    <option key="0" value="0">NO</option>
                                                </Input>
                                                {validator.current.message('DichiarazioneConformita', DichiarazioneConformita, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtAnnoPrimaInstallazione">Anno 1° inst. (1900 = sconosciuto)</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtAnnoPrimaInstallazione" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('AnnoPrimaInstallazione')}
                                                    value={AnnoPrimaInstallazione} 
                                                    onChange={handleAnnoPrimaInstallazione}
                                                />
                                                {validator.current.message('AnnoPrimaInstallazione', AnnoPrimaInstallazione, 'numeric|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtDittaInstallatrice">Ditta installatrice</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtDittaInstallatrice" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('DittaInstallatrice')}
                                                    value={DittaInstallatrice} 
                                                    onChange={handleDittaInstallatrice}
                                                />
                                                {validator.current.message('DittaInstallatrice', DittaInstallatrice, 'string|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>*/}
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="selDisponibilitaProgetto">Disponibilità progetto</Label>
                                                <Input type="select"
                                                    id="selDisponibilitaProgetto"
                                                    value={DispProgetto}
                                                    onChange={handleDispProgetto}
                                                    onBlur={() => validator.current.showMessageFor('DispProgetto')}
                                                >
                                                    <option key="" value=""></option>
                                                    <option key="1" value="1">SI</option>
                                                    <option key="0" value="0">NO</option>
                                                </Input>
                                                {validator.current.message('DispProgetto', DispProgetto, 'numeric|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col> 
                                        <Col md={3}>
                                            <FormGroup>
                                            <Label for="selDisponibilitaDIRI">Disp. DI.RI. D.M. 37/08</Label>
                                                <Input type="select"
                                                    id="selDisponibilitaDIRI"
                                                    value={DispDiRi}
                                                    onChange={handleDispDiRi}
                                                    onBlur={() => validator.current.showMessageFor('DispDiRi')}
                                                >
                                                    <option key="" value=""></option>
                                                    <option key="1" value="1">SI</option>
                                                    <option key="0" value="0">NO</option>
                                                </Input>
                                                {validator.current.message('DispDiRi', DispDiRi, 'numeric|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="txtDescrizioneImpianto">Descrizione Impianto * [Max 400 caratteri] </Label>
                                                <Input 
                                                    type="textarea" 
                                                    name="txtDescrizioneImpianto" 
                                                    id="txtDescrizioneImpianto" 
                                                    autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('DescrizioneImpianto')}
                                                    value={DescrizioneImpianto} 
                                                    onChange={handleDescrizioneImpianto} 
                                                    maxLength="400"
                                                />
                                                {validator.current.message('DescrizioneImpianto', DescrizioneImpianto, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <div className={classes.titleSezione}>
                                &nbsp;Prove di verifica
                            </div>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    {
                                    (TipoVerifica===Tipo_verifica_MessaTerra) && 
                                    (<div className={classes.riquadro}> 
                                        <Row>
                                            <Col md={4}>
                                                <Label for="selectUsoMedico1">Locale Adibito ad uso medico</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input type="select"
                                                        id="selectLocaleUsoMedico"
                                                        readOnly
                                                        disabled
                                                    >
                                                        <option key="0" value="0" selected={IdTipoAmbiente!==3}>NO</option>
                                                        <option key="1" value="1" selected={IdTipoAmbiente===3}>SI</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <h3>Esito Verifica</h3>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Input type="select"
                                                        id="selectEsitoVerificaMt"
                                                        style={{fontSize:"1.2em"}}
                                                        value={EsitoVerificaMt}
                                                        onChange={handleEsitoVerificaMt}
                                                        // onBlur={() => validator.current.showMessageFor('EsitoVerificaSa')}
                                                        disabled
                                                        readOnly
                                                    >
                                                        <option key="" value=""></option>
                                                        <option key="0" value="0">NEGATIVO</option>
                                                        <option key="1" value="1">POSITIVO</option>
                                                    </Input>
                                                    {/* {validator.current.message('EsitoVerificaSa', EsitoVerificaSa, 'required|string', { className: 'text-danger' })} */}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Label for="selectGruppo">Gruppo</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input type="select"
                                                        id="selectGruppo"
                                                        value={Gruppo}
                                                        onChange={handleGruppo}
                                                        onBlur={() => validator.current.showMessageFor('Gruppo')}
                                                        disabled={IdTipoAmbiente!==3}
                                                        readOnly={IdTipoAmbiente!==3}
                                                    >
                                                        <option key="" value=""></option>
                                                        <option key="0" value="0">0</option>
                                                        <option key="1" value="1">1</option>
                                                        <option key="2" value="2">2</option>
                                                    </Input>
                                                    {validator.current.message('Gruppo', Gruppo, IdTipoAmbiente===3 ? ('required|numeric'):('numeric'), { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Label for="selectNodoEquipotenziale">Nodo equipotenziale</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input type="select"
                                                        id="selectNodoEquipotenziale"
                                                        onChange={handleNodoEquipotenziale}
                                                        value={NodoEquipotenziale}
                                                        onBlur={() => validator.current.showMessageFor('NodoEquipotenziale')}
                                                        disabled={IdTipoAmbiente!==3}
                                                        readOnly={IdTipoAmbiente!==3}
                                                    >
                                                        <option key="" value=""></option>
                                                        <option key="0" value="0">NO</option>
                                                        <option key="1" value="1">SI</option>
                                                    </Input>
                                                    {validator.current.message('NodoEquipotenziale', NodoEquipotenziale, IdTipoAmbiente===3 ? ('required|numeric'):('numeric'), { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className={classes.legenda}>
                                                    Prova funzionale dei dispositivi di controllo di sistemi IT-M<br/>
                                                    Misura per verificare il collegamento equipotenziale supplementare (710.413.1.2.2.);<br/>
                                                    Misure delle correnti di dispersione dell’avvolgimento secondario a vuoto e sull’involucro dei trasformatori per uso medico<br/>
                                                    prova funzionale dei dispositivi di controllo dell’isolamento
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>)}
                                    {(TipoVerifica===Tipo_verifica_MessaTerra) && (<div className={classes.legenda}>&nbsp;</div>)}
                                    {
                                    (TipoVerifica===Tipo_verifica_MessaTerra || TipoVerifica===Tipo_verifica_RischioEsplosione) && (
                                    <div className={classes.riquadro}> 
                                        <Row>
                                            <Col md={3}>
                                                <Label>Metodo di misura:</Label>
                                            </Col>
                                            <Col md={5}>
                                                <Label>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleMetodoMisuraVoltAmperometrico} 
                                                        checked={MetodoMisuraVoltAmperometrico>0}
                                                    />
                                                    Volt amperometrico
                                                </Label>
                                            </Col>
                                            <Col md={4}>
                                                <Label>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleMetodoMisuraAnelloDiGuasto} 
                                                        checked={MetodoMisuraAnelloDiGuasto>0}
                                                    />
                                                    Anello di guasto
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>
                                                <Label>Resistenza di terra</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input 
                                                        type="text" 
                                                        name="txtResistenzaTerra" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        onBlur={() => validator.current.showMessageFor('ResistenzaTerra')}
                                                        value={ResistenzaTerra} 
                                                        onChange={handleResistenzaTerra}
                                                    />
                                                    {validator.current.message('ResistenzaTerra', ResistenzaTerra, 'numeric|required', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={7}>
                                                <div className={classes.legenda}>* La Corrente Differenziale I∆N Massima fra tutti gli interruttori</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>
                                                <Label>Differenziali presenti è I∆N MAX</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input 
                                                        type="text" 
                                                        name="txtDifferenzialiPresenti" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        onBlur={() => validator.current.showMessageFor('DifferenzialiPresenti')}
                                                        value={DifferenzialiPresenti} 
                                                        onChange={handleDifferenzialiPresenti}
                                                    />
                                                    {/* {validator.current.message('DifferenzialiPresenti', DifferenzialiPresenti, 'numeric|required', { className: 'text-danger' })} */}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>
                                                <Label>È quindi la Tensione limite di contatto V</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input 
                                                        type="text" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        readOnly 
                                                        disabled
                                                        value={ccyFormatValue(TensioneLimite)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className={classes.legenda}>
                                            Prove di Continuità di conduttori di terra, conduttori di protezione, conduttori equipotenziali.
                                            Criterio di campionamento adottato: percentuale stimata 50%.
                                        </div>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <Row>
                                            <Col md={3}>
                                                <Label>Esito massima resistenza</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input 
                                                        type="text" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        onBlur={() => validator.current.showMessageFor('MassimaResistenza')}
                                                        value={MassimaResistenza} 
                                                        onChange={handleMassimaResistenza}
                                                    />
                                                    {/* {validator.current.message('MassimaResistenza', MassimaResistenza, 'string|required', { className: 'text-danger' })} */}
                                                </FormGroup>
                                            </Col>
                                            <Col md={7}>
                                                <div className={classes.legenda}>( valore accettabile &lt; 20 Ω )</div>
                                            </Col>
                                        </Row>
                                        <div className={classes.titleLegenda}>
                                            PROVE DEGLI INTERRUTTORI DIFFERENZIALI
                                        </div>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Sono stati provati n&deg;</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <MyNumInput 
                                                        value={NumeroProveInterruttori} 
                                                        onBlur={() => validator.current.showMessageFor('NumeroProveInterruttori')}
                                                        onChange={handleNumeroProveInterruttori}
                                                        width={'120px'}
                                                    />
                                                    {/* {validator.current.message('NumeroProveInterruttori', NumeroProveInterruttori, 'numeric|required', { className: 'text-danger' })} */}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label>Sul Totale n&deg;</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    {/* <Input type="text" 
                                                        autoComplete="new-password" 
                                                        style={{maxWidth:'80px'}}
                                                        placeholder=""
                                                        onBlur={() => validator.current.showMessageFor('NumeroInterruttoriTotali')}
                                                        value={NumeroInterruttoriTotali} 
                                                        onChange={handleNumeroInterruttoriTotali}
                                                    /> */}
                                                    <MyNumInput 
                                                        style={{maxWidth:'120px'}}
                                                        value={NumeroInterruttoriTotali} 
                                                        onBlur={() => validator.current.showMessageFor('NumeroInterruttoriTotali')}
                                                        onChange={handleNumeroInterruttoriTotali}
                                                        width={'120px'}
                                                    />
                                                    {/* {validator.current.message('NumeroInterruttoriTotali', NumeroInterruttoriTotali, 'numeric|required', { className: 'text-danger' })} */}
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <div className={classes.legenda}>( criterio di campionamento adottato: 50% )</div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label>Gli interrutori differenziali sono conformi agli schemi elettrici</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input type="select"
                                                        id="selectDifferenzialiConformi"
                                                        onChange={handleDifferenzialiConformi}
                                                        value={DifferenzialiConformi}
                                                        onBlur={() => validator.current.showMessageFor('DifferenzialiConformi')}
                                                    >
                                                        <option key="" value=""></option>
                                                        <option key="0" value="0">NO</option>
                                                        <option key="1" value="1">SI</option>
                                                    </Input>
                                                    {/* {validator.current.message('DifferenzialiConformi', DifferenzialiConformi, 'required|string', { className: 'text-danger' })} */}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="selSistemaProtezione">Sistema di protezione</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={5}>
                                                <FormGroup>
                                                    
                                                    <Input type="select"
                                                        id="selSistemaProtezione"
                                                        value={SistemaProtezione}
                                                        onChange={handleSistemaProtezione}
                                                        onBlur={() => validator.current.showMessageFor('SistemaProtezione')}
                                                    >
                                                        <option key="" value=""></option>
                                                        <option key="0" value="0">Dispositivo di protezione</option>
                                                        <option key="1" value="1">Differenziale</option>
                                                    </Input>
                                                    {/* {validator.current.message('SistemaProtezione', SistemaProtezione, 'string|required', { className: 'text-danger' })} */}
                                                </FormGroup>
                                            </Col> 
                                        </Row>
                                        <div className={classes.legenda}>
                                            La prova di tutti gli Interruttori Differenziali ha dato <b>ESITO POSITIVO</b>, ed i tempi di intervento 
                                            sono <b>CONFORMI</b> alle <b>NORME</b> CEI cogenti e vigenti. I differenziali presenti nell'insediamento, sottoposti a prova di intervento, sono 
                                            stati correttamente intervenuti per le rispettive Idn di targa. In considerazione delle protezioni installate sui vari circuiti e del valore della
                                            resistenza di terra rilevato risulta soddisfatto il coordinamento.
                                        </div>
                                    </div>)}
                                    {
                                    (TipoVerifica!==Tipo_verifica_ScaricheAtmosferiche) && (<div className={classes.legenda}>&nbsp;</div>)}
                                    {
                                    (TipoVerifica!==Tipo_verifica_RischioEsplosione) && (
                                    <div className={classes.riquadro}>
                                        <div className={classes.titleLegenda}>
                                            SISTEMA DISPERDENTE
                                        </div>
                                        <div>
                                            <Label><b>Dispersore di fatto</b></Label>
                                            <Checkbox 
                                                style={{transform: "scale(1.8)",display: TipoVerifica===Tipo_verifica_MessaTerra ? "":"none"}}
                                                onChange={handleSaImpBaseDispersoreDiFatto} 
                                                checked={SaImpBaseDispersoreDiFatto>0}
                                            />
                                        </div>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <table className={classes.tableCustom}>
                                            <tr>
                                                <td style={{width:'10%'}}></td>
                                                <td style={{width:'60%'}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>Posa nel Terreno</h1></td>
                                                <td style={{width:'15%',}} className={classes.tableCustomBorder}>1</td>
                                                <td style={{width:'15%',}} className={classes.tableCustomBorder}>2</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleDispersorePiastra} 
                                                        checked={DispersorePiastra>0}
                                                    /></td>
                                                <td className={classes.tableCustomBorder}>Piastra</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        readOnly={!DispersorePiastra}
                                                        disabled={!DispersorePiastra} 
                                                        onChange={handleDispersorePiastra1}
                                                        checked={DispersorePiastra1>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        readOnly={!DispersorePiastra}
                                                        disabled={!DispersorePiastra} 
                                                        onChange={handleDispersorePiastra2}
                                                        checked={DispersorePiastra2>0}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleDispersoreNastro} 
                                                        checked={DispersoreNastro>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Nastro</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        readOnly={!DispersoreNastro}
                                                        disabled={!DispersoreNastro} 
                                                        onChange={handleDispersoreNastro1} 
                                                        checked={DispersoreNastro1>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        readOnly={!DispersoreNastro}
                                                        disabled={!DispersoreNastro}
                                                        onChange={handleDispersoreNastro2}  
                                                        checked={DispersoreNastro2>0}
                                                    />
                                                    </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        checked={DispersoreTondino>0}
                                                        onChange={handleDispersoreTondino} 
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Tondino o Conduttore massiccio</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        readOnly={!DispersoreTondino}
                                                        disabled={!DispersoreTondino} 
                                                        onChange={handleDispersoreTondino1} 
                                                        checked={DispersoreTondino1>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        readOnly={!DispersoreTondino}
                                                        disabled={!DispersoreTondino} 
                                                        onChange={handleDispersoreTondino2} 
                                                        checked={DispersoreTondino2>0}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        checked={DispersoreConduttoreCordato>0}
                                                        onChange={handleDispersoreConduttoreCordato} 
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Conduttore Cordato</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        readOnly={!DispersoreConduttoreCordato}
                                                        disabled={!DispersoreConduttoreCordato} 
                                                        onChange={handleDispersoreConduttoreCordato1}
                                                        checked={DispersoreConduttoreCordato1>0}
                                                    /></td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}} 
                                                        readOnly={!DispersoreConduttoreCordato}
                                                        disabled={!DispersoreConduttoreCordato} 
                                                        onChange={handleDispersoreConduttoreCordato2}
                                                        checked={DispersoreConduttoreCordato2>0}
                                                    /></td>
                                            </tr>
                                        </table>
                                        <table className={classes.tableCustom}>
                                            <tr>
                                                <td style={{width:'10%'}}></td>
                                                <td style={{width:'60%'}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>Infissione nel Terreno</h1></td>
                                                <td style={{width:'10%'}}>1</td>
                                                <td style={{width:'10%'}}>2</td>
                                                <td style={{width:'10%'}}>3</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handlePicchettoTubo} 
                                                        checked={PicchettoTubo>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Picchetto a tubo</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        readOnly={!PicchettoTubo}
                                                        disabled={!PicchettoTubo} 
                                                        onChange={handlePicchettoTubo1}
                                                        checked={PicchettoTubo1>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        readOnly={!PicchettoTubo}
                                                        disabled={!PicchettoTubo} 
                                                        onChange={handlePicchettoTubo2}
                                                        checked={PicchettoTubo2>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        readOnly={!PicchettoTubo}
                                                        disabled={!PicchettoTubo} 
                                                        onChange={handlePicchettoTubo3}
                                                        checked={PicchettoTubo3>0}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handlePicchettoMassiccio} 
                                                        checked={PicchettoMassiccio>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Picchetto Massiccio</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        readOnly={!PicchettoMassiccio}
                                                        disabled={!PicchettoMassiccio} 
                                                        onChange={handlePicchettoMassiccio1}
                                                        checked={PicchettoMassiccio1>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        readOnly={!PicchettoMassiccio}
                                                        disabled={!PicchettoMassiccio} 
                                                        onChange={handlePicchettoMassiccio2}
                                                        checked={PicchettoMassiccio2>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        readOnly={!PicchettoMassiccio}
                                                        disabled={!PicchettoMassiccio} 
                                                        onChange={handlePicchettoMassiccio3}
                                                        checked={PicchettoMassiccio3>0}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handlePicchettoProfilato} 
                                                        checked={PicchettoProfilato>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Picchetto in Profilato</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        readOnly={!PicchettoProfilato}
                                                        disabled={!PicchettoProfilato} 
                                                        onChange={handlePicchettoProfilato1}
                                                        checked={PicchettoProfilato1>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        readOnly={!PicchettoProfilato}
                                                        disabled={!PicchettoProfilato}  
                                                        onChange={handlePicchettoProfilato2}
                                                        checked={PicchettoProfilato2>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        readOnly={!PicchettoProfilato}
                                                        disabled={!PicchettoProfilato}  
                                                        onChange={handlePicchettoProfilato3}
                                                        checked={PicchettoProfilato3>0}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td style={{border: '1pt solid #C0C0C0'}}>
                                                    <table style={{width:'100%',fontSize:'3em'}}>
                                                        <tr>
                                                            <td style={{width:'50%',verticalAlign:"top"}}>
                                                                <div style={{display:'flex'}}>
                                                                    <Avatar style={{backgroundColor:'#fdcd3b',color:'#454343',float:'left'}}>1</Avatar>
                                                                    <span style={{fontSize:'0.15em',paddingTop:'6px'}}>&nbsp;Acciaio zincato a caldo (Norma CEI (7-6);</span>
                                                                </div>
                                                            </td>
                                                            <td style={{width:'20%',verticalAlign:"top"}}>
                                                                <div style={{display:'flex'}}>
                                                                    <Avatar style={{backgroundColor:'#fdcd3b',color:'#454343',float:'left'}}>2</Avatar>
                                                                    <span style={{fontSize:'0.15em',paddingTop:'6px'}}>&nbsp;Rame;</span>
                                                                </div>
                                                            </td>
                                                            <td style={{width:'30%',verticalAlign:"top"}}>
                                                                <div style={{display:'flex'}}>
                                                                    <Avatar style={{backgroundColor:'#fdcd3b',color:'#454343'}}>3</Avatar>
                                                                    <span style={{fontSize:'0.15em',paddingTop:'6px'}}>&nbsp;Acciaio rivestito in Rame</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </table>
                                    </div>)}
                                    {(TipoVerifica===Tipo_verifica_ScaricheAtmosferiche) && (<div className={classes.legenda}>&nbsp;</div>)}
                                    {(TipoVerifica===Tipo_verifica_MessaTerra && Tensione>1000) && (<div className={classes.legenda}>&nbsp;</div>)}
                                    {
                                    (TipoVerifica===Tipo_verifica_MessaTerra && Tensione>1000) && (
                                    <div className={classes.riquadro}>
                                        <div className={classes.titleLegenda}>
                                            MISURE DELLA RESISTENZA DI TERRA COORDINAMENTO DEL CIRCUITO LATO MT
                                        </div>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <Row>
                                            <Col md={4}>
                                            </Col>
                                            <Col md={2}>
                                                <h3>Esito Verifica</h3>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Input type="select"
                                                        id="selectEsitoVerificaMt"
                                                        style={{fontSize:"1.2em"}}
                                                        value={EsitoVerificaMt}
                                                        onChange={handleEsitoVerificaMt}
                                                        // onBlur={() => validator.current.showMessageFor('EsitoVerificaMt')}
                                                        disabled
                                                        readOnly
                                                    >
                                                        <option key="" value=""></option>
                                                        <option key="0" value="0">NEGATIVO</option>
                                                        <option key="1" value="1">POSITIVO</option>
                                                    </Input>
                                                    {/* {validator.current.message('EsitoVerificaMt', EsitoVerificaMt, 'required|string', { className: 'text-danger' })} */}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className={classes.legenda}>
                                            La misura è stata effettuata sul collettore di terra della cabina di ricezione utilizzando il metodo Volt Amperometrico, così
                                            come previsto dalla norma CEI 99-3 fascicolo 11372 e relativi allegati. <br/>Il Distributore dell’Energia elettrica ha comunicato
                                            Che la corrente di guasto a terra (If) è pari a 50A, mentre il tempo di eliminazione del guasto risulta essere (tf) &gt;=10 S.
                                        </div>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <Row>
                                            <Col md={4}>
                                                <Label>Il valore misurato è di Ohm</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input type="text" 
                                                     autoComplete="new-password" 
                                                     placeholder=""
                                                     onBlur={() => validator.current.showMessageFor('MisuraResistenzaOhm')}
                                                     value={MisuraResistenzaOhm} 
                                                     onChange={handleMisuraResistenzaOhm}
                                                     readOnly
                                                     disabled
                                                 />
                                                 {validator.current.message('MisuraResistenzaOhm', MisuraResistenzaOhm, 'string|required', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Label>Essendo la tensione totale di terra calcolata (Ue) uguale a V</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Input type="text" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        readOnly
                                                        disabled
                                                        value={ccyFormatValue(TensioneCalcolata)} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className={classes.legenda}>
                                            essa è quindi inferiore al limite massimo consentito (Utp).<br/>
                                            In relazione ai valori sopra riportati il coordinamento in MT risulta verificato, (in caso di esito negativo è necessario
                                            procedere alla misura diretta delle tensioni di contatto)
                                        </div>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <table className={classes.tableCustom}>
                                            <tr>
                                                <td colSpan={4} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>Stato del terreno</h1></td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox 
                                                            onChange={handleTerrenoAsciutto} 
                                                            checked={TerrenoAsciutto>0}
                                                        />
                                                        } 
                                                        label="Asciutto" 
                                                        style={{transform: "scale(1.8)"}}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox 
                                                            onChange={handleTerrenoUmido} 
                                                            checked={TerrenoUmido>0}
                                                        />
                                                        } 
                                                        label="Umido" 
                                                        style={{transform: "scale(1.8)"}}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox 
                                                            onChange={handleTerrenoBagnato} 
                                                            checked={TerrenoBagnato>0}
                                                        />
                                                        } 
                                                        label="Bagnato" 
                                                        style={{transform: "scale(1.8)"}}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <FormControlLabel 
                                                        control={
                                                        <Checkbox 
                                                            onChange={handleTerrenoGelato} 
                                                            checked={TerrenoGelato>0}
                                                        />
                                                        } 
                                                        label="Gelato" 
                                                        style={{transform: "scale(1.8)"}}
                                                    />
                                                </td>
                                            </tr>
                                        </table>
                                    </div>)}
                                    {
                                    TipoVerifica===Tipo_verifica_ScaricheAtmosferiche && (
                                    <div className={classes.riquadro}>
                                        <div className={classes.titleLegenda}>
                                            VERIFICA DI DISPOSITIVI DI PROTEZIONE CONTRO LE SCARICHE ATMOSFERICHE
                                        </div>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <Row>
                                            <Col md={3}>
                                                &nbsp;
                                            </Col>
                                            <Col md={3}>
                                                <h3>Esito Verifica</h3>
                                            </Col>
                                            <Col lg={2} md={4}>
                                                <FormGroup>
                                                    <Input type="select"
                                                         id="selectEsitoVerificaSa"
                                                         style={{fontSize:"1.2em"}}
                                                         value={EsitoVerificaSa}
                                                         onChange={handleEsitoVerificaSa}
                                                         //onBlur={() => validator.current.showMessageFor('EsitoVerificaSa')}
                                                         disabled
                                                         readOnly
                                                     >
                                                         <option key="" value=""></option>
                                                         <option key="0" value="0">NEGATIVO</option>
                                                         <option key="1" value="1">POSITIVO</option>
                                                     </Input>
                                                     {/* {validator.current.message('EsitoVerificaSa', EsitoVerificaSa, 'string', { className: 'text-danger' })} */}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={3}>
                                                &nbsp;
                                            </Col>
                                            <Col md={3}>
                                                <h3>Valutazione Fulminazione</h3>
                                            </Col>
                                            <Col lg={2} md={4}>
                                                <FormGroup>
                                                    <Input type="select"
                                                        id="selectFulminazione"
                                                        onChange={handleFulminazione}
                                                        value={Fulminazione}
                                                        onBlur={() => validator.current.showMessageFor('Fulminazione')}
                                                    >
                                                        <option key="" value=""></option>
                                                        <option key="0" value="0">NO</option>
                                                        <option key="1" value="1">SI</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <table className={classes.tableCustom}>
                                            <tr>
                                                <td style={{width:'4%'}}></td>
                                                <td style={{width:'24%'}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>Impianto base</h1></td>
                                                <td style={{width:'24%'}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>Elementi Captaz.</h1></td>
                                                <td style={{width:'48%'}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>Organi in Discesa</h1></td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleSaImpBaseElementoArtificiale} 
                                                        checked={SaImpBaseElementoArtificiale>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Elementi ARTIFICIALI</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <table style={{width:'100%'}}>
                                                        <tr>
                                                            <td style={{width:'30%',textAlign:'left'}}>
                                                            <Checkbox 
                                                                style={{transform: "scale(1.8)"}}
                                                                onChange={handleSaElCapAdAsteVerticali} 
                                                                checked={SaElCapAdAsteVerticali>0}
                                                            />
                                                            </td>
                                                            <td style={{width:'70%',textAlign:'left'}}>Ad Aste Verticali</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Row>
                                                        <Col md={2}>{SaElCapAdAsteVerticali ? 'N°':'N°'}</Col>
                                                        <Col md={4} style={{paddingTop:'10px',fontSize:'0.7em'}}>
                                                            <MyNumInput 
                                                                readOnly={!SaElCapAdAsteVerticali}
                                                                disabled={!SaElCapAdAsteVerticali} 
                                                                value={SaElCapAdAsteVerticaliN} 
                                                                onBlur={() => validator.current.showMessageFor('SaElCapAdAsteVerticaliN')}
                                                                onChange={handleSaElCapAdAsteVerticaliN}
                                                                width="120px"
                                                            />
                                                            {validator.current.message('SaElCapAdAsteVerticaliN', SaElCapAdAsteVerticaliN, SaElCapAdAsteVerticali ? 'required|numeric':'numeric', { className: 'text-danger-min' })}
                                                        </Col>
                                                        <Col md={2}>{SaElCapAdAsteVerticali ? 'Mq':'Mq'}</Col>
                                                        <Col md={4} style={{paddingTop:'10px',fontSize:'0.7em'}}>
                                                            <Input type="text" 
                                                                autoComplete="new-password" 
                                                                placeholder=""
                                                                readOnly={!SaElCapAdAsteVerticali}
                                                                disabled={!SaElCapAdAsteVerticali}
                                                                value={SaElCapAdAsteVerticaliMq} 
                                                                onBlur={() => validator.current.showMessageFor('SaElCapAdAsteVerticaliMq')}
                                                                onChange={handleSaElCapAdAsteVerticaliMq}                                                                
                                                            />
                                                            {validator.current.message('SaElCapAdAsteVerticaliMq', SaElCapAdAsteVerticaliMq, SaElCapAdAsteVerticali ? 'required|numeric':'numeric', { className: 'text-danger-min' })}
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleSaImpBaseElementoNaturale} 
                                                        checked={SaImpBaseElementoNaturale>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Elementi NATURALI</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <table style={{width:'100%'}}>
                                                        <tr>
                                                            <td style={{width:'30%',textAlign:'left'}}>
                                                                <Checkbox 
                                                                    style={{transform: "scale(1.8)"}}
                                                                    onChange={handleSaElCapAFuni} 
                                                                    checked={SaElCapAFuni>0}
                                                                />
                                                            </td>
                                                            <td style={{width:'70%',textAlign:'left'}}>A Funi</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Row>
                                                        <Col md={2}>{SaElCapAFuni ? 'N°':'N°'}</Col>
                                                        <Col md={4} style={{paddingTop:'10px',fontSize:'0.7em'}}>
                                                            <MyNumInput 
                                                                readOnly={!SaElCapAFuni}
                                                                disabled={!SaElCapAFuni} 
                                                                value={SaElCapAFuniN} 
                                                                onBlur={() => validator.current.showMessageFor('SaElCapAFuniN')}
                                                                onChange={handleSaElCapAFuniN}
                                                                width="120px"
                                                            />
                                                            {validator.current.message('SaElCapAFuniN', SaElCapAFuniN, SaElCapAFuni ? 'required|numeric':'numeric', { className: 'text-danger-min' })}
                                                        </Col>
                                                        <Col md={2}>{SaElCapAFuni ? 'Mq':'Mq'}</Col>
                                                        <Col md={4} style={{paddingTop:"10px",fontSize:'0.7em'}}>
                                                            <Input type="text" 
                                                                autoComplete="new-password" 
                                                                placeholder=""
                                                                readOnly={!SaElCapAFuni}
                                                                disabled={!SaElCapAFuni}
                                                                value={SaElCapAFuniMq} 
                                                                onBlur={() => validator.current.showMessageFor('SaElCapAFuniMq')}
                                                                onChange={handleSaElCapAFuniMq}
                                                            />
                                                            {validator.current.message('SaElCapAFuniMq', SaElCapAFuniMq, SaElCapAFuni ? 'required|numeric':'numeric', { className: 'text-danger-min' })}
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleSaImpBaseSistemaDisperdente} 
                                                        checked={SaImpBaseSistemaDisperdente>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Sistema disperdente</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <table style={{width:'100%'}}>
                                                        <tr>
                                                            <td style={{width:'30%',textAlign:'left'}}>
                                                                <Checkbox 
                                                                    style={{transform: "scale(1.8)"}}
                                                                    onChange={handleSaElCapAMaglia} 
                                                                    checked={SaElCapAMaglia>0}
                                                                />
                                                            </td>
                                                            <td style={{width:'70%',textAlign:'left'}}>A Maglia</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Row>
                                                        <Col md={2}>{SaElCapAMaglia ? 'N°':'N°'}</Col>
                                                        <Col md={4} style={{paddingTop:'10px',fontSize:'0.7em'}}>
                                                            <MyNumInput 
                                                                readOnly={!SaElCapAMaglia}
                                                                disabled={!SaElCapAMaglia} 
                                                                value={SaElCapAMagliaN} 
                                                                onBlur={() => validator.current.showMessageFor('SaElCapAMagliaN')}
                                                                onChange={handleSaElCapAMagliaN}
                                                                width="120px"
                                                            />
                                                            {validator.current.message('SaElCapAMagliaN', SaElCapAMagliaN, SaElCapAMaglia ? 'required|numeric':'numeric', { className: 'text-danger-min' })}
                                                        </Col>
                                                        <Col md={2}>{SaElCapAMaglia ? 'Mq':'Mq'}</Col>
                                                        <Col md={4} style={{paddingTop:"10px",fontSize:'0.7em'}}>
                                                            <Input type="text" 
                                                                autoComplete="new-password" 
                                                                placeholder=""
                                                                readOnly={!SaElCapAMaglia}
                                                                disabled={!SaElCapAMaglia}
                                                                value={SaElCapAMagliaMq} 
                                                                onBlur={() => validator.current.showMessageFor('SaElCapAMagliaMq')}
                                                                onChange={handleSaElCapAMagliaMq}
                                                            />
                                                            {validator.current.message('SaElCapAMagliaMq', SaElCapAMagliaMq, SaElCapAMaglia ? 'required|numeric':'numeric', { className: 'text-danger-min' })}
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleSaImpBaseDispersoreDiFatto} 
                                                        checked={SaImpBaseDispersoreDiFatto>0}
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>Dispersore di fatto</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <table style={{width:'100%'}}>
                                                        <tr>
                                                            <td style={{width:'30%',textAlign:'left'}}>
                                                                <Checkbox 
                                                                    style={{transform: "scale(1.8)"}}
                                                                    onChange={handleSaElCapFerroArmatura} 
                                                                    checked={SaElCapFerroArmatura>0}
                                                                />
                                                            </td>
                                                            <td style={{width:'70%',textAlign:'left'}}>Ferro di Armatura</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <Row>
                                                    <Col md={2}>{SaElCapFerroArmatura ? 'N°':'N°'}</Col>
                                                        <Col md={4} style={{paddingTop:'10px',fontSize:'0.7em'}}>
                                                            <MyNumInput 
                                                                readOnly={!SaElCapFerroArmatura}
                                                                disabled={!SaElCapFerroArmatura} 
                                                                value={SaElCapFerroArmaturaN} 
                                                                onBlur={() => validator.current.showMessageFor('SaElCapFerroArmaturaN')}
                                                                onChange={handleSaElCapFerroArmaturaN}
                                                                width="120px"
                                                            />
                                                            {validator.current.message('SaElCapFerroArmaturaN', SaElCapFerroArmaturaN, SaElCapFerroArmatura ? 'required|numeric':'numeric', { className: 'text-danger-min' })}
                                                        </Col>
                                                        <Col md={2}>{SaElCapFerroArmatura ? 'Mq':'Mq'}</Col>
                                                        <Col md={4} style={{paddingTop:"10px",fontSize:'0.7em'}}>
                                                            <Input type="text" 
                                                                autoComplete="new-password" 
                                                                placeholder=""
                                                                readOnly={!SaElCapFerroArmatura}
                                                                disabled={!SaElCapFerroArmatura}
                                                                value={SaElCapFerroArmaturaMq} 
                                                                onBlur={() => validator.current.showMessageFor('SaElCapFerroArmaturaMq')}
                                                                onChange={handleSaElCapFerroArmaturaMq}
                                                            />
                                                            {validator.current.message('SaElCapFerroArmaturaMq', SaElCapFerroArmaturaMq, SaElCapFerroArmatura ? 'required|numeric':'numeric', { className: 'text-danger-min' })}
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td className={classes.tableCustomBorder} colSpan={2}>
                                                    <Row>
                                                        <Col md={8}>RESISTENZA DI TERRA Ω</Col>
                                                        <Col md={4} style={{paddingTop:"10px",fontSize:'0.7em',paddingBottom:"10px"}}>
                                                            <Input 
                                                                type="text" 
                                                                name="txtResistenzaTerra" 
                                                                autoComplete="new-password" 
                                                                placeholder=""
                                                                onBlur={() => validator.current.showMessageFor('ResistenzaTerra')}
                                                                value={ResistenzaTerra} 
                                                                onChange={handleResistenzaTerra}
                                                            />
                                                            {validator.current.message('ResistenzaTerra', ResistenzaTerra, 'numeric|required', { className: 'text-danger' })}
                                                        </Col>
                                                    </Row>
                                                </td>
                                                <td className={classes.tableCustomBorder}>&nbsp;</td>
                                            </tr>
                                        </table>
                                        <table className={classes.tableCustom}>
                                            <tr>
                                                <td style={{width:'4%'}}></td>
                                                <td style={{width:'40%'}} className={classes.tableCustomBorder}>Metodo di misura:</td>
                                                <td style={{width:'5%'}} className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleMetodoMisuraVoltAmperometrico} 
                                                        checked={MetodoMisuraVoltAmperometrico>0}
                                                    />
                                                </td>
                                                <td style={{width:'23%',fontSize:'0.6em'}} className={classes.tableCustomBorder}><b>Volt amperometrico</b></td>
                                                <td style={{width:'5%'}} className={classes.tableCustomBorder}>
                                                    <Checkbox 
                                                        style={{transform: "scale(1.8)"}}
                                                        onChange={handleMetodoMisuraAnelloDiGuasto} 
                                                        checked={MetodoMisuraAnelloDiGuasto>0}
                                                    />
                                                </td>
                                                <td style={{width:'23%',fontSize:'0.6em'}} className={classes.tableCustomBorder}><b>Anello di guasto</b></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td className={classes.tableCustomBorder}>Vedi Osservazioni:</td>
                                                <td style={{width:'5%'}} className={classes.tableCustomBorder}>
                                                    <input type="radio" 
                                                        style={{transform: "scale(2.2)"}}
                                                        name="radioVediOsservazioni" 
                                                        value="1" 
                                                        checked={VediOsservazioni===1} 
                                                        onClick={handleVediOsservazioni} 
                                                    />
                                                </td>
                                                <td style={{width:'10%'}} className={classes.tableCustomBorder}><b>SI</b></td>
                                                <td style={{width:'5%'}} className={classes.tableCustomBorder}>
                                                    <input type="radio" 
                                                        style={{transform: "scale(2.2)"}}
                                                        name="radioVediOsservazioni" 
                                                        value="0" 
                                                        checked={VediOsservazioni===0} 
                                                        onClick={handleVediOsservazioni} 
                                                    />
                                                </td>
                                                <td style={{width:'10%'}} className={classes.tableCustomBorder}><b>NO</b></td>
                                            </tr>
                                        </table>
                                    </div>
                                    )}
                                    {TipoVerifica===Tipo_verifica_ScaricheAtmosferiche && (<div className={classes.legenda}>&nbsp;</div>)}
                                    {
                                    TipoVerifica===Tipo_verifica_ScaricheAtmosferiche && (
                                    <div className={classes.riquadro}>
                                        <Row>
                                            <Col md={6}>
                                            <div className={classes.titleLegenda}>
                                                ESAME DELL'IMPIANTO LPS ESTERNO ED INTERNO
                                            </div>
                                            </Col>
                                            <Col lg={2} md={4}>
                                                <FormGroup>
                                                    <Input type="select"
                                                        id="selectEsitoEsameImpiantoLPS"
                                                        style={{fontSize:"1.2em"}}
                                                        placeholder=""
                                                        onBlur={() => validator.current.showMessageFor('EsameImpiantoLPS')}
                                                        value={EsameImpiantoLPS} 
                                                        onChange={handleEsameImpiantoLPS}
                                                    >
                                                        <option key="" value=""></option>
                                                        <option key="1" value="1">CONFORME</option>
                                                        <option key="2" value="2">NON CONFORME</option>
                                                    </Input>
                                                    {validator.current.message('EsameImpiantoLPS', EsameImpiantoLPS, 'required|numeric', { className: 'text-danger' })}
                                                </FormGroup>
                                               
                                            </Col>
                                        </Row>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <table className={classes.tableCustom}>
                                            <tr>
                                                <td style={{width:'80%'}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>DESCRIZIONE</h1></td>
                                                <td style={{width:'10%',}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>SI</h1></td>
                                                <td style={{width:'10%',}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>NO</h1></td>
                                            </tr>
                                            {
                                            DataDomandeSa.map((row, index) => {
                                                 return(
                                                    <tr>
                                                        <td style={{textAlign:"left"}} className={classes.tableCustomBorder}>{row.Descrizione}</td>
                                                        <td className={classes.tableCustomBorder}>{
                                                            <input type="radio" 
                                                                style={{transform: "scale(2.2)"}}
                                                                name={"radioDomandaSa_" + row.id}
                                                                value="1"
                                                                checked={row.Valore===1}
                                                                onChange={() =>handleChangeDomandaSa("1",row.id)} 
                                                            />
                                                            }
                                                        </td>
                                                        <td className={classes.tableCustomBorder}>{
                                                            <input type="radio" 
                                                                style={{transform: "scale(2.2)"}}
                                                                name={"radioDomandaSa_" + row.id}
                                                                checked={row.Valore===0} 
                                                                value="0" 
                                                                onChange={() =>handleChangeDomandaSa("0",row.id)} 
                                                            />
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </table>
                                    </div>
                                    )}
                                    {
                                    TipoVerifica===Tipo_verifica_RischioEsplosione && (
                                        <div className={classes.riquadro}>
                                            <Row>
                                                <Col md={6}>
                                                <div className={classes.titleLegenda}>
                                                    VERIFICA LUOGHI A MAGGIOR RISCHIO ESPLOSIONE
                                                </div>
                                                </Col>
                                                <Col lg={2} md={4}>
                                                    <FormGroup>
                                                        <Input type="select"
                                                            id="selectEsitoVerificaEx"
                                                            style={{fontSize:"1.2em"}}
                                                            value={EsitoVerificaEx}
                                                            onChange={handleEsitoVerificaEx}
                                                            onBlur={() => validator.current.showMessageFor('EsitoVerificaEx')}
                                                            disabled
                                                            readOnly
                                                        >
                                                            <option key="" value=""></option>
                                                            <option key="0" value="0">NEGATIVO</option>
                                                            <option key="1" value="1">POSITIVO</option>
                                                        </Input>
                                                        {validator.current.message('EsitoVerificaEx', EsitoVerificaEx, 'required|numeric', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div className={classes.legenda}>
                                                &nbsp;
                                            </div>
                                            <table className={classes.tableCustom}>
                                                <tr>
                                                    <td colSpan={4} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>Planimetria Delle Zone Classificate</h1></td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}>Gas, vapori</td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleZone0} 
                                                                checked={Zone0>0}
                                                            />
                                                            } 
                                                            label="Zone 0" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleZone1} 
                                                                checked={Zone1>0}
                                                            />
                                                            } 
                                                            label="Zone 1" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleZone2} 
                                                                checked={Zone2>0}
                                                            />
                                                            } 
                                                            label="Zone 2" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}>Polveri</td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleZone20} 
                                                                checked={Zone20>0}
                                                            />
                                                            } 
                                                            label="Zone 20" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleZone21} 
                                                                checked={Zone21>0}
                                                            />
                                                            } 
                                                            label="Zone 21" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleZone22} 
                                                                checked={Zone22>0}
                                                            />
                                                            } 
                                                            label="Zone 22" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                </tr>
                                            </table>
                                            <div className={classes.legenda}>
                                                &nbsp;
                                            </div>
                                            <div className={classes.legenda}>
                                                L’Ispettore ha eseguito la verifica seguendo la metodologia indicata nelle direttive ATEX, nelle Norme:
                                                CEI EN 60079-10; CEI 31/30; CEI 31/33, CEI 31/34, CEI 31/35; CEI 31/35A; CEI EN 50281-3 (CEI 31/52); CEI EN 50281-3 (CEI 31/56), nella Linea Guida n. 4.
                                            </div>
                                            <div className={classes.legenda}>
                                                &nbsp;
                                            </div>
                                            <h1>Protezione adottata per le apparecchiature elettriche:</h1>
                                            <div className={classes.legenda}>
                                                &nbsp;
                                            </div>
                                            <table className={classes.tableCustom}>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}><b>Ex-s (per zona 0)</b></td>
                                                    <td className={classes.tableCustomBorder}><b>Ex-s (per zona 1)</b></td>
                                                    <td className={classes.tableCustomBorder}><b>Ex-s (per zona 2)</b></td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleEx_ia} 
                                                                checked={Ex_ia>0}
                                                            />
                                                            } 
                                                            label="Ex-ia&nbsp;&nbsp;" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleEx_d} 
                                                                checked={Ex_d>0}
                                                            />
                                                            } 
                                                            label="Ex-d&nbsp;" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleEx_n} 
                                                                checked={Ex_n>0}
                                                            />
                                                            } 
                                                            label="Ex-n" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleEx_ma} 
                                                                checked={Ex_ma>0}
                                                            />
                                                            } 
                                                            label="Ex-ma" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleEx_e} 
                                                                checked={Ex_e>0}
                                                            />
                                                            } 
                                                            label="Ex-e&nbsp;" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleZona2_Ex_i} 
                                                                checked={Zona2_Ex_i>0}
                                                            />
                                                            } 
                                                            label="Ex-i&nbsp;" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleZona0_Ex_i} 
                                                                checked={Zona0_Ex_i>0}
                                                            />
                                                            } 
                                                            label="Ex-i&nbsp;&nbsp;&nbsp;&nbsp;" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleEx_p} 
                                                                checked={Ex_p>0}
                                                            />
                                                            } 
                                                            label="Ex-p&nbsp;" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}>
                                                        
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleEx_m} 
                                                                checked={Ex_m>0}
                                                            />
                                                            } 
                                                            label="Ex-m" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}>
                                                        
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleEx_o} 
                                                                checked={Ex_o>0}
                                                            />
                                                            } 
                                                            label="Ex-o&nbsp;" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}>
                                                        
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleEx_q} 
                                                                checked={Ex_q>0}
                                                            />
                                                            } 
                                                            label="Ex-q&nbsp;" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className={classes.tableCustomBorder}>
                                                        
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        <FormControlLabel 
                                                            control={
                                                            <Checkbox 
                                                                onChange={handleZona1_Ex_i} 
                                                                checked={Zona1_Ex_i>0}
                                                            />
                                                            } 
                                                            label="Ex-i&nbsp;&nbsp;" 
                                                            style={{transform: "scale(1.8)"}}
                                                        />
                                                    </td>
                                                    <td className={classes.tableCustomBorder}>
                                                        
                                                    </td>
                                                </tr>
                                            </table>
                                            <div className={classes.legenda}>
                                                &nbsp;
                                            </div>
                                            <table className={classes.tableCustom}>
                                                <tr>
                                                    <td style={{width:'70%'}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>DESCRIZIONE</h1></td>
                                                    <td style={{width:'10%',}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>SI</h1></td>
                                                    <td style={{width:'10%',}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>NO</h1></td>
                                                    <td style={{width:'10%',}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h1>NA</h1></td>
                                                </tr>
                                                {
                                                DataDomandeExp.map((row, index) => {
                                                    return (
                                                        <tr>
                                                            <td style={{textAlign:"left"}} className={classes.tableCustomBorder}>{row.Descrizione}</td>
                                                            <td className={classes.tableCustomBorder}>{
                                                                <input type="radio" 
                                                                    style={{transform: "scale(2.2)"}}
                                                                    name={"radioDomandaExp_" + row.id} 
                                                                    value="1"
                                                                    checked={row.Valore===1}
                                                                    onChange={() =>handleChangeDomandaExp("1",row.id)}  
                                                                />
                                                                }
                                                            </td>
                                                            <td className={classes.tableCustomBorder}>{
                                                                <input type="radio" 
                                                                    style={{transform: "scale(2.2)"}}
                                                                    name={"radioDomandaExp_" + row.id} 
                                                                    value="0"
                                                                    checked={row.Valore===0} 
                                                                    onChange={() =>handleChangeDomandaExp("0",row.id)} 
                                                                />
                                                                }
                                                            </td>
                                                            <td className={classes.tableCustomBorder}>{
                                                                <input type="radio" 
                                                                    style={{transform: "scale(2.2)"}}
                                                                    name={"radioDomandaExp_" + row.id} 
                                                                    value="2"
                                                                    checked={row.Valore===2} 
                                                                    onChange={() =>handleChangeDomandaExp("2",row.id)} 
                                                                />
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </div>
                                        )
                                    }
                                </Paper>
                            </Grid>
                            <div className={classes.titleSezione}>
                                &nbsp;PIANO DI ISPEZIONE
                            </div>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <div className={classes.riquadro}>
                                        <Row>
                                            <Col md={9}>
                                            
                                            </Col>
                                            <Col md={3}>
                                                <div className={classes.legenda}>
                                                    Calcolo Automatico Piano Ispezione<br/>
                                                    1 - Inserire il tempo stimato per ogni FASE<br/>
                                                    2 - Digitare l'ora inizio nel campo sottostante
                                                </div>
                                                <div className={classes.legenda}>&nbsp;</div>
                                            </Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col md={6}>
                                            
                                            </Col>
                                            <Col md={3}>
                                                <Label>Ora Inizio Ispezione</Label>
                                            </Col>
                                            <Col md={2}>
                                                <MyTimeInput 
                                                    onBlur={() => validator.current.showMessageFor('OraInizio')}
                                                    value={OraInizio} 
                                                    onChange={handleOraInizio}
                                                />
                                                {validator.current.message('OraInizio', OraInizio, 'string|required', { className: 'text-danger' })}
                                            </Col>
                                        </Row>
                                        <table className={classes.tableCustom}>
                                            <tr>
                                                <td className={classes.tableCustomBorder}><b>FASE</b></td>
                                                <td className={classes.tableCustomBorder}><b>TEMPO STIMATO</b></td>
                                                <td className={classes.tableCustomBorder}><b>ORA INIZIO</b></td>
                                                <td className={classes.tableCustomBorder}><b>ORA FINE</b></td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>VERIFICA COMPLESSIVA</td>
                                                <td className={classes.tableCustomBorder}>
                                                     <MyTimeInput 
                                                        onBlur={() => validator.current.showMessageFor('StimaFase1')}
                                                        value={StimaFase1} 
                                                        onChange={handleStimaFase1}
                                                        disabled readOnly
                                                    />
                                                 {validator.current.message('StimaFase1', StimaFase1, 'string|required', { className: 'text-danger' })}
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        value={OraInizioFase1} 
                                                        disabled readOnly
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        value={OraFineFase1} 
                                                        disabled readOnly
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>VERIFICA DOCUMENTALE</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        onBlur={() => validator.current.showMessageFor('StimaFase2')}
                                                        value={StimaFase2} 
                                                        onChange={handleStimaFase2}
                                                    />
                                                    {validator.current.message('StimaFase2', StimaFase2, 'string|required', { className: 'text-danger' })}
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        value={OraInizioFase2} 
                                                        disabled readOnly
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        value={OraFineFase2} 
                                                        disabled readOnly
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>VERIFICA DELL’IMPIANTO</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        onBlur={() => validator.current.showMessageFor('StimaFase3')}
                                                        value={StimaFase3} 
                                                        onChange={handleStimaFase3}
                                                    />
                                                    {validator.current.message('StimaFase3', StimaFase3, 'string|required', { className: 'text-danger' })}
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        value={OraInizioFase3} 
                                                        disabled readOnly
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        value={OraFineFase3} 
                                                        disabled readOnly
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableCustomBorder}>RIUNIONE DI CHIUSURA</td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        onBlur={() => validator.current.showMessageFor('StimaFase4')}
                                                        value={StimaFase4} 
                                                        onChange={handleStimaFase4}
                                                    />
                                                    {validator.current.message('StimaFase4', StimaFase4, 'string|required', { className: 'text-danger' })}
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        value={OraInizioFase4} 
                                                        disabled readOnly
                                                    />
                                                </td>
                                                <td className={classes.tableCustomBorder}>
                                                    <MyTimeInput 
                                                        value={OraFineFase4} 
                                                        disabled readOnly
                                                    />
                                                </td>
                                            </tr>
                                        </table> 
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <Row>
                                            <Col md={3}>
                                                <Label>Persona di riferimento Cliente</Label>
                                            </Col>
                                            <Col lg={4} md={9}>
                                                <Input 
                                                    type="text" 
                                                    name="txtRiferimentoCliente" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('RiferimentoCliente')}
                                                    value={RiferimentoCliente} 
                                                    onChange={handleRiferimentoCliente}
                                                />
                                                {validator.current.message('RiferimentoCliente', RiferimentoCliente, 'string|required', { className: 'text-danger' })}
                                            </Col>
                                        </Row>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <Row>
                                            <Col md={3}>
                                                <Label>N° Tecnici Impegnati [max 2]</Label>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <MyNumInput 
                                                        value={NumTecnici} 
                                                        onChange={handleNumTecnici}
                                                        onBlur={() => validator.current.showMessageFor('NumTecnici')}
                                                        width={'120px'}
                                                    />
                                                    {validator.current.message('NumTecnici', NumTecnici, 'numeric|required', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {
                                            (NumTecnici>1) && (
                                                <Row>
                                                    <Col md={3}>
                                                        <Label>Secondo Ispettore</Label>
                                                    </Col>
                                                    <Col lg={4} md={9}>
                                                        <MyAutoComplete id="txtIspettore2" name="txtIspettore2" 
                                                            url={process.env.REACT_APP_API_URL+'ispettoresinglelist'} 
                                                            optionname="DesIspettore" optionvalue="id"
                                                            customvalue={DesIspettore2} onChange={handleIspettore2}
                                                            onBlur={() => validator.current.showMessageFor('Ispettore2')}
                                                            />
                                                        {validator.current.message('Ispettore2', IdIspettore2, 'required|alpha_num', { className: 'text-danger' })}
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="txtNote">Note [max 500 caratteri]</Label>
                                                <Input 
                                                    type="textarea" 
                                                    name="txtNote" 
                                                    id="txtNote" 
                                                    autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('Note')}
                                                    value={Note} 
                                                    onChange={handleNote} 
                                                    maxLength="500"
                                                />
                                                {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                </Paper>
                            </Grid>
                            {
                                IdIspettore >0 && (
                                    <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col lg={5} md={8}>
                                            <FormGroup>
                                                <Label for="selDescFoto">Descrizione Foto</Label>
                                                <Input 
                                                    type="select" 
                                                    name="selDescFoto" 
                                                    id="selDescFoto" 
                                                    placeholder=""
                                                    onChange={handleDescFoto}
                                                    value={DescFoto}
                                                    disabled={ApprovatoOld===1}
                                                >
                                                    <option key="0" value=""></option>
                                                    <option key="Foto quadro elettrico" value="Foto quadro elettrico">Foto quadro elettrico</option>
                                                    <option key="Foto certificato conformità" value="Foto certificato conformità">Foto certificato conformità</option>
                                                    <option key="Foto progetto impianto" value="Foto progetto impianto">Foto progetto impianto</option>
                                                    <option key="Foto picchetto di terra non ispezionabile" value="Foto picchetto di terra non ispezionabile">Foto picchetto di terra non ispezionabile</option>
                                                    <option key="Altra foto" value="Altra foto">Altra foto</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col lg={5} md={4}>
                                            <FormGroup>
                                                <Label for="txtSpecificaFoto">Specifica</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtSpecificaFoto" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={SpecificaFoto} 
                                                    onChange={handleSpecificaFoto}
                                                    disabled={ApprovatoOld===1}
                                                    readOnly={ApprovatoOld===1}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={2} md={4}>
                                            <FormGroup>
                                                <input
                                                    type="file"
                                                    ref={hiddenFilePhoto}
                                                    onChange={handleChangePhoto}
                                                    accept="image/*" 
                                                    capture
                                                    style={{display: 'none'}} 
                                                />
                                                <Label>&nbsp;</Label>
                                                <Button onClick={handleClickPhoto} disabled={ApprovatoOld===1} className="btn-mbg-cert btn-block">Allega Foto</Button>
                                            </FormGroup>
                                        </Col>
                                        <Col md={5}></Col>
                                    </Row>
                                        <div className={classes.legenda}>
                                            &nbsp;
                                        </div>
                                        <Row>
                                            <table style={{width:'700px'}} className={classes.tableCustom}>
                                                <tr>
                                                    <td style={{width:'70px'}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h3>APRI</h3></td>
                                                    <td style={{width:'70px',}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h3>CANC</h3></td>
                                                    <td style={{width:'300px',}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h3>DESCRIZIONE</h3></td>
                                                    <td style={{width:'200px',}} className={classes.tableCustomColor + " " + classes.tableCustomBorder}><h3>DATA INSERIMENTO</h3></td>
                                                </tr>
                                                {
                                                DataFoto.map((row, index) => {
                                                    return (
                                                        <tr>
                                                            <td style={{paddingLeft:"18px",textAlign:"center",paddingTop:"20px",paddingBottom:"20px"}} className={classes.tableCustomBorder}>
                                                                <Avatar className={"btn-mbg-cert " + classes.BtnEdit}  onClick={() => onOpenFoto(row.Foto_path,row.Descrizione)}>
                                                                    <OpenInBrowserIcon />
                                                                </Avatar>
                                                            </td>
                                                            <td style={{paddingLeft:"20px",textAlign:"center",paddingTop:"20px",paddingBottom:"20px"}} className={classes.tableCustomBorder}>{
                                                                <Avatar className={"btn-mbg-cert " + classes.BtnEdit}  onClick={() => onCancellaFoto(row.id)}>
                                                                    <DeleteIcon />
                                                                </Avatar>
                                                                }
                                                            </td>
                                                            <td style={{textAlign:"center",padding:"20px;"}} className={classes.tableCustomBorder}>{row.Descrizione}</td>
                                                            <td style={{textAlign:"center",padding:"20px;"}} className={classes.tableCustomBorder}>{row.DataInserimento}</td>
                                                        </tr>
                                                    );
                                                })}
                                                {
                                                    DataFoto.length===0 && (
                                                        
                                                        <tr>
                                                            <td style={{padding:"20px;"}}className={classes.tableCustomBorder} colSpan={4}><h3>NESSUNA FOTO CARICATA!</h3></td>
                                                        </tr>
                                                    )
                                                }
                                                
                                            </table>
                                        </Row>
                                </Paper>
                            </Grid>
                                )
                            }
                            {
                            (VerificaEseguita===0 && IdIspettore>0) &&
                            (
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>&nbsp;</Label>
                                                <Button 
                                                    onClick={onOpenSignCliente} 
                                                    className="btn-mbg-cert btn-block">Firma Cliente</Button>
                                            </FormGroup>
                                        </Col>
                                        {
                                            ImgSignCliente !== null &&
                                            (
                                            <Col md={1}>
                                                <div style={{textAlign:'left',paddingTop:'30px'}}>
                                                    <img style={{height:"20pt"}} src={icoOk} alt=""/>
                                                </div>
                                            </Col>
                                            )
                                        }
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtImportoDaSaldare">Importo</Label>
                                                <Input 
                                                    type="text" 
                                                    name="txtImportoDaSaldare" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={"€ " + ccyFormat(ImportoSaldo)} 
                                                    disabled readOnly
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} xs={12}>
                                            <FormGroup>
                                                <div style={{fontSize:'24px'}}>&nbsp;</div>
                                                <Label check>
                                                    <Input 
                                                        type="checkbox" 
                                                        name="chkSaldo" 
                                                        onChange={handleSaldo} 
                                                        checked={Saldo === 1}
                                                        style={{transform: "scale(3)"}}
                                                        disabled={ImportoSaldo===0} 
                                                        readOnly={ImportoSaldo===0} 
                                                        />{' '}
                                                    <span style={{paddingLeft:'10px',fontSize:"1.4em"}}>&nbsp;Saldo</span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        {
                                        (()=> {
                                                if (Saldo ===1 && ImportoSaldo>0)
                                                {
                                                    return (
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="selModalPag">Modalità di Pagamento *</Label>
                                                            <Input type="select" name="selModalPag" id="selModalPag" placeholder=""
                                                                onBlur={() => validator.current.showMessageFor('ModalPag')}
                                                                value={ModalPag} onChange={handleModalPag}
                                                                disabled={ImportoSaldo===0} 
                                                                readOnly={ImportoSaldo===0}
                                                            >
                                                                <option key=""></option>
                                                                <option key="1" value="1">Assegno bancario</option>
                                                                <option key="2" value="2">Assegno Circolare</option>
                                                                <option key="3" value="3">Bonifico Bancario</option>
                                                                <option key="4" value="4">Denaro Contante</option>
                                                                <option key="5" value="5">Pos</option>
                                                            </Input>
                                                            {validator.current.message('ModalPag', ModalPag, 'required|numeric', { className: 'text-danger' })}
                                                        </FormGroup>
                                                    </Col>)
                                                }
                                            })()
                                        }
                                    </Row>
                                </Paper>
                            </Grid>
                            )}
                            
                                {
                                (VerificaEseguita===0 && IdIspettore>0) &&
                                (
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={4} xs={12}>
                                                <FormGroup>
                                                    <div>&nbsp;</div>
                                                    <Label check> 
                                                        <Input 
                                                            type="checkbox" 
                                                            name="chkRimandato" 
                                                            onChange={handleRimandato} 
                                                            checked={Rimandato> 0} 
                                                            style={{transform: "scale(3)"}}
                                                            readOnly
                                                            disabled
                                                        />{' '}
                                                        <span style={{paddingLeft:'10px',fontSize:"1.4em"}}>&nbsp;Rimandato</span>
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            {
                                                (()=> {
                                                    if (Rimandato >0)
                                                    {
                                                        return (
                                                        <Col md={8} xs={12}>
                                                            <FormGroup>
                                                                <Label for="txtMotivoRimandato">Motivo [max 50 caratteri]</Label>
                                                                <Input 
                                                                    type="text" 
                                                                    name="txtMotivoRimandato" 
                                                                    id="txtMotivoRimandato" 
                                                                    autoComplete="new-password"
                                                                    onBlur={() => validator.current.showMessageFor('MotivoRimandato')}
                                                                    value={MotivoRimandato} 
                                                                    onChange={handleMotivoRimandato} 
                                                                    maxLength="50"
                                                                    readOnly
                                                                    disabled
                                                                />
                                                                {validator.current.message('MotivoRimandato', MotivoRimandato, 'required|string', { className: 'text-danger' })}
                                                            </FormGroup>
                                                        </Col>)
                                                    }
                                                })()
                                            }
                                        </Row>
                                    </Paper>
                                </Grid>
                                )
                                }
                            
                            {
                                /* (VerificaEseguita===0) &&
                                (
                                <Col md={3} xs={12}>
                                    <FormGroup>
                                        <div>&nbsp;</div>
                                        <Label check> 
                                            <Input 
                                                type="checkbox" 
                                                name="chkAnnulla" 
                                                onChange={handleAnnulla} 
                                                checked={Annulla> 0} 
                                                style={{transform: "scale(3)"}}
                                            />{' '}
                                            <span style={{paddingLeft:'10px',fontSize:"1.4em"}}>Annulla</span>
                                        </Label>
                                    </FormGroup>
                                </Col>
                                ) */
                                }
                                {
                                (VerificaEseguita===1 && isAdmin===true && ApprovatoOld===0) &&
                                (
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={3} lg={6} xs={12}>
                                                <FormGroup>
                                                    <div>&nbsp;</div>
                                                    <Label check>
                                                        <Input 
                                                            type="checkbox" 
                                                            name="chkApprova" 
                                                            onChange={handleApprova} 
                                                            checked={Approvato> 0} 
                                                            style={{transform: "scale(3)"}}
                                                        />{'  '}
                                                        <span style={{paddingLeft:'10px',fontSize:"1.4em"}}>&nbsp;Approva Rapporto</span>
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            </Row>
                                    </Paper>
                                </Grid>
                                )
                            }
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
}
        