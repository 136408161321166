import React, {useState,useRef} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {FormGroup, Label,Col,Input,Row,Button} from "reactstrap";
import MyAlert from './MyAlert';
import {GetMessage} from "../Modules/Message";
import {GetToken} from "../Modules/Funzioni";
import "../Modules/Costanti";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from 'simple-react-validator';
import '../css/Form.css';
import { app_cpService } from "../Modules/Costanti";
export default function ModUser(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const MsgAlertDefault="Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [Email, setEmail] = React.useState("");
    const [Pwd_new, setPwd_new] = React.useState("");
    const [Pwd_old, setPwd_old] = React.useState("");
    const [Pwd_repeat, setPwd_repeat] = React.useState("");
    let ButtonClassName="";
    const app_selected =process.env.REACT_APP_APPSELECT;
    if (parseInt(app_selected)===app_cpService) {
        ButtonClassName="btn-mbg";
    }
    else{
        ButtonClassName ="btn-mbg-cert";
    }
    const handleEmail = (event) => {  
        setEmail(event.target.value); 
    }; 
    
    const handlePwd_old = (event) => {  
        setPwd_old(event.target.value); 
    }; 

    const handlePwd_new = (event) => {  
        setPwd_new(event.target.value); 
    }; 

    const handlePwd_repeat = (event) => {  
        setPwd_repeat(event.target.value); 
    }; 
  React.useEffect(() => {
    if (props.id!==""){
        LoadData();
    }
}, [props.id]);

const validator = useRef(new SimpleReactValidator({
    messages: {
        default: 'obbligatorio o non corretto!'
      },
      validators: {
        cphone: {  // name the rule
          //message: 'obbligatorio o non corretto',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
          },
          //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          //required: true  // optional
        }
      }
}));  

const LoadData = (event) => {
    if(props.id!==""){
        setIsLoading(true); 
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'me';               
                result = await ax.post(url);
                setEmail(result.data.email);
                
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning")
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
        })();  
    }  
}

const submitForm= (event) => 
{
    if (validator.current.allValid()) {
        if (Pwd_repeat===Pwd_new){
            setisSubmit(true);
            const fd = new FormData();
            fd.append("id", props.id);
            fd.append("email", Email);
            fd.append("password", Pwd_old);
            fd.append("pwd_new", Pwd_new);
        
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            
            
            (async () => {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                try {
                    let result = await ax.post(process.env.REACT_APP_API_URL+'cambiopwd', fd,axiosConfig)

                    if (result.status === 200) {
                        if (result.data.error==="OK"){
                            props.handleClose();
                        }
                        else {
                            let msg="";
                            let ris=JSON.stringify(result.data);
                            ris=ris.replaceAll("\"", "");
                            ris=ris.replaceAll("{", "");
                            ris=ris.replaceAll("}", "");
                            ris=ris.replaceAll("[", "");
                            ris=ris.replaceAll("]", "");
                            msg="errore durante l'operazione! " + ris;
                            setSeverityAlert("error")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                        }
                    }
                    else
                    { 
                        const msg=GetMessage(result.status,result.data.message);
                        setSeverityAlert("error")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    }
                }    
                catch(error)
                {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("error")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                }
            })();
        } 
        else {
            setSeverityAlert("error")
            setMsgAlert("la Nuova Password è diversa da quella ripetuta!");
            setOpenAlert(true);
        }
    } else {
        validator.current.showMessages();
        setSeverityAlert("error")
        setMsgAlert(MsgAlertDefault);
        setOpenAlert(true);
    }
}

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={true} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Modifica Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/>     
          {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
            
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label for="txtEmail">Email *</Label>
                        <Input type="email" name="txtEmail" autoComplete="new-password" placeholder=""
                        value={Email} onChange={handleEmail} readOnly disabled
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label for="txtPwd_old">Vecchia Password *</Label>
                        <Input type="password" name="txtPwd_old" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('VecchiaPwd')}
                        value={Pwd_old} onChange={handlePwd_old} 
                        />
                        {validator.current.message('VecchiaPwd', Pwd_old, 'required|string', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label for="txtPwd_new">Nuova Password *</Label>
                        <Input type="password" name="txtPwd_new" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('NuovaPwd')}
                        value={Pwd_new} onChange={handlePwd_new}
                        />
                        {validator.current.message('NuovaPwd', Pwd_new, 'required|string', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label for="txtPwd_repeat">Ripeti Password *</Label>
                        <Input type="password" name="txtPwd_repeat" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('RepeatPwd')}
                        value={Pwd_repeat} onChange={handlePwd_repeat} 
                        />
                        {validator.current.message('RepeatPwd', Pwd_repeat, 'required|string', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"50%"}}>
                <Button  onClick={submitForm} className={ButtonClassName + " btn-block"}>
                    Conferma {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                </Button>
            </div>
            <div style={{width:"50%"}}>
                    
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
    </div>
  );
}