import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Col, Row, Input, FormGroup, Label } from "reactstrap";
import MyNumInput from '../../Components/MyNumInput';
import MyAlert from '../../Components/MyAlert';
import MyConfirmInvioPreventivo from '../../Components/MyConfirm';
import MyAlertSelServizio from '../../Components/MyAlert';
import { GetMessage } from "../../Modules/Message";
import { GetToken, VerifyPDF, GetRole, ccyFormat, VerifyImage } from "../../Modules/Funzioni";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import imgSearch from "../../img/search.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import MyConfirmDelServizio from '../../Components/MyConfirm';
import SignCanvas from 'react-signature-canvas'
import { Ruolo_Admin } from '../../Modules/Costanti';
import MyDatePicker from '../../Components/MyDatePicker';
import MyAutoComplete from '../../Components/MyAutoComplete';
import MyPhoneInput from "../../Components/MyPhoneInput";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 400,
        minHeight: 400,
    },
    FormPopup: {
        maxWidth: "900px !important",
        minWidth: "800px !important",
    },

    BtnEdit: {
        '&:hover': {
            cursor: "pointer"
        }
    }
}));
function ElencaServizio(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [RicDescrizione, setRicDescrizione] = React.useState("");
    const [isSending, setIsSending] = useState(true);
    const [DisableFilter, setDisableFilter] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selected, setSelected] = React.useState("");
    const [SelectedData, setSelectedData] = React.useState([]);


    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert, setMsgAlert] = React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const handleRicDescrizione = (event) => {
        setRicDescrizione(event.target.value);
        setDisableFilter(false);
    };

    const handleRowClick = (_id, _descrizione, _prezzo) => {
        let newSelected = "";
        newSelected = _id;
        setSelected(newSelected);
        const ArrayRis = [
            { id: _id, Descrizione: _descrizione, Prezzo: _prezzo, Quantita: 1 },
        ];
        setSelectedData(ArrayRis);
    };

    const onFilter = () => {
        setDisableFilter(true);
        setSelected("");
        setSelectedData([]);
        setIsSending(true);
    };

    const onConfirm = () => {
        props.handleConfirm(SelectedData);
    }

    React.useEffect(() => {
        setSelected("");
    }, [props.open]);

    useEffect(() => {

        if (isSending) {
            setIsLoading(true);
            const token = GetToken(localStorage.getItem("token"));
            const ax = axios;
            ax.defaults.headers.common['Authorization'] = "Bearer " + token;

            (async () => {
                let result = [];
                try {
                    let url = process.env.REACT_APP_API_URL + 'serviziosalist'
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    const fd = new FormData();
                    fd.append("Descrizione", RicDescrizione);

                    result = await ax.post(url, fd, axiosConfig);
                }
                catch (error) {
                    let msg = ""
                    if (error.response !== undefined) {
                        msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
                    }
                    else {
                        msg = GetMessage(undefined, "");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);
                if (result.data !== undefined) {
                    setData(result.data);
                }
                else {
                    setData([]);
                }
                setIsSending(false);
            })();
        }

    }, []);


    const isSelected = (name) => selected === name;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
        >
            <DialogTitle id="responsive-dialog-title">
                <div className="small" style={{ width: "98%" }}>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <Label bsSize="sm" for="selRicDescrizione">Descrizione</Label>
                                <Input type="search" className="search" name="txtRicDescrizione" placeholder=""
                                    value={RicDescrizione} onChange={handleRicDescrizione}
                                    bsSize="sm" />
                                <img style={{ height: "15pt" }} src={imgSearch} className="input-icon" alt="" />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <Label bsSize="sm">&nbsp;</Label>
                                <Button type="button" className="btn-mbg-cert btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </DialogTitle>
            <DialogContent className={classes.FormPopup} >
                <DialogContentText>
                    <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert} />
                    {isLoading ? (<CircularProgress className="sp-center" />) : (<span></span>)}
                    <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="grid-head" align="center">Descrizione</TableCell>
                                    <TableCell className="grid-head" align="center">Prezzo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length > 0 ? (
                                    data.map((row) => {
                                        const isItemSelected = isSelected(row.id);

                                        return (

                                            <TableRow key={row.id} hover tabIndex={-1} className={isItemSelected ? 'row-selected' : ''} onClick={(event) => handleRowClick(row.id, row.Descrizione, row.Prezzo)}>
                                                <TableCell>{row.Descrizione}</TableCell>
                                                <TableCell align="right">{row.Prezzo}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3} style={{ textAlign: "center" }}><h4>- Nessun Servizio Presente -</h4></TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div style={{ width: "30%" }}>
                    <Button onClick={onConfirm} className="btn-mbg-cert btn-block" disabled={selected === ""}>
                        Conferma
                    </Button>
                </div>
                <div style={{ width: "30%" }}>
                    <Button onClick={props.handleClose} className="btn-block">
                        Annulla
                    </Button>

                </div>
            </DialogActions>
        </Dialog>
    );
}

function CreateTableServizi(props) {
    const classes = useStyles();
    const [Rows, setRows] = React.useState([]);
    const [PercSconto, setPercSconto] = React.useState(0);
    const [PercIva, setPercIva] = React.useState(0);
    const [PercIvaSpesa, setPercIvaSpesa] = React.useState(0);
    const [CostoScontato, setCostoScontato] = React.useState(0);
    const [CostoImponibile, setCostoImponibile] = React.useState(0);
    const [SpeseKm, setSpeseKm] = React.useState(0);
    const [NumKm, setNumKm] = React.useState(0);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [CostoSpesa, setCostoSpesa] = React.useState(0);
    const [DescrizioneSpesa, setDescrizioneSpesa] = React.useState("");
    const [IDSpesa, setIDSpesa] = React.useState(0);
    const [CostoIva, setCostoIva] = React.useState(0);
    const [CostoIvaSpesa, setCostoIvaSpesa] = React.useState(0);
    const [ApplicaSconto, setApplicaSconto] = React.useState(0);
    const [VisualizzaCheckSconto, setVisualizzaCheckSconto] = React.useState(false);

    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert, setMsgAlert] = React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const handleTipoSpesa = (value,text) => {
        if (value!==""){
            var arrValue = value.split("|");
            setDescrizioneSpesa(text);  
            CalcolaPrezzi(PercSconto,PercIva,NumKm,arrValue[1],PercIvaSpesa);
            props.onChangeCostoSpesa(arrValue[1],arrValue[0]);
        }
        else
        {
            setDescrizioneSpesa("");  
            CalcolaPrezzi(PercSconto,PercIva,NumKm,0,PercIvaSpesa);
            props.onChangeCostoSpesa(0,0);
        }
    }; 
    const handleClickAltro = () => {
        setVisualizzaCheckSconto(!VisualizzaCheckSconto);
    }
    const handleApplicaSconto = (event) => {
        setApplicaSconto(!ApplicaSconto);
        setPercSconto(0);
        CalcolaPrezzi(0, PercIva, NumKm, CostoSpesa,PercIvaSpesa);
        props.onChangeSconto(0);
    }
    const handlePercSconto = (event) => {
        if (parseFloat(event.target.value) > 100) {
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
            event.target.value = 0;

        }
        let _PercSconto = parseFloat(event.target.value);
        if (isNaN(_PercSconto)) _PercSconto = 0;
        setPercSconto(_PercSconto);
        CalcolaPrezzi(_PercSconto, PercIva, NumKm, CostoSpesa,PercIvaSpesa);
        props.onChangeSconto(_PercSconto);

    }
    const handleFocusPercSconto = (event) => {
        event.target.select();
    }
    const handlePercIva = (event) => {
        if (parseFloat(event.target.value) > 100) {
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
            event.target.value = 0;

        }
        let _PercIva = parseFloat(event.target.value);
        if (isNaN(_PercIva)) _PercIva = 0;
        setPercIva(_PercIva);
        CalcolaPrezzi(PercSconto, _PercIva, NumKm, CostoSpesa,PercIvaSpesa);
        props.onChangeIva(_PercIva);

    }
    const handlePercIvaSpesa = (event) => {
        if (parseFloat(event.target.value) > 100) {
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
            event.target.value = 0;

        }
        let _PercIvaSpesa = parseFloat(event.target.value);
        if (isNaN(_PercIvaSpesa)) _PercIvaSpesa = 0;
        setPercIvaSpesa(_PercIvaSpesa);
        CalcolaPrezzi(PercSconto, PercIva, NumKm, CostoSpesa, _PercIvaSpesa);
        props.onChangeIvaSpesa(_PercIvaSpesa);

    }
    const handleNumKm = (event) => {
        if (parseFloat(event.target.value) > 10000) {
            setMsgAlert("Valore massimo: 10000");
            setOpenAlert(true);
            event.target.value = 0;

        }
        let _NumKm = parseFloat(event.target.value);
        if (isNaN(_NumKm)) _NumKm = 0;
        setNumKm(_NumKm);
        CalcolaPrezzi(PercSconto, PercIva, _NumKm, CostoSpesa, PercIvaSpesa);
        props.onChangeNumKm(_NumKm);

    };

    const handleFocusPercIva = (event) => {
        event.target.select();
    };
    const handleFocusPercIvaSpesa = (event) => {
        event.target.select();
    };
    const handleFocusNumKm = (event) => {
        event.target.select();
    };

    const handleQuantita = (_num, _id) => {
        if (props.rows.length > 0) {
            props.rows.map((item) => {
                if (item.id === _id) {
                    item.Quantita = _num;
                    props.onChangeQuantita();
                }
                return true;
            });
        }
        CalcolaPrezzi(PercSconto, PercIva, NumKm, CostoSpesa, PercIvaSpesa);
    }
    const CalcolaPrezzi = (_PercSconto, _PercIva, _NumKm, _CostoSpesa, _PercIvaSpesa) => {
        let newCostoImponibile = 0;
        let newCostoScontato = 0;
        let newCostoSpesa=parseFloat(_CostoSpesa);
        let newCostoImpianto = parseFloat(props.CostoImpianto);
        let newCostoImpiantoExp = parseFloat(props.CostoImpiantoExp);
        let newCostoSpeseKm = _NumKm * props.CostoPerKm;
        let newCostoTotale = 0;
        let newCostoIvaSpesa = 0;
        let newCostoIva = 0;
        if (props.rows.length > 0) {
            props.rows.map((item) => {
                newCostoImponibile += (parseFloat(item.Prezzo) * parseFloat(item.Quantita));
                return newCostoImponibile;
            });
        }

        newCostoScontato = ((newCostoImponibile - (newCostoImponibile * (_PercSconto / 100))) + (newCostoImpianto - (newCostoImpianto * (_PercSconto / 100))) + (newCostoImpiantoExp - (newCostoImpiantoExp * (_PercSconto / 100))));

        newCostoScontato = parseFloat(newCostoScontato.toFixed(2));
        newCostoIva = newCostoScontato * (parseFloat(_PercIva) / 100);
        newCostoIva = parseFloat(newCostoIva.toFixed(2));
        newCostoSpesa=parseFloat(newCostoSpesa.toFixed(2));
        newCostoSpeseKm = parseFloat(newCostoSpeseKm.toFixed(2));
        newCostoIvaSpesa = (newCostoSpesa + newCostoSpeseKm) * (parseFloat(_PercIvaSpesa) / 100);
        newCostoIvaSpesa = parseFloat(newCostoIvaSpesa.toFixed(2));
        newCostoTotale = newCostoIva + newCostoScontato + newCostoSpeseKm  + newCostoSpesa + newCostoIvaSpesa;
        setCostoImponibile(newCostoImponibile);
        setSpeseKm(newCostoSpeseKm);
        setCostoSpesa(newCostoSpesa);
        setCostoScontato(newCostoScontato);
        setCostoIva(newCostoIva);
        setCostoIvaSpesa(newCostoIvaSpesa);
        setCostoTotale(newCostoTotale);
        props.onChangeCostoTotale(newCostoTotale);
    }
    React.useEffect(() => {
        setRows(props.rows);
        //alert("state rows");
        CalcolaPrezzi(PercSconto, PercIva, NumKm, CostoSpesa, PercIvaSpesa);
    }, [props.rows]);

    React.useEffect(() => {
        CalcolaPrezzi(PercSconto, PercIva, NumKm, CostoSpesa, PercIvaSpesa);
    }, [props.onRicalcola]);

    React.useEffect(() => {
        CalcolaPrezzi(PercSconto,PercIva,NumKm, CostoSpesa, PercIvaSpesa);
    }, [props.CostoImpianto]);
    
    React.useEffect(() => {
        CalcolaPrezzi(PercSconto,PercIva,NumKm, CostoSpesa, PercIvaSpesa);
    }, [props.CostoImpiantoExp]);

    React.useEffect(() => {
        setCostoSpesa(props.CostoSpesa);
        CalcolaPrezzi(PercSconto, PercIva, NumKm, props.CostoSpesa, PercIvaSpesa);
    }, [props.CostoSpesa]);

    React.useEffect(() => {
        setIDSpesa(props.IDSpesa);
    }, [props.IDSpesa]);

    React.useEffect(() => {
        setDescrizioneSpesa(props.DescrizioneSpesa);
    }, [props.DescrizioneSpesa]);

    React.useEffect(() => {
        if (parseFloat(props.setSconto) > 0) {
            handleClickAltro();
            setApplicaSconto(!ApplicaSconto);
        }
        setPercSconto(props.setSconto);
        CalcolaPrezzi(props.setSconto, PercIva, NumKm, CostoSpesa, PercIvaSpesa);
    }, [props.setSconto]);

    React.useEffect(() => {
        setPercIva(props.setIva);
        CalcolaPrezzi(PercSconto, props.setIva, NumKm, CostoSpesa, PercIvaSpesa);
    }, [props.setIva]);
    React.useEffect(() => {
        setPercIvaSpesa(props.setIvaSpesa);
        CalcolaPrezzi(PercSconto, PercIva, NumKm, CostoSpesa, props.setIvaSpesa);
    }, [props.setIvaSpesa]);
    React.useEffect(() => {
        setNumKm(props.setNumKm);
        CalcolaPrezzi(PercSconto, PercIva, props.setNumKm, CostoSpesa, PercIvaSpesa);
    }, [props.setNumKm]);

    return (

        <TableContainer component={Paper}>
            <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert} />
            <Table className={classes.table} aria-label="spanning table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className="grid-head"></TableCell>
                        <TableCell className="grid-head">Descrizione</TableCell>
                        <TableCell className="grid-head" align="right">Prezzo</TableCell>
                        <TableCell className="grid-head">Q.ta</TableCell>
                        <TableCell className="grid-head" align="right">Importo</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {Rows.length > 0 ? (Rows.map((row) => {
                        return (
                            <TableRow key={row.id} className="rt-tr-group">
                                <TableCell>
                                    {
                                        (props.isAdmin === true || props.isInviato === 0) && (
                                            <Avatar className={"btn-mbg-cert " + classes.BtnEdit} onClick={() => props.onDelete(row.id)}>
                                                <DeleteIcon />
                                            </Avatar>
                                        )
                                    }
                                </TableCell>
                                <TableCell>{row.Descrizione}</TableCell>
                                <TableCell align="right">{row.Prezzo}</TableCell>
                                <TableCell>
                                    {
                                        (props.isAdmin === true || props.isInviato === 0) ?
                                            (<MyNumInput otherparam={row.id} value={row.Quantita} onChange={handleQuantita} />) : (row.Quantita)
                                    }
                                </TableCell>
                                <TableCell align="right">{ccyFormat(parseFloat(row.Prezzo) * parseFloat(row.Quantita))}</TableCell>
                            </TableRow>
                        );
                    }
                    )) : (
                        <TableRow>
                            <TableCell colSpan={5} style={{ textAlign: "center" }}><h5>- Nessun Servizio di Scariche Atmosferiche Selezionato -</h5></TableCell>
                        </TableRow>
                    )}
                    <TableRow className="rt-tr-group">
                        <TableCell colSpan={5}>&nbsp;</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell colSpan={2} style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)" }}><b>Totale Scariche Atmosferiche</b></TableCell>
                        <TableCell align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(parseFloat(CostoImponibile))}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell colSpan={2} style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)" }}><b>Totale Verifica Impianto Messa a Terra</b></TableCell>
                        <TableCell align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(parseFloat(props.CostoImpianto))}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell colSpan={2} style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
                            <b>Totale Verifica Maggior Rischio Esplosione</b>
                        </TableCell>
                        <TableCell align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(parseFloat(props.CostoImpiantoExp))}</b></TableCell>
                    </TableRow>
                    {ApplicaSconto ? (
                        <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)", display: "flex" }}>
                                <span style={{ paddingTop: "4px", width: "200px" }}><b>Sconto</b></span>
                                <Input type="number" name="txtSconto" min="0" max="99" autoComplete="new-password"
                                    onChange={handlePercSconto} value={PercSconto}
                                    onFocus={handleFocusPercSconto}
                                    disabled={props.isInviato===1} readOnly={props.isInviato===1}
                                    style={{ width: "90px" }} bsSize="sm"
                                />
                                <span style={{ paddingTop: "4px" }}>  <b>&nbsp;&nbsp;%</b></span>
                            </TableCell>
                            <TableCell colSpan={2} align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(CostoScontato)}</b></TableCell>
                        </TableRow>) : ('')}
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)", display: "flex" }}>
                            <span style={{ paddingTop: "4px", width: "200px" }}><b>IVA</b></span>
                            <Input type="number" name="txtIva" min="0" max="99" autoComplete="new-password"
                                onChange={handlePercIva} value={PercIva}
                                onFocus={handleFocusPercIva}
                                style={{ width: "90px" }} bsSize="sm"
                                disabled={props.isInviato===1} readOnly={props.isInviato===1}
                            />
                            <span style={{ paddingTop: "4px" }}>  <b>&nbsp;&nbsp;%</b></span>
                        </TableCell>
                        <TableCell colSpan={2} style={{ background: "#deeaf6" }} align="right"><b>{ccyFormat(CostoIva)}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)", display: "flex" }}>
                            <span style={{ paddingTop: "4px", width: "200px" }}><b>Rimborso Km {props.CostoPerKm} &euro;</b></span>
                            <Input type="number" name="txtNumKm" min="0" max="9999" autoComplete="new-password"
                                onChange={handleNumKm} value={NumKm}
                                onFocus={handleFocusNumKm}
                                style={{ width: "90px" }} bsSize="sm"
                                disabled={props.isInviato===1} readOnly={props.isInviato===1}
                            />
                            <span style={{ paddingTop: "4px" }}>  <b>&nbsp;&nbsp;Km</b></span>
                        </TableCell>
                        <TableCell colSpan={2} style={{ background: "#deeaf6" }} align="right"><b>{ccyFormat(SpeseKm)}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}/>
                        <TableCell style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}>
                            <MyAutoComplete id="txtTipoSpesa" name="txtTipoSpesa" 
                                url={process.env.REACT_APP_API_URL+'persspeseordinisinglelist'} 
                                optionname="Descrizione" optionvalue="Codice"
                                customvalue={DescrizioneSpesa} onChange={handleTipoSpesa}
                                placeholder="Nessuna Spesa selezionata"
                                disabled={props.isInviato===1}
                            /> 
                        </TableCell>
                        <TableCell colSpan={2} style={{background:"#deeaf6"}} align="right"><b>{ccyFormat(CostoSpesa)}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)", display: "flex" }}>
                            <span style={{ paddingTop: "4px", width: "200px" }}><b>IVA Spese</b></span>
                            <Input type="number" name="txtIvaSpese" min="0" max="99" autoComplete="new-password"
                                onChange={handlePercIvaSpesa} value={PercIvaSpesa}
                                onFocus={handleFocusPercIvaSpesa}
                                style={{ width: "90px" }} bsSize="sm"
                                disabled={props.isInviato===1} readOnly={props.isInviato===1}
                            />
                            <span style={{ paddingTop: "4px" }}>  <b>&nbsp;&nbsp;%</b></span>
                        </TableCell>
                        <TableCell colSpan={2} style={{ background: "#deeaf6" }} align="right"><b>{ccyFormat(CostoIvaSpesa)}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <div style={{ display: "flex" }}>
                                <Button className="btn-mbg-cert btn-small" onClick={handleClickAltro}>Altro {VisualizzaCheckSconto ? ('<<') : ('>>')}</Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                {VisualizzaCheckSconto ? (
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" name="chkApplicaSconto" onChange={handleApplicaSconto} disabled={props.isInviato===1} checked={ApplicaSconto > 0} />{' '}
                                            Applica Sconto
                                        </Label>
                                    </FormGroup>
                                ) : ('')}
                            </div>
                        </TableCell>
                        <TableCell colSpan={2} style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)" }}><b>Totale &euro;</b></TableCell>
                        <TableCell align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(CostoTotale)}</b></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default function ModPreventivo(props) {
    const classes = useStyles();
    const MsgAlertDefault = "Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    const [OpenConfirmInvioPreventivo, setOpenConfirmInvioPreventivo] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [OpenAlertSelServizio, setOpenAlertSelServizio] = React.useState(false);
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [MsgAlert, setMsgAlert] = React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const onCloseAlertSelServizio = (event) => {
        setOpenAlertSelServizio(false);
    };
    //------------
    const [CloseForm, setCloseForm] = React.useState(false);
    const [ChangeServizi, setChangeServizi] = React.useState(false);
    const [NumPreventivo, setNumPreventivo] = React.useState("");
    const [DataPreventivo, setDataPreventivo] = React.useState("");
    const [DesCommerciale, setDesCommerciale] = React.useState("");
    const [IdCommerciale, setIdCommerciale] = React.useState("");
    const [Approva, setApprova] = React.useState("");
    const [Inviato, setInviato] = React.useState("");
    const [ApprovatoOld, setApprovatoOld] = React.useState("");
    const [Annulla, setAnnulla] = React.useState("");
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [PartitaIva, setPartitaIva] = React.useState("");    
    const [IndirizzoAzienda, setIndirizzoAzienda] = React.useState("");
    const [ComuneAzienda, setComuneAzienda] = React.useState("");
    const [CapAzienda, setCapAzienda] = React.useState("");
    const [CodiceUnivocoAzienda, setCodiceUnivocoAzienda] = React.useState("");
    const [Sconto, setSconto] = React.useState(0);
    const [CostoSpesa, setCostoSpesa] = React.useState(0);
    const [IDSpesa, setIDSpesa] = React.useState(0);
    const [DescrizioneSpesa, setDescrizioneSpesa] = React.useState("");
    const [CodiceIva, setCodiceIva] = React.useState(0);
    const [CodiceIvaSpesa, setCodiceIvaSpesa] = React.useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [NumKm, setNumKm] = React.useState(0);
    const [DescrizioneImpianto, setDescrizioneImpianto] = React.useState("");
    const [CostoImpianto, setCostoImpianto] = React.useState(0);
    const [CostoImpiantoExp, setCostoImpiantoExp] = React.useState(0);
    const [VerificaExp, setVerificaExp] = React.useState(0);
    const [VerificaMt, setVerificaMt] = React.useState(0);
    const [CostoPerKm, setCostoPerKm] = React.useState(0);
    const [CifraAcconto, setCifraAcconto] = React.useState(0);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [Ricalcola, setRicalcola] = React.useState(false);
    const [RowsServizi, setRowsServizi] = React.useState([]);
    const [Note, setNote] = React.useState("");
    const [OpenSelServizio, setOpenSelServizio] = React.useState(false);
    const [Telefono, setTelefono] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    const [NumCabine, setNumCabine] = React.useState("");
    const [Superficie, setSuperficie] = React.useState("");
    const [Indirizzo, setIndirizzo] = React.useState("");
    const [Comune, setComune] = React.useState("");
    const [CodComune, setCodComune] = React.useState("");
    const [DescrizioneAttivita, setDescrizioneAttivita] = React.useState("");
    const [Tensione, setTensione] = React.useState("");
    const [SistDistribuzione, setSistDistribuzione] = React.useState("");
    const [PotenzaKw, setPotenzaKw] = React.useState("");
    const [AnniValidita, setAnniValidita] = React.useState("");
    const [IDPersVerifica, setIDPersVerifica] = React.useState("");
    const [IDTipoAmbiente, setIDTipoAmbiente] = React.useState("");
    const [TipoAmbiente, setTipoAmbiente] = React.useState("");
    const [IDImpianto, setIDImpianto] = React.useState(0);
    //confirm object
    const [MsgConfirmDelServizio, setMsgConfirmDelServizio] = React.useState("Si desidera eliminare il servizio dalla lista dei selezionati?");
    const [OpenConfirmDelServizio, setOpenConfirmDelServizio] = React.useState(false);
    const [OpenConfirmSubmit, setOpenConfirmSubmit] = React.useState(false);
    const [IdConfirmDelServizio, setIdConfirmDelServizio] = React.useState(false);
    const [forceSconto, setforceSconto] = React.useState("0");
    const [forceIva, setforceIva] = React.useState("0");
    const [forceIvaSpesa, setforceIvaSpesa] = React.useState("0");
    const [forceNumKm, setforceNumKm] = React.useState("0");
    const onCloseConfirmDelServizio = (event) => {
        setOpenConfirmDelServizio(false);
    };
    const toggleDelServizio = (_Id) => {
        setIdConfirmDelServizio(_Id);
        setOpenConfirmDelServizio(true);
    };
    const handleNote = (event) => {
        setNote(event.target.value);
    };
    const onChangeQuantita = () => {
        setChangeServizi(true);
    }

    const ChangeCostoTotale = (_value) => {
        setCostoTotale(_value);
        ControllaValoreAcconto(_value, CifraAcconto);
    };

    const ControllaValoreAcconto = (_costo, _acconto) => {

        if (parseFloat(_acconto) > parseFloat(_costo)) {
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("L'acconto supera il totale da pagare verrà azzerato!");
            setCifraAcconto(0);
            setOpenAlert(true);
        }
    }
    const onDelServizio = (event) => {
        setOpenConfirmDelServizio(false);

        let newRows = RowsServizi;
        let index = -1;
        let indexSel = -1;

        if (newRows.length > 0) {
            newRows.map((item) => {
                index += 1;
                if (item.id === IdConfirmDelServizio) {
                    indexSel = index;
                }
                return indexSel;
            });
        }

        if (indexSel !== -1) {
            newRows.splice(indexSel, 1);
            setRowsServizi(newRows);
            setRicalcola(!Ricalcola);
            setChangeServizi(true);
        }

    };
    const handleOpenSelServizio = (event) => {
        setOpenSelServizio(true);
    };

    const CloseSelServizio = (event) => {
        setOpenSelServizio(false);
    };

    const onConfirmSelServizio = (RisArray) => {

        let newArray;


        let id = RisArray[0].id;

        let res = false;

        if (RowsServizi.length > 0) {
            RowsServizi.map(function (item) {
                if (item.id === id) {
                    res = true;
                    return true;
                }
                return res;
            });
        }


        if (!res) {
            if (RowsServizi.length > 0) {
                newArray = RowsServizi.concat(RisArray);
            }
            else {
                newArray = RisArray;
            }
            //newArray.push(RisArray)
            setRowsServizi(newArray);
            setChangeServizi(true);
            setOpenSelServizio(false);
        }
        else {
            setMsgAlert("Servizio già selezionato, per aumentare il quantitativo incrementare il campo quantità!");
            setOpenAlertSelServizio(true);

        }
    };

    const handleAnnulla = (event) => {
        setAnnulla(!Annulla);
    };
    const handleCommerciale = (value, text) => {
        setIdCommerciale(value);
        setDesCommerciale(text);
    };
    const handleApprova = (event) => {
        setApprova(!Approva);
    };

    const ChangeSconto = (_value) => {
        setSconto(_value);
    };
    const ChangeCostoSpesa = (_value,_IDSpesa) => {
        setCostoSpesa(_value);
        setIDSpesa(_IDSpesa); 
    };
    const ChangeIva = (_value) => {
        setCodiceIva(_value);
    };
    const ChangeIvaSpesa = (_value) => {
        setCodiceIvaSpesa(_value);
    };
    const ChangeNumKm = (_value) => {
        setNumKm(_value);
    };

    const handleTipoAmbiente = (value,text) => {
        var arrValue = value.split("|");
        setIDTipoAmbiente(arrValue[0]);
        setAnniValidita(arrValue[1]);
        setIDPersVerifica(arrValue[2]);
        setTipoAmbiente(text);  
    }; 

    const handleDescrizioneAttivita = (event) => {  
        setDescrizioneAttivita(event.target.value);  
    }; 
    const handlePotenzaKw = (event) => { 
         
        setPotenzaKw(event.target.value);  
        if (VerificaExp>0 || VerificaMt>0){
            (async () => { 
                setIsLoading(true);
                await LoadCostoImpianto(event.target.value,VerificaMt,VerificaExp);
                setIsLoading(false);
            })(); 
        }
    }; 
    const handleIndirizzo = (event) => {  
        setIndirizzo(event.target.value);  
    }; 
    const handleSistDistribuzione = (event) => {  
        setSistDistribuzione(event.target.value);  
    }; 
    const handleComune = (value,text) => {
        setCodComune(value);
        setComune(text);  
    }; 
    const handleTensione = (event) => {  
        setTensione(event.target.value);  
    }; 
    const handleNumCabine = (event) => {  
        setNumCabine(event.target.value);  
    }; 
    const handleSuperficie = (event) => {  
        setSuperficie(event.target.value);  
    };   
    const handleVerificaMt= (event) =>{
        setVerificaMt(event.target.checked);
        if (event.target.checked){
            (async () => { 
                setIsLoading(true);
                await LoadCostoImpianto(PotenzaKw,event.target.checked,VerificaExp);
                setIsLoading(false);
            })(); 
        }
        else {
            setCostoImpianto(0);
        }
    };
    const handleVerificaExp= (event) =>{
        setVerificaExp(event.target.checked);
        if (event.target.checked){
            (async () => { 
                setIsLoading(true);
                await LoadCostoImpianto(PotenzaKw,VerificaMt,event.target.checked);
                setIsLoading(false);
            })(); 
        }
        else {
            setCostoImpiantoExp(0);
        }
    };
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
        },
        validators: {
            cphone: {  // name the rule
                //message: 'obbligatorio o non corretto',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
                },
                //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                //required: true  // optional
            }
        }
    }));

    React.useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (localStorage.getItem("idpreventivo") !== "") {
                await LoadDatiPreventivo();
                await LoadServiziPreventivo();
            }
            if (localStorage.getItem("idazienda") !== "") {
                await LoadDatiAzienda();
            }
            setIsLoading(false);
        })();
    }, []);

    
    async function LoadDatiAzienda () {
            const token = GetToken(localStorage.getItem("token"));
        const ax = axios;
        ax.defaults.headers.common['Authorization'] = "Bearer " + token;
        let result = [];
        try {
            let url = process.env.REACT_APP_API_URL + 'customer/' + localStorage.getItem("idazienda");
            result = await ax.post(url);
            setRagioneSociale(result.data[0].RagioneSociale);
            setEmail(result.data[0].Email);
            setPartitaIva(result.data[0].PartitaIva);
            setCodiceUnivocoAzienda(result.data[0].CodUnivoco);
            setCapAzienda(result.data[0].CapSL);
            setComuneAzienda(result.data[0].DesComuneSL);
            setIndirizzoAzienda(result.data[0].IndirizzoSL);
            setTelefono(result.data[0].TelefonoSL);
            setCellulare(result.data[0].CellulareReferente);
        }
        catch (error) {
            let msg = ""
            if (error.response !== undefined) {
                msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
            }
            else {
                msg = GetMessage(undefined, "");
            }
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msg);
            setOpenAlert(true);
        }
    }

    async function LoadDatiPreventivo () {
        const token = GetToken(localStorage.getItem("token"));
        const ax = axios;
        ax.defaults.headers.common['Authorization'] = "Bearer " + token;
        let result = [];
        try {
            let url = process.env.REACT_APP_API_URL + 'preventivocert/' + localStorage.getItem("idpreventivo");
            result = await ax.post(url);
            setNumPreventivo(result.data[0].NumPreventivo);
            setDataPreventivo(result.data[0].DataPreventivo);
            setPotenzaKw(result.data[0].PotenzaKw);
            setCostoPerKm(result.data[0].CostoPerKm);
            setCostoImpianto(result.data[0].CostoImpianto);
            setCostoImpiantoExp(result.data[0].CostoImpiantoExp);
            setVerificaExp(result.data[0].VerificaExp);
            setVerificaMt(result.data[0].VerificaMt);
            setInviato(result.data[0].Inviato);
            setAnnulla(result.data[0].Annullato);
            setApprova(result.data[0].Approvato);
            setApprovatoOld(result.data[0].Approvato);
            setCodiceIva(result.data[0].CodiceIva);
            setCodiceIvaSpesa(result.data[0].CodiceIvaSpesa);
            setDesCommerciale(result.data[0].Nome + " " + result.data[0].Cognome);
            setIdCommerciale(result.data[0].Commerciale_id);
            ChangeSconto(result.data[0].Sconto);
            ChangeIva(result.data[0].CodiceIva);
            ChangeIvaSpesa(result.data[0].CodiceIvaSpesa);
            ChangeNumKm(result.data[0].NumKm);

            setNote(result.data[0].Note);
            if (result.data[0].DescImpianto !== null) {
                setDescrizioneImpianto(result.data[0].DescImpianto);
            }
            else {
                setDescrizioneImpianto("");
            }
            setforceSconto(String(result.data[0].Sconto));
            setforceIva(String(result.data[0].CodiceIva));
            setforceIvaSpesa(String(result.data[0].CodiceIvaSpesa));
            setforceNumKm(String(result.data[0].NumKm));
            setIndirizzo(result.data[0].Indirizzo);
            setCodComune(result.data[0].CodComune);
            setComune(result.data[0].DesComune);
            setSuperficie(result.data[0].Superficie);
            setSistDistribuzione(result.data[0].SistemaDistribuzione);
            setTensione(result.data[0].TensioneAlimentazione);
            setNumCabine(result.data[0].NumeroCabine);
            setDescrizioneAttivita(result.data[0].DescrizioneAttivita);
            setSuperficie(result.data[0].SuperficieLuogo);
            setIDImpianto(result.data[0].Impianto_id);
            setIDTipoAmbiente(result.data[0].TipoAmbiente_id);
            setTipoAmbiente(result.data[0].DescAmbiente);
            setAnniValidita(result.data[0].AnniValidita);
            setIDPersVerifica(result.data[0].PersVerifica_id);
            setIDSpesa(result.data[0].Spesa_id);
            setCostoSpesa(result.data[0].CostoSpesa);
            setDescrizioneSpesa(result.data[0].DescrizioneSpesa);
        }
        catch (error) {
            let msg = ""
            if (error.response !== undefined) {
                msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
            }
            else {
                msg = GetMessage(undefined, "");
            }
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msg);
            setOpenAlert(true);
        }
    }
    async function LoadCostoImpianto(_PotenzaKw,_verificaMt,_verificaExp) {
        const token =  GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        let result=[];
        if (parseFloat(_PotenzaKw)>0){
            try{

                let url=process.env.REACT_APP_API_URL+'preventivocertcostobypotenza';
                const fd = new FormData();
                fd.append("PotenzaKw", _PotenzaKw);
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                result = await ax.post(url,fd,axiosConfig);
                if (_verificaMt>0) {
                    setCostoImpianto(result.data[0].CostoMessaTerra);
                }
                else {
                    setCostoImpianto(0);
                }
                if (_verificaExp>0) {
                    setCostoImpiantoExp(result.data[0].CostoRischioEsplosione);
                }
                else {
                    setCostoImpiantoExp(0);
                }
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning")
                setMsgAlert(msg);
                setVerticalAlert("center");
                setOpenAlert(true);
    
            }    
        }
    }
    async function LoadServiziPreventivo ()  {
        const token = GetToken(localStorage.getItem("token"));
        const ax = axios;
        ax.defaults.headers.common['Authorization'] = "Bearer " + token;
        let result = [];
        try {
            let url = process.env.REACT_APP_API_URL + 'servizisapreventivolist/' + localStorage.getItem("idpreventivo");
            result = await ax.post(url);
            setRowsServizi(result.data);
        }
        catch (error) {
            let msg = ""
            if (error.response !== undefined) {
                msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
            }
            else {
                msg = GetMessage(undefined, "");
            }
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msg);
            setOpenAlert(true);
        }       
    }

    const onCloseForm = (event) => {
        setCloseForm(true);
    };

    const onConfirmInvioPreventivo = (event) => {
        setOpenConfirmInvioPreventivo(false);
        onSubmitForm(true);
    };
    const onCloseConfirmInvioPreventivo = (event) => {
        setOpenConfirmInvioPreventivo(false);
        onSubmitForm(false);
    };

    const handleOpenConfirmInvioPreventivo = (event) => {
        
        let datiOk=validator.current.allValid();
        if ( datiOk && (RowsServizi.length>0 || VerificaExp>0 || VerificaMt>0)) {
            if (Inviato===0 && parseInt(Annulla)===0) 
            {
                setOpenConfirmInvioPreventivo(true);
            }
            else {
                onSubmitForm(false);
            }
        } else {
            if (RowsServizi.length>0 || VerificaExp>0 || VerificaMt>0){
                validator.current.showMessages();
                setMsgAlert(MsgAlertDefault);        
                setSeverityAlert("error")
                setVerticalAlert("top")
                
                setOpenAlert(true);
            }
            else
            {
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert("Selezionare almeno un servizio!");
                setOpenAlert(true);
            }
        }
    };

    const onSubmitForm = (_invioPreventivo) => {

        setisSubmit(true);
        const intApprovato = Approva ? 1 : 0;
        const intAnnullato = Annulla ? 1 : 0;
        const fd = new FormData();
        fd.append("id", localStorage.getItem("idpreventivo"));
        fd.append("Azienda_id", localStorage.getItem("idazienda"));
        fd.append("Commerciale_id", IdCommerciale);
        fd.append("Impianto_id", IDImpianto);
        fd.append("Spesa_id", IDSpesa); 
        fd.append("CostoSpesa", CostoSpesa); 
        fd.append("Sconto", Sconto);
        fd.append("CostoPerKm", CostoPerKm);
        fd.append("NumPreventivo", NumPreventivo);
        fd.append("NumKm", NumKm);
        fd.append("PotenzaKw", PotenzaKw);
        fd.append("CostoImpianto", CostoImpianto);
        fd.append("CostoImpiantoExp", CostoImpiantoExp);
        fd.append("VerificaExp", VerificaExp);
        fd.append("VerificaMt", VerificaMt);
        fd.append("DescImpianto", DescrizioneImpianto);
        fd.append("CodiceIva", CodiceIva);
        fd.append("CodiceIvaSpesa", CodiceIvaSpesa);
        fd.append("Approvato", intApprovato);
        fd.append("Annullato", intAnnullato);
        fd.append("Indirizzo", Indirizzo);
        if (CodComune!=null){
            fd.append("CodComune", CodComune);    
        }
        else {
            fd.append("CodComune", 0);
        }
        fd.append("DesComune", Comune);
        fd.append("Superficie", Superficie);
        fd.append("SistemaDistribuzione", SistDistribuzione);
        fd.append("TensioneAlimentazione", Tensione);
        fd.append("NumeroCabine", NumCabine);
        fd.append("CostoPerKm", process.env.REACT_APP_COSTOKM);
        fd.append("DescrizioneAttivita", DescrizioneAttivita);
        fd.append("SuperficieLuogo", Superficie);
        fd.append("NumKm", NumKm);
        fd.append("CostoImpianto", CostoImpianto);
        fd.append("CostoImpiantoExp", CostoImpiantoExp);
        fd.append("PotenzaKw", PotenzaKw);
        if (VerificaExp){
            fd.append("VerificaExp", 1);
        } 
        else {
            fd.append("VerificaExp", 0);
        }
        if (VerificaMt){
            fd.append("VerificaMt", 1);
        }
        else {
            fd.append("VerificaMt", 0);
        }

        if (_invioPreventivo){
            fd.append("Inviato",1)
        }
        else {
            fd.append("Inviato",Inviato)
        }
        fd.append("TipoAmbiente_id", IDTipoAmbiente);
        fd.append("PersVerifica_id", IDPersVerifica);
        fd.append("Note", Note);
        if (ChangeServizi) {
            let ParamServizi = "";
            ParamServizi = RowsServizi.map(function (item) {
                return item.id + "|" + item.Quantita;
            });
            fd.append("ParamServizi", ParamServizi);
        }

        const token = GetToken(localStorage.getItem("token"));
        const ax = axios;
        ax.defaults.headers.common['Authorization'] = "Bearer " + token;
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        ax
            .post(process.env.REACT_APP_API_URL + 'preventivocertupdate', fd, axiosConfig)
            .then((response) => {

                if (response.status === 200) {
                    if (response.data.error === "OK") {
                        setCloseForm(true);
                    }
                    else {
                        let msg = "";
                        let ris = JSON.stringify(response.data);
                        ris = ris.replaceAll("\"", "");
                        ris = ris.replaceAll("{", "");
                        ris = ris.replaceAll("}", "");
                        ris = ris.replaceAll("[", "");
                        ris = ris.replaceAll("]", "");
                        msg = "errore durante l'inserimento! " + ris;
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    }
                }
                else {
                    const msg = GetMessage(response.status, response.data.message);
                    setSeverityAlert("error")
                    setVerticalAlert("top")
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
            })
            .catch((error) => {
                let msg = ""
                if (error.response !== undefined) {
                    msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
                }
                else {
                    msg = GetMessage(undefined, "");
                }
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
            });
        setisSubmit(false);
    }

    let UserRole = GetRole(localStorage.getItem("token"));
    let isAdmin = false;
    if (parseFloat(UserRole) === Ruolo_Admin) isAdmin = true
    let urlRedirect = "";

    if (CloseForm) {
        if (localStorage.getItem("menunew") === "1") {
            urlRedirect = "/home";
        }
        else {
            urlRedirect = "/preventivi";
        }
    }

    if (urlRedirect !== "") {
        return <Redirect to={urlRedirect} />;
    }
    let disableImpianto=(Inviato>0 || IDImpianto>0);
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className="bg-head-form-cert">
                    <IconButton edge="start" color="inherit" onClick={onCloseForm} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Dettaglio Preventivo - Servizi
                    </Typography>
                    <MatButton autoFocus color="inherit" onClick={handleOpenConfirmInvioPreventivo}>
                        Conferma {isSubmit ? (<span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>) : (<span></span>)}
                    </MatButton>
                </Toolbar>
            </AppBar>
            <div className="page-container bg-form">
                <div className="content-wrap">
                    <form autoComplete ="new-password">
                        {isLoading ? (<CircularProgress className="sp-center" />) : (<span></span>)}
                        <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert} />
                        <MyConfirmInvioPreventivo title="Conferma invio preventivo" message="Si desidera inviare al cliente il preventivo? Premere il tasto annulla per salvare il preventivo e inviarlo in seguito." handleClose={onCloseConfirmInvioPreventivo} handleConfirm={onConfirmInvioPreventivo} open={OpenConfirmInvioPreventivo} textOk="Si" textAnnulla="No"/>
                        <ElencaServizio open={OpenSelServizio} handleClose={CloseSelServizio} handleConfirm={onConfirmSelServizio} />
                        <Grid container spacing={3} className="custom-form">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label for="txtRagioneSociale">Ragione Sociale</Label>
                                                <Input type="text" name="txtRagioneSociale" autoComplete="new-password" placeholder=""
                                                value={RagioneSociale} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label for="txtEmail">Email</Label>
                                                <Input type="text" name="txtEmail" autoComplete="new-password" placeholder=""
                                                value={Email} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label for="txtImpianto">Impianto</Label>
                                                <Input type="text" name="txtImpianto" autoComplete="new-password" placeholder=""
                                                value={DescrizioneImpianto} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <Accordion>
                                                <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{backgroundColor:'#D3D3D3'}}>
                                                    <Typography ><b>Altri Dati Azienda</b></Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs={12}>
                                                        <Row>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                    <Label for="txtPartitaIva">Partita IVA</Label>
                                                                    <Input type="text" name="txtPartitaIva" autoComplete="new-password" placeholder=""
                                                                    value={PartitaIva} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                    <Label for="txtCodiceUnivoco">Cod. Univoco</Label>
                                                                    <Input type="text" name="txtCodiceUnivoco" autoComplete="new-password" placeholder=""
                                                                    value={CodiceUnivocoAzienda} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                    <Label for="txtTelefono">Telefono</Label>
                                                                    <MyPhoneInput 
                                                                        type="text" 
                                                                        name="txtTelefono" 
                                                                        autoComplete="new-password" 
                                                                        placeholder=""
                                                                        value={Telefono} 
                                                                        readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                <Label for="txtCellulare">Cellulare</Label>
                                                                    <MyPhoneInput 
                                                                        type="text" 
                                                                        name="txtCellulare" 
                                                                        autoComplete="new-password" 
                                                                        placeholder=""
                                                                        value={Cellulare} 
                                                                        readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                    <Label for="txtIndirizzo">Indirizzo</Label>
                                                                    <Input type="text" name="txtIndirizzo" autoComplete="new-password" placeholder=""
                                                                    value={IndirizzoAzienda} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={3} md={5}>
                                                                <FormGroup>
                                                                    <Label for="txtComune">Comune</Label>
                                                                    <Input type="text" name="txtComune" autoComplete="new-password" placeholder=""
                                                                    value={ComuneAzienda} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={1} md={1}>
                                                                <FormGroup>
                                                                    <Label for="txtCap">Cap</Label>
                                                                    <Input type="text" name="txtComune" autoComplete="new-password" placeholder=""
                                                                    value={CapAzienda} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            
                                                        </Row>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtDescrizioneAttivita">Tipo Attività</Label>
                                                <Input type="text" name="txtDescrizioneAttivita" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('DescrizioneAttivita')}
                                                value={DescrizioneAttivita} onChange={handleDescrizioneAttivita}
                                                readOnly={disableImpianto} disabled={disableImpianto}
                                                />
                                                {validator.current.message('DescrizioneAttivita', DescrizioneAttivita, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtTipoAmbiente">Tipo Ambiente *</Label>
                                                <MyAutoComplete id="txtTipoAmbiente" name="txtTipoAmbiente" 
                                                url={process.env.REACT_APP_API_URL+'tipoambientiimplist'} 
                                                optionname="Descrizione" optionvalue="Codice"
                                                onBlur={() => validator.current.showMessageFor('TipoAmbiente')}
                                                customvalue={TipoAmbiente} onChange={handleTipoAmbiente}
                                                readOnly={disableImpianto} disabled={disableImpianto}
                                                />
                                                {validator.current.message('TipoAmbiente', TipoAmbiente, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtPersValidita">Periodicità *</Label>
                                                <Input type="text" name="txtPersValidita" 
                                                disabled={true}
                                                autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('AnniValidita')}
                                                value={AnniValidita}
                                                />
                                                {/* <MyAutoComplete id="txtPersValidita" name="txtPersValidita" 
                                                url={process.env.REACT_APP_API_URL+'persverificheimplist'} 
                                                optionname="AnniValidita" optionvalue="id"
                                                onBlur={() => validator.current.showMessageFor('AnniValidita')}
                                                customvalue={AnniValidita} onChange={handleAnniValidita}
                                                /> */}
                                                {validator.current.message('AnniValidita', AnniValidita, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtPotenzaKw">Potenza Kw *</Label>
                                                <Input type="text" name="txtPotenzaKw" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('PotenzaKw')}
                                                value={PotenzaKw} onChange={handlePotenzaKw}
                                                readOnly={disableImpianto} disabled={disableImpianto}
                                                />
                                                {validator.current.message('PotenzaKw', PotenzaKw, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="selSistDistribuzione">Categoria *</Label>
                                                <Input type="select" name="selSistDistribuzione" id="selSistDistribuzione" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('SistDistribuzione')}
                                                value={SistDistribuzione} onChange={handleSistDistribuzione}
                                                readOnly={disableImpianto} disabled={disableImpianto}
                                                >
                                                    <option key=""></option>
                                                    <option key="0" value="0">TT</option>
                                                    <option key="1" value="1">TN</option>
                                                </Input>
                                                {validator.current.message('SistDistribuzione', SistDistribuzione, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtTensione">Tensione V *</Label>
                                                <Input type="text" name="txtTensione" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('Tensione')}
                                                value={Tensione} onChange={handleTensione}
                                                readOnly={disableImpianto} disabled={disableImpianto}
                                                />
                                                {validator.current.message('Tensione', Tensione, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtNumCabine">N. Cabine *</Label>
                                                <Input type="text" name="txtNumCabine" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('NumCabine')}
                                                value={NumCabine} onChange={handleNumCabine}
                                                readOnly={disableImpianto} disabled={disableImpianto}
                                                />
                                                {validator.current.message('NumCabine', NumCabine, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtSuperficie">Superficie *</Label>
                                                <Input type="text" name="txtSuperficie" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('Superficie')}
                                                value={Superficie} onChange={handleSuperficie}
                                                readOnly={disableImpianto} disabled={disableImpianto}
                                                />
                                                {validator.current.message('Superficie', Superficie, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtIndirizzo">Indirizzo *</Label>
                                                <Input type="text" name="txtIndirizzo" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('Indirizzo')}
                                                value={Indirizzo} onChange={handleIndirizzo}
                                                readOnly={disableImpianto} disabled={disableImpianto}
                                                />
                                                {validator.current.message('Indirizzo', Indirizzo, 'string|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtComune">Comune *</Label>
                                                <MyAutoComplete id="txtComune" name="txtComune" url={process.env.REACT_APP_API_URL+'comuni'} optionname="Comune" optionvalue="CodComune"
                                                onBlur={() => validator.current.showMessageFor('Comune')}
                                                customvalue={Comune} onChange={handleComune}
                                                readOnly={disableImpianto} disabled={disableImpianto}
                                                />
                                                {validator.current.message('Comune', Comune, 'string|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row> 
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    (Inviato === 0) && (
                                        <Toolbar>
                                            <Button className="btn-mbg-cert" onClick={handleOpenSelServizio}>Aggiungi Servizio</Button>
                                        </Toolbar>
                                    )
                                }
                                <MyAlertSelServizio message={MsgAlert} severity="warning" handleClose={onCloseAlertSelServizio} vertical="top" horizontal="center" open={OpenAlertSelServizio} />
                                <MyConfirmDelServizio title="Conferma Cancellazione" message={MsgConfirmDelServizio} handleClose={onCloseConfirmDelServizio} handleConfirm={onDelServizio} open={OpenConfirmDelServizio} />
                                <CreateTableServizi
                                    rows={RowsServizi}
                                    onDelete={toggleDelServizio}
                                    onChangeCostoTotale={ChangeCostoTotale}
                                    onChangeQuantita={onChangeQuantita}
                                    isAdmin={isAdmin}
                                    CostoImpianto={CostoImpianto}
                                    CostoImpiantoExp={CostoImpiantoExp}
                                    CostoSpesa={CostoSpesa}
                                    IDSpesa={IDSpesa}
                                    DescrizioneSpesa={DescrizioneSpesa}
                                    CostoPerKm={CostoPerKm}
                                    isInviato={Inviato}
                                    onRicalcola={Ricalcola}
                                    onChangeSconto={ChangeSconto}
                                    onChangeCostoSpesa={ChangeCostoSpesa}
                                    onChangeIva={ChangeIva}
                                    onChangeIvaSpesa={ChangeIvaSpesa}
                                    onChangeNumKm={ChangeNumKm}
                                    setSconto={forceSconto}
                                    setIva={forceIva}
                                    setIvaSpesa={forceIvaSpesa}
                                    setNumKm={forceNumKm}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={1}></Col>
                                        <Col md={11}>
                                            <FormGroup style={{textAlign:"left"}}>
                                                <div>&nbsp;</div>
                                                <Label>
                                                    <Input type="checkbox" name="chkVerificaMt" onChange={handleVerificaMt} checked={VerificaMt> 0} readOnly={Inviato===1} disabled={Inviato===1}/>{' '}
                                                    Verifica Messa a terra
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={1}></Col>
                                        <Col md={11}>
                                            <FormGroup style={{textAlign:"left"}}>
                                                <Label>
                                                    <Input type="checkbox" name="chkVerificaExp" onChange={handleVerificaExp} checked={VerificaExp> 0} readOnly={Inviato===1} disabled={Inviato===1}/>{' '}
                                                    Verifica Rischio Esplosione
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="txtNote">Note [max 200 caratteri]</Label>
                                                <Input type="textarea" name="txtNote" id="txtNote" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('Note')}
                                                    value={Note} onChange={handleNote} maxLength="200"
                                                />
                                                {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col xl={2} lg={2} md={3} xs={12}>
                                            <FormGroup>
                                                <Label for="txtNumPreventivo">N. Preventivo</Label>
                                                <Input type="text" name="txtNumPreventivo" autoComplete="new-password" placeholder=""
                                                    value={NumPreventivo} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={2} lg={2} md={3} xs={12}>
                                            <FormGroup>
                                                <Label for="txtDataPreventivo">Del</Label>
                                                <Input type="text" name="txtDataPreventivo" autoComplete="new-password"
                                                    value={DataPreventivo} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={1} lg={1} md={2} xs={12}>
                                            <FormGroup>
                                                <div>&nbsp;</div>
                                                <Label check style={{ fontSize: "18px" }}>
                                                    <Input type="checkbox" name="chkAnnulla" onChange={handleAnnulla} checked={Annulla > 0} />{' '}
                                                    Annulla
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        {
                                            (Inviato === 1) &&
                                            (
                                                <Col xl={2} lg={2} md={2} xs={12}>
                                                    <FormGroup>
                                                        <div>&nbsp;</div>
                                                        <Label check style={{ fontSize: "18px" }}>
                                                            <Input 
                                                                type="checkbox" 
                                                                name="chkApprova" 
                                                                onChange={handleApprova} 
                                                                checked={Approva > 0} 
                                                                readOnly={ApprovatoOld> 0} 
                                                                disabled={ApprovatoOld> 0}/>{' '}
                                                            Approva
                                                        </Label>
                                                    </FormGroup>
                                                </Col>)
                                        }
                                    </Row>
                                    <Row>
                                        <Col xl={5} lg={5} md={6} xs={12}>
                                            <FormGroup>
                                                <Label for="txtCommerciale">Commerciale *</Label>
                                                <MyAutoComplete id="txtCommerciale" name="txtCommerciale"
                                                    url={process.env.REACT_APP_API_URL + 'commercialesinglelist'}
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    customvalue={DesCommerciale} onChange={handleCommerciale}
                                                    onBlur={() => validator.current.showMessageFor('Commerciale')}
                                                    disabled={isAdmin === false} readOnly={isAdmin === false}
                                                />
                                                {validator.current.message('Commerciale', IdCommerciale, 'required|alpha_num', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
}
