import React, { useEffect,useState } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
      width: '30px',
      height: '30px',
      backgroundColor:'#FF0000',
  }
}));

export default function MyCountAvvisi(props) {
  const classes = useStyles();
  const [SecondForRetrieveAvvisi,setSecondForRetrieveAvvisi]=useState(0);
  const [CountAvvisi,setCountAvvisi]=useState(21);
  var [date,setDate] = useState(new Date());

useEffect(() => {
    var timer = setInterval(()=>setDate(new Date()), 1000 );
    return function cleanup() {
        clearInterval(timer)
    }
});

useEffect(() => {
    
    if ((SecondForRetrieveAvvisi + 1)>=parseFloat(process.env.REACT_APP_SECOND_RETRIEVE_AVVISI))
    {
        setSecondForRetrieveAvvisi(0);
        retrieveAvvisi();
    }
    else {
      setSecondForRetrieveAvvisi(SecondForRetrieveAvvisi+1);
    }
},[date]);

async function retrieveAvvisi()  {
  try {

    } catch(error) {
         
    }
}
if (CountAvvisi>0) {
  return (<Avatar className={classes.root}>{CountAvvisi}</Avatar>);
}
else 
  return (null);
}