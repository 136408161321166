import React, {useRef} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {FormGroup, Label,Col,Input,Row,Button} from "reactstrap";
import MyAlert from '../MyAlert';
import {GetMessage} from "../../Modules/Message";
import {GetToken} from "../../Modules/Funzioni";
import "../../Modules/Costanti";
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import '../../css/Form.css';
export default function InsServizioMt(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [KwMin, setKwMin] = React.useState("");
    const [KwMax, setKwMax] = React.useState("");
    const [Prezzo, setPrezzo] = React.useState("");
    const handleKwMin = (event) => {  
        setKwMin(event.target.value);  
    }; 
    const handleKwMax = (event) => {  
        setKwMax(event.target.value);  
    }; 
    const handlePrezzo = (event) => {  
        setPrezzo(event.target.value);  
    }; 
    
const validator = useRef(new SimpleReactValidator({
    messages: {
        default: 'obbligatorio o non corretto!'
      },
      validators: {
        cphone: {  // name the rule
          //message: 'obbligatorio o non corretto',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
          },
          //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          //required: true  // optional
        }
      }
}));  

const submitForm= (event) => 
{
    if (validator.current.allValid()) {
        setisSubmit(true);
        const fd = new FormData();
        fd.append("KwMin", KwMin);
        fd.append("KwMax", KwMax);
        fd.append("Prezzo", Prezzo);
        
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
            
        ax
        
        .post(process.env.REACT_APP_API_URL+'serviziomtinsert', fd,axiosConfig)
        .then((response) => {
            
            
            if (response.status === 200) {
                if (response.data.error==="OK"){
                    props.handleClose();
                    props.onInsert();
                }
                else {
                    let msg="";
                    let ris=JSON.stringify(response.data);
                    ris=ris.replaceAll("\"", "");
                    ris=ris.replaceAll("{", "");
                    ris=ris.replaceAll("}", "");
                    ris=ris.replaceAll("[", "");
                    ris=ris.replaceAll("]", "");
                    msg="errore durante l'inserimento! " + ris;
                    setSeverityAlert("error");
                    setMsgAlert(msg);
                    setOpenAlert(true);
                }
            }
            else
            { 
            const msg=GetMessage(response.status,response.data.message);
            setSeverityAlert("error")
            setMsgAlert(msg);
            setOpenAlert(true);
            }
        })
        .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setMsgAlert(msg);
                setOpenAlert(true);
            });
            setisSubmit(false);
    } else {
        validator.current.showMessages();
        setSeverityAlert("error")
        setMsgAlert(MsgAlertDefault);
        //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
        setOpenAlert(true);
    }
}

  return (
      <Dialog
        fullScreen={fullScreen}
        open={true} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Inserimento Listino Messa a Terra</DialogTitle>
        <DialogContent className="form-popup">
          <DialogContentText>
          <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/>     
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for="txtKwMin">DA KW *</Label>
                        <Input type="text" name="txtKwMin" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('KwMin')}
                        value={KwMin} onChange={handleKwMin}
                        />
                        {validator.current.message('KwMin', KwMin, 'required|numeric', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="txtKwMax">A KW (0 = infinito) *</Label>
                        <Input type="text" name="txtKwMax" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('KwMax')}
                        value={KwMax} onChange={handleKwMax}
                        />
                        {validator.current.message('KwMax', KwMax, 'required|numeric', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for="txtPrezzo">Prezzo *</Label>
                        <Input type="number" name="txtPrezzo" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('Prezzo')}
                        value={Prezzo} onChange={handlePrezzo}
                        />
                        {validator.current.message('Prezzo', Prezzo, 'required|numeric', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"50%"}}>
                <Button  onClick={submitForm} className="btn-mbg-cert btn-block">
                    Conferma {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                </Button>
            </div>
            <div style={{width:"50%"}}>
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
  );
}