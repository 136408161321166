import React from "react";
import { Redirect, Switch, Route} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Card from 'react-bootstrap/Card'
import ImgLogoCPServ from '../img/LogoCPService.png';
import ImgLogoCPCert from '../img/LogoCPCertificazioni.png';
import ImgLavoro from '../img/sicurezzaLavoro-150x150.jpg';
import ImgMessaTerra from '../img/TowerVoltage.jpg';
import { makeStyles } from '@material-ui/core/styles';
import { app_cpCert, app_cpService } from "../Modules/Costanti";
import {Ruolo_Azienda,Ruolo_Admin } from '../Modules/Costanti';
import {GetRole} from "../Modules/Funzioni";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        },
}));

export default function Welcome(props)  {
    const classes = useStyles();
    const [SelectedApp, setSelectedApp] = React.useState(0);
    const handleCard = (cardType) => {  
        setSelectedApp(cardType);  
        localStorage.setItem("app_selected",cardType);  
    };

    const token = localStorage.getItem("token");
    if (token==null || token ==="") {
        return <Redirect to="/login"/>;
    }
    const selApp = parseInt(process.env.REACT_APP_APPSELECT);
    
    if (selApp>0 && isNaN(selApp) ===false) {
        return <Redirect to="/home"/>;
    }
    let UserRole =GetRole(localStorage.getItem("token"));
        
    return (
        
        <div className="welcome-page-container">
            <Header/>
            <div className="page-container bg-form">
                <div className="custom-form" style={{textAlign:'center',fontFamily:"Source Sans Pro,Arial,sans-serif",fontSize:'1.3em',color:'#175489'}}><strong>Benvenuto sulla piattaforma dei servizi C&P</strong>,<br/>Seleziona in questa schermata l'applicazione a cui vuoi accedere.</div>
                <Grid container spacing={2} 
                justify="center"
                alignItems="center" 
                className="custom-form">
                    <Grid item xs={12} md={5} xl={3} lg={3}>
                        <Card className="welcome-card" style={{borderBottomRadius:"calc(.25rem - 1px)",borderColor:"#12235f",cursor:'pointer'}} onClick={() => handleCard(app_cpService)}>
                            <Card.Header style={{backgroundColor:"#12235f"}}>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title style={{textAlign:'center',font:'bold 26px garamond',color:'#12235f'}}><img src={ImgLogoCPServ} style={{maxWidth:"600px",margin:'5px'}}></img>
                                C&P Service</Card.Title>
                                <Card.Text>
                                    <div>Sicurezza sul Lavoro - D.Lgs. 81/08</div>
                                    <div style={{textAlign:'center'}}><img src={ImgLavoro} style={{maxWidth:"600px"}}></img></div>
                                </Card.Text>
                            </Card.Body>
                            <Card.Body>
                                <Card.Link onClick={() => handleCard(app_cpService)}>Apri</Card.Link>
                            </Card.Body>
                        </Card>
                    </Grid>
                    {
                    (parseFloat(UserRole)!==Ruolo_Azienda) &&
                    (<Grid item xs={12} md={5} xl={3} lg={3}>
                        <Card className="welcome-card" style={{borderBottomRadius:"calc(.25rem - 1px)",borderColor:"#296E9C",cursor:'pointer'}} onClick={() => handleCard(app_cpCert)}>
                            <Card.Header style={{backgroundColor:"#296E9C"}}>
                            </Card.Header>
                            <Card.Body>
                                <Card.Title style={{textAlign:'center',font:'bold 26px garamond',color:'#296E9C'}}><img style={{maxWidth:"600px",margin:'5px'}} src={ImgLogoCPCert}></img>
                                    C&P Certificazioni</Card.Title>
                                <Card.Text>
                                    <div>Impianti di messa a terra - D.P.R. 462/01</div>
                                    <div style={{textAlign:'center'}}><img src={ImgMessaTerra} style={{maxWidth:"600px"}}></img></div>
                                </Card.Text>
                            </Card.Body>
                            <Card.Body>
                                <Card.Link onClick={() => handleCard(app_cpCert)}>Apri</Card.Link>
                            </Card.Body>
                        </Card>
                    </Grid>)
                    }
                </Grid>
            </div>
            <Footer/>
        </div>
                
            
    );
}