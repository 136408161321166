import React, {useState,useRef} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {FormGroup, Label,Col,Input,Row,Button} from "reactstrap";
import MyAlert from '../MyAlert';
import {GetMessage} from "../../Modules/Message";
import {GetToken} from "../../Modules/Funzioni";
import "../../Modules/Costanti";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from 'simple-react-validator';
import '../../css/Form.css';
export default function ModServizioMt(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const MsgAlertDefault="Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [OrarioMin, setOrarioMin] = React.useState("");
    const [OrarioMax, setOrarioMax] = React.useState("");
    const [Prezzo, setPrezzo] = React.useState("");
    const handleOrarioMin = (event) => {  
        setOrarioMin(event.target.value);  
    };
    const handleOrarioMax = (event) => {  
        setOrarioMax(event.target.value);  
    }; 
    const handlePrezzo = (event) => {  
        setPrezzo(event.target.value);  
    }; 
    
  React.useEffect(() => {
    if (props.id!==""){
        LoadData();
    }
}, [props.id]);

const validator = useRef(new SimpleReactValidator({
    messages: {
        default: 'obbligatorio o non corretto!'
      },
      validators: {
        cphone: {  // name the rule
          //message: 'obbligatorio o non corretto',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
          },
          //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          //required: true  // optional
        }
      }
}));  

const LoadData = (event) => {
    if(props.id!==""){
        setIsLoading(true); 
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'servizioexp/' + props.id;               
                result = await ax.post(url);
                setOrarioMin(result.data[0].OrarioMin);
                setOrarioMax(result.data[0].OrarioMax);
                setPrezzo(result.data[0].Prezzo);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning")
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
        })();  
    }  
}

const submitForm= (event) => 
{
    if (validator.current.allValid()) {
        setisSubmit(true);
        const fd = new FormData();
        fd.append("id", props.id);
        fd.append("OrarioMin", OrarioMin);
        fd.append("OrarioMax", OrarioMax);
        fd.append("Prezzo", Prezzo);

        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
            
        ax
        
        .post(process.env.REACT_APP_API_URL+'servizioexpupdate', fd,axiosConfig)
        .then((response) => {
            
            
            if (response.status === 200) {
                if (response.data.error==="OK"){
                    props.handleClose();
                    props.onUpdate();
                }
                else {
                    let msg="";
                    let ris=JSON.stringify(response.data);
                    ris=ris.replaceAll("\"", "");
                    ris=ris.replaceAll("{", "");
                    ris=ris.replaceAll("}", "");
                    ris=ris.replaceAll("[", "");
                    ris=ris.replaceAll("]", "");
                    msg="errore durante la modifica! " + ris;
                    setSeverityAlert("error")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                }
            }
            else
            { 
            const msg=GetMessage(response.status,response.data.message);
            setSeverityAlert("error")
            setMsgAlert(msg);
            setOpenAlert(true);
            }
        })
        .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setMsgAlert(msg);
                setOpenAlert(true);
            });
            setisSubmit(false);
    } else {
        validator.current.showMessages();
        setSeverityAlert("error")
        setMsgAlert(MsgAlertDefault);
        //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
        setOpenAlert(true);
    }
}

  return (
      <Dialog
        fullScreen={fullScreen}
        open={true} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Modifica Listino Rischio Esplosione</DialogTitle>
        <DialogContent className="form-popup">
          <DialogContentText>
          <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/>     
          {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for="txtOrarioMin">DA N. ore *</Label>
                        <Input type="text" name="txtOrarioMin" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('OrarioMin')}
                        value={OrarioMin} onChange={handleOrarioMin}
                        />
                        {validator.current.message('OrarioMin', OrarioMin, 'required|numeric', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="txtOrarioMax">A N. ore (0 = infinito) *</Label>
                        <Input type="text" name="txtOrarioMax" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('OrarioMax')}
                        value={OrarioMax} onChange={handleOrarioMax}
                        />
                        {validator.current.message('OrarioMax', OrarioMax, 'required|numeric', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for="txtPrezzo">Prezzo *</Label>
                        <Input type="number" name="txtPrezzo" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('Prezzo')}
                        value={Prezzo} onChange={handlePrezzo}
                        />
                        {validator.current.message('Prezzo', Prezzo, 'required|numeric', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"50%"}}>
                <Button  onClick={submitForm} className="btn-mbg-cert btn-block">
                    Conferma {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                </Button>
            </div>
            <div style={{width:"50%"}}>
                    
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
  );
}