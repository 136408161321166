import React from 'react';
import { Button, Col, Row, Input, FormGroup, Label } from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DriveFileRenameOutlineOutlinedIcon from '@material-ui/icons/CreateOutlined';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css';
import { useState, useEffect } from 'react';
import { Toolbar } from '@material-ui/core';
import NewIspettore from './NewIspettore';
import ModIspettore from './ModIspettore';
import { GetIDIspettore, GetRole, GetToken } from "../../Modules/Funzioni";
import { GetMessage } from "../../Modules/Message";
import MyAlert from '../MyAlert';
import imgSearch from "../../img/search.png";
import MyIspettoreSign from './MyIspettoreSign';
import { Ruolo_Admin, Ruolo_CommTecnico, Ruolo_Ispettore } from '../../Modules/Costanti';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
    BtnEdit: {
        '&:hover': {
            cursor: "pointer"
        }
    }
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Avatar', string: false, disablePadding: true, label: '' },
    { id: 'AvatarSign', string: false, disablePadding: true, label: 'Firma' },
    { id: 'Cognome', string: false, disablePadding: true, label: 'Cognome' },
    { id: 'Nome', string: false, disablePadding: true, label: 'Nome' },
    { id: 'Cellulare', string: false, disablePadding: true, label: 'Cellulare' },
    { id: 'DesStrumento', string: false, disablePadding: true, label: 'Strumento' },
    { id: 'NumVerifiche', string: false, disablePadding: true, label: 'N.Verifiche' },
    { id: 'DataInserimentoCast', string: false, disablePadding: true, label: 'Inserito Il' },
];


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
export default function MatPaginationTable() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');

    const [RicCodFiscale, setRicCodFiscale] = React.useState("");
    const [RicCognome, setRicCognome] = React.useState("");
    const [orderBy, setOrderBy] = React.useState('Cognome');
    const [page, setPage] = React.useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [DisableFilter, setDisableFilter] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [dense, setDense] = React.useState(false);
    const [isOpenNewIspettore, setIsOpenNewIspettore] = useState(false);
    const [isOpenModIspettore, setIsOpenModIspettore] = useState(false);
    const [IdModIspettore, setIdModIspettore] = useState("");
    const [OpenSignIspettore, setOpenSignIspettore] = useState(false);
    const UserRole =GetRole(localStorage.getItem("token"));
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert, setMsgAlert] = React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //-----------
    const toggleNewIspettore = () => {
        setIsOpenNewIspettore(!isOpenNewIspettore);
    };
    const toggleModIspettore = (id) => {
        setIsOpenModIspettore(!isOpenModIspettore);
        if (!isOpenModIspettore) {
            setIdModIspettore(id);
        }
    };
    const onCloseSignIspettore = (event) => {
        setOpenSignIspettore(false);
    };

    const toggleOpenSignIspettore = (id) => {
        setOpenSignIspettore(true);
        setIdModIspettore(id);
    };
    const onSubmitSign = (_imgSign) => {
        const fd = new FormData();
        
        setIsLoading(true);
        if (_imgSign !== null) {

            fd.append("SignImage", _imgSign);
            fd.append("id", IdModIspettore);
            const token = GetToken(localStorage.getItem("token"));
            const ax = axios;
            ax.defaults.headers.common['Authorization'] = "Bearer " + token;
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            ax
                .post(process.env.REACT_APP_API_URL + 'ispettoresign', fd, axiosConfig)
                .then((response) => {

                    if (response.status === 200) {
                        if (response.data.error === "OK") {
                            setOpenSignIspettore(false);
                        }
                        else {
                            let msg = "";
                            let ris = JSON.stringify(response.data);
                            ris = ris.replaceAll("\"", "");
                            ris = ris.replaceAll("{", "");
                            ris = ris.replaceAll("}", "");
                            ris = ris.replaceAll("[", "");
                            ris = ris.replaceAll("]", "");
                            msg = "errore durante l'inserimento della firma! " + ris;
                            setMsgAlert(msg);
                            setOpenAlert(true);
                        }
                    }
                    else {
                        const msg = GetMessage(response.status, response.data.message);
                        setMsgAlert(msg);
                        setOpenAlert(true);

                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    let msg = ""
                    if (error.response !== undefined) {
                        msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
                    }
                    else {
                        msg = GetMessage(undefined, "");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);
                    setIsLoading(false);
                });


        }

    }
    useEffect(() => {
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const ax = axios;
        ax.defaults.headers.common['Authorization'] = "Bearer " + token;
        (async () => {
            let result = [];
            try {
                let url = process.env.REACT_APP_API_URL + 'ispettorelist'
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("CodFiscale", RicCodFiscale);
                fd.append("Cognome", RicCognome);
                if (UserRole===Ruolo_Ispettore || UserRole===Ruolo_CommTecnico){
                    let _idispettore=GetIDIspettore(localStorage.getItem("token"));
                    fd.append("Ispettore_id", _idispettore);
                }   

                //idazienda/0/codfiscale/0/cognome/0
                result = await ax.post(url, fd, axiosConfig);
            }
            catch (error) {
                let msg = ""
                if (error.response !== undefined) {
                    msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
                }
                else {
                    msg = GetMessage(undefined, "");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);
            if (result.data !== undefined) {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })();

    }, [isSending]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const onLoadData = () => {
        setIsSending(!isSending);
    };

    const onFilter = () => {
        setDisableFilter(true);
        setIsSending(!isSending);
        setPage(0);
    };

    const handleRicCodFiscale = (event) => {
        setRicCodFiscale(event.target.value);
        setDisableFilter(false);
    };

    const handleRicCognome = (event) => {
        setRicCognome(event.target.value);
        setDisableFilter(false);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    let ButtonClassName = "btn-mbg-cert";

    return (

        <div className={classes.root}>
            <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert} />
            {isLoading ? (<CircularProgress className="sp-center" />) : (<span></span>)}
            {isOpenModIspettore && <ModIspettore onUpdate={onLoadData} handleClose={toggleModIspettore} id={IdModIspettore} />}
            {isOpenNewIspettore && <NewIspettore onInsert={onLoadData} handleClose={toggleNewIspettore} />}
            <MyIspettoreSign open={OpenSignIspettore} handleClose={onCloseSignIspettore} handleConfirm={onSubmitSign} title="Deposito Firma Tecnico" />
            <div className="small" style={{ width: "98%" }}>
                <Row>
                    <Col md={5}>
                        <FormGroup>
                            <Label for="txtRicCognome">Cognome</Label>
                            <Input type="search" className="search" name="txtRicCognome" placeholder=""
                                value={RicCognome} onChange={handleRicCognome}
                                bsSize="sm" />
                            <img style={{ height: "15pt" }} src={imgSearch} className="input-icon" alt="" />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="txtRicCodFiscale">Cod. Fiscale</Label>
                            <Input type="search" className="search" name="txtRicCodFiscale" placeholder=""
                                value={RicCodFiscale} onChange={handleRicCodFiscale}
                                bsSize="sm" />
                            <img style={{ height: "15pt" }} src={imgSearch} className="input-icon" alt="" />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label>&nbsp;</Label>
                            <Button type="button" className={ButtonClassName + " btn-sm btn-block"} disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
            <div>&nbsp;</div>
            <Paper className={classes.paper}>
                <Toolbar>
                    {
                        UserRole===Ruolo_Admin && (<div style={{ width: "100%" }}><Button onClick={toggleNewIspettore} className={ButtonClassName}>Inserisci</Button></div>)
                    }
                    
                </Toolbar>
                <TableContainer>
                    <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />
                        <TableBody>
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={index}
                                            className="rt-tr-group"
                                        >
                                            <TableCell id={labelId} scope="row">
                                                {
                                                    UserRole===Ruolo_Admin && 
                                                    (<Avatar className={ButtonClassName + " " + classes.BtnEdit} onClick={() => toggleModIspettore(row.id)}>
                                                        <AssignmentIcon />
                                                    </Avatar>)
                                                }
                                                
                                            </TableCell>
                                            <TableCell id={labelId} scope="row">
                                                <Avatar className={row.Sign_Insert===1 ? (ButtonClassName):("btn-mbg1") + " " + classes.BtnEdit} onClick={() => toggleOpenSignIspettore(row.id)}>
                                                    <DriveFileRenameOutlineOutlinedIcon />
                                                </Avatar>
                                            </TableCell>
                                            <TableCell>{row.Cognome}</TableCell>
                                            <TableCell>{row.Nome}</TableCell>
                                            <TableCell>{row.Cellulare}</TableCell>
                                            <TableCell>{row.DesStrumento}</TableCell>
                                            <TableCell>{row.NumVerifiche}</TableCell>
                                            <TableCell>{row.DataInserimento}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
        </div>
    );
} 