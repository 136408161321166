import React, {useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import '../../css/Form.css';
import SignCanvas from 'react-signature-canvas'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DialogTitle} from "@material-ui/core";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

export default function MyIspettoreSign (props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [Signed, setSigned] = React.useState(false);
    const mySign=useRef({});

    const onConfirm = () => {
        props.handleConfirm(mySign.current.getTrimmedCanvas().toDataURL("image/png"));
    }
    
    const onClear = () => {
        setSigned(false);
        mySign.current.clear();
    }

    const onSign = () => {
        setSigned(true);
    }
    
  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle>
            <Typography variant="h6" className={classes.title}>
                {props.title}
            </Typography>
        </DialogTitle>
        <DialogContent className={classes.FormPopup} >
            <SignCanvas ref={mySign}  canvasProps={{className: 'signCanvas'}} onEnd={onSign} />
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg-cert btn-block" disabled={!Signed} readOnly={!Signed}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-mbg-gray btn-block">
                    Annulla
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button  onClick={onClear} className="btn-mbg1 btn-block" disabled={!Signed} readOnly={!Signed}>
                    Cancella 
                </Button>
            </div>            
        </DialogActions>
      </Dialog>
  );
}