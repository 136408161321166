import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Avatar from '@material-ui/core/Avatar';
import AssignmentIcon from '@material-ui/icons/Assignment';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Toolbar } from '@material-ui/core';
import NewAzienda from './NewAzienda';
import ModAzienda from './ModAzienda';
import MyAutoComplete from './MyAutoComplete';
import {GetMessage} from "../Modules/Message";
import {GetIDCommerciale, GetIDIspettore, GetRole, GetToken} from "../Modules/Funzioni";
import { app_cpService, Ruolo_Commerciale, Ruolo_CommTecnico, Ruolo_Ispettore } from "../Modules/Costanti";
import MyAlert from './MyAlert';
import ReactExport from "react-export-excel";
import imgSearch from "../img/search.png";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
      BtnEdit: {
        '&:hover':{
            cursor:"pointer"
      }  
    }
}));
  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Avatar', string: false, disablePadding: true, label: '' },
    { id: 'id', string: false, disablePadding: true, label: 'Cod.' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'PartitaIVA', string: false, disablePadding: true, label: 'Partita IVA' },
    { id: 'Email', string: false, disablePadding: true, label: 'Email' },
    { id: 'DataInserimentoCast', string: false, disablePadding: true, label: 'Inserito Il' },
];


function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
function ExportToExcel (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        <ExcelFile element={<Button className="btn-mbg">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Aziende">
                <ExcelColumn label="Ragione Sociale" value="RagioneSociale"/>
                <ExcelColumn label="Partita Iva" value="PartitaIva"/>
                <ExcelColumn label="Email" value="Email"/>
            </ExcelSheet>
        </ExcelFile>
    );
}
export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [RicTextLuogo, setRicTextLuogo] = React.useState("");
  const [order, setOrder] = React.useState('asc');
  const [RicRagioneSociale, setRicRagioneSociale] = React.useState("");
  const [RicResponsabile, setRicResponsabile] = React.useState("");
  const [RicIDResponsabile, setRicIDResponsabile] = React.useState("");
  const [RicPartitaIVA, setRicPartitaIVA] = React.useState("");
  const [orderBy, setOrderBy] = React.useState('RagioneSociale');
  const [page, setPage] = React.useState(0);  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(5);  
  const [dense, setDense] = React.useState(false);
  const [isOpenNewAzienda, setIsOpenNewAzienda] = useState(false);
  const [isOpenModAzienda, setIsOpenModAzienda] = useState(false);
  const [IdModAzienda, setIdModAzienda] = useState("");
  const [RicTipoLuogo, setRicTipoLuogo] = React.useState("0");
  const [RicLuogo, setRicLuogo] = React.useState("");
  const [RicOptionValue, setRicOptionValue] = useState("");
  const [RicOptionName, setRicOptionName] = useState("");
  const [RicUrlLuogo, setRicUrlLuogo] = useState(process.env.REACT_APP_API_URL+"comuni");
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
  //-----------
  const toggleNewAzienda = () => {
    setIsOpenNewAzienda(!isOpenNewAzienda);
  }
  const toggleModAzienda = (id) => {
    setIsOpenModAzienda(!isOpenModAzienda);
    if (!isOpenModAzienda){
        setIdModAzienda(id);
    }
  }

  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const IDIspettore = GetIDIspettore(localStorage.getItem("token"));
        const IDCommerciale = GetIDCommerciale(localStorage.getItem("token"));
        let UserRole =GetRole(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("RagioneSociale", RicRagioneSociale);
                fd.append("PartitaIVA", RicPartitaIVA);
                if (RicTipoLuogo==="0") {
                    fd.append("CodComune", RicLuogo);
                }
                else
                {
                    fd.append("CodProvincia", RicLuogo);
                }
                if (UserRole===Ruolo_Commerciale || UserRole===Ruolo_Ispettore || UserRole===Ruolo_CommTecnico){
                    if (parseFloat(IDCommerciale)>0){
                        fd.append("Commerciale_id", IDCommerciale); 
                    } 
                    if (parseFloat(IDIspettore)>0){
                        fd.append("Ispettore_id", IDIspettore);    
                    }  
                }
                fd.append("Responsabile_id", RicIDResponsabile);
                let url=process.env.REACT_APP_API_URL+'customerlist'
                
                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
            
        })(); 
        
}, [isSending]);   


  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
  
  const handleRicResponsabile = (value,text) => {
    setRicResponsabile(text);
    setRicIDResponsabile(value);;
    setDisableFilter(false);
};
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onLoadData = () => {
    setIsSending(!isSending);
}; 

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
    setPage(0);
}; 
const handleRicRagioneSociale = (event) => {
    setRicRagioneSociale(event.target.value);
    setDisableFilter(false);
};

const handleRicPartitaIVA = (event) => {
    setRicPartitaIVA(event.target.value);
    setDisableFilter(false);
};

const handleRicLuogo = (value,text) => {
    setRicTextLuogo(text);
    setRicLuogo(value);
    setDisableFilter(false);
};

const handleRicTipoLuogo = (event) => { 
    setRicTipoLuogo(event.target.value);
    
    setRicLuogo("");
    setRicTextLuogo("");
    if (event.target.value==="0"){
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"comuni");
        setRicOptionValue("CodComune");
        setRicOptionName("Comune");
    }
    else
    {
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"province");
        setRicOptionValue("CodProv");
        setRicOptionName("Provincia");
    }
    
    setDisableFilter(false);
};
        
   
const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
let ButtonClassName="";
const app_selected =process.env.REACT_APP_APPSELECT;
if (parseInt(app_selected)===app_cpService) {
    ButtonClassName="btn-mbg";
}
else{
    ButtonClassName ="btn-mbg-cert";
}
  return (  
    
    <div className={classes.root}>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>     
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        {isOpenModAzienda && <ModAzienda onUpdate={onLoadData} handleClose={toggleModAzienda} id={IdModAzienda}/>}
        {isOpenNewAzienda && <NewAzienda onInsert={onLoadData} handleClose={toggleNewAzienda}/>}
        <div className="small" style={{width:"98%"}}>
            <Row> 
                <Col md={4}>
                    <FormGroup>
                        <Label for="txtRicRagioneSociale">Ragione Sociale</Label>
                        <Input type="search" className="search" name="txtRicRagioneSociale" placeholder=""
                        value={RicRagioneSociale} onChange={handleRicRagioneSociale} bsSize="sm"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label for="txtRicPartitaIVA">Partita IVA</Label>
                        <Input type="search" className="search" name="txtRicPartitaIVA" placeholder=""
                        value={RicPartitaIVA} onChange={handleRicPartitaIVA}
                        bsSize="sm"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Row>
                           <Col md="6">
                                <label>
                                <input type="radio" name="radioRicLuogo" value="0" onClick={handleRicTipoLuogo} />
                                    &nbsp;Comune
                                </label>
                           </Col> 
                           <Col md="6">
                            <label>
                                <input type="radio" name="radioRicLuogo" value="1" onClick={handleRicTipoLuogo}/>
                                &nbsp;Provincia
                            </label>
                           </Col> 
                        </Row>
                        <MyAutoComplete id="txtRicLuogo" name="txtRicLuogo" 
                            url={RicUrlLuogo} 
                            optionname={RicOptionName} optionvalue={RicOptionValue}
                            customvalue={RicTextLuogo} 
                            onChange={handleRicLuogo} 
                            className="MuiAutocomplete-inputsm-search"
                        />
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                {
                    parseInt(app_selected)===app_cpService && (
                        <Col md={4}>
                            <FormGroup>
                                <Label for="txtRicLegRappresentante">Legale Rappresentante</Label>
                                <MyAutoComplete id="txtRicLegRappresentante" name="txtRicLegRappresentante" 
                                url={process.env.REACT_APP_API_URL+'responsabilesinglelist'} 
                                optionname="Descrizione" optionvalue="id"
                                customvalue={RicResponsabile} onChange={handleRicResponsabile} 
                                className="MuiAutocomplete-inputsm-search"/>
                                <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                            </FormGroup>
                        </Col>
                    )
                }
                <Col md={2}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className={ButtonClassName + " btn-sm btn-block"} disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4} lg={2} xl={2}><ExportToExcel data={data}/></Col>
            </Row>
        </div>
        <div>&nbsp;</div>
        <Paper className={classes.paper}> 
        <Toolbar>
        <div style={{width:"100%" }}><Button onClick={toggleNewAzienda} className={ButtonClassName}>Inserisci</Button></div>
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                                className="rt-tr-group"
                            >
                                <TableCell id={labelId} scope="row">
                                    <Avatar className={ButtonClassName + " " + classes.BtnEdit}  onClick={() => toggleModAzienda(row.id)}>
                                        <AssignmentIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell id={labelId} scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell>{row.RagioneSociale}</TableCell>
                                <TableCell>{row.PartitaIva}</TableCell>
                                <TableCell>{row.Email}</TableCell>
                                <TableCell>{row.DataInserimento}</TableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[5, 10, 15]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 