import React, {Component} from "react";
import "reactstrap";
import '../css/Footer.css';
export default class Footer extends Component {
    render() {
        return (
            <div className="main-footer">
                <div className="container-fluid">
                    <div className="row-cols-1">
                        <p className="col-sm">&copy;{new Date().getFullYear()} <a href="#top" className="czr">C&P
                            APP</a> – Tutti i diritti riservati | Termini del servizio | Privacy
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}