import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';  
import CheckCircleIcon from '@material-ui/icons/CheckCircle';  
import { red } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/DeleteForever';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Dialog, DialogTitle, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import MyAutoComplete from './MyAutoComplete';
import {GetMessage} from "../Modules/Message";
import {GetTextNotificationChannel, GetToken} from "../Modules/Funzioni";
import {Notification_Channel_WhatsApp, Notification_Direction_Inbound, Notification_Direction_Outbound, app_cpService} from "../Modules/Costanti";
import MyConfirm from './MyConfirm';
/*import { User_Azienda } from "../Modules/Costanti";*/
import MyAlert from './MyAlert';
import imgSearch from "../img/search.png";
import Avatar from '@material-ui/core/Avatar';
import ChatIcon from '@material-ui/icons/Chat';
import { MoreVertRounded } from '@material-ui/icons';
import ViewThreadNotifications from './ViewThreadNotifications';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
        marginTop: '10px',
        borderRadius: '5px'
    },
    button: {
        margin: theme.spacing(1),
        color: '#fff',
        backgroundColor: 'rgb(220, 0, 78)'
    },
    paper: {
        width: '98%',
        padding: '5px',
        marginBottom: theme.spacing(2),
    },
    avatar: {
        backgroundColor: red[500],
    },
    table: {
        minWidth: 750,
    },
    headerTitle: {
        fontWeight: 'bold'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    BtnEditThread: {
    backgroundColor:"#25d366 !important",
    color: '#fff',
        '&:hover':{
            cursor:"pointer"
        }
    },  
    BtnEditDelete: {
    backgroundColor:"#f50057 !important",
    color: '#fff',
        '&:hover':{
            cursor:"pointer"
        }
    }, 
    BtnEditOld: {
    backgroundColor:"#3f51b5 !important",
    color: '#fff',
        '&:hover':{
            cursor:"pointer"
        }  
    }
}));
  
OperationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isNew: PropTypes.number.isRequired,
    Channel: PropTypes.number.isRequired,
  };
  
  function OperationDialog(props) {
  const { classes, onClose, onSelected,  open , isNew , channel} = props;
    
    const handleClose = () => {
        onClose();
    };
    const handleListItemClick = (value) => {
        onSelected(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="operation-dialog-title" open={open}>
        <DialogTitle id="operation-dialog-title">Seleziona il tipo di operazione</DialogTitle>
        <List>
            {
                channel === Notification_Channel_WhatsApp && (
                <ListItem button onClick={() => handleListItemClick("ViewThread")}>
                <ListItemAvatar>
                    <Avatar className={classes.BtnEditThread}>
                        <ChatIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText>Visualizza Thread</ListItemText>
                </ListItem>
                )
            }
            {
                isNew === 1 && (
                    <ListItem button onClick={() => handleListItemClick("SetOnOld")}>
                        <ListItemAvatar>
                            <Avatar className={classes.BtnEditOld}>
                                <CheckCircleIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText>Segna come già letto</ListItemText>
                    </ListItem>

                )
            }
            <ListItem button onClick={() => handleListItemClick("Delete")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEditDelete}>
                    <DeleteIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Cancella</ListItemText>
            </ListItem>
        </List>
      </Dialog>
    );
  }

export default function MatPaginationTable() {  
  const classes = useStyles();  
  
  const [RicAzienda, setRicAzienda] = React.useState("");
  const [RicCellulare, setRicCellulare] = React.useState("");
  const [UserId, setUserId] = React.useState("");
  const [RicChannel, setRicChannel] = React.useState("");
  const [RicDirection, setRicDirection] = React.useState("");
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isOpenThread, setIsOpenThread] = useState(false);
  const [OpenOperDialog,setOpenOperDialog]=useState(false);
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
  //-----------
  //confirm object
  const MsgConfirm = "Si desidera procedere alla cancellazione della Notifica?";
  const [OpenConfirm, setOpenConfirm] = React.useState(false);
  const [ItemSelected, setItemSelected] = React.useState({});
  
const onCloseConfirm = (event) => {
    setOpenConfirm(false);
};

const onCloseThread = (event) => {
    toggleOpenThread();
};

const onSelectedItemOperDialog = (_keyValue) => {
    setOpenOperDialog(false);

    switch (_keyValue){
        case "ViewThread":
            {
                toggleOpenThread(ItemSelected.id);
                break;
            }
        case "SetOnOld":
            {
                onOld(ItemSelected.id);
                break;
            }
        case "Delete":
            {
                toggleDelNotification(ItemSelected.id);
                break;
            }
        
        default:

    }
};

const onCloseOperDialog = () => {
    setOpenOperDialog(false);
};

const onDelConfirm = (event) => {
    setOpenConfirm(false);
    setIsLoading(true);    
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    (async () => {
        let result=[];
        try{
            let url=process.env.REACT_APP_API_URL+'notificationdelete/' + ItemSelected.id;
                       
            result = await ax.post(url);
            normalizeData(ItemSelected.id, 0);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
        
    })(); 

};

const onOld = (_id) => {
    setOpenConfirm(false);
    setIsLoading(true);    
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    (async () => {
        let result=[];
        try{
            let url=process.env.REACT_APP_API_URL+'notificationsetold/' + _id;
                       
            //idazienda/0/codfiscale/0/cognome/0
            result = await ax.post(url);
            normalizeData(_id, 1);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
        
    })(); 

};

  const normalizeData = (_id , _operationType) => {
    const _data = data;

    switch (_operationType) {
        case 0: //delete
        {
            const index = _data.findIndex(e => e.id === _id);
            if (index > -1) {
                _data.splice(index, 1)
            }
            
            setData(_data);
            break;
        }
        case 1: //old
        {
            const result = _data.map(e => {
                return e.id === _id ? { ...e, IsNew: 0 } : e
            });
            setData(result);
        }
    }
  };

  const toggleOpenThread = (_id) => {
    setIsOpenThread(!isOpenThread);
    if (!isOpenThread){
        handleSelected(_id);
    }
  };

  const toggleDelNotification = (_id) => {
    handleSelected(_id)
    setOpenConfirm(true);
  };

  const handleSelected = (_id) => {
    const _item = data.filter(e => e.id === _id);
    
    if (_item.length === 1) 
    {
        setItemSelected(_item[0]);
    }
    else 
    {
        setItemSelected(null);
    }
  };

  async function RetrieveUser() {
    const token = GetToken(localStorage.getItem("token"));
    const ax = axios;
    ax.defaults.headers.common['Authorization'] = "Bearer " + token;
    let url=process.env.REACT_APP_API_URL+'me';
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    let result = await ax.post(url)
    return result;
}

  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                let _userId = UserId;
                if (UserId == "") {
                    const user = await RetrieveUser();
                    _userId = user.data.id;
                    setUserId(_userId)
                }
                let url=process.env.REACT_APP_API_URL+'notificationslist'
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("User_id", _userId);
                fd.append("Cellulare", RicCellulare);
                fd.append("Channel", RicChannel);
                fd.append("Direction", RicDirection);
                fd.append("RagioneSociale", RicAzienda);

                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })(); 
        
}, [isSending]);   

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
}; 
const handleRicAzienda = (value,text) => {
    setRicAzienda(text);
    setDisableFilter(false);
};

const handleRicChannel = (event) => {
    setRicChannel(event.target.value);
    setRicCellulare("");
    setRicDirection("");
    setDisableFilter(false);
};

const handleRicDirection = (event) => {
    setRicDirection(event.target.value);
    setDisableFilter(false);
};

const handleRicCellulare = (event) => {
    setRicCellulare(event.target.value);
    setDisableFilter(false);
};

const toggleOperDialog = (_Id) => {
    handleSelected(_Id);
    setOpenOperDialog(true);
};

let ButtonClassName="";
const app_selected =process.env.REACT_APP_APPSELECT;
if (parseInt(app_selected)===app_cpService) {
    ButtonClassName="btn-mbg";
}
else{
    ButtonClassName ="btn-mbg-cert";
}

  return (  
    
    <div className={classes.root}>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
        <MyConfirm title="Conferma Cancellazione" message={MsgConfirm} handleClose={onCloseConfirm} handleConfirm={onDelConfirm} open={OpenConfirm}/> 
        <OperationDialog classes={classes} onClose={onCloseOperDialog} onSelected={onSelectedItemOperDialog} open={OpenOperDialog} isNew={ItemSelected.IsNew !== undefined ? ItemSelected.IsNew : 0} channel={ItemSelected.Channel !== undefined ? ItemSelected.Channel : 1}/>         
        {isOpenThread && (<ViewThreadNotifications UserId = {UserId} onNormalizeData = {normalizeData} SelectedItem = {ItemSelected} handleClose = {onCloseThread}/>)}
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        <div className="small" style={{width:"98%"}}>
            <Row>
                <Col md={5}>
                    <FormGroup>
                        <Label for="txtRicAzienda">Azienda</Label>
                        <MyAutoComplete id="txtRicAzienda" name="txtRicAzienda" 
                        url={process.env.REACT_APP_API_URL+'customersinglelist'} 
                        optionname="RagioneSociale" optionvalue="id"
                        customvalue={RicAzienda} onChange={handleRicAzienda} 
                        className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label for="selRicChannel">Fonte</Label>
                        <Input type="select" className="search" name="selRicChannel" 
                        id="selRicChannel" placeholder="" bsSize="sm"
                            value={RicChannel} onChange={handleRicChannel}>
                                <option key="Tutti" value="">Tutti</option>
                                <option key="0" value="0">WhatsApp</option>
                                <option key="1" value="1">Memo</option>
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                {
                    parseInt(RicChannel) === Notification_Channel_WhatsApp && (
                    <>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="selRicDirection">Direzione</Label>
                                <Input type="select" className="search" name="selRicDirection" 
                                id="selRicDirection" placeholder="" bsSize="sm"
                                    value={RicDirection} onChange={handleRicDirection}>
                                        <option key="Tutti" value="">Tutti</option>
                                        <option key="0" value="0">Entrata</option>
                                        <option key="1" value="1">Uscita</option>
                                </Input>
                                <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="txtRicCellulare">Cellulare</Label>
                                <Input type="search" className="search" name="txtRicCellulare" placeholder="000-00000000"
                                value={RicCellulare} onChange={handleRicCellulare}
                                bsSize="sm"/>
                                <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                            </FormGroup>
                        </Col>
                    </>
                    )
                } 
                <Col md={1}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className={ButtonClassName + " btn-sm btn-block"} disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <Grid container spacing={3} className="custom-form">
            <Grid item xs={12}>
                {data.map((row, index) => {
                    return (
                        <Row key = {row.id}>
                            {
                                parseInt(row.Direction) === Notification_Direction_Outbound && (
                                    <Col xs={1}>
                                        &nbsp;
                                    </Col>
                                )
                            }
                            <Col>
                                <Card className={classes.root} style={parseInt(row.Direction) === Notification_Direction_Inbound ? parseInt(row.NotificationError) === 1 ? {backgroundColor: '#ffadad'} : {backgroundColor: '#fff'} : {backgroundColor: '#d9fdd3'}}>
                                    <CardHeader
                                        avatar={
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            {row.From.substring(0, 1).toUpperCase()}
                                        </Avatar>
                                        }
                                        action={
                                        <IconButton aria-label="settings" component="span" onClick={() => toggleOperDialog(row.id)}>
                                            <MoreVertRounded />
                                        </IconButton>
                                        }
                                        // titleTypographyProps={row.IsNew === 1 && {variant:'h5' }}
                                        classes={row.IsNew === 1 ? {
                                            title: classes.headerTitle
                                        } : null} 
                                        title={row.From}
                                        subheader={GetTextNotificationChannel(row.Channel) + ": " + row.DataInserimentoCast}
                                    />
                                    {/* <CardMedia
                                        className={classes.media}
                                        image="/static/images/cards/paella.jpg"
                                        title="Paella dish"
                                    /> */}
                                    <CardContent>
                                        <Typography style={{fontSize:'1.5em'}} color="textSecondary" component="p">
                                            {row.Message}
                                        </Typography>
                                    </CardContent>
                                    <CardActions 
                                        disableSpacing
                                        style={{ width: '100%', justifyContent: 'flex-end' }}
                                    >
                                        <IconButton aria-label="delete"  
                                            color="secondary"
                                            component="span"
                                            size="medium"
                                            onClick={() => toggleDelNotification(row.id)}
                                        >
                                            <DeleteIcon style={{minWidth : '1.3em', minHeight : '1.3em'}}/>
                                        </IconButton>
                                        <IconButton aria-label="set old"  
                                            color="primary"
                                            component="span"
                                            disabled = {row.IsNew !== 1}
                                            onClick={() => onOld(row.id)}
                                        >
                                            <CheckCircleIcon style={{minWidth : '1.3em', minHeight : '1.3em'}} />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            </Col>
                            {
                                parseInt(row.Direction) === Notification_Direction_Inbound && (
                                    <Col xs={1}>
                                        &nbsp;
                                    </Col>
                                )
                            }
                        </Row>
                    );
                })}
            </Grid>
        </Grid>
    </div>  
  );  
} 