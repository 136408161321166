import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Button} from "reactstrap";
import { useTheme } from '@material-ui/core/styles';
import '../css/Form.css';

export default function MyImageView(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
    fullScreen={fullScreen}
    open={props.open} 
    onClose={props.handleClose}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">{props.descFoto !=="" ? (props.descFoto):("Vedi Foto")}</DialogTitle>
    <DialogContent className="form-popup">
      <DialogContentText>    
        <img style={{width:"100%"}} src={props.pathPhoto} alt=""/>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
        <div style={{width:"100%"}}>
            <Button  onClick={props.handleClose} className="btn-mbg-cert btn-block">
                Chiudi
            </Button>
        </div>         
    </DialogActions>
  </Dialog>
  );
}